import produce from 'immer';

// UTILITY
import store from '@/utility/store';

import {
  getPromoDetails,
  getPromoCode,
  cleanPromoState,
  checkPromoAuthCode,
} from '../actions/promotion';

const promoCodes = store.get('promo_codes') || [];

const initialState = {
  promoCodes,
  data: {},
  loadings: {
    data: false,
    promocode: false,
    promoAuthCode: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET PROMO DETAILS
    case getPromoDetails.types.START:
      state.loadings.data = true;
      return state;

    case getPromoDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getPromoDetails.types.FAIL:
      state.loadings.data = false;
      return state;

    // GET PROMO CODE
    case getPromoCode.types.START:
      state.loadings.promocode = true;
      return state;

    case getPromoCode.types.SUCCESS:
      state.promoCodes = action.payload.promoCodes;
      state.data.user_promo_code = action.payload.data;
      state.loadings.promocode = false;
      return state;

    case getPromoCode.types.FAIL:
      state.data.promoAuthErrorMessage = action.payload.message;
      state.loadings.promocode = false;
      return state;

    // CHECK AUTH PROMO CODE
    case checkPromoAuthCode.types.START:
      state.loadings.promoAuthCode = true;
      return state;

    case checkPromoAuthCode.types.SUCCESS:
      state.loadings.promoAuthCode = false;
      return state;

    case checkPromoAuthCode.types.FAIL:
      state.data.promoAuthErrorMessage = action.payload.message;
      state.loadings.promoAuthCode = false;
      return state;

    // CLEAN STATE
    case cleanPromoState.types.INIT:
      state.data = initialState.data;
      return state;

    default:
      return state;
  }
});

export default reducer;
