import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import packageJson from '../../package.json';

const ignoreExtFilenames = [
  'static-tracking.klaviyo.com',
  'static.hotjar.com',
  'connect.facebook.net',
  'snap.licdn.com',
  'cdn.segment.com',
  'static.klaviyo.com',
  'googleads.g.doubleclick.net',
  'www.googletagmanager.com',
  'js-agent.newrelic.com',
];

const ignorePatterns = [
  /^ResizeObserver loop limit exceeded/,
  /^ResizeObserver loop completed with undelivered notifications./,
  /^Cannot read property 'default' of undefined./,
  /^Cannot read properties of undefined \(reading 'default'\)./,
  event => {
    const { stacktrace } = event.errors[0];
    const isCrashed = event.getMetadata('react', 'isCrashed');
    const shouldMarkAsInfo =
      !isCrashed &&
      stacktrace.some(stack => ignoreExtFilenames.some(filename => stack.file?.includes(filename)));

    if (shouldMarkAsInfo) {
      event.severity = 'info';
    }
  },
];

const bugsnagClient = Bugsnag.createClient({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.REACT_APP_ENV,
  appVersion: packageJson.version,
  enabledReleaseStages: ['production', 'staging', 'develop'],
  plugins: [new BugsnagPluginReact()],
  onError: event => {
    if (
      ignorePatterns.some(pattern => {
        return typeof pattern === 'function'
          ? pattern(event)
          : pattern.test(event.errors[0].errorMessage);
      })
    ) {
      return false;
    }
  },
});

export default bugsnagClient;
