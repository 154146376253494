import { createAction } from '@/utility/actions';

import {
  INTERNET_OFFERS_GET_DATA,
  INTERNET_OFFERS_GET_LIST,
  INTERNET_OFFERS_GET_LIST_BY_ID,
  INTERNET_OFFERS_SAVE_ANSWERS,
  INTERNET_OFFERS_CLEAN,
  INTERNET_OFFERS_SUBSCRIBE,
  INTERNET_OFFERS_CHECK_SUBSCRIPTION,
} from './types';

export const getInternetOffersData = createAction(INTERNET_OFFERS_GET_DATA);

/**
 * getInternetOffers - params: address
 */
export const getInternetOffers = createAction(INTERNET_OFFERS_GET_LIST);

/**
 * getInternetOffersById - params: offerId, params
 */
export const getInternetOffersById = createAction(INTERNET_OFFERS_GET_LIST_BY_ID);

/**
 * saveInternetOffersAnswers - params: offerId, data, callback
 */
export const saveInternetOffersAnswers = createAction(INTERNET_OFFERS_SAVE_ANSWERS);

export const cleanInternetOffers = createAction(INTERNET_OFFERS_CLEAN);

/**
 * subscribeToInternetOffers - params: email, internet_option_id
 */
export const subscribeToInternetOffers = createAction(INTERNET_OFFERS_SUBSCRIBE);

/**
 * checkInternetOffersSubscription - params: email, internet_option_id
 */
export const checkInternetOffersSubscription = createAction(INTERNET_OFFERS_CHECK_SUBSCRIPTION);

export default {
  getInternetOffersData,
  getInternetOffers,
  getInternetOffersById,
  saveInternetOffersAnswers,
  cleanInternetOffers,
  subscribeToInternetOffers,
  checkInternetOffersSubscription,
};
