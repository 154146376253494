import produce from 'immer';

import { getSkipQuestions, resetSkipQuestions, getUniqueId } from '../actions/wizard';

const initialState = {
  partner: {},
  partner_id: null,
  unique_id: null,
  skipQuestions: [],
  extraQuestions: [],
  requiredQuestions: [],
  question_email_required: false,
  loading: false,
  loadingUniqueId: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET SKIP QUESTIONS
    case getSkipQuestions.types.START:
      state.partner = initialState.partner;
      state.partner_id = initialState.partner_id;
      state.skipQuestions = initialState.skipQuestions;
      state.extraQuestions = initialState.extraQuestions;
      state.requiredQuestions = initialState.requiredQuestions;
      state.question_email_required = initialState.question_email_required;
      state.loading = true;
      return state;

    case getSkipQuestions.types.SUCCESS:
      state.partner = action.payload.partner;
      state.partner_id = action.payload.partner_id;
      state.skipQuestions = action.payload.skip;
      state.extraQuestions = action.payload.extra;
      state.requiredQuestions = action.payload.require;
      state.question_email_required = action.payload.question_email_required;
      state.loading = false;
      return state;

    case getSkipQuestions.types.FAIL:
      state.loading = false;
      return state;

    // GET UNIQUE ID
    case getUniqueId.types.START:
      state.unique_id = initialState.unique_id;
      state.loadingUniqueId = true;
      return state;

    case getUniqueId.types.SUCCESS:
      state.unique_id = action.payload.unique_id;
      state.loadingUniqueId = false;
      return state;

    case getUniqueId.types.FAIL:
      state.loadingUniqueId = false;
      return state;

    // RESET SKIP QUESTIONS
    case resetSkipQuestions.types.INIT:
      state.partner_id = initialState.partner_id;
      state.skipQuestions = initialState.skipQuestions;
      state.extraQuestions = initialState.extraQuestions;
      state.requiredQuestions = initialState.requiredQuestions;
      state.question_email_required = initialState.question_email_required;
      return state;

    default:
      return state;
  }
});

export default reducer;
