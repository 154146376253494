import MD5 from 'md5';
import localforage from 'localforage';
import { stringify } from 'query-string';

// UTILITY
import localStore from '@/utility/store';
import { localStorageAvailable } from '@/utility/common';

// STORE
import store from '@/store/configureStore';
import { AuthSelectors } from '@/store/selectors';

const initialDependencies = {
  url: '',
  token: '',
  apps: [],
  content: [],
  bingleList: [],
};

export function serializeCacheKey(request, dependencies = initialDependencies) {
  const token = localStore.get('token') || '';

  let apps = [];
  let content = [];
  let bingleList = [];
  let watchlists = [];

  if (token) {
    const state = store.getState();
    const user = AuthSelectors.getUser(state);
    let content_count = {};

    if (user) {
      content_count = AuthSelectors.getUserContentCount(user);
    } else {
      content_count = AuthSelectors.getCacheDependencies(state);
    }

    apps = content_count.app_ids || [];
    content =
      content_count.content_ids?.map(
        item => `${item.id}_${item.status}_${item.watchlist_ids.join('_')}`,
      ) || [];
    bingleList = content_count.binge_list_ids || [];
    watchlists = content_count.watchlists_ids || [];
  } else {
    const app_ids = localStore.get('app_ids') || [];

    apps = app_ids.sort((a, b) => a - b);
  }

  dependencies.url = request.url;
  dependencies.token = token;
  dependencies.apps = apps;
  dependencies.content = content;
  dependencies.bingleList = bingleList;
  dependencies.watchlists = watchlists;

  const stringifiedDependencies = stringify(
    { ...dependencies, ...request.params },
    {
      arrayFormat: 'comma',
      skipEmptyString: true,
    },
  );

  const hash = MD5(stringifiedDependencies);

  return hash;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split('; ');

  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase = `${encodeURIComponent(
        cookies[c].split(';')[0].split('=')[0],
      )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`;

      const p = window.location.pathname.split('/');

      document.cookie = `${cookieBase}/`;

      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }

      d.shift();
    }
  }
}

export function clearCache(reload = true, clearLocalStore = false) {
  try {
    const isLocalStorageAv = localStorageAvailable();

    if (isLocalStorageAv) localforage.clear();

    if (clearLocalStore) {
      localStore.clearAll();
    }

    deleteAllCookies();

    if (window.caches) {
      try {
        // Service worker cache should be cleared with window.caches.delete()
        window.caches.keys().then(names => {
          for (const name of names) window.caches.delete(name);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('window.caches not available');
      }
    }

    if (reload) {
      // delete browser cache and hard reload
      window.location.reload(true);
    }
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
}

export default serializeCacheKey;
