import styled from 'styled-components';

// CONSTANTS
import { SHAMROCK } from '@/constants/colors';

export const PoweredByContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  div {
    padding: 0 5px;

    &.poweredBy {
      font-size: 1.6rem;
      color: ${SHAMROCK};
      white-space: nowrap;
    }

    &.svgLogo img {
      width: auto;
      height: 35px;
      margin-top: 3px;
    }
  }
`;

export default PoweredByContainer;
