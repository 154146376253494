import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// UTILITY
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';

// STORE
import { WatchListActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// HOC
import CollapseHOC from '@/hoc/Collapse';

// COMPONENTS
import Loader from '@/components/Loader';

// STYLES
import { DialogStyled } from './styles';

const DialogAddToWatchlist = () => {
  const [t] = useTranslation();
  const [showCreateWL, setShowCreateWL] = useState(false);
  const formEl = useRef(null);

  const {
    addToWLDialogIsOpen,
    addToWLDialogProps,
    watchlists,
    loadingAction,
    loadingWatchlists,
    loadingCreate,
  } = useSelector(({ watchList }) => ({
    addToWLDialogIsOpen: watchList.addToWLDialogIsOpen,
    addToWLDialogProps: watchList.addToWLDialogProps,
    watchlists: watchList.watchlists,
    loadingAction: watchList.loadings.action,
    loadingWatchlists: watchList.loadings.watchlists,
    loadingCreate: watchList.loadings.create,
  }));

  const [
    AddToWLDialogClose,
    getWatchlists,
    addToWatchList,
    removeFromWatchList,
    createWatchlist,
  ] = useActions([
    dialogProps =>
      WatchListActions.manageAddToWLDialogState.action({ state: false, props: dialogProps }),
    onSuccess => WatchListActions.getWatchlists.action({ onSuccess }),
    (content_id, watch_lists, contentData, isShared, callback) => {
      return WatchListActions.addToWatchList.action({
        content_id,
        watch_lists,
        contentData,
        callback,
        isSharedList: isShared,
      });
    },
    (content_id, watch_lists, isShared, callback) => {
      return WatchListActions.removeFromWatchList.action({
        content_id,
        watch_lists,
        callback,
        isSharedList: isShared,
      });
    },
    (data, onSuccess) => WatchListActions.createWatchlist.action({ data, onSuccess }),
  ]);

  const contentData = addToWLDialogProps.data;
  const isSharedList = addToWLDialogProps.isSharedList;
  const addCallback = addToWLDialogProps.addCallback;
  const removeCallback = addToWLDialogProps.removeCallback;
  const createWLCallback = addToWLDialogProps.createWLCallback;
  const loading = loadingWatchlists || loadingCreate || loadingAction;

  function autoAddToWatchlist(wls) {
    if (!wls || !contentData) return;

    if (!contentData.in_watchlist) {
      const defWl = wls.find(wl => wl.default) || wls[0];

      addToWatchList(contentData.id, [defWl.id], contentData, isSharedList, addCallback);
    }
  }

  function handleDialogOpen() {
    const hasWatchlists = !!watchlists.length;

    if (!hasWatchlists) {
      getWatchlists(wls => {
        autoAddToWatchlist(wls);
      });
    } else {
      autoAddToWatchlist(watchlists);
    }
  }

  function handleDialogClose(e, reason) {
    if (reason === 'backdropClick' && !loading) return;

    AddToWLDialogClose();

    if (AddToWLDialogClose.onDialogClose) AddToWLDialogClose.onDialogClose();
  }

  function handleCreateListClick() {
    setShowCreateWL(!showCreateWL);

    if (showCreateWL) {
      const { resetForm } = formEl?.current;

      if (resetForm) resetForm();
    }
  }

  function handleWatchlistChange(e) {
    const isChecked = e.target.checked;
    const value = +e.target.value;

    if (isChecked) {
      addToWatchList(contentData.id, [value], contentData, isSharedList, addCallback);
    } else {
      removeFromWatchList(contentData.id, [value], isSharedList, removeCallback);
    }
  }

  function handleSubmitCreateWL(values, { setSubmitting, resetForm }) {
    createWatchlist({ title: values.name }, wl => {
      addToWatchList(contentData.id, [wl.id], contentData, isSharedList, addCallback);

      if (createWLCallback) createWLCallback();

      resetForm();
      setSubmitting(false);
    });
  }

  return (
    <DialogStyled
      className="add-to-wl"
      fullWidth
      maxWidth="xs"
      open={addToWLDialogIsOpen && !!contentData}
      onClose={handleDialogClose}
      disableEnforceFocus
      TransitionProps={{ onEnter: handleDialogOpen }}
    >
      <div className="add-to-wl__content">
        <Typography className="m-b-30" variant="h3">
          {t('addToWatchlistDialog.title')}
        </Typography>
        <ul className="add-to-wl__wl-list">
          {watchlists.map(watchlist => {
            const contentInWatchlist =
              contentData && !!contentData.watchlists.find(cWl => cWl.id === watchlist.id);

            return (
              <li key={watchlist.id}>
                <FormControlLabel
                  control={<Checkbox name="watchlist" value={watchlist.id} />}
                  checked={contentInWatchlist}
                  label={watchlist.title}
                  onChange={handleWatchlistChange}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="create-wl">
        <Button
          className="create-wl__btn grey"
          startIcon={<i className="icon-plus" />}
          fullWidth
          onClick={handleCreateListClick}
        >
          {t('buttons.createNewList')}
        </Button>
        <CollapseHOC showCollapse open={showCreateWL}>
          <Formik
            innerRef={formEl}
            initialValues={{ name: '', content: [contentData?.id || ''] }}
            validationSchema={setYupValidationSchema('createWlSchema', t)}
            onSubmit={handleSubmitCreateWL}
            enableReinitialize
            validateOnMount
          >
            {({ values, touched, errors, handleChange, handleBlur, isValid, isSubmitting }) => (
              <Form>
                <TextField
                  className="m-b-15"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  placeholder={t('inputs.enterName')}
                  minLength={1}
                  maxLength={255}
                  required
                />
                <div className="text-right">
                  <Button
                    type="submit"
                    className=""
                    color="primary"
                    size="small"
                    disabled={!isValid || isSubmitting}
                  >
                    {t('buttons.create')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </CollapseHOC>
      </div>
      <Loader active={loading} />
    </DialogStyled>
  );
};

export default React.memo(DialogAddToWatchlist);
