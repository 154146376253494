import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, MAIN_BLACK, BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

const centuryiframe = {
  primary: '#48D597',
  primaryButtonTextColor: '#000000',
  secondary: '#0047BB',
  buttonHoverBackground: '#EEEEEE',
  background: '#FFFFFF',
  inputBackground: '#FFFFFF',
  hoverPrimary: '#339469',
  secondarytextcolor: '#53565A',
  greybg: '#F2F2F2',
};
const centuryiframeStyles = theme => css`
  @font-face {
    font-family: 'MaisonNeue';
    src: local('MaisonNeue Light'),
      url(${`${process.env.PUBLIC_URL}/fonts/MaisonNeue/MaisonNeue-Light.woff`}) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: local('MaisonNeue Book'),
      url(${`${process.env.PUBLIC_URL}/fonts/MaisonNeue/MaisonNeue-Book.woff`}) format('woff');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: local('MaisonNeue Medium'),
      url(${`${process.env.PUBLIC_URL}/fonts/MaisonNeue/MaisonNeue-Medium.woff`}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: local('MaisonNeue Bold'),
      url(${`${process.env.PUBLIC_URL}/fonts/MaisonNeue/MaisonNeue-Bold.woff`}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: local('MaisonNeue ExtraBold'),
      url(${`${process.env.PUBLIC_URL}/fonts/MaisonNeue/MaisonNeue-ExtraBold.woff`}) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  body,
  #root.centuryiframe {
    background-color: ${centuryiframe.background};
    /* Other Custom */
    .content-hero {
      background: ${centuryiframe.greybg};
      &__title {
        color: ${theme?.primaryTextColor};
      }
    }
  }

  #root.centuryiframe {
    min-height: 300px;
  }
  /* Bottom Right Notification */
  .MuiSnackbar-anchorOriginBottomRight {
    [class*='MuiSnackbarContent-root'] {
      background-color: ${centuryiframe.primary};
      color: ${centuryiframe.primaryButtonTextColor};
      svg {
        color: ${centuryiframe.primaryButtonTextColor};
      }
    }
  }

  /* Popover Components */
  [class*='MuiPopover-root'] {
    &#menu-devices,
    &#menu-genre_search {
      /* Mui List option */
      [class*='MuiList-root'] {
        [class*='MuiListItem-root'] {
          &:hover {
            color: ${centuryiframe.primary};
          }
        }
        [class*='Mui-selected'] {
          color: ${centuryiframe.primary};
        }

        [class*='touchRipple'] {
          background-color: ${centuryiframe.primary};
        }
      }
    }
  }

  /* Mui Breadcrumbs */
  [class*='MuiBreadcrumbs-root'] {
    [class*='MuiLink-root'] {
      &:hover {
        color: ${centuryiframe.primary};
      }
    }
  }

  /* Mui Circular Progress */
  [class*='MuiCircularProgress'] {
    color: ${centuryiframe.primary};
  }

  .MuiPagination-ul {
    .MuiPaginationItem-textSecondary.Mui-selected {
      background: ${WHITE};
    }
    .MuiPaginationItem-textSecondary:hover {
      background: ${centuryiframe.primary};
    }
  }

  /* COMPONENTS */
  /* Footer */
  .footer-wrapper {
    .section {
      &--dark {
        background-color: ${BLACK};
      }
    }
  }

  /* PAGES */
  /* App Details page */
  .centuryiframe .app-details-page,
  .centurylink .app-details-page {
    .hero-container {
      .p1 {
        font-weight: 350;
      }
    }
    .page-content p {
      font-weight: 350;
    }
    .grid-item__back-to-results .MuiButton-containedSecondary {
      background-color: ${centuryiframe.buttonHoverBackground};
      border-radius: 0;
      font-size: 1.6rem;
      color: ${BLACK} !important;
      font-weight: bold;
      border: 2px solid ${centuryiframe.primary};
      padding: 17px 35px;
      &:hover {
        background-color: ${centuryiframe.primary};
      }
    }

    .back-to-results-wrapper {
      .MuiButton-outlined,
      .MuiButton-root,
      button[class*='MuiButtonBase-root'] {
        @media (max-width: ${breakpoints.xs}) {
          padding: 1.7rem 3.5rem !important;
        }
      }
      .MuiButton-contained,
      .MuiButton-outlinedPrimary {
        background-color: ${centuryiframe.buttonHoverBackground};
        border-radius: 0;
        font-size: 1.6rem;
        color: ${BLACK} !important;
        font-weight: bold;
        border: 2px solid ${centuryiframe.primary};
        padding: 17px 35px;
        &.MuiButton-containedPrimary {
          background-color: ${centuryiframe.primary};
        }
        &:hover {
          background-color: ${centuryiframe.primary};
        }
      }
    }
  }

  /* All pages */
  body .partner-centuryiframe.formpage,
  body .partner-centuryiframe.channelspage,
  body .partner-centuryiframe.resultspage,
  body .partner-centurylink.formpage,
  body .partner-centurylink.channelspage,
  body .partner-centurylink.resultspage {
    background-color: ${centuryiframe.background};
    font-family: 'MaisonNeue' !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
      font-family: 'MaisonNeue' !important;
    }

    /* MATERIAL UI STYLES */
    /* Mui Buttons */
    /* All Buttons */
    .MuiButton-outlined,
    .MuiButton-root,
    button[class*='MuiButtonBase-root'] {
      border-radius: 0;
      font-size: 1.6rem;
      color: ${BLACK} !important;
      font-weight: bold;
      font-family: 'MaisonNeue';
      border: 2px solid ${centuryiframe.primary};
      padding: 17px 35px;

      &[class*='Mui-disabled'] {
        color: ${rgba(BLACK, 0.26)};
        background-color: ${rgba(MAIN_BLACK, 0.12)};
      }

      @media (max-width: ${breakpoints.md}) {
        padding: 17px 25px;
      }

      @media (max-width: ${breakpoints.sm}) {
        padding: 17px 15px;
      }

      @media (max-width: ${breakpoints.xs}) {
        padding: 17px 0px;
      }
      &.internet-option__collapse-btn {
        padding: 15px 20px 15px 50px;
        border: none;
      }

      &.next-button,
      &.previous-button {
        padding: 1.3rem 2rem;
      }
    }

    /* Mui Button Contained */
    [class*='MuiButton-contained'] {
      &:not([class*='MuiButton-containedPrimary']):not(.previous-button) {
        background-color: ${centuryiframe.buttonHoverBackground};
        &:hover {
          background-color: ${centuryiframe.primary};
        }
      }
    }

    /* Mui Button Contained Primary */
    [class*='MuiButton-containedPrimary'],
    #root .post-page .primary button {
      background-color: ${centuryiframe.primary};
      &:hover {
        background-color: ${centuryiframe.buttonHoverBackground};
      }
    }

    /* Mui Button Outlined */
    [class*='MuiButton-outlined'] {
      background-color: ${centuryiframe.buttonHoverBackground};
      &:hover {
        background-color: ${centuryiframe.primary};
      }
    }

    /* Mui Button Outlined Primary */
    [class*='MuiButton-outlinedPrimary'],
    .header-menu-drawer-button {
      background-color: ${centuryiframe.buttonHoverBackground};
      &:hover {
        background-color: ${centuryiframe.primary};
      }
    }

    /* Mui Autocomplete */
    [class*='MuiAutocomplete-root'] {
      background-color: ${centuryiframe.inputBackground};

      [class*='MuiAutocomplete-endAdornment'] {
        width: auto;
        height: auto;
      }

      [class*='MuiAutocomplete-clearIndicator'] {
        padding: 4px;
      }
    }

    /* Mui Autocomplete option */
    [class*='MuiAutocomplete-option'] {
      [data-reason*='remove-option'] {
        color: ${centuryiframe.primary};
        .icon-check {
          color: ${centuryiframe.primary};
        }
      }
    }

    /* Mui Input */
    [class*='MuiInputBase-root'] {
      background-color: ${centuryiframe.inputBackground};

      &[class*='Mui-focused'] {
        [class*='MuiOutlinedInput'] {
          border-color: ${centuryiframe.primary};
        }
      }
    }

    /* Mui Chip */
    [class*='MuiChip-root'] {
      background-color: ${centuryiframe.buttonHoverBackground};
      border-radius: 0;
      font-weight: bold;
      border: 2px solid ${centuryiframe.primary};
      color: ${BLACK};

      [class*='MuiChip-deleteIconColorPrimary'] {
        color: ${BLACK};
      }

      &[class*='MuiChip-clickable'] {
        &:hover,
        &:focus {
          background-color: ${centuryiframe.primary};
        }
      }
    }

    /* Mui Form Label */
    [class*='MuiFormLabel-root'] {
      &[class*='Mui-focused'] {
        &:not([class*='Mui-error']) {
          color: ${centuryiframe.primary};
        }
      }
    }

    /* Mui Form Control */
    [class*='MuiFormControlLabel-root'] {
      /* Mui Checkbox */
      [class*='MuiCheckbox-root'] {
        .icon-correct-symbol {
          color: ${BLACK};
          border-color: ${centuryiframe.primary};
          &:before {
            background-color: ${centuryiframe.buttonHoverBackground};
          }
        }

        &[class*='Mui-checked'] {
          & + [class*='MuiFormControlLabel-label'] {
            color: ${centuryiframe.primary};
          }
          .icon-correct-symbol {
            color: ${centuryiframe.buttonHoverBackground};
            &:before {
              background-color: ${centuryiframe.primary};
            }
          }
        }
      }
    }

    /* Mui Rating */
    [class*='MuiRating-root'] {
      &[class*='MuiRating-readOnly'] {
        color: ${centuryiframe.primary};
      }
      &[class*='sizeLarge'] {
        [class*='MuiRating-icon'] {
          &[class*='MuiRating-iconFilled'] {
            background-color: ${centuryiframe.primary};
          }
        }
      }
    }

    /* Mui Tab */
    .MuiTab-root {
      color: ${BLACK};
      border: 2px solid ${centuryiframe.primary};
      background-color: ${centuryiframe.buttonHoverBackground};
      border-radius: 0;
    }
    .MuiTab-root.Mui-selected {
      background-color: ${centuryiframe.primary};
    }

    /* COMPONENTS */
    /* Marker */
    .marker--el,
    .marker:before {
      background-color: ${centuryiframe.primary};
    }

    /* Footer */
    footer {
      background-color: ${centuryiframe.background};

      .previous-button {
        background: none;
        color: ${centuryiframe.secondary} !important;
        font-size: 1.4rem;
        font-weight: 500;
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    /* Header Nav Button */
    .header-menu-drawer-button {
      width: 60px;
      height: 60px;
    }

    /* Info Card */
    .info-card {
      &:hover {
        .title {
          color: ${centuryiframe.primary};
        }
      }
    }

    /* footer links */
    [class*='MuiLink-root'] {
      &:hover {
        color: ${centuryiframe.primary};
      }
    }
  }

  /* Start of Centruy Link iFrame */
  .partner-centuryiframe.formpage,
  .partner-centurylink.formpage,
  .partner-centurylink.channelspage,
  .partner-centuryiframe.channelspage,
  .partner-centurylink.resultspage,
  .partner-centuryiframe.resultspage {
    form {
      h1 {
        font-size: 2.375rem;
        font-family: 'MaisonNeue' !important;
        font-weight: 500;
        color: ${BLACK};
      }

      p {
        font-size: 1.4rem;
        color: ${centuryiframe.secondarytextcolor};
        font-weight: 350;
      }
    }

    .backtohome {
      a {
        display: none !important;
      }
    }
    .internet-option__phone,
    .modal-item__phone {
      color: ${centuryiframe.primary};
    }

    .right-section {
      .stepper__dot--active {
        background-color: ${centuryiframe.primary};
        border-color: ${centuryiframe.primary};
      }

      .stepper__dot--complete {
        border-color: ${centuryiframe.primary};
      }

      .stepper {
        & > span {
          color: ${centuryiframe.primary};
        }
      }
    }

    .toolbar__link {
      padding: 10px 20px;
      &.nextlink {
        color: ${BLACK};
        background-color: ${centuryiframe.primary};
        padding: 11px 20px;
        border-radius: 0;

        &.Mui-disabled {
          color: ${rgba(MAIN_BLACK, 0.4)};
        }
      }
      &.prevlink {
        font-weight: 350;
        color: ${centuryiframe.secondary};
      }
    }
    .zipcodeinput {
      background-color: ${centuryiframe.inputBackground};

      > div {
        border-radius: 0;
      }

      .Mui-focused {
        color: ${centuryiframe.primary} !important;
        fieldset {
          border-color: ${centuryiframe.primary} !important;
        }
      }
    }
    .radio-card {
      background-color: ${centuryiframe.inputBackground};
      border-radius: 0 !important;

      &:hover {
        border-color: ${centuryiframe.primary} !important;
      }
      .radio-card__title {
        font-weight: 350 !important;
      }

      &.radio-card--checked {
        &:before {
          background-color: ${centuryiframe.primary};
        }

        .radio-card__title {
          color: ${centuryiframe.primary};
        }

        &:after {
          box-shadow: 0 0 0 2px ${centuryiframe.primary};
          border-radius: 0 !important;
        }
      }
    }

    .checkbox-card {
      background-color: ${centuryiframe.inputBackground};
      border-radius: 0 !important;

      &:hover {
        border-color: ${centuryiframe.primary} !important;
      }

      .checkbox-card__title {
        font-weight: 350 !important;
      }
      &.checkbox-card--checked {
        &:before {
          background-color: ${centuryiframe.primary};
        }

        .checkbox-card__title {
          color: ${centuryiframe.primary};
        }

        &:after {
          box-shadow: 0 0 0 2px ${centuryiframe.primary};
          border-radius: 0 !important;
        }
      }
    }

    .swiper-button-nav {
      background-color: ${centuryiframe.background} !important;

      &.swiper-button-prev:before {
        background: linear-gradient(
          90deg,
          ${rgba(centuryiframe.background, 1)} 0%,
          ${rgba(centuryiframe.background, 0)} 80%
        ) !important;
      }

      &.swiper-button-next:before {
        background: linear-gradient(
          90deg,
          ${rgba(centuryiframe.background, 0)} 0%,
          ${rgba(centuryiframe.background, 1)} 80%
        ) !important;
      }
    }

    //Channel Page
    &.channelspage {
      form > div:not(.page-content) {
        margin: 0 auto;
        padding: 67px 10px;
      }

      .channel-header {
        background-color: ${centuryiframe.background};

        .search-channels,
        .genre-select {
          background-color: ${centuryiframe.inputBackground};
        }

        .search-channels .Mui-focused {
          color: ${centuryiframe.primary};
          fieldset {
            border-color: ${centuryiframe.primary};
          }
        }

        .genre-select.Mui-focused {
          fieldset {
            border-color: ${centuryiframe.primary};
          }
        }
      }
    }

    //email page
    .email-address .m-b-30 {
      background-color: ${centuryiframe.inputBackground};

      .Mui-focused {
        color: ${centuryiframe.primary} !important;

        fieldset {
          border-color: ${centuryiframe.primary} !important;
        }
      }
    }

    .terms-wrapper {
      > span {
        background: ${centuryiframe.inputBackground};
      }
      .icon-correct-symbol:before {
        border: 2px solid ${centuryiframe.primary} !important;
      }
      .Mui-checked .icon-correct-symbol:before {
        background-color: ${centuryiframe.primary};
        border: 2px solid ${centuryiframe.primary} !important;
      }
      p.noselect {
        font-size: 1.2rem;
        a {
          color: ${centuryiframe.secondary};
        }
      }
    }
    //end email page

    &.resultspage {
      .streaming-section,
      .share-section {
        display: none !important;
      }
      & > .section--grey {
        background-color: ${centuryiframe.background};
        padding-bottom: 70px;
      }
      .p5 {
        font-weight: 350;
      }
      .internet-option {
        box-shadow: 2px 2px 2px ${rgba(BLACK, 0.4)};
        border: 2px solid ${centuryiframe.primary};
        border-radius: 0;
      }
      .bundle {
        box-shadow: 2px 2px 2px ${rgba(BLACK, 0.4)};
        border: 2px solid ${centuryiframe.primary};
        border-radius: 0;
        .bundle__description {
          font-weight: 350;
        }
        .service__cost {
          font-weight: 500;
        }
      }

      .learn-more-button {
        background-color: ${centuryiframe.primary};
        border-radius: 0;
        color: ${BLACK};
        font-weight: bold;
        border: 2px solid ${centuryiframe.primary};

        &:hover {
          background-color: ${centuryiframe.buttonHoverBackground};
        }
      }

      .channel-list h2 {
        color: ${centuryiframe.primary};
      }

      .channel-list-collapse__title-icon:before {
        color: ${centuryiframe.secondary};
      }

      .share__item a:hover {
        color: ${centuryiframe.primary} !important;
      }

      .share__item.share--copy-link:hover {
        color: ${centuryiframe.primary};
      }

      .edit-channels-link {
        background-color: ${centuryiframe.primary};
        border-radius: 0;
        color: ${BLACK};
        border: 2px solid ${centuryiframe.primary};
        &:hover {
          background-color: ${centuryiframe.buttonHoverBackground};
        }
      }

      .email-section__btn {
        background-color: ${centuryiframe.primary};

        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }

      .visit-site {
        background-color: ${centuryiframe.primary};
      }

      @media (max-width: ${breakpoints.xs}) {
        header.results {
          display: none !important;
        }

        .channel-list > div {
          box-shadow: none;
        }

        footer.app-bar--shadow {
          box-shadow: none;
          background-color: ${WHITE};

          .swiper-button-nav {
            background-color: ${WHITE} !important;

            &.swiper-button-prev:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 1)} 0%,
                ${rgba(WHITE, 0)} 80%
              ) !important;
            }

            &.swiper-button-next:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 0)} 0%,
                ${rgba(WHITE, 1)} 80%
              ) !important;
            }
          }
        }

        .channel-list__link {
          color: ${centuryiframe.primary};
        }
      }

      &__modal {
        background-color: transparent;

        .modal {
          /* styles for modal */
        }
      }

      .no-results {
        &__button {
          background-color: ${centuryiframe.primary};
          border-radius: 0;
          font-size: 1.6rem;
          color: ${BLACK};
          font-weight: bold;
          font-family: 'MaisonNeue';
          border: 2px solid ${centuryiframe.primary};

          &:hover {
            background-color: ${centuryiframe.buttonHoverBackground};
          }

          &.Mui-disabled {
            background-color: ${rgba(MAIN_BLACK, 0.12)};
          }
        }
      }
    }
    // end results page

    .success {
      i {
        color: ${centuryiframe.primary};
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${centuryiframe.primary} !important;
    }

    &.wizard-form {
      @media screen and (max-width: ${breakpoints.sm}) {
        header {
          top: auto;
          bottom: 0;
          position: fixed;
        }

        .right-section {
          > div {
            flex-wrap: nowrap;
            white-space: nowrap;
          }
        }
      }
    }

    /* Popover class */
    &.popover-wrapper {
      background-color: transparent;

      .popover-wrapper__dropdown-container {
        /* styles for dropdown container */
      }
    }

    /* Modal Content */
    .modal-content {
      .visit-site {
        background-color: ${centuryiframe.primary};
        border-radius: 0;
        color: ${BLACK};
        border: 2px solid ${centuryiframe.primary};
        padding: 1.3rem 2rem;
        &:hover {
          background-color: ${centuryiframe.buttonHoverBackground};
        }
      }
      .learnmore {
        background-color: ${centuryiframe.buttonHoverBackground};
        border-radius: 0;
        color: ${BLACK};
        padding: 1.3rem 2rem;
        border: 2px solid ${centuryiframe.primary};
        &:hover {
          background-color: ${centuryiframe.primary};
        }
      }
      .detail-item__title {
        .total,
        .servicename {
          color: ${centuryiframe.primary};
        }
      }
    }
  }

  .filters,
  .filters-dialog__content-filters {
    .collapsable__button:hover {
      color: inherit !important;
    }

    [class*='MuiFormControlLabel-root'] [class*='MuiCheckbox-root'] .icon-correct-symbol:before {
      background-color: transparent !important;
    }
  }
`;

export default centuryiframeStyles;
