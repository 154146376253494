import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// CONSTANTS
import { ROUTE_ROOT } from '@/constants/routes';
import { LOCATIONS_FOOTER } from '@/constants/locations';

// UTILITY
import { externalLink } from '@/utility/analytics';

// COMPONENTS
import NavLink from '@/components/NavLink';

// STYLES
import { CollapseTitleWrapper, ListWrapper, ListContainer, ListItem } from '../styles';

const expandStyles = { marginLeft: 5 };

const CollapseHOC = props => {
  const { children, showCollapse, open } = props;

  if (showCollapse) {
    return <Collapse in={open}>{children}</Collapse>;
  }

  return children;
};

const List = ({ title, list, showCollapse, t }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (showCollapse) {
      setOpen(!open);
    }
  };

  const handleExternalLink = ({ href }) => {
    const properties = {
      location: 'header',
      other: 'nav external link',
      href,
    };

    externalLink(properties);
  };

  const titleComponent = showCollapse ? (
    <CollapseTitleWrapper onClick={handleOpen}>
      <Typography variant="h5">{title}</Typography>
      {open ? <ExpandLess style={expandStyles} /> : <ExpandMore style={expandStyles} />}
    </CollapseTitleWrapper>
  ) : (
    <Typography variant="h5">{title} </Typography>
  );
  return (
    <ListWrapper>
      {titleComponent}
      <CollapseHOC showCollapse={showCollapse} open={open}>
        <ListContainer>
          {list.map((el, index) => {
            if (el.type === 'external') {
              return (
                <ListItem
                  className="p4"
                  key={index}
                  href={el.route}
                  location="footer"
                  target="_blank"
                  component={Link}
                  onClick={() => handleExternalLink({ href: el.route })}
                >
                  {t(el.label)}
                </ListItem>
              );
            }

            return (
              <ListItem
                className="p4"
                key={index}
                to={el.route}
                component={NavLink}
                end={el.route === ROUTE_ROOT}
                location={LOCATIONS_FOOTER}
              >
                {t(el.label)}
              </ListItem>
            );
          })}
        </ListContainer>
      </CollapseHOC>
    </ListWrapper>
  );
};

CollapseHOC.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  showCollapse: PropTypes.number,
};

List.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  showCollapse: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default React.memo(List);
