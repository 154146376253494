import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

// const centuryiframe = {
//   primary: '#48D597',
//   secondary: '#0047BB',
//   buttonHoverBackground: '#EEEEEE',
//   background: '#FFFFFF',
//   inputBackground: '#FFFFFF',
//   hoverPrimary: '#339469',
//   secondarytextcolor: '#53565A',
// };

const metronet = {
  primary: '#7ac342',
  secondary: '#246ba3',
  buttonHoverBackground: '#7AC142',
  background: '#f0f0f0',
  inputBackground: '#FFFFFF',
  hoverPrimary: '#339469',
  primaryTextColor: '#262626',
  secondarytextcolor: '#53565A',
  primaryFont: 'Titillium Web',
  secondaryFont: '',
};

const iframeStyles = css`
  /* Popover Components */
  /* Mui Popover */
  [class*='MuiPopover-root'] {
    [class*='MuiMenu-paper'] {
      max-height: 350px;
    }
  }

  /* MATERIAL UI STYLES */
  /* Mui Autocomplete Popper */
  [class*='MuiAutocomplete-popper'] {
    [class*='MuiAutocomplete-listbox'] {
      max-height: 350px;
    }
  }

  // IFRAME
  .iframe {
    .App {
      min-width: 300px;
    }
    .wizard-form {
      @media (max-width: ${breakpoints.xs}) {
        &.formpage .page-content {
          padding-bottom: 80px !important;
          padding-top: 24px !important;
        }
      }

      overflow: hidden;
      &--scroll {
        overflow-y: auto;
        max-height: 100vh;
      }

      &--iframe-footer {
        max-height: none !important;

        @media (max-width: ${breakpoints.xs}) {
          header {
            &.channels.channels-step1 {
              display: none;
            }
          }

          &.wizard-form.channelspage {
            .page-content {
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }
      }

      //iframe customizations:
      header {
        display: none;

        &:not(.fmb),
        &:not(.channels) {
          .toolbar__logos > div {
            display: none;
          }
        }

        @media (max-width: ${breakpoints.sm}) {
          display: inherit;
        }
      }

      &:not(.fmbpage),
      &:not(.channelspage) {
        .formpage .page-content {
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }

      .page-content {
        height: auto;
        > div {
          height: auto;
          min-height: auto;
        }
      }
      footer {
        position: relative;
        &.channels {
          position: fixed;
          bottom: 0;
        }

        @media (max-width: ${breakpoints.sm}) {
          &.fmb {
            position: fixed;
            bottom: 0;
          }
        }
      }
      form {
        height: auto;
      }

      @media screen and (max-width: ${breakpoints.sm}) {
        header {
          position: fixed;
          top: auto;

          &:not(.fmb),
          &:not(.channels) {
            bottom: 0;
          }
        }

        .right-section {
          > div {
            flex-wrap: nowrap;
            white-space: nowrap;
          }
        }
      }

      &.formpage {
        footer {
          > div > div {
            max-width: 990px;
            margin: 0 auto;
            div.left-side {
              display: none;
            }
            div.right-side {
              max-width: 100%;
              flex-basis: 100%;
              > div {
                justify-content: center;
              }
            }
          }
        }
      }

      &.channelspage {
        .page-content.mail-step {
          padding-bottom: 100px;
          > div {
            overflow: hidden;
          }
        }

        footer {
          > div {
            @media (max-width: ${breakpoints.xs}) {
              width: 100%;
              padding-left: 8px;
              padding-right: 8px;
              padding-top: 0;
            }

            max-width: 990px;
            margin: 0 auto;
          }
        }

        footer.channels-step1 {
          > div {
            width: 100%;

            > div {
              @media (max-width: ${breakpoints.xs}) {
                .channel-list__link {
                  display: none;
                }

                .right-side {
                  .previous-button__grid-item {
                    flex: 0;
                  }
                }
              }
            }
          }
        }

        @media (max-width: ${breakpoints.sm}) {
          header {
            .backtohome,
            .toolbar__logos {
              display: none;
            }
            .right-section {
              max-width: 100%;
              flex-basis: 100%;
              > div {
                justify-content: center;
              }
            }
          }

          .sticky-outer-wrapper.active {
            .sticky-inner-wrapper {
              margin-top: 0;
            }
          }
        }
      }
      &.resultspage {
        .section--grey {
          padding-bottom: 0;
        }
        footer {
          > div > div {
            max-width: 990px;
            margin: 0 auto;
          }
        }
      }
    }

    /* App Pages */
    &.centuryiframe,
    &.centurylink {
      .app-details-page {
        .social-wrapper,
        .other-apps-container,
        #ratings,
        .genre-wrapper {
          display: none;
        }
      }
    }
    &.metronet {
      .app-details-page {
        /* nav.hero-container__breadcrumbs, */
        .social-wrapper,
        .other-apps-container,
        #ratings,
        .grid-item__add-to-bundle-button,
        .genre-wrapper {
          display: none;
        }
        .MuiButton-outlined,
        .MuiButton-root {
          background-color: ${metronet.secondary};
          border-radius: 0;
          font-size: 1.6rem;
          color: ${WHITE};
          font-weight: bold;
          font-family: ${metronet.primaryFont};
          border: 2px solid ${metronet.secondary};
          padding: 17px 35px;

          &:hover {
            background-color: ${metronet.secondary};
          }
        }
        .grid-item__back-to-results {
          .MuiButton-outlined,
          .MuiButton-root {
            @media (max-width: ${breakpoints.xs}) {
              padding: 17px 0;
            }
          }
        }
        .MuiButton-containedPrimary {
          background-color: ${metronet.primary};
          border-radius: 0;
          font-size: 1.6rem;
          color: ${WHITE};
          font-weight: bold;
          font-family: ${metronet.primaryFont};
          border: 2px solid ${metronet.primary};
          &:hover {
            background-color: ${metronet.primary};
          }
        }
        .MuiTab-root {
          color: ${BLACK};
          border: 2px solid ${metronet.primary};
          background-color: ${metronet.buttonHoverBackground};
          border-radius: 0;
        }
        .MuiTab-root.Mui-selected {
          background-color: ${metronet.primary};
        }

        .hero-container {
          .p1 {
            font-weight: 350;
          }
        }
        .page-content p {
          font-weight: 350;
        }
        .gallery-wrapper {
          .MuiButton-root {
            background-color: ${rgba(metronet.primary, 0.8)};
            border-radius: 50%;
            color: ${WHITE};
            font-size: 24px;
          }
          .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail.active,
          .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:hover,
          .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:focus {
            border-color: ${metronet.primary};
          }
        }
      }
    }
    &.wow,
    &.pbb,
    &.point-broadband,
    &.centuryiframe,
    &.htc {
      .wizard-form--iframe-footer {
        form {
          .step {
            padding-top: 80px;
            padding-bottom: 120px;
          }
        }

        .channels-container {
          margin-bottom: 0;
        }
      }
    }
    .confirmation-wrapper {
      padding: 0;
    }

    &--small {
      &#root {
        min-height: auto;
      }
      .wizard-form {
        .page-content {
          padding-top: 0;
          padding-bottom: 30px;
        }

        .form-title {
          margin-bottom: 5px;

          h1 {
            font-size: 2rem;
            line-height: 2.5rem;
          }

          p {
            font-size: 1.6rem;
          }
        }
        .MuiGrid-grid-sm-6 {
          max-width: 25%;
          flex-basis: 25%;
          @media (max-width: 500px) {
            max-width: 50%;
            flex-basis: 50%;
          }
        }
        form .MuiGrid-grid-xs-12 {
          @media (max-width: 500px) {
            max-width: 50%;
            flex-basis: 50%;
          }
        }
        .zipcode-title {
          margin-bottom: 5px;
        }

        .radio-card {
          margin: 6px;
          height: 80px;

          .radio-card__title {
            font-size: 1.5rem;
            line-height: 1.8rem;
          }
        }
        .MuiGrid-grid-sm-4 .radio-card {
          height: 20px;
        }

        .checkbox-card {
          margin: 6px;
          height: 80px;
          .MuiGrid-grid-xs-12;
        }
      }
    }
  }

  .sticky-outer-wrapper.active {
    .sticky-inner-wrapper {
      @media (max-width: ${breakpoints.sm}) {
        margin-top: 0;
      }
    }
  }
`;

export default iframeStyles;
