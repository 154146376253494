import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// STORE
import { AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// COMPONENTS
import Loader from '@/components/Loader';
import Registration from '@/components/Auth/Registration';
import Logo from '@/components/Logo';
import Login from '@/components/Auth/Login';
import ForgotPassword from '@/components/Auth/ForgotPassword';
import UpdatePassword from '@/components/Auth/UpdatePassword';
import CommonDrawer from '@/components/Drawers/CommonDrawer';

// STYLES
import { DrawerContent, LogoContent } from './styles';

const AuthDrawer = () => {
  const [t] = useTranslation();
  const { authDrawerIsOpen, authDrawerProps, loading, partner } = useSelector(
    ({ auth, partner: partnerStore }) => ({
      authDrawerIsOpen: auth.authDrawerIsOpen,
      authDrawerProps: auth.authDrawerProps,
      loading: auth.loading,
      partner: partnerStore.data,
    }),
  );
  const [authDrawerClose] = useActions([AuthActions.authDrawerClose.action]);

  const [curScreen, setCurScreen] = useState(authDrawerProps.screen || 'login');
  const [screenProps, setScreenProps] = useState({});
  const drawerProps = { ...authDrawerProps, ...screenProps };

  useEffect(() => {
    if (authDrawerProps.screen) setCurScreen(authDrawerProps.screen);
  }, [authDrawerProps.screen]);

  function handleChangeScreen(screen, newProps) {
    setScreenProps(newProps || {});
    setCurScreen(screen);
  }

  function handleDrawerClose() {
    authDrawerClose();

    if (authDrawerProps.onDrawerClose) {
      authDrawerProps.onDrawerClose();
    }

    setTimeout(() => {
      handleChangeScreen('login');
    }, 100);
  }

  return (
    <CommonDrawer
      className="auth-drawer"
      open={authDrawerIsOpen}
      onClose={handleDrawerClose}
      title={`${t(`authDrawer.${curScreen}.header`)}`}
      ModalProps={{
        keepMounted: false,
      }}
    >
      {partner?.partner_slug && (
        <LogoContent className="m-t-20">
          <Logo linkProps={{ component: 'div' }} />
        </LogoContent>
      )}
      <DrawerContent>
        {curScreen === 'login' && (
          <Login
            data={{ ...drawerProps, description: partner?.text_overwrites?.auth_login }}
            onScreenChange={handleChangeScreen}
          />
        )}
        {curScreen === 'registration' && (
          <Registration
            data={{
              ...drawerProps,
              description:
                partner?.text_overwrites?.auth_description || t(`authDialog.description`),
            }}
            onScreenChange={handleChangeScreen}
          />
        )}
        {curScreen === 'forgot' && <ForgotPassword onScreenChange={handleChangeScreen} />}
        {curScreen === 'updatePassword' && <UpdatePassword />}
        <Loader active={loading} />
      </DrawerContent>
    </CommonDrawer>
  );
};

export default React.memo(AuthDrawer);
