export default {
  title: 'Profile Details',
  subscription: {
    email: 'Email: {{value}}',
    status: 'Status: {{value}}',
    start_date: 'Subscription start date: {{value}}',
    end_date: 'Subscription end date: {{value}}',
    cancel_date: 'Subscription cancel date: {{value}}',
    subscription_status: 'Subscription status: {{value}}',
    subscription_ended: 'Subscription ended: {{value}}',
    max_requests_error: 'Maximum requests exceeded. Try later',
  },
  creditCard: {
    title: 'Credit Card Info',
    modalTitle: 'New Card Setup',
    confirmationDesc:
      'All subscriptions are linked to this card, are you sure you want to change current card and link all subscriptions to new one?',
    setupDesc: 'Please add a new credit card',
  },
};
