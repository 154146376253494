import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';

const CollapseHOC = props => {
  const { children, showCollapse, open, ...restProps } = props;

  if (showCollapse) {
    return (
      <Collapse in={open} {...restProps}>
        {children}
      </Collapse>
    );
  }

  return children;
};

CollapseHOC.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool.isRequired,
  showCollapse: PropTypes.bool.isRequired,
};

export default CollapseHOC;
