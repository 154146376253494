import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, MAIN_BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

const wow = {
  primary: '#0065e3',
  yellow: '#FFC40C',
  primaryButtonTextColor: '#FFFFFF',
  primaryTextColor: '#000000',
  background: '#F2F2F2',
  inputBackground: '#FFFFFF',
  hoverPrimary: '#0065e3',
  footerBackground: '#000000',
  secondary: '#FFFFFF',
  secondaryButtonTextColor: '#2A2A35',
  secondaryBorderColor: '#2A2A35',
  roundCorners: 'false',
  primaryTextFont: 'Poppins',
  primaryOutlineBg: '#ffffff',
};

/*
  -Previous Button

*/
const wowStyles = css`
  /* ALL PAGES */
  body,
  #root.wow,
  #root.wow-csr {
    background-color: ${wow.background};
  }
  #root.wow,
  #root.wow-csr {
    min-height: 300px;
  }

  /* Bottom Right Notification */
  .MuiSnackbar-anchorOriginBottomRight {
    [class*='MuiSnackbarContent-root'] {
      background-color: ${wow.primary};
      color: ${wow.primaryButtonTextColor};
      svg {
        color: ${wow.primaryButtonTextColor};
      }
    }
  }

  .formpage,
  .channelspage {
    .MuiButton-root,
    button[class*='MuiButtonBase-root'] {
      font-size: 1.6rem;
      &.viewMoreChannels {
        background: none;
        color: ${wow.primary};
        border: none;
        font-size: 2rem;
      }
    }
  }

  /* App Details Page */
  .app-details-page {
    .hero-container {
      .p1 {
        font-weight: 350;
      }
      .grid-item__back-to-results {
        a {
          background-color: ${wow.secondary};
          border-color: ${wow.secondaryBorderColor};
          color: ${wow.secondaryButtonTextColor};
        }
      }
    }
    .page-content p {
      font-weight: 350;
    }
    .gallery-wrapper {
      .MuiButton-root {
        background-color: ${rgba(wow.primary, 0.8)};
        border-radius: 50%;
        color: ${wow.primaryButtonTextColor};
        font-size: 24px;
      }
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail.active,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:hover,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:focus {
        border-color: ${wow.primary};
      }
      .play-fake {
        background-color: ${rgba(wow.primary, 0.8)};
      }
    }

    #packages {
      .package-card {
        height: 100%;

        &--active {
          border-color: ${wow.primary};
        }

        .label {
          background-color: ${wow.primary};
        }

        a.MuiButton-contained:not(.MuiButton-containedPrimary) {
          border: 2px solid ${wow.secondaryBorderColor};

          background-color: ${wow.secondary};
          color: ${wow.secondaryButtonTextColor};
        }

        &:hover {
          .title {
            color: ${wow.primary};
          }
        }
      }
    }
    #deals {
      .MuiButton-outlinedPrimary {
        border-color: ${wow.primary};
        background-color: ${wow.primary};
        color: ${wow.primaryButtonTextColor};
      }
    }
    #ratings {
      .MuiRating-icon {
        border-color: ${wow.primary};
        .icon-star-solid:before {
          color: ${wow.primaryButtonTextColor};
        }
        &.MuiRating-iconFilled {
          .icon-star-solid:before {
            color: ${wow.primary};
          }
        }
      }
      .write-review {
        .MuiRating-icon {
          border-color: ${wow.primary};
          .icon-star-solid:before {
            color: ${wow.primary};
          }
          &.MuiRating-iconFilled {
            .icon-star-solid:before {
              color: ${wow.primaryButtonTextColor};
            }
          }
        }
      }
    }

    .MuiPagination-ul {
      [class*='MuiPaginationItem-root'] {
        color: ${wow.primary};
        background-color: ${wow.secondary};
      }
      .MuiPaginationItem-page {
        color: ${wow.primary};
        background-color: ${wow.secondary};
      }
    }
    #channels {
      .filter-wrapper {
        &__count-available {
          color: ${wow.primary};
        }
      }
    }
  }

  .discover-page {
    .collapsable-button {
      &.is-open {
        background-color: ${wow.primary};
        .icon-chevron-down:before {
          color: ${wow.primaryButtonTextColor};
        }
      }
    }
    .MuiPagination-ul {
      svg {
        color: ${wow.primary} !important;
      }
      button:hover svg {
        color: ${wow.primaryButtonTextColor} !important;
      }
      .MuiPaginationItem-page {
      }
    }
    .MuiAppBar-root button[class*='MuiButtonBase-root']:hover {
      color: ${wow.primary};
    }
    .marker--el,
    .marker:before {
      background-color: ${wow.primary};
      color: ${wow.primaryButtonTextColor};
    }
  }

  .partner-wow.wizard-form,
  .partner-wow-csr.wizard-form {
    background-color: ${wow.background};

    &.popover-wrapper {
      background-color: transparent;
    }

    header {
      .toolbar__link.nextlink {
        border-radius: 0;
        padding: 11px 20px;
        background-color: ${wow.primary};
        color: ${wow.primaryButtonTextColor};
        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }
    }

    footer {
      background-color: ${wow.background};
      .next-button {
        background-color: ${wow.primary};
        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }
    }

    .backtohome {
      a {
        color: ${wow.primary};
      }
      &:after {
        //  content: 'Powered By MyBundle.TV';
      }
    }
    .internet-option {
      &__phone,
      .modal-item__phone {
        color: ${wow.primary};
      }
      &__main-info {
        .internet-option__title {
          font-size: 2.3rem;
          line-height: 2.3rem;
        }
        .internet-option__description {
          color: ${MAIN_BLACK};
        }
      }
      &__right {
        min-width: 27%;
        @media (max-width: ${breakpoints.xs}) {
          width: 100%;
        }
        a {
          width: 100%;
        }
      }
    }
    .previous-button {
      background: ${wow.primaryOutlineBg};
      color: ${wow.primary};
    }

    .right-section {
      .stepper__dot--active {
        background-color: ${wow.primary};
        border-color: ${wow.primary};
      }
      .stepper__dot--complete {
        border-color: ${wow.primary};
      }
      .stepper {
        & > span {
          color: ${wow.primary};
        }
      }
      @media (max-width: ${breakpoints.sm}) {
        > div > div {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
    .toolbar__link {
      padding: 10px 20px;
      &.nextlink {
        color: ${wow.primaryButtonTextColor};
        background-color: ${wow.primary};
        padding: 11px 20px;
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
        &.Mui-disabled {
          color: ${rgba(MAIN_BLACK, 0.4)};
        }
      }
      &.prevlink {
        font-weight: 350;
        color: ${wow.primaryTextColor};
      }
    }
    .zipcodeinput {
      background-color: ${wow.inputBackground};
      > div {
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
      }

      .Mui-focused {
        color: ${wow.primary} !important;
        fieldset {
          border-color: ${wow.primary} !important;
        }
      }
    }
    .radio-card {
      background-color: ${wow.inputBackground};
      &:hover {
        border-color: ${wow.primary} !important;
      }
      &.radio-card--checked {
        &:before {
          background-color: ${wow.primary};
        }
        .radio-card__title {
          color: ${wow.primary} !important;
        }
        &:after {
          box-shadow: 0 0 0 2px ${wow.primary};
        }
      }
    }
    .checkbox-card {
      background-color: ${wow.inputBackground};
      &:hover {
        border-color: ${wow.primary} !important;
      }
      &.checkbox-card--checked {
        &:before {
          background-color: ${wow.primary} !important;
        }
        .checkbox-card__title {
          color: ${wow.primary} !important;
        }
        &:after {
          box-shadow: 0 0 0 2px ${wow.primary};
        }
      }
    }
    .swiper-button-nav {
      background-color: ${wow.background} !important;

      &.swiper-button-prev:before {
        background: linear-gradient(
          90deg,
          ${rgba(wow.background, 1)} 0%,
          ${rgba(wow.background, 0)} 80%
        ) !important;
      }

      &.swiper-button-next:before {
        background: linear-gradient(
          90deg,
          ${rgba(wow.background, 0)} 0%,
          ${rgba(wow.background, 1)} 80%
        ) !important;
      }
    }

    .zipcode__submit {
      background-color: ${wow.primary};
      &:hover {
        background-color: ${wow.hoverPrimary};
      }
      &.Mui-disabled {
        background-color: ${rgba(MAIN_BLACK, 0.12)};
      }
    }
    //Channel Page
    &.channelspage {
      form > div:not(.page-content) {
        margin: 0 auto;
        padding: 80px 0;
      }

      h6 {
        font-size: 2.4rem;
        line-height: 2.6rem;
      }

      .channel-header {
        background-color: ${wow.background};
        .search-channels,
        .genre-select {
          background-color: ${wow.inputBackground};
        }
        .search-channels .Mui-focused {
          color: ${wow.primary};
          fieldset {
            border-color: ${wow.primary};
          }
        }
        .genre-select.Mui-focused {
          fieldset {
            border-color: ${wow.primary};
          }
        }
      }

      .channels-zipcode {
        &__submit {
          background-color: ${wow.primary};
          &.Mui-disabled {
            background-color: ${rgba(MAIN_BLACK, 0.12)};
          }
        }
      }

      .channel-list > span {
        color: ${wow.primaryTextColor};
      }

      .channel-list > a {
        color: ${wow.primary};
        text-decoration: underline;
      }
    }
    //email page
    .email-address .m-b-30 {
      background-color: ${wow.inputBackground};
      .Mui-focused {
        color: ${wow.primary} !important;
        fieldset {
          border-color: ${wow.primary} !important;
        }
      }
    }
    .terms-wrapper {
      > span {
        background: ${wow.inputBackground};
      }
      .MuiCheckbox-root {
        border-radius: 3px;

        .icon-correct-symbol {
          &:before {
            background-color: ${wow.buttonHoverBackground};
            border-color: ${wow.primary};
          }
        }

        &.Mui-checked {
          .icon-correct-symbol {
            &:before {
              background-color: ${wow.primary};
              border: 1px solid ${wow.primary};
            }
          }
        }
      }
      p.noselect {
        a {
          color: ${wow.primary};
        }
      }
    }
    //end email page

    &.resultspage {
      .streaming-section,
      .share-section {
        display: none !important;
      }
      & > .section--grey {
        background-color: ${wow.background};
        padding-bottom: 0;
      }

      .bundle {
        height: 100%;
      }

      .marker--el,
      .marker:before {
        background-color: ${wow.primary};
      }
      .learn-more-button {
        background-color: ${wow.primary};
        font-weight: bold;
        color: ${wow.primaryButtonTextColor};

        &:hover {
          background-color: ${wow.hoverPrimary};
        }
      }
      .streaming-marketplace-button {
        color: ${wow.primary};
        border-color: ${wow.primary};
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
        &:hover {
          color: ${WHITE};
          background-color: ${wow.primary};
        }
      }
      .channel-list h2 {
        color: ${wow.primary};
      }
      .channel-list-collapse__title-icon:before {
        color: ${wow.primaryTextColor};
      }

      .share__item a:hover {
        color: ${wow.primary} !important;
      }
      .share__item.share--copy-link:hover {
        color: ${wow.primary};
      }
      .edit-channels-link {
        background-color: ${wow.primary};
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
        font-weight: bold;
        color: ${wow.primaryButtonTextColor};
      }
      .email-section__btn {
        background-color: ${wow.primary};
        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }
      .visit-site {
        background-color: ${wow.primary};
        color: ${wow.primaryButtonTextColor};
      }
      @media (max-width: ${breakpoints.xs}) {
        header.results {
          display: none !important;
        }
        .channel-list > div {
          box-shadow: none;
        }
        footer.app-bar--shadow {
          box-shadow: none;
          background-color: ${WHITE};
          .swiper-button-nav {
            background-color: ${WHITE} !important;

            &.swiper-button-prev:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 1)} 0%,
                ${rgba(WHITE, 0)} 80%
              ) !important;
            }

            &.swiper-button-next:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 0)} 0%,
                ${rgba(WHITE, 1)} 80%
              ) !important;
            }
          }
        }
        .channel-list__link {
          color: ${wow.primary};
        }
      }
      &__modal {
        background-color: transparent;
      }
      .no-results {
        &__button {
          background-color: ${wow.primary};
          ${wow.roundCorners === 'false' && 'border-radius: 0'};
          color: ${wow.primaryButtonTextColor};
          font-weight: bold;
          &:hover {
            background-color: ${wow.buttonHoverBackground};
          }
          &.Mui-disabled {
            background-color: ${rgba(MAIN_BLACK, 0.12)};
          }
        }
      }
    }
    // end results page

    .success {
      i {
        color: ${wow.primary};
      }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${wow.primary} !important;
    }

    /* Modal content */
    .modal-content {
      .visit-site {
        background-color: ${wow.primary};
        color: ${wow.primaryButtonTextColor};
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
        border: 2px solid ${wow.primaryButtonTextColor};
        font-weight: bold;
        padding: 1.3rem 2rem;
      }
      .learnmore {
        background-color: ${wow.yellow};
        ${wow.roundCorners === 'false' && 'border-radius: 0'};
        padding: 1.3rem 2rem;
        font-weight: bold;
        border: none;
        &:hover {
          background-color: ${wow.yellow};
        }
      }
      .detail-item__title {
        .total,
        .servicename {
          color: ${wow.primary};
        }
      }
    }

    /* ZipCode step */
    .zipcode-title {
      p {
        max-width: 650px;
        margin: 0 auto;
      }
    }
  }
`;

export default wowStyles;
