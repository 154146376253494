export default {
  hero: {
    extra_title: 'Offer Ended December 31, 2023',
    title:
      'Get Sling from <span>MyBundle</span> and \n Get a FREE AirTV 2 + FREE HD Antenna \n for FREE Local Channels',
    description1:
      'Don’t miss this MyBundle exclusive! Subscribe to Sling through MyBundle, and Sling will send you an AirTV 2 plus an HD antenna absolutely FREE!',
    description2:
      'Seamlessly integrate free local channels alongside your favorite Sling channels for all-in-one access, and connect one HD antenna to all of your streaming network connected devices via WiFi using the Sling App on your favorite streaming devices like Roku, FireTV, Samsung Smart TVs and others.',
  },
  offer_details: {
    title: 'Exclusive mybundle offer details',
    description1:
      'This is a $180 value available only through MyBundle and there’s no shipping costs, no contracts, and no fees. What do you have to lose? If you change your mind, you can cancel Sling after one month, keep all the equipment, and still receive the free local channels. But we don’t think that will happen. Why? Because Sling offers top networks with the shows you’re craving.',
    description2:
      'Get Sling Blue for the top entertainment channels, or Sling Orange for more sports and family content like ESPN and Disney. Combine them all with your new HD Antenna for an experience similar to traditional cable.',
    watch_video: 'Watch Video: AirTV - What is AirTV?',
  },
  benefits: {
    benefit1: 'FREE Shipping',
    benefit2: 'FREE AirTV 2 and \n HD Antenna',
    benefit3: 'No Contracts \n No Obligations',
  },
  package: {
    addons: 'Add-ons',
  },
  howItWorks: {
    title: 'How it Works',
    watch_video: 'Watch Video: AirTV Set Up',
    desc1_header: 'Step 1',
    description1: 'Sign up for <span>Sling</span> through MyBundle',
    desc2_header: 'Step 2',
    description2:
      'Send us your <span>address.</span> \n You’ll receive an email after signing up - let us know where you want your free AirTV 2 and HD Antenna sent.',
    desc3_header: 'Step 3',
    description3:
      'When your <span>equipment</span> arrives in 10-14 business days, install your HD Antenna and AirTV 2 and enjoy!',
  },
  channels: {
    desc: '*Free Local Channel availability varies based on location.',
  },
  airTvDevices: {
    title: 'AirTV 2 connects with your favorite streaming devices',
    subtitle:
      'Download the app for the device you need to begin your streaming experience. \n Access your local channels through the Sling app.',
    logosDesc:
      'Roku is a registered trademark of /Roku, Inc. Android is a trademark of Google LLC. \n Amazon logo and Fire are trademarks of Amazon.com, Inc.',
  },
};
