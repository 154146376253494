import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import { GET_PAGES_CONTENT } from '@/constants/api';

// UTILITY
import { getLink } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { PagesActions } from '@/store/actions';

const { getPagesContent } = PagesActions;

export function* getPagesContentSaga(action) {
  yield put(getPagesContent.start());

  const { pageSlug } = action.payload;
  const url = getLink(GET_PAGES_CONTENT, { page: pageSlug });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(
      getPagesContent.success({
        data: respData.static,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getPagesContent.fail());
  }
}

export default getPagesContentSaga;
