import produce from 'immer';

import {
  getAppDetails,
  getAppChannels,
  getAppReviews,
  addAppReview,
  cleanAppDetails,
  appListUpdateStatus,
  appListUpdateAppStatus,
  appListRemoveStatus,
} from '../actions/appDetails';

const initialState = {
  data: null,
  channelsData: null,
  reviews: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 3,
    },
  },
  loadings: {
    data: true,
    channelsData: true,
    reviews: false,
    addReview: false,
    statusesLoading: false,
    statusesLoadings: {},
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET APP DETAILS
    case getAppDetails.types.START:
      state.loadings.data = true;
      return state;

    case getAppDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getAppDetails.types.FAIL:
      state.loadings.data = false;
      return state;

    // GET APP CHANNELS
    case getAppChannels.types.START:
      state.loadings.channelsData = true;
      return state;

    case getAppChannels.types.SUCCESS:
      state.channelsData = action.payload.data;
      state.loadings.channelsData = false;
      return state;

    case getAppChannels.types.FAIL:
      state.loadings.channelsData = false;
      return state;

    // GET APP REVIEWS
    case getAppReviews.types.START:
      state.loadings.reviews = true;
      return state;

    case getAppReviews.types.SUCCESS:
      state.reviews.data = action.payload.data;
      state.reviews.meta = action.payload.meta;
      state.loadings.reviews = false;
      return state;

    case getAppReviews.types.FAIL:
      state.loadings.reviews = false;
      return state;

    // ADD APP REVIEW
    case addAppReview.types.START:
      state.loadings.addReview = true;
      return state;

    case addAppReview.types.SUCCESS:
      state.data.ratings.appRatings = action.payload.appRatings;
      state.data.ratings.rating = action.payload.rating;
      state.data.ratings.ratingCount = action.payload.ratingCount;
      state.loadings.addReview = false;
      return state;

    case addAppReview.types.FAIL:
      state.loadings.addReview = false;
      return state;

    // CLEAN APP DETAILS
    case cleanAppDetails.types.INIT:
      state.data = initialState.data;
      state.reviews = initialState.reviews;
      state.channelsData = initialState.channelsData;
      state.loadings.data = initialState.loadings.data;
      state.loadings.reviews = initialState.loadings.reviews;
      state.loadings.channelsData = initialState.loadings.channelsData;
      return state;

    // UPDATE/REMOVE LIST STATUS
    case appListUpdateStatus.types.START:
    case appListRemoveStatus.types.START:
      state.loadings.statusesLoading = true;
      state.loadings.statusesLoadings[action.payload.app_id] = true;
      return state;

    case appListUpdateStatus.types.SUCCESS:
    case appListRemoveStatus.types.SUCCESS:
    case appListUpdateStatus.types.FAIL:
    case appListRemoveStatus.types.FAIL:
      state.loadings.statusesLoading = false;
      state.loadings.statusesLoadings[action.payload.app_id] = false;
      return state;

    // UPDATE APP LIST STATUS
    case appListUpdateAppStatus.types.INIT:
      if (state.data) state.data.app.list_status = action.payload.status;
      return state;

    default:
      return state;
  }
});

export default reducer;
