import styled from 'styled-components';
import { MaterialDesignContent } from 'notistack';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)`
  font-size: 1.4rem;
  line-height: 2.6rem;
  border-radius: 8px;
  padding: 11px 15px;
  min-height: 48px;

  > div:first-of-type {
    padding: 0;
  }

  &.notistack-MuiContent-success {
    background-color: ${p => p.theme.palette.primary.main};

    svg {
      color: ${p => p.theme.palette.primary.contrastText};
    }
  }

  &.notistack-MuiContent-error {
    background-color: ${p => p.theme.palette.error.main};

    svg {
      color: ${p => p.theme.palette.error.contrastText};
    }
  }

  &.notistack-MuiContent-warning {
    background-color: ${p => p.theme.palette.warning.main};
    color: ${p => p.theme.palette.text.primary};

    svg {
      color: ${p => p.theme.palette.text.primary};
    }
  }

  &.notistack-MuiContent-info {
    background-color: ${p => p.theme.palette.primary.contrastText};
  }
`;

export default StyledMaterialDesignContent;
