import axios from 'axios';
import { put, select, call, fork } from 'redux-saga/effects';

// CONSTANTS
import { SHOW_DETAILS, SHOW_STREAMING_DATA, SHOW_SIMILAR } from '@/constants/api';
import { contentTypes } from '@/constants/watchList';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';
import { getLink, getSerializedParams } from '@/utility/routes';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';

// STORE
import { ShowDetailsActions } from '@/store/actions';
import { ShowDetailsSelectors } from '@/store/selectors';
import { getContentMediaSaga } from './content';

const { getShowDetails, getShowStreamingData, getSimilarShows } = ShowDetailsActions;

function getAppUniqueId(app) {
  return app.plan ? `plan_${app.plan.id}` : app.id;
}

function flatAndSortApps(apps, apps_order) {
  return Object.values(apps).sort(
    (app1, app2) => apps_order.indexOf(app1.id) - apps_order.indexOf(app2.id),
  );
}

function setSeason(app, seasonData) {
  if (!app.seasons) {
    app.seasons = [seasonData];
  } else {
    app.seasons.push(seasonData);
  }

  return app;
}

function modifyAccAppsData(apps, data, season) {
  if (apps.length) {
    for (const app of apps) {
      const uniqueId = getAppUniqueId(app);

      const curApp = data[uniqueId];

      const { season_number, full_episodes } = season;
      const seasonData = { season_number, full_episodes };

      data[uniqueId] = setSeason(curApp || app, seasonData);
    }
  }
}

export function getAllAppsFromSeasons(seasons = [], apps_order = []) {
  const arpKey = 'apps-rent-purchase';

  const data = seasons.reduce(
    (acc, season) => {
      const apps = season.apps.filter(app => app.is_season_app) || [];
      const appsRentPurchase = season[arpKey] || [];

      modifyAccAppsData(apps, acc.apps, season);
      modifyAccAppsData(appsRentPurchase, acc[arpKey], season);

      return acc;
    },
    {
      apps: {},
      [arpKey]: {},
    },
  );

  return {
    apps: flatAndSortApps(data.apps, apps_order),
    [arpKey]: flatAndSortApps(data[arpKey], apps_order),
  };
}

export function* getShowDetailsSaga(action) {
  yield put(getShowDetails.start());

  const { show_id, onError } = action.payload;
  const url = getLink(SHOW_DETAILS, { show_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield fork(getContentMediaSaga, {
      payload: {
        content_id: respData.show.id,
        type: contentTypes.show,
      },
    });

    yield put(getShowDetails.success({ data: respData }));
  } catch (error) {
    const status = error.response?.status;

    if ([422, 404].includes(status)) {
      if (onError) onError();

      bugsnagClient.notify(error, event => {
        event.severity = 'info';
        event.errors[0].errorMessage += `. Show slug: ${show_id}`;
      });
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getShowDetails.fail());
  }
}

export function* getShowStreamingDataSaga(action) {
  const { show_id, isTemp = false } = action.payload;
  const streamingDataFetched = window.history.state?.usr?.streamingDataFetched;

  yield put(getShowStreamingData.start({ isTemp }));

  const url = getLink(SHOW_STREAMING_DATA, { show_id });

  try {
    let data = null;
    const dataTemp = yield select(ShowDetailsSelectors.getStreamingDataTemp);

    if (streamingDataFetched && dataTemp) {
      data = dataTemp;
    } else {
      const partner = yield call(getPartnerSlug);
      const serializedParams = getSerializedParams({ partner });
      const { data: respData } = yield axios.get(url, serializedParams);

      data = respData;

      const apps_order = data.apps_order || [31, 61];
      const appsData = getAllAppsFromSeasons(data.seasons, apps_order);

      data = { ...data, ...appsData };
    }

    yield put(getShowStreamingData.success({ data, isTemp }));
  } catch (error) {
    if (error?.response?.status !== 422) {
      yield call(showErrorNotification, error);
    }

    yield put(getShowStreamingData.fail({ isTemp }));
  }
}

export function* getSimilarShowsSaga(action) {
  yield put(getSimilarShows.start());

  const { show_id } = action.payload;
  const url = getLink(SHOW_SIMILAR, { show_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getSimilarShows.success({ data: respData['similar-content'] }));
  } catch (error) {
    if (error?.response?.status !== 422) {
      yield call(showErrorNotification, error);
    }

    yield put(getSimilarShows.fail());
  }
}
