import React from 'react';
import Icon from '@material-ui/core/Icon';

// ASSETS
import { ReactComponent as FilmPIconSvg } from '@/assets/images/icons/film-projector.svg';

function FilmPIcon(props) {
  return <Icon {...props} component={FilmPIconSvg} />;
}

export default React.memo(FilmPIcon);
