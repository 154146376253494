import { createAction } from '@/utility/actions';

import {
  SHOWS_AND_MOVIES_GET,
  SHOWS_AND_MOVIES_CLEAN,
  SHOWS_AND_MOVIES_GET_FILTERS,
  SHOWS_AND_MOVIES_GET_SEARCH,
  SHOWS_AND_MOVIES_CLEAN_SEARCH,
  SHOWS_AND_MOVIES_GET_COLLECTIONS,
} from './types';

/**
 * getShowsAndMoviesList - params: params(pagination | filters)
 */
export const getShowsAndMoviesList = createAction(SHOWS_AND_MOVIES_GET);

/**
 * cleanShowsAndMoviesList - params: params
 */
export const cleanShowsAndMoviesList = createAction(SHOWS_AND_MOVIES_CLEAN);

export const getShowsAndMoviesFilters = createAction(SHOWS_AND_MOVIES_GET_FILTERS);

/**
 * getShowsAndMoviesSearch - params: params(search)
 */
export const getShowsAndMoviesSearch = createAction(SHOWS_AND_MOVIES_GET_SEARCH);

/**
 * cleanShowsAndMoviesSearch
 */
export const cleanShowsAndMoviesSearch = createAction(SHOWS_AND_MOVIES_CLEAN_SEARCH);

/**
 * getShowsMoviesCollections
 */
export const getShowsMoviesCollections = createAction(SHOWS_AND_MOVIES_GET_COLLECTIONS);

export default {
  getShowsAndMoviesList,
  cleanShowsAndMoviesList,
  getShowsAndMoviesFilters,
  getShowsAndMoviesSearch,
  getShowsMoviesCollections,
  cleanShowsAndMoviesSearch,
};
