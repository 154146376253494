/**
 *  @type {<T extends string>(type: T)=> {
 *         type: T
 *         types: {
 *                  INIT: T,
 *                  START: `${T}_START`,
 *                  SUCCESS: `${T}_SUCCESS`,
 *                  FAIL: `${T}_FAIL`
 *                  STOP: `${T}_STOP`,
 *                }
 *        action(payload: any): {type: T, payload: any}
 *        start(payload: any): {type: `${T}_START`, payload: any}
 *        success(payload: any): {type: `${T}_SUCCESS`, payload: any}
 *        fail(payload: any): {type: `${T}_FAIL`, payload: any}
 *        stop(payload: any): {type: `${T}_STOP`, payload: any}
 * }}
 */
export const createAction = type => {
  const action = payload => ({
    type,
    payload,
  });

  const types = {
    INIT: type,
    START: `${type}_START`,
    STOP: `${type}_STOP`,
    SUCCESS: `${type}_SUCCESS`,
    FAIL: `${type}_FAIL`,
  };

  const init = payload => ({
    type: types.INIT,
    payload,
  });

  const start = payload => ({
    type: types.START,
    payload,
  });

  const success = payload => ({
    type: types.SUCCESS,
    payload,
  });

  const fail = payload => ({
    type: types.FAIL,
    payload,
  });

  const stop = payload => ({
    type: types.STOP,
    payload,
  });

  return { type, action, types, init, start, success, fail, stop };
};

export default createAction;
