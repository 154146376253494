export default {
  welcome: {
    title: 'Discover the best streaming services for you',
    subtitle:
      'Explore and sign up for a wide range of over 100+ paid and free streaming services that match your interest! There are so many options out there, we make it easy to find the best services and deals for you in our Streaming Marketplace',
    imagealt: 'Find the best Live TV streaming for you',
  },
  showsMovies: {
    title: 'Personalized Streaming TV Guide',
    subtitle:
      'By creating a free Streaming Profile, you get the following features across all your streaming services:',
    features: {
      item1: 'Personalized Show & Movie Recommendations',
      item2: 'Search Where to Watch Specific Titles',
      item3: 'Manage your Streaming Expenses',
      item4: 'Create Watchlists & Track What You’re Watching',
    },
  },
  questions: {
    title: 'Frequently Asked Questions',
    subtitle: '',
    question1: {
      question: 'What is Streaming TV? ',
      answer:
        'Put simply, streaming TV is entertainment transmitted over the internet instead of a cable or satellite box. With our reliable internet, you can stream your favorite TV channels, shows, movies, documentaries, and more – live or on demand. Using a streaming device or smart TV, you can watch services on your TV, or you can stream them on your computer or mobile devices. ',
    },
    question2: {
      question: 'What is MyBundle? ',
      answer:
        "We've partnered with MyBundle to help simplify your streaming TV experience because we know it can be confusing. MyBundle's suite of free tools help you eliminate all the work needed to find the streaming services that have the content you actually want. Whether it's finding a replacement for cable or satellite with the channels you need, discovering new streaming services that match your interests, or deciding what to watch next – MyBundle can help. (And save you hundreds of dollars and hours in the process!)",
    },
    question3: {
      question: 'What’s a Smart TV or streaming device? Do I need one? ',
      answer:
        'If you’d like to enjoy streaming content on your TV, you’ll need a Smart TV or streaming device. A lot of newer TVs come equipped Smart TV capabilities, which allow you to connect your TV to your Wi-Fi network or modem and access streaming service apps. Alternatively, a streaming device such as a Roku, Amazon’s Firestick, Apple TV, or Google Chromecast, will turn your TV into a Smart TV. With a simple interface, streaming devices allow you to use streaming apps and watch your favorite shows and movies. ',
    },
    question4: {
      question: 'Why should I switch to Streaming? ',
      answer:
        'Cable TV is constantly getting more expensive and often includes more channels than you actually watch. With Streaming, you can enjoy the same content and channels you’re used to in a more affordable package. Beyond live TV, with streaming you can access low-cost or free streaming services that offer movies, shows, and documentaries that match your interests. Streaming also provides you the ability to watch your favorite content wherever and however you want.  ',
    },
  },
  sellInternet: {
    title:
      'Don’t forget to sign-up for {{partner_name}} internet or check if you’re eligible for an upgrade.',
  },
};
