import * as Yup from 'yup';

// CONSTANTS
import { FundsPaymentType } from '@/constants/streamingRewards';

import stripePaymentSchema from './stripePaymentSchema';

export default t => {
  return Yup.object().shape({
    amount: Yup.number()
      .min(5, t('validation.amountMin'))
      .max(1000, t('validation.amountMax'))
      .required(t('validation.amountRequired')),
    type: Yup.string().required(),
    cardFields: Yup.object().when('cardMode', (value, schema) => {
      return value === 'form' ? schema.shape(stripePaymentSchema(t)) : schema;
    }),
    save: Yup.boolean().test('save', t('validation.saveCardRequired'), function testSave(value) {
      const { cardMode, type } = this.parent;

      if (type !== FundsPaymentType.MONTHLY || cardMode !== 'form') {
        return true;
      }

      return value;
    }),
  });
};
