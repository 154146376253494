export default {
  whereToWatch: {
    title: 'Where can I watch {{name}}?',
    description: '{{name}} is available on ',
    withSubscription: '{{app_names}} with a subscription; ',
    forFree: '{{app_names}} for free; ',
    forPurchase: 'To rent/purchase from {{app_names}};',
    notAvailable: '{{name}} is not available for streaming, rental, or purchase at this time.',
  },
  services: {
    title: 'You can watch this show on the following services:',
  },
  seasons: {
    title: 'Seasons',
  },
  similarContent: {
    title: "Similar TV Shows We Think You'd Like",
  },
  notifyMe: {
    title: 'Get notified for any updates or changes regarding this show.',
    subtitle:
      "You may experience content moving between streaming services, price fluctuations, and changes in available content. Don't worry, we've got you covered! Simply enter your email to receive notifications for all updates and changes.",
  },
  fullSeries: 'Full Series',
  fullSeriesAvailable: 'Full Series Available',
  seasonsAvailability: 'All Seasons',
  partialSeasonAvailable: 'Season {{seasons}} Available',
  partialSeasonsAvailable: 'Seasons {{seasons}} Available',
  partialSeason: 'partial season',
  partialSeason_plural: 'partial seasons',
};
