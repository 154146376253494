import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';

// STORE
import { initAxiosRetry, createAxiosResponseInterceptor } from '@/store/configDefaultAPI';

import './configImmer';
import rootReducer from './reducers';
import rootSaga from './saga';

// initAxiosRetry
initAxiosRetry();
// create Interceptor
createAxiosResponseInterceptor();

const sagaMiddleware = createSagaMiddleware({
  onError: (err, { sagaStack }) =>
    bugsnagClient.notify(err, event => {
      event.addMetadata('Saga', { sagaStack });
    }),
});

export function configureStore() {
  const composeEnhancers =
    process.env.REACT_APP_ENV === 'local' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  let sagaTask = sagaMiddleware.run(rootSaga);

  if (process.env.REACT_APP_FAST_REFRESH && process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')?.default; // eslint-disable-line global-require

      store.replaceReducer(nextRootReducer);
    });

    module.hot.accept('./saga', () => {
      const newRootSaga = require('./saga')?.default; // eslint-disable-line global-require

      store.dispatch(END);
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(newRootSaga);
      });
    });
  }

  return store;
}

const store = configureStore();

export default store;
