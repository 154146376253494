import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// CONSTANTS
import { LOCATIONS_STREAMING_REWARDS_BANNER } from '@/constants/locations';

// COMPONENTS
import BannerNotification from '@/components/Banners/BannerNotification';
import RouterLink from '@/components/Link';

const BannerNotClaimedReward = ({ link }) => {
  const [t] = useTranslation();
  const { isAuth } = useSelector(({ auth }) => ({
    isAuth: auth.isAuth,
  }));

  if (!link) return null;

  return (
    <BannerNotification
      title={t(`bannerNotClaimedReward.${isAuth ? 'title' : 'titleLogout'}`)}
      btnText={t('buttons.useMyCreditsNow')}
      btnProps={{
        component: RouterLink,
        to: link,
        analyticsProps: {
          location: LOCATIONS_STREAMING_REWARDS_BANNER,
        },
      }}
    />
  );
};

BannerNotClaimedReward.propTypes = {
  link: PropTypes.string,
};

export default React.memo(BannerNotClaimedReward);
