import styled from 'styled-components';
import { AppBar, Dialog } from '@material-ui/core';

import { PORCELAIN, WHITE } from '@/constants/colors';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

export const Wrapper = styled(Dialog)`
  z-index: 1301 !important;

  .onboarding,
  &.onboarding {
    min-width: 360px;

    &__header {
      justify-content: space-between;
      min-height: 70px;

      .logo {
        cursor: pointer;
        pointer-events: none;
      }
    }

    &__header-controls {
      > :not(:last-of-type) {
        margin-right: 10px;
      }
    }

    &__content-wrap,
    .loader {
      background-color: ${PORCELAIN};
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 70px;

      ${breakpoints.down('xs')} {
        padding-top: 60px;
      }
    }

    &__search-container {
      margin: 0 auto;
      width: 50%;

      ${breakpoints.down('xs')} {
        width: 100%;
      }

      .search-apps {
        background-color: ${WHITE};
      }
    }

    &__apps {
      margin-top: 20px;
      width: 100%;

      ${breakpoints.down('xs')} {
        margin-top: 40px;
      }

      &-container {
        position: relative;
        margin-top: 20px;
        min-height: 153px;
        margin-bottom: 80px;

        ${breakpoints.down('xs')} {
          margin-bottom: 30px;
        }
      }

      .checkbox-card {
        background-color: ${WHITE};
      }
    }

    &__footer {
      box-shadow: rgba(0, 0, 0, 0.06) 0 1px 20px;
    }
  }
`;

export const FooterAppBar = styled(AppBar)`
  top: auto;
  bottom: 0;

  &.o-footer {
    &--no-apps {
      ${breakpoints.down('xs')} {
        display: none;
      }
    }
  }

  .o-footer {
    &__toolbar {
      justify-content: space-between;
      min-height: 70px;
    }

    &__controls {
      flex-shrink: 0;
      margin-left: 15px;

      ${breakpoints.down('xs')} {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        width: 100%;

        .save-btn {
          min-width: 220px;
        }
      }

      > :not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;
