import styled from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, BLACK, SANTAS_GRAY } from '@/constants/colors';

export const AutocompleteWrapper = styled.div`
  position: relative;

  .loading {
    position: absolute;
    right: 15px;
  }
`;

export const SearchItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-item {
    &__main-info {
      display: flex;
      align-items: center;
      margin-right: 10px;
      overflow: hidden;
      flex-grow: 1;
    }

    &__image {
      display: flex;
      align-items: center;
      height: 24px;
      width: 45px;
      margin-right: 15px;
      flex-shrink: 0;

      img {
        max-width: 100%;
        max-height: 24px;
      }
    }

    &__type-image {
      display: flex;
      align-items: center;
      height: 24px;
      max-width: 45px;
      flex-shrink: 0;

      img {
        max-width: 100%;
        max-height: 24px;
      }
    }

    &__title {
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__type {
      text-transform: capitalize;
      color: ${SANTAS_GRAY};
    }
  }
`;

export const NoResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background-color: ${WHITE};
  cursor: default;
  overflow: hidden;
  font-size: 1.4rem;
  box-shadow: 0 20px 30px ${rgba(BLACK, 0.1)};
  font-weight: 500;
  line-height: 1.4rem;
  border-radius: 0 0 3px 3px;
  padding: 16px 15px;
`;

export default SearchItem;
