import { createAction } from '@/utility/actions';

import {
  WATCH_LIST_ADD_TO,
  WATCH_LIST_ADD_TO_DEFAULT_LIST,
  WATCH_LIST_REMOVE_FROM,
  WATCH_LIST_CLEAN,
  WATCH_LIST_GET_COLLECTIONS,
  WATCH_LIST_GET,
  WATCH_LIST_GET_CONTENT,
  WATCH_LIST_COPY_CONTENT,
  WATCH_LIST_GET_LIST,
  WATCH_LIST_CLEAN_LIST,
  WATCH_LIST_UPDATE,
  WATCH_LIST_DELETE,
  WATCH_LIST_CREATE,
  WATCH_LIST_ADD_CONTENT_DIALOG_MANAGE_STATE,
  WATCH_LIST_GET_CONTENT_ADD_NEW_LIST,
  WATCH_LIST_ADD_CONTENTS_TO_WATCHLISTS,
} from './types';

/**
 * addToWatchList - content_id; watch_lists (array); contentData (optional); callback (optional);
 *                  isSharedList (optional)
 */
export const addToWatchList = createAction(WATCH_LIST_ADD_TO);

/**
 * addToDefWatchList - content_id; data - { user_token }; onSuccess
 */
export const addToDefWatchList = createAction(WATCH_LIST_ADD_TO_DEFAULT_LIST);

/**
 * removeFromWatchList - content_id; watch_lists (array); callback (optional);
 *                       isSharedList (optional)
 */
export const removeFromWatchList = createAction(WATCH_LIST_REMOVE_FROM);

export const cleanWatchList = createAction(WATCH_LIST_CLEAN);

/**
 * watchListGetCollections - page
 */
export const watchListGetCollections = createAction(WATCH_LIST_GET_COLLECTIONS);

/**
 * getWatchList - params: id, params, onError, clearCache
 */
export const getWatchList = createAction(WATCH_LIST_GET);

/**
 * getWatchListContent - params: id, params, clearCache
 */
export const getWatchListContent = createAction(WATCH_LIST_GET_CONTENT);

/**
 * copyWatchListContent - params: id, onSuccess
 */
export const copyWatchListContent = createAction(WATCH_LIST_COPY_CONTENT);

/**
 * getWatchlists - withContent - default false, onSuccess
 */
export const getWatchlists = createAction(WATCH_LIST_GET_LIST);

/**
 * cleanWatchlists
 */
export const cleanWatchlists = createAction(WATCH_LIST_CLEAN_LIST);

/**
 * updateWatchlist - id; data - title (not required), access (not required); onSuccess
 */
export const updateWatchlist = createAction(WATCH_LIST_UPDATE);

/**
 * deleteWatchlist - id; onSuccess
 */
export const deleteWatchlist = createAction(WATCH_LIST_DELETE);

/**
 * createWatchlist - data - title, access(not required), content; onSuccess
 */
export const createWatchlist = createAction(WATCH_LIST_CREATE);

/**
 * manageAddToWLDialogState - params: state, props
 */
export const manageAddToWLDialogState = createAction(WATCH_LIST_ADD_CONTENT_DIALOG_MANAGE_STATE);

/**
 * getContentAddNewWatchList - params: id, params
 */
export const getContentAddNewWatchList = createAction(WATCH_LIST_GET_CONTENT_ADD_NEW_LIST);

/**
 * addContentsToWatchLists - params: data, onSuccess
 */
export const addContentsToWatchLists = createAction(WATCH_LIST_ADD_CONTENTS_TO_WATCHLISTS);

export default {
  addToWatchList,
  addToDefWatchList,
  removeFromWatchList,
  cleanWatchList,
  watchListGetCollections,
  getWatchList,
  getWatchListContent,
  copyWatchListContent,
  getWatchlists,
  cleanWatchlists,
  updateWatchlist,
  deleteWatchlist,
  createWatchlist,
  manageAddToWLDialogState,
  getContentAddNewWatchList,
  addContentsToWatchLists,
};
