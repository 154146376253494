import { createAction } from '@/utility/actions';

import {
  ONBOARDING_GET_LIST,
  ONBOARDING_COMPLETE,
  ONBOARDING_SET_STATE,
  ONBOARDING_UPDATE_SELECTED_APPS,
} from './types';

/**
 * getOnboardingApps - params
 */
export const getOnboardingApps = createAction(ONBOARDING_GET_LIST);

export const onboardingComplete = createAction(ONBOARDING_COMPLETE);

/**
 * onboardingSetState - params: state(bool)
 */
export const onboardingSetState = createAction(ONBOARDING_SET_STATE);

/**
 * onboardingSetState - data: object
 */
export const onboardingUpdateSelectedApps = createAction(ONBOARDING_UPDATE_SELECTED_APPS);

export default {
  getOnboardingApps,
  onboardingComplete,
  onboardingSetState,
  onboardingUpdateSelectedApps,
};
