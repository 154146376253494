/**
 * Get promo codes
 * @param {object} state
 * @returns {array}
 */
const getPromoDictionary = ({ promotions }) => promotions.dictionary;

export default {
  getPromoDictionary,
};
