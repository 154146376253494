export default {
  title: 'Manage all your streaming charge in <span>one</span> place',
  subtitle:
    'Easily track and manage your streaming subscriptions and expenses with a convenient all-in-one platform. Simplify your entertainment budgeting',
  features: {
    title: 'Take full control in <span>one place</span>',
    subtitle:
      'Effortlessly organize your streaming subscriptions and payments with an all-in-one platform',
    feature1: {
      title: 'Your subscription control center',
      option1: 'Manage Your Subscriptions',
      option2: 'Try, Pause, Restart',
      option3: 'View Recent and Upcoming Payments',
      option4: 'Easy-to-use Dashboard',
    },
    feature2: {
      title: 'Personal Fraud Prevention',
      option1: 'Secure Virtual MasterCard',
      option2: 'Never Expose Credit Card or Bank',
      option3: 'Highest Level of Encryption',
      option4: 'Safest Way to Pay',
    },
    feature3: {
      title: 'Savings Calculator',
      option1: 'Consolidated Statement',
      option2: 'Avoid Bank Overdrafts',
      option3: 'Create Monthly Budget',
      option4: "Save $100's Per Year",
    },
  },
  benefits: {
    title: 'What benefit will you get?',
    benefit1:
      'Never expose your credit/debit card or bank account information to various streaming services again.',
    benefit2:
      'Sign up for subscription service free trials and manage, pause or restart them from one account.',
    benefit3:
      'Let us remember the payments for you and avoid overdraft fees for those services you forgot about',
    benefit4: 'Have one payment each month and let us make your payments to each service for you',
    benefit5: 'Increased Fraud Protection & Security',
  },
  feedbacks: {
    title: 'What our client have to says',
  },
};
