/**
 * Get partner slug
 * @param {object} state
 * @returns {string}
 */
const getPartner = ({ app }) => app.partner;

/**
 * Get partner slug
 * @param {object} state
 * @returns {bool}
 */
const getIsSimpleView = ({ app }) => app.isSimpleView;

/**
 * Get dictionary
 * @param {object} state
 * @returns {bool}
 */
const getDictionary = ({ app }) => app.dictionary;

/**
 * Get Ytpc
 * @param {object} state
 * @returns {bool}
 */
const getYtpc = ({ app }) => app.ytpc;

export default {
  getPartner,
  getIsSimpleView,
  getDictionary,
  getYtpc,
};
