export default {
  title: 'Free Streaming Apps',
  description: 'Find the right streaming apps for your business',
  banner1: {
    title: 'Experience the World’s Fastest Internet',
    description: 'Choose speeds from 100 Mbps up to 10 Gigs with no data caps or added fees.',
    button: 'Learn More',
  },
  banner2: {
    title: 'Get corner-to-corner managed WiFi',
    description: 'Two different powerful, turnkey WiFi solutions optimized for peak performance.',
    button: 'Learn More',
  },
  banner3: {
    title: 'Change the way you buy TV – and save!',
    description: 'Eliminate monthly TV plan costs with EPB Managed Streaming TV for Business.',
    button: 'Learn More',
  },
};
