// CONSTANTS
import {
  ROUTE_RESULTS_INTERNET_OPTION,
  ROUTE_PARTNER_BUY_APP,
  ROUTE_BUY_APP,
} from '@/constants/routes';

// UTILITY
import { getSubscriptionLink } from './subscription';
import { getDomainData } from './partner';
import { updateUrl } from './common';
import { getLink, getReferrer } from './routes';
import { getExternalUrlParams, analyticsIdentify } from './analytics';
import { getUtcDate } from './date';

const SITE_URL = process.env.REACT_APP_SITE_URL || window.location.origin;

const { originPath, isPartnerDomain } = getDomainData();

const formatAddons = addons => {
  if (!addons.length) {
    return '';
  }
  const suffix = '(Add-on)';

  return addons.reduce((acc, addon, index) => {
    let addonsString = acc;

    if (addon.name.includes(suffix)) {
      addonsString += addon.name;
    } else {
      addonsString += `${addon.name} ${suffix}`;
    }

    if (index + 1 < addons.length) {
      addonsString += ', ';
    }

    return addonsString;
  }, '');
};

const getCleanLinkByApp = ({ app, formData, siteUrl }) => {
  let link = '';

  if (app.clean_link) {
    const source = getReferrer();
    link = siteUrl;

    if (Object.keys(formData.partner).length) {
      link += getLink(ROUTE_PARTNER_BUY_APP, {
        slug: app.clean_link,
        partner: formData.partner.slug,
      });
    } else {
      link += getLink(ROUTE_BUY_APP, {
        slug: app.clean_link,
      });
    }

    link += '?track&location=email';

    if (formData.partner_id_fk) {
      link += `&partner_id=${formData.partner_id_fk}`;
    }

    link += `&page=email_flow&source=${source}&unique_id=${formData.unique_id}`;
  } else {
    const externalUrlParams = getExternalUrlParams({
      unique_id: formData.unique_id,
      page: 'email_flow',
    });

    link = updateUrl(app.link, externalUrlParams);
  }

  return link;
};

export const sendMailchimpUserData = ({
  unique_id,
  results,
  formData,
  hasFirstBundle,
  hasSecondBundle,
  hasThirdBundle,
  siteUrl = SITE_URL,
  getCleanLink = getCleanLinkByApp,
  formatStringOfAddons = formatAddons,
  exist,
}) => {
  const externalUrlParams = getExternalUrlParams({
    unique_id: formData.unique_id,
    page: 'email_flow',
  });

  const hasPartner = Object.keys(formData.partner).length;
  const partnerSettings = (hasPartner && formData.partner?.settings) || {};
  const partnerSlug = (hasPartner && `/${formData.partner?.slug}`) || '';
  let useSubscription = true;

  if (hasPartner) {
    useSubscription = !!partnerSettings.use_authorizing && !!partnerSettings.use_subscription;
  }

  function getServiceLink(service) {
    const subscriptionIsAvailable =
      useSubscription && service.has_subscription && !!service.app_slug;
    let serviceUrl;

    if (subscriptionIsAvailable) {
      const addons = service.addons || [];
      const domainPart = !isPartnerDomain ? originPath + partnerSlug : originPath;

      serviceUrl =
        domainPart +
        getSubscriptionLink(service.app_slug, service.plan_id, addons, formData.zipcode);
    } else {
      serviceUrl = getCleanLink({ app: service, formData, siteUrl });
    }

    return serviceUrl;
  }

  let bundle1service1Data = {
    B1HT: '',
    B1HS: '',
    B1S1U: '',
    B1S1I: '',
    B1S1N: '',
    B1S1A: '',
    B1S1P: '',
  };
  let bundle1service2Data = {
    B1S2U: '',
    B1S2I: '',
    B1S2N: '',
    B1S2A: '',
    B1S2P: '',
  };
  let bundle2service1Data = {
    B2HT: '',
    B2HS: '',
    B2S1U: '',
    B2S1I: '',
    B2S1N: '',
    B2S1A: '',
    B2S1P: '',
  };
  let bundle2service2Data = {
    B2S2U: '',
    B2S2I: '',
    B2S2N: '',
    B2S2A: '',
    B2S2P: '',
  };
  let bundle3service1Data = {
    B3HT: '',
    B3HS: '',
    B3S1U: '',
    B3S1I: '',
    B3S1N: '',
    B3S1A: '',
    B3S1P: '',
  };
  let bundle3service2Data = {
    B3S2U: '',
    B3S2I: '',
    B3S2N: '',
    B3S2A: '',
    B3S2P: '',
  };
  let internetOptions = {
    INTERNETN: '',
    INTERNETI: '',
    INTERNETU: '',
    INTERNETP: '',
  };
  let recommendationData = {
    RBS1U: getCleanLink({
      app: {
        clean_link: 'hulu',
        link:
          'https://www.kmtrak.com/874BJD/3HZ765S/?uid=212&sub1={{unique_id}}&sub2={{source}}&sub3={{page}}',
      },
      formData,
      siteUrl,
    }),
    RBS1I: 'https://d35qfqdd1hb7yd.cloudfront.net/assets/images/logos/services/hulu-wide.png',
    RBS1P: '5.99',
    RBS1D:
      'Hulu has over 10,000 titles including popular shows, award winning originals and hit movies. <strong>Get one month free.<strong>',
  };
  const willLoveService1Data = {
    WLBS1U: '',
    WLBS1I: '',
    WLBS1P: '',
    WLBS1D: '',
  };
  const willLoveService2Data = {
    WLBS2U: '',
    WLBS2I: '',
    WLBS2P: '',
    WLBS2D: '',
  };

  if (hasFirstBundle) {
    bundle1service1Data = {
      B1HT: results.bundle_1.title,
      B1HS: results.bundle_1.desc,
      B1S1U: getServiceLink(results.bundle_1.services[0]),
      B1S1I: results.bundle_1.services[0].logo,
      B1S1N: results.bundle_1.services[0].company_name
        ? `${results.bundle_1.services[0].company_name} ${results.bundle_1.services[0].name ||
            ''}`.trim()
        : '',
      B1S1A: formatStringOfAddons(results.bundle_1.services[0].addons),
      B1S1P: results.bundle_1.services[0].cost,
    };

    if (results.bundle_1.services[1]) {
      bundle1service2Data = {
        B1S2U: getServiceLink(results.bundle_1.services[1]),
        B1S2I: results.bundle_1.services[1].logo,
        B1S2N: results.bundle_1.services[1].name
          ? `${results.bundle_1.services[1].company_name} ${results.bundle_1.services[1].name}`
          : '',
        B1S2A: formatStringOfAddons(results.bundle_1.services[1].addons),
        B1S2P: results.bundle_1.services[1].cost,
      };
    }
  }

  if (hasSecondBundle) {
    bundle2service1Data = {
      B2HT: results.bundle_2.title,
      B2HS: results.bundle_2.desc,
      B2S1U: getServiceLink(results.bundle_2.services[0]),
      B2S1I: results.bundle_2.services[0].logo,
      B2S1N: results.bundle_2.services[0].name
        ? `${results.bundle_2.services[0].company_name} ${results.bundle_2.services[0].name}`
        : '',
      B2S1A: formatStringOfAddons(results.bundle_2.services[0].addons),
      B2S1P: results.bundle_2.services[0].cost,
    };

    if (results.bundle_2.services[1]) {
      bundle2service2Data = {
        B2S2U: getServiceLink(results.bundle_2.services[1]),
        B2S2I: results.bundle_2.services[1].logo,
        B2S2N: results.bundle_2.services[1].name
          ? `${results.bundle_2.services[1].company_name} ${results.bundle_2.services[1].name}`
          : '',
        B2S2A: formatStringOfAddons(results.bundle_2.services[1].addons),
        B2S2P: results.bundle_2.services[1].cost,
      };
    }
  }

  if (hasThirdBundle && results.bundle_3?.services) {
    bundle3service1Data = {
      B3HT: results.bundle_3.title,
      B3HS: results.bundle_3.desc,
      B3S1U: getServiceLink(results.bundle_3.services[0]),
      B3S1I: results.bundle_3.services[0].logo,
      B3S1N: results.bundle_3.services[0].name
        ? `${results.bundle_3.services[0].company_name} ${results.bundle_3.services[0].name}`
        : '',
      B3S1A: formatStringOfAddons(results.bundle_3.services[0].addons),
      B3S1P: results.bundle_3.services[0].cost,
    };

    if (results.bundle_3.services[1]) {
      bundle3service2Data = {
        B3S2U: getServiceLink(results.bundle_3.services[1]),
        B3S2I: results.bundle_3.services[1].logo,
        B3S2N: results.bundle_3.services[1].name
          ? `${results.bundle_3.services[1].company_name} ${results.bundle_3.services[1].name}`
          : '',
        B3S2A: formatStringOfAddons(results.bundle_3.services[1].addons),
        B3S2P: results.bundle_3.services[1].cost,
      };
    }
  }

  if (!!results.internet_options.length && results.internet_options[0]) {
    const internetOption = results.internet_options[0];
    const internet_option_id = internetOption.id;
    const product = results.internet_options[0].product;
    let url = '';

    if (results.internet_options[0].show_button) {
      const internetOptionLink = getLink(ROUTE_RESULTS_INTERNET_OPTION, {
        unique_id,
        internet_option_id,
      });
      url = internetOption.show_internet_option_page
        ? siteUrl + internetOptionLink
        : updateUrl(internetOption.button_url, externalUrlParams);
    }

    internetOptions = {
      internet_option_id,
      INTERNETN: internetOption.title,
      INTERNETI: internetOption.image_black,
      INTERNETU: url,
      INTERNETP: product?.phone || internetOption?.phone || '',
    };
  }

  if (results.recommended_block && results.recommended_block.clean_link) {
    recommendationData = {
      RBS1U: getCleanLink({
        app: {
          clean_link: results.recommended_block.clean_link,
          link: results.recommended_block.link,
        },
        formData,
        siteUrl,
      }),
      RBS1I: results.recommended_block.image,
      RBS1P:
        typeof results.recommended_block.price === 'number'
          ? `$${results.recommended_block.price}`
          : results.recommended_block.price,
      RBS1D: results.recommended_block.description,
    };
  }

  let partnerInfo = {};

  if (hasPartner) {
    const partner = formData.partner;

    const getIsCustomer = () => {
      let isCustomer = 0;

      if (formData.cable_provider && formData.cable_provider !== 'no') {
        isCustomer = 1;
      }

      return isCustomer;
    };

    partnerInfo = {
      flow_color: partner.theme.primary,
      flow_logo: partner.email_logo || partner.logo,
      flow_partner_name: partner.partner_name,
      partner_mybundle_url: isPartnerDomain ? originPath : `${originPath}/${partner.slug}`,
      flow_is_customer: getIsCustomer(),
    };
  }

  analyticsIdentify(formData.email, {
    fmb_submit_date: getUtcDate(),
    email: formData.email,
    dma_id: formData.dma_id || '',
    UNIQUEID: formData.unique_id,
    PARTNERID: formData.partner?.slug || '',
    TRIGGERFL: true,
    HASACC: exist,
    ...bundle1service1Data,
    ...bundle1service2Data,
    ...bundle2service1Data,
    ...bundle2service2Data,
    ...bundle3service1Data,
    ...bundle3service2Data,
    ...internetOptions,
    ...recommendationData,
    ...willLoveService1Data,
    ...willLoveService2Data,
    ...partnerInfo,
  });
};

export default sendMailchimpUserData;
