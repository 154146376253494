export default {
  content: {
    title: 'Choose Multiple',
    subtitle: 'Add content to a list that your friends will vote on',
    search: {
      title: 'Search',
    },
    suggested: {
      title: 'Suggested',
    },
  },
  review: {
    title: 'Review Ask My Friends List',
    listName: {
      title: 'Name your Ask My Friends List',
    },
  },
  complete: {
    title: 'Your Ask My Friends List has been created',
    subtitle: 'Share below to get your friends votes on what to watch next',
    share: {
      title: 'Share this link to your friend',
    },
    email: {
      title: 'Enter your email to save and see your friends vote results',
    },
  },
  enableRecomm: {
    title: 'Do you want to let your friends add their picks to the list?',
    subtitle:
      'Getting movie recommendations from friends can help you decide what to watch next. However, if you prefer to keep your list private, you can disable this feature.',
    confirm: 'Yes, enable the recommendation feature',
    cancel: 'No, disable the recommendation feature',
    disableRecomm: 'Disable Friend’s Recommendation feature',
    enableRecomm: 'Enable Friend’s Recommendation feature',
  },
};
