export default {
  section1: {
    title: 'MyBundle is here to help simplify the new Streaming TV World.',
    paragraph1:
      "is here to help you navigate the changing TV world by simplifying and customizing the process.  With so many choices of what to watch and where to watch it, the streaming world has become confusing.   It doesn't have to be!  Our platform simplifies all of it so you can quickly get back to watching the channels, shows, and movies you love while saving money & time in the process. Our personalized tools make it easy for everyone to join the #StreamingRevolution. Here's a brief overview: ",
    subsection1: {
      subtitle1: 'Find My Bundle:',
      paragraph1:
        'Built for people who are ready to drop their big cable TV bills and SAVE money. Based on your preferences and favorite channels, our Find My Bundle tool will figure out which Live TV streaming package is best for you, making the transition to streaming TV as seamless as can be. Get all the live TV channels and content you love without all the expensive and hidden fees of cable.  ',
      paragraph3:
        'Hulu + Live TV, Sling TV, YouTube TV, Vidgo, DIRECTV STREAM, fubo TV, Philo and Frndly TV.',
      paragraph4:
        'We will mix and match these services with other services that either include the specific content you are looking for or are replacement options to save more money and still get the type of content you want.',
    },
    subsection2: {
      subtitle1: 'Streaming Marketplace:',
      paragraph1:
        "Our Streaming Marketplace offers you a place to discover and learn about new streaming services, whether you decide to keep your cable TV or never had it in the first place. While most people know about Netflix, Hulu, Prime Video, and Disney+, there are hundreds of other streaming services! Our Streaming Marketplace helps you find and sign-up for unique and niche services that match your interests. Also, if you're looking for a particular show or movie, search our Streaming Marketplace to find on which service it can be streamed.",
    },
    subsection3: {
      subtitle1: 'MyBundle Profiles:',
      paragraph1:
        'By signing up for a free MyBundle Profile and inputting your current streaming subscriptions, you are able to quickly discover Movies and TV Shows from across your services, track your streaming costs, get personalized recommendations, and build custom Watchlists all in one place. ',
      paragraph2:
        'We are always improving and simplifying your experience! You will soon be able to integrate your payments and access a wide range of constantly updating features to elevate your Streaming management.',
    },
    subsection4: {
      subtitle1: 'Internet Offers:',
      paragraph1:
        "The foundation for great streaming is a great internet service! So, we've partnered with a number of high-speed broadband providers to offer you the best service possible.  If you're moving, looking for more affordable high-speed data, or want to upgrade your service, use our Internet Offers search to check for special offers from our partners at your address. ",
    },
    paragraph7: '',
  },
  section2: {
    title: 'FAQ',
    subtitle: 'Streaming can be confusing. Here are some of the most popular questions we get: ',
    item1: {
      title: 'What is cord cutting?',
      paragraph:
        "As you may have heard, no longer is your cable/satellite company the only way to get great TV shows or movies. As Smart TVs and Netflix have exploded in popularity, more and more content is now available outside of the traditional cable/satellite bundle. All you need is an internet connection and a streaming device to get the Live TV channels you love, reruns On-Demand, and even brand new content from new streaming services you've never heard of! Additionally, a Digital Antenna can be used to pick up Broadcast Networks such as ABC, CBS, NBC, and FOX for free. ",
    },
    item2: {
      title: "Wait a second, what's a Smart TV or Streaming device? Do I have one?",
      paragraph:
        "If you've purchased a TV in the last few years it most likely has the ability to stream many of the services available. If you are interested in having access to a broader set of Streaming services, or have an older TV, then Roku, Amazon, Google and others all sell affordable Streaming Devices that turn almost any TV into a Smart TV. Streaming Devices offer an easy and simple-to-use interface that allow you to watch your favorite shows and movies. You can either connect the TV or Streaming device to your Wi-Fi network or often right into a modem. Once connected, you're ready to stream! ",
    },
    item3: {
      title:
        'Can I really get the same channels I watch now without the set top boxes or huge cable bill?',
      paragraph:
        "Yes you can! There are many Live TV streaming options available today, but they each have their own channel lineup and features which makes this whole process pretty confusing. That's why we built our Find My Bundle tool, to help you find which one is best for you! Not only will we help you figure out which service has the channels you want, we will also recommend replacement options for channels that may allow you to choose a smaller bundle and save even more money. ",
    },
    item4: {
      title:
        'O.K. So I have a Smart TV and I got my Find My Bundle Results, can I cancel cable right now? ',
      paragraph:
        'You can get rid of your current video package and return your cable boxes tomorrow, but you\'re still going to need internet service to access your Streaming services. One thing you should consider when getting ready to get rid of cable is how much your internet costs will be relative to your total cable bill. We encourage you to see if there are other broadband providers in your area that give you more choice (and lower prices.) First, you can check our Internet Offers search to see if any of our partners offer service at your address. If none of our partners offer service in your area, we\'d recommend just running a browser search for "Internet offers near me."',
    },
  },
  section3: {
    title: 'Interested in Partnering with Us?',
    subtitle: '',
    description1: {
      part1: 'Whether you are a ',
      part2: 'broadband provider',
      part3: ' looking to help inform your customers & grow internet subscribers, or a ',
      part4: 'streaming service',
      part5:
        ' looking to be matched with the right consumer, we can help through our multiple partnership options.',
    },
    description2: {
      part1: 'Send us a note at',
      part2: ' to learn more. We look forward to working together!',
    },
    title2: 'Some of our partners:',
  },
  section4: {
    title: 'Acknowledgement and Member Organizations',
    safely:
      'Protect yourself and your devices from malicious software and identity theft threats by streaming from safe, legitimate sources',
    safelyName: 'StreamSafely',
  },
  contactUs: {
    getInTouch: {
      title: 'Contact Us',
      description: 'For general queries, including partnership opportunities, please email',
    },
  },
  careers: {
    title: 'Come create a better way to TV!',
    subtitle:
      'At MyBundle, we’re building a better streaming ecosystem, and not just for consumers but for streaming services and broadband providers as well. We’re simplifying the way people discover, buy, manage and use their streaming services by connecting them with their favorite shows, channels, and services - all while saving them money. If you’re a team player and have a passion about streaming TV, movies, and entertainment...come create a better way to TV!',
  },
};
