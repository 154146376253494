export default {
  title: '{{name}} Channel Lineups',
  lookingForMore:
    "Didn't find what you were looking for? More channels can be found in the Search Box above.",
  topChannels: {
    title: 'Top Channels',
  },
  zip: {
    title: 'Filter Channels By ZIP Code',
  },
  search: {
    title: 'Find More Channels',
    results: {
      title: 'Search Result for <span>{{name}}</span>',
    },
    noResults: {
      title: "Sorry we couldn't find any channels with that search.",
      subtitle: 'Please try a different search.',
    },
  },
  filters: {
    title: 'Sort channels by',
    similarContent: 'Similar content available on',
  },
  footer: {
    title: 'Channel availability subject to change.',
  },
};
