import axios from 'axios';
import { call, put, delay } from 'redux-saga/effects';

// CONSTANTS
import { GET_COUNTIES, GET_COUNTIES_BY_ADDRESS } from '@/constants/api';

// UTILITY
import { customAnalyticsEvent } from '@/utility/analytics';
import { showErrorNotification } from '@/utility/saga';

import { CountiesActions } from '../actions';

import { asyncInternetOptionsSaga } from './wizard';

const { getCounties, getCountiesByAddress } = CountiesActions;

export function* getCountiesSaga(action) {
  yield put(getCounties.start());

  const { zipcode, partner_id_fk, unique_id, location } = action.payload;

  try {
    const { data: respData } = yield axios.post(GET_COUNTIES, {
      zipcode,
      partner_id_fk,
      unique_id,
    });

    customAnalyticsEvent('Zip Code Form Filled Out', {
      zip: zipcode,
      location,
    });

    yield put(
      getCounties.success({
        counties: respData.counties,
        // !!!emulating that we always have internet offer for skipping ibex logic
        // internet_option: data.internet_option,
        internet_option: true,
      }),
    );

    if (respData.counties.length === 1) {
      // Delay applied specifically for loader if results has 1 county for better ui
      yield delay(100);
    }

    yield put(getCounties.stop());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCounties.fail());
  }
}

export function* getCountiesByAddressSaga(action) {
  yield put(getCountiesByAddress.start());

  const { address, unique_id, zipcode } = action.payload;

  try {
    const { data: respData } = yield axios.post(GET_COUNTIES_BY_ADDRESS, { address, unique_id });

    yield put(
      getCountiesByAddress.success({
        counties: respData.counties,
        zip_code: respData.zip_code || zipcode,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCountiesByAddress.fail());
  }

  yield call(asyncInternetOptionsSaga, { payload: { unique_id } });
}
