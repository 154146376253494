import * as Yup from 'yup';

// UTILITY
import { isRequiredField } from '@/utility/common';

// Schemas
import startSchema from './schemas/startSchema';
import startCRSSchema from './schemas/startCRSSchema';
import channelsSchema from './schemas/channelsSchema';
import resultsEmailSchema from './schemas/resultsEmailSchema';
import emailSchema from './schemas/emailSchema';
import discoverFiltersSchema from './schemas/discoverFiltersSchema';
import appDetailsWriteReview from './schemas/appDetailsWriteReview';
import watchNextSchema from './schemas/watchNextSchema';
import userNameSchema from './schemas/userNameSchema';
import loginSchema from './schemas/loginSchema';
import registrationSchema from './schemas/registrationSchema';
import forgotPasswordSchema from './schemas/forgotPasswordSchema';
import updatePasswordSchema from './schemas/updatePasswordSchema';
import profileEditSchema from './schemas/profileEditSchema';
import changePriceSchema from './schemas/changePriceSchema';
import stripePaymentSchema from './schemas/stripePaymentSchema';
import subscriptionRegistrationSchema from './schemas/subscriptionRegistrationSchema';
import subscriptionPlanSchema from './schemas/subscriptionPlanSchema';
import reportSchema from './schemas/reportSchema';
import zipSchema from './schemas/zipSchema';
import authCuriosityStreamRegistrationSchema from './schemas/authCuriosityStreamRegistrationSchema';
import virtualCardBalanceSchema from './schemas/virtualCardBalanceSchema';
import changePasswordSchema from './schemas/changePasswordSchema';
import fundsPaymentSchema from './schemas/fundsPaymentSchema';
import changeNameSchema from './schemas/changeNameSchema';
import createWlSchema from './schemas/createWlSchema';

export const setYupValidationSchema = (schemaName, t, step, data = {}) => {
  let curSchema;
  let schema;

  switch (schemaName) {
    case 'startSchema':
      curSchema = startSchema(t)[step];
      schema = curSchema || {};

      if (!curSchema && data.extraQuestions) {
        const question = data.extraQuestions.find(item => item.slug === step);

        if (question) {
          switch (question.type) {
            case 'text':
              schema = {
                [question.slug]: Yup.string()
                  .max(255)
                  .when('$requiredQuestions', (requiredQuestions, validateSchema) => {
                    return isRequiredField(question.slug, requiredQuestions)
                      ? validateSchema.required()
                      : validateSchema;
                  }),
              };
              break;
            case 'radio':
              schema = {
                [question.slug]: Yup.string().when(
                  '$requiredQuestions',
                  (requiredQuestions, validateSchema) => {
                    return isRequiredField(question.slug, requiredQuestions)
                      ? validateSchema.required()
                      : validateSchema;
                  },
                ),
              };
              break;
            default:
              break;
          }
        }
      }

      break;
    case 'startCSRSchema':
      schema = startCRSSchema(t, data);

      if (data.extraQuestions) {
        data.extraQuestions.forEach(question => {
          if (question) {
            switch (question.type) {
              case 'text':
                schema = {
                  ...schema,
                  [question.slug]: Yup.string()
                    .max(255)
                    .when('$requiredQuestions', (requiredQuestions, validateSchema) => {
                      const reqQuestions = requiredQuestions || data.requiredQuestions;

                      if (!reqQuestions) return validateSchema;

                      return isRequiredField(question.slug, reqQuestions)
                        ? validateSchema.required()
                        : validateSchema;
                    }),
                };
                break;
              case 'radio':
                schema = {
                  ...schema,
                  [question.slug]: Yup.string().when(
                    '$requiredQuestions',
                    (requiredQuestions, validateSchema) => {
                      const reqQuestions = requiredQuestions || data.requiredQuestions;

                      if (!reqQuestions) return validateSchema;

                      return isRequiredField(question.slug, reqQuestions)
                        ? validateSchema.required()
                        : validateSchema;
                    },
                  ),
                };
                break;
              default:
                break;
            }
          }
        });
      }

      break;
    case 'questionsSchema':
      schema = {};

      if (data.questions) {
        data.questions.forEach(question => {
          if (question) {
            switch (question.type) {
              case 'text':
                schema = {
                  ...schema,
                  [question.slug]: Yup.string()
                    .max(255)
                    .when('$requiredQuestions', (requiredQuestions, validateSchema) => {
                      const reqQuestions = requiredQuestions || data.requiredQuestions;

                      if (!reqQuestions) return validateSchema;

                      return isRequiredField(question.slug, reqQuestions)
                        ? validateSchema.required()
                        : validateSchema;
                    }),
                };
                break;
              case 'radio':
                schema = {
                  ...schema,
                  [question.slug]: Yup.string().when(
                    '$requiredQuestions',
                    (requiredQuestions, validateSchema) => {
                      const reqQuestions = requiredQuestions || data.requiredQuestions;

                      if (!reqQuestions) return validateSchema;

                      return isRequiredField(question.slug, reqQuestions)
                        ? validateSchema.required()
                        : validateSchema;
                    },
                  ),
                };
                break;
              default:
                break;
            }
          }
        });
      }

      break;
    case 'channelsSchema':
      schema = channelsSchema(t)[step] ? channelsSchema(t)[step] : {};
      break;
    case 'resultsEmailSchema':
      schema = resultsEmailSchema(t);
      break;
    case 'emailSchema':
      schema = emailSchema(t);
      break;
    case 'discoverFiltersSchema':
      schema = discoverFiltersSchema(t);
      break;
    case 'appDetailsWriteReview':
      schema = appDetailsWriteReview(t);
      break;
    case 'watchNextSchema':
      schema = watchNextSchema(t)[step] ? watchNextSchema(t)[step] : {};
      break;
    case 'userNameSchema':
      schema = userNameSchema(t);
      break;
    case 'loginSchema':
      schema = loginSchema(t);
      break;
    case 'registrationSchema':
      schema = registrationSchema(t, data);
      break;
    case 'forgotPasswordSchema':
      schema = forgotPasswordSchema(t);
      break;
    case 'updatePasswordSchema':
      schema = updatePasswordSchema(t);
      break;
    case 'profileEditSchema':
      schema = profileEditSchema(t, data);
      break;
    case 'changePriceSchema':
      schema = changePriceSchema(t);
      break;
    case 'stripePaymentSchema':
      schema = stripePaymentSchema(t);
      break;
    case 'subscriptionRegistrationSchema':
      schema = subscriptionRegistrationSchema(t, data);
      break;
    case 'subscriptionPlanSchema':
      schema = subscriptionPlanSchema(t);
      break;
    case 'reportSchema':
      schema = reportSchema(t);
      break;
    case 'zipSchema':
      schema = zipSchema(t);
      break;
    case 'authCuriosityStreamRegistrationSchema':
      schema = authCuriosityStreamRegistrationSchema(t, data);
      break;
    case 'virtualCardBalanceSchema':
      schema = virtualCardBalanceSchema(t)[step] ? virtualCardBalanceSchema(t)[step] : {};
      break;
    case 'changePasswordSchema':
      schema = changePasswordSchema(t);
      break;
    case 'fundsPaymentSchema':
      schema = fundsPaymentSchema(t);
      break;
    case 'changeNameSchema':
      schema = changeNameSchema(t);
      break;
    case 'createWlSchema':
      schema = createWlSchema(t);
      break;
    default:
      schema = {};
      break;
  }

  return Yup.isSchema(schema) ? schema : Yup.object().shape(schema);
};

export const validateFieldFromSchema = (field, value, schema) => {
  try {
    const fieldSchema = Yup.reach(schema, field);

    // Because isValidSync return true for string if pass array
    if (fieldSchema.type === 'string' && typeof value !== 'string') return false;

    return fieldSchema.isValidSync(value);
  } catch (e) {
    return false;
  }
};

export default setYupValidationSchema;
