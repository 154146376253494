import styled from 'styled-components';

export const DrawerContent = styled.div`
  position: relative;
  margin: auto;
  padding: 20px;
  width: 100%;
`;

export const LogoContent = styled.div`
  margin: 0 auto;
  padding: 20px 20px 0;
`;

export default DrawerContent;
