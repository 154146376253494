import { all, put, call } from 'redux-saga/effects';

// UTILITY
import { showErrorNotification } from '@/utility/saga';

// STORE
import { swimlanesGetSaga } from '@/store/saga/swimlanes';
import { EmmyAwardsActions } from '../actions';

const { getEmmyAwardsDetails } = EmmyAwardsActions;

export function* getChannelSwimlanesSaga(action) {
  const { params = {} } = action.payload;

  yield all([
    call(swimlanesGetSaga, {
      payload: {
        params: { page: 'emmy-awards', section: 1, ...params },
      },
    }),
    call(swimlanesGetSaga, {
      payload: {
        params: { page: 'emmy-awards', section: 2, ...params },
      },
    }),
  ]);
}

export function* getEmmyAwardsDetailsSaga() {
  yield put(getEmmyAwardsDetails.start());

  try {
    yield call(getChannelSwimlanesSaga, { payload: {} });

    yield put(getEmmyAwardsDetails.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getEmmyAwardsDetails.fail());
  }
}
