export default {
  login: {
    header: 'Login',
    title: 'Log-in',
    subtitle: "Don't have an account?",
    subtitleAccess: 'Login to your account to access {{name}}',
  },
  registration: {
    header: 'Sign Up',
    title: 'Sign Up',
    subtitle: 'Already have an account?',
    termsStart: 'By clicking',
    termsMiddle: "I agree to MyBundle's",
  },
  forgot: {
    header: 'Reset Password',
    title: 'Reset Password',
    subtitle:
      'Enter the email address you used when you joined and we’ll send you instructions to reset your password',
  },
  updatePassword: {
    header: 'Update Password',
    title: 'Update Password',
    subtitle: '',
  },
};
