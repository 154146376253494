import * as Yup from 'yup';

// CONSTANTS
import * as validationConst from '@/constants/validation';

// UTILITY
import { isRequiredField } from '@/utility/common';

export default t => {
  return {
    household: {
      household: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('household', requiredQuestions) ? schema.required() : schema;
      }),
    },
    zip: {
      zipcode: Yup.string()
        .min(
          validationConst.MIN_ZIP_CODE_LENGTH,
          t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
        )
        .max(
          validationConst.MAX_ZIP_CODE_LENGTH,
          t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
        )
        .when('$requiredQuestions', (requiredQuestions, schema) => {
          return isRequiredField('zipcode', requiredQuestions) ? schema.required() : schema;
        }),
      dma_id: Yup.string(),
      county_id_fk: Yup.string(),
    },
    dvr: {
      dvr: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('dvr', requiredQuestions) ? schema.required() : schema;
      }),
    },
    'already-have': {
      'already-have': Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('already-have', requiredQuestions) ? schema.min(1) : schema;
      }),
    },
    streaming: {
      streaming: Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('streaming', requiredQuestions) ? schema.min(1) : schema;
      }),
    },
    content: {
      content: Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('content', requiredQuestions) ? schema.min(1) : schema;
      }),
      'content-other': Yup.string(),
    },
    sports: {
      sports: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('sports', requiredQuestions) ? schema.required() : schema;
      }),
    },
    'device-included': {
      'device-included': Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
        return isRequiredField('device-included', requiredQuestions) ? schema.required() : schema;
      }),
    },
  };
};
