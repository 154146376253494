import { createAction } from '@/utility/actions';

import {
  PROMOTIONS_GET_DICTIONARY,
  PROMOTIONS_GET_LIST,
  PROMOTIONS_GET_BLACK_FRIDAY_CONTENT,
} from './types';

/**
 * getPromoDictionary - params
 */
export const getPromoDictionary = createAction(PROMOTIONS_GET_DICTIONARY);

/**
 * getPromoContent - params
 */
export const getPromoContent = createAction(PROMOTIONS_GET_LIST);

/**
 * getBlackFridayContent - params
 */
export const getBlackFridayContent = createAction(PROMOTIONS_GET_BLACK_FRIDAY_CONTENT);

export default {
  getPromoDictionary,
  getPromoContent,
  getBlackFridayContent,
};
