import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';

// STYLES
import { Container, App } from './styles';

const AddedApps = props => {
  const { apps, onAppRemove, onUnselectAll } = props;

  const [t] = useTranslation();

  const handleUnselectClick = event => {
    event.preventDefault();
    onUnselectAll();
  };

  return (
    <Container className="added-apps">
      <Typography className="m-b-15" align="center" variant="h6">
        {t('onboarding.addedApps')}
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        {apps.map(app => (
          <Grid item xs={3} sm={2} md={2} key={app.id}>
            <App
              className="app noselect"
              onClick={event => {
                onAppRemove(event, app);
              }}
            >
              <img
                src={app.thumbnail || `https://dummyimage.com/64X64?text=${app.name}`}
                alt={app.name}
              />
            </App>
          </Grid>
        ))}
      </Grid>
      {apps.length > 1 && (
        <Button
          className="added-apps__btn sizeExtraLarge"
          variant="outlined"
          color="secondary"
          onClick={handleUnselectClick}
        >
          {t('buttons.unselectAll')}
        </Button>
      )}
    </Container>
  );
};

AddedApps.propTypes = {
  apps: PropTypes.array.isRequired,
  onAppRemove: PropTypes.func.isRequired,
  onUnselectAll: PropTypes.func.isRequired,
};

export default React.memo(AddedApps);
