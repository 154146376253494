import styled from 'styled-components';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

export const Container = styled.div`
  padding: 64px 15px 72px;

  ${breakpoints.down('xs')} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .hero {
    &__title {
      margin-bottom: 48px;

      ${breakpoints.down('xs')} {
        margin-bottom: 33px;
      }
    }

    &__content {
      display: flex;
      justify-content: center;

      ${breakpoints.down('xs')} {
        flex-direction: column;

        > :not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    &__section {
      width: 50%;
      max-width: 385px;
      text-align: center;
      margin: 0 40px;

      ${breakpoints.down('sm')} {
        margin: 0 20px;
      }

      ${breakpoints.down('xs')} {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }

    &__icon {
      height: 48px;
      width: 48px;
      margin-bottom: 15px;
    }
  }
`;

export default Container;
