import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import {
  CHANNEL_LINEUP_GET_CHANNELS,
  CHANNEL_LINEUP_GET_DICTIONARY,
  CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS,
  CHANNEL_LINEUP_GET_TOP,
  CHANNEL_LINEUP_SEARCH,
} from '@/constants/api';

// UTILITY
import { getLink } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { ChannelLineupActions } from '../actions';

const {
  getCLDictionary,
  getCLTopChannels,
  cLSearch,
  getCLChannels,
  getCLReplacementChannels,
} = ChannelLineupActions;

export function* getCLDictionarySaga(action) {
  yield put(getCLDictionary.start());

  const { slug } = action.payload;
  const url = getLink(CHANNEL_LINEUP_GET_DICTIONARY, { slug });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(
      getCLDictionary.success({
        service_name: respData.service_name,
        genres: respData.genres || [],
        addons: respData.addons || [],
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCLDictionary.fail());
  }
}

export function* getCLTopChannelsSaga(action) {
  yield put(getCLTopChannels.start());

  const { slug, params } = action.payload;
  const url = getLink(CHANNEL_LINEUP_GET_TOP, { slug });

  try {
    const { data: respData } = yield axios.get(url, { params });

    yield put(getCLTopChannels.success({ channels: respData.channels || [] }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCLTopChannels.fail());
  }
}

export function* cLSearchSaga(action) {
  yield put(cLSearch.start());

  const { slug, params } = action.payload;
  const url = getLink(CHANNEL_LINEUP_SEARCH, { slug });

  try {
    const { data: respData } = yield axios.get(url, { params });

    yield put(cLSearch.success({ data: params.search ? respData.channels : null }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(cLSearch.fail());
  }
}

export function* getCLChannelsSaga(action) {
  yield put(getCLChannels.start());

  const { slug, params } = action.payload;
  const url = getLink(CHANNEL_LINEUP_GET_CHANNELS, { slug });

  try {
    const { data: respData } = yield axios.get(url, { params });

    yield put(getCLChannels.success({ data: respData.channels }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCLChannels.fail());
  }
}

export function* getCLReplacementChannelsSaga(action) {
  yield put(getCLReplacementChannels.start());

  const { slug } = action.payload;
  const url = getLink(CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS, { slug });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getCLReplacementChannels.success({ data: respData.channels }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCLReplacementChannels.fail());
  }
}
