export default {
  contact_message: 'Having trouble using your card?',
  mobile_notification: 'You’re almost there! Keep scrolling to complete claiming your reward.',
  create_acc: {
    registration: 'Create a MyBundle Account',
    login: 'Log-in',
  },
  not_eligible: {
    card: {
      support: 'For any questions, please reach out to',
    },
  },
  claimed: {
    apps: {
      search: 'Search for a streaming service....e.g. Netflix, Disney+, SlingTV.',
    },
  },
};
