export const WN_S_ACTION_ADD = 'add';
export const WN_S_ACTION_LIKE = 'like';
export const WN_STATUS_NOT_INTERESTED = 'Not Interested';
export const WN_STATUS_WATCHED = 'Watched';
export const WN_VOTE_LIKE = 'like';
export const WN_VOTE_DISLIKE = 'dislike';
export const WN_R_TYPE_SERVICE = 'mybundle';
export const WN_R_TYPE_FRIEND = 'friend';
export const WN_R_TYPE_OWNER = 'owner';

export const watchNextInitFormData = {
  apps: [],
  genres: [],
  content_types: [],
  runtime: '',
  title: '',
};

export const watchNextSteps = [{ name: 'apps' }, { name: 'genres' }];
export const watchNextEditSteps = [{ name: 'content' }, { name: 'review' }];
export const watchNextResultsSteps = [{ name: 'intro' }, { name: 'vote' }, { name: 'completed' }];

export default watchNextSteps;
