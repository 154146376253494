import produce from 'immer';

// UTILITY
import { getDomainData } from '@/utility/partner';

import {
  getPartner,
  cleanPartner,
  getPartnerByDomain,
  getPartnerInternetOffer,
  claimPartnerInternetOffer,
} from '../actions/partner';
import { setMaintenanceMode } from '../actions/app';

const { domain, isPartnerDomain } = getDomainData();

const initialState = {
  data: {},
  partner_id: null,
  internetOffer: null,
  domain,
  isPartnerDomain,
  loadings: {
    partner: false,
    internetOffer: false,
    claimInternetOffer: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET PARTNER
    case getPartner.types.START:
      state.loadings.partner = true;
      return state;

    case getPartner.types.SUCCESS:
      state.partner_id = action.payload.partner.id;
      state.data = { ...state.data, ...action.payload.partner };
      return state;

    case getPartner.types.STOP:
    case getPartner.types.FAIL:
      state.loadings.partner = false;
      return state;

    // GET PARTNER BY DOMAIN
    case getPartnerByDomain.types.START:
      state.loadings.partner = true;
      return state;

    case getPartnerByDomain.types.SUCCESS:
      state.partner_id = action.payload.partner.id;
      state.data = action.payload.partner;
      state.isPartnerDomain = true;
      return state;

    case getPartnerByDomain.types.STOP:
      state.loadings.partner = false;
      return state;

    // CLEAN PARTNER
    case cleanPartner.types.INIT:
      return { ...initialState };

    // GET INTERNET PARTNER OFFER
    case getPartnerInternetOffer.types.START:
      state.loadings.internetOffer = true;
      return state;

    case getPartnerInternetOffer.types.SUCCESS:
      state.internetOffer = action.payload.data;
      state.loadings.internetOffer = false;
      return state;

    case getPartnerInternetOffer.types.FAIL:
      state.loadings.internetOffer = false;
      return state;

    // CLAIM INTERNET PARTNER OFFER
    case claimPartnerInternetOffer.types.START:
      state.loadings.claimInternetOffer = true;
      return state;

    case claimPartnerInternetOffer.types.SUCCESS:
      if (state.internetOffer) {
        state.internetOffer = {
          ...state.internetOffer,
          ...(action.payload.data || {}),
        };
      }
      state.loadings.claimInternetOffer = false;
      return state;

    case claimPartnerInternetOffer.types.FAIL:
      state.loadings.claimInternetOffer = false;
      return state;

    // SET MAINTENANCE MODE
    case setMaintenanceMode.types.INIT:
      state.loadings.partner = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
