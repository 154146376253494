/**
 * Get Streaming Data Temp
 * @param {object} state
 * @returns {null | object}
 */
const getStreamingDataTemp = ({ movieDetails }) => movieDetails.streamingDataTemp;

export default {
  getStreamingDataTemp,
};
