import axios from 'axios';
import { call, put } from 'redux-saga/effects';

// CONSTANTS
import { VIRTUAL_CARD_BUY_PRODUCT, VIRTUAL_CARD_GET_PRODUCTS } from '@/constants/api';

// UTILITY
import { showErrorNotification } from '@/utility/saga';

// STORE
import { UserActions, VirtualCardBalanceActions, NotifActions } from '@/store/actions';

// LOCALIZATION
import { t } from '@/locale/i18n';

const { userEditProfile } = UserActions;

const { getVirtualCardBalanceData, addVirtualCardBalance } = VirtualCardBalanceActions;

const { pushSuccessNotificationAction } = NotifActions;

export function* virtualCardAddProductSaga(action) {
  yield put(addVirtualCardBalance.start());

  const { paymentData, createPaymentMethod, callback, onError } = action.payload;
  let { payment_method_id } = paymentData;

  try {
    if (!payment_method_id) {
      const payment = yield call(createPaymentMethod);
      payment_method_id = payment.id;
    }

    const { data: respData } = yield axios.post(VIRTUAL_CARD_BUY_PRODUCT, {
      ...paymentData,
      payment_method_id,
    });

    yield put(getVirtualCardBalanceData.init({ onError }));

    yield put(addVirtualCardBalance.success());

    yield put(userEditProfile.success({ user: { card: respData.card } }));

    yield put(pushSuccessNotificationAction(t('notification.successfullyPurchasedProduct')));

    if (callback?.call) callback();
  } catch (error) {
    yield put(addVirtualCardBalance.fail());

    yield call(showErrorNotification, error);
  }
}

export function* getVirtualCardDataSaga(action) {
  yield put(getVirtualCardBalanceData.start());

  const { onError } = action.payload;

  try {
    const respData = yield call(axios.get, VIRTUAL_CARD_GET_PRODUCTS, {
      cache: { ignoreCache: true },
    });

    yield put(getVirtualCardBalanceData.success(respData));
  } catch (error) {
    yield put(getVirtualCardBalanceData.fail());

    yield call(showErrorNotification, error);

    if (error.response?.status !== 519) {
      if (onError) onError();
    }
  }
}
