import { createAction } from '@/utility/actions';

import { SHARE_GET_INFO } from './types';

/**
 * getShareInfo - unique_id
 */
export const getShareInfo = createAction(SHARE_GET_INFO);

export default {
  getShareInfo,
};
