import { css } from 'styled-components';

const montana = {
  background: '#F2F2F2',
};

const montanaStyles = css`
  .partner-montana,
  .partner-montana-csr {
    &.resultspage {
      & > .section--grey {
        background-color: ${montana.background};
      }
    }
  }
`;

export default montanaStyles;
