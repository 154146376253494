export default {
  title: {
    have: 'My Apps',
  },
  recommendedApps: {
    title: ' Recommended Apps',
  },
  whatToWatchOnApps: {
    title: 'What To Watch on Your Apps',
  },
  popularOn: {
    title: 'Popular on {{appName}}',
  },
  subscription: 'Subscription',
  changePrice: 'Change price to what you pay for this service',
  totalCost: 'Total Cost of All Apps',
  emptyState: {
    title: 'Still have no streaming apps?',
    subtitle:
      'Go to marketplace to find new apps or add apps to your profile that you already have.',
  },
};
