import * as Yup from 'yup';

export default (/* t */) => {
  return {
    text: Yup.string(),
    genres: Yup.array(),
    channels: Yup.array(),
    keywords: Yup.array(),
    type: Yup.array(),
    devices: Yup.string(),
    show_id: Yup.string(),
    movie_id: Yup.string(),
  };
};
