import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

// CONSTANTS
import { MAIN_BLACK, WHITE } from '@/constants/colors';

// UTILITY
import { getBillingAddress } from '@/utility/common';
import { breakpoints } from '@/utility/breakpoints';

// STORE
import { NotifActions, StreamingRewardsActions } from '@/store/actions';

const { getVirtualCardExpandedData } = StreamingRewardsActions;

export const VirtualCardContext = React.createContext({});

const formatCardNumber = number => number.replace(/(.{4})/g, '$1-').replace(/-$/, '');

const useCopyToClipboard = ({ showFullCard, setShowFullCard, checkCardData }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { card, expandedCard } = useSelector(store => ({
    card: store.auth.user?.card,
    expandedCard: store.streamingRewards.data.expand || {},
  }));

  const copyToClipboard = (text, message) => {
    copy(text);

    dispatch(
      NotifActions.pushNotification.action({
        message,
        options: {
          variant: 'success',
        },
      }),
    );
  };

  const runCopyActionByType = (type, data) => {
    let text;
    let message;

    switch (type) {
      case 'number':
        text = formatCardNumber(data.number);
        message = t('streamingRewards.card.copy.card');
        break;
      case 'expDate':
        text = `${data.exp_month}/${data.exp_year}`;
        message = t('streamingRewards.card.copy.date');
        break;
      case 'cvc':
        text = data.cvc;
        message = t('streamingRewards.card.copy.cvc');
        break;
      case 'billingAddress':
        text = getBillingAddress(data.billing);
        message = t('streamingRewards.card.copy.billingAddress');
        break;
      default:
        break;
    }

    copyToClipboard(text, message);

    if (!showFullCard) {
      setShowFullCard(true);
    }
  };

  const handleInputFieldClick = type => {
    if (type === 'expDate' || type === 'billingAddress') {
      runCopyActionByType(type, card);

      return;
    }

    const hasCardData = checkCardData(data => {
      runCopyActionByType(type, data);
    });

    if (hasCardData) {
      runCopyActionByType(type, expandedCard);
    }
  };

  return { handleInputFieldClick };
};

export const FakeForm = styled.form`
  ${breakpoints.down('sm')} {
    display: none;
  }

  .btn {
    color: ${MAIN_BLACK};
    background-color: ${WHITE};
    border: 0.2rem solid ${MAIN_BLACK};
  }
`;

const useVirtualCardData = () => {
  const [showFullCard, setShowFullCard] = React.useState(true);
  const dispatch = useDispatch();
  const { card, expandedCard, loading } = useSelector(store => ({
    card: store.auth.user?.card,
    expandedCard: store.streamingRewards.data.expand || {},
    loading: store.streamingRewards.loadings.expand,
  }));

  const checkCardData = React.useCallback(
    callback => {
      const hasExtCard = !!Object.keys(expandedCard).length;

      if (!(hasExtCard || card?.number)) {
        dispatch(
          getVirtualCardExpandedData.action({
            callback,
          }),
        );
      } else if (callback) {
        callback();
      }

      return hasExtCard;
    },
    [expandedCard, card],
  );

  const handleIconClick = React.useCallback(
    e => {
      e.stopPropagation();

      checkCardData();

      setShowFullCard(!showFullCard);
    },
    [showFullCard, checkCardData],
  );

  const handleShowFullCard = () => {
    checkCardData(() => setShowFullCard(true));
  };

  const cardNumber = React.useMemo(() => {
    const number = expandedCard.number || card?.number;
    const showLast4 = loading || !showFullCard || !number;

    if (showLast4) {
      return `**** **** **** ${card?.last4 ?? '****'}`;
    }

    return formatCardNumber(number);
  }, [loading, showFullCard, expandedCard.number, card]);

  const cardCVC = React.useMemo(() => {
    const cvcNumber = expandedCard.cvc || card?.cvc;
    const hideCVC = loading || !showFullCard || !cvcNumber;

    if (hideCVC) {
      return `***`;
    }

    return cvcNumber;
  }, [loading, showFullCard, expandedCard.cvc, card]);

  const expDate = React.useMemo(() => `${card?.exp_month ?? 'MM'}/${card?.exp_year ?? 'YY'}`, [
    card?.exp_month,
    card?.exp_year,
  ]);

  const inputEndAdornment = React.useMemo(() => {
    return (
      <InputAdornment position="end">
        {loading ? (
          <CircularProgress size="18" className="card__icon" />
        ) : (
          <IconButton onClick={handleIconClick}>
            {showFullCard ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        )}
      </InputAdornment>
    );
  }, [loading, showFullCard]);

  const { handleInputFieldClick } = useCopyToClipboard({
    showFullCard,
    setShowFullCard,
    checkCardData,
  });

  return {
    cardNumber,
    cardCVC,
    expDate,
    showFullCard,
    handleShowFullCard,
    inputEndAdornment,
    handleInputFieldClick,
  };
};

export const useVirtualCard = () => {
  const ctx = React.useContext(VirtualCardContext);

  return ctx;
};

export default useVirtualCardData;
