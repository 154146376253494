import React from 'react';
import PropTypes from 'prop-types';
import bugsnagClient from '@/utility/bugsnagClient';

// HELP
import ErrorBoundaryView from './ErrorBoundaryView';

export const BugsnagErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);
function ErrorBoundary({ children }) {
  return (
    <BugsnagErrorBoundary
      FallbackComponent={ErrorBoundaryView}
      onError={event => {
        event.errors[0].errorMessage += '. Page crashed!';
        event.addMetadata('react', 'isCrashed', true);
      }}
    >
      {children}
    </BugsnagErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
