import styled from 'styled-components';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const LogoutItem = styled(ListItem)`
  color: ${p => p.theme.palette.error.main};
  transition: color 0.3s ease;
  text-transform: capitalize;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${p => p.theme.palette.primary.main};
    color: ${p => p.theme.palette.primary.contrastText};
  }
`;

export const useStyles = makeStyles(() => ({
  // Drawer Paper
  drawerPaper: {
    width: 300,

    '& .drawer-buttons': {
      paddingTop: 14,
    },
  },
}));

export default LogoutItem;
