import styled from 'styled-components';

export const LogoContainer = styled.div`
  a {
    display: block;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export default LogoContainer;
