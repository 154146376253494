import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';

// CONSTANTS
import {
  PROMOTION_GET_DETAILS,
  PROMOTION_GET_CODE,
  PROMOTION_CHECK_CODE_STATUS,
  PROMOTION_CHECK_AUTH_CODE,
} from '@/constants/api';
import { PROMOTION } from '@/constants/swimlanes';

// UTILITY
import getErrorMessage from '@/utility/errors';
import { getLink } from '@/utility/routes';
import { getPartnerData, showErrorNotification } from '@/utility/saga';

// STORE
import store from '@/utility/store';
import { PromotionActions, UserActions } from '@/store/actions';
import { AuthSelectors, PartnerSelectors, PromotionSelectors } from '@/store/selectors';
import { swimlanesGetSaga } from '@/store/saga/swimlanes';

const { getPromoDetails, getPromoCode, checkPromoCode, checkPromoAuthCode } = PromotionActions;
const { userGetContentCount } = UserActions;

export function* checkPromoCodeSaga(action) {
  yield put(checkPromoCode.start());

  const { promo_slug, code } = action.payload;

  const url = getLink(PROMOTION_CHECK_CODE_STATUS, { promo_slug, code });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(checkPromoCode.success());

    return respData;
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(checkPromoCode.fail());

    return null;
  }
}

export function* getPromoDetailsSaga(action) {
  yield put(getPromoDetails.start());

  const { promo_slug, onError } = action.payload;
  const url = getLink(PROMOTION_GET_DETAILS, { promo_slug });

  try {
    const partnerData = yield call(getPartnerData);

    const { data: respData } = yield axios.get(url, { params: { partner: partnerData.id } });
    const storedCodes = store.get('promo_codes') || [];
    const storedCode = storedCodes.find(code => code.promo_slug === promo_slug);

    let userPromo = respData.user_promo_code;

    if (!userPromo && storedCode) {
      userPromo = yield call(checkPromoCodeSaga, {
        payload: { promo_slug, code: storedCode.promo_code },
      });
    }

    yield put(
      getPromoDetails.success({
        data: {
          ...respData,
          user_promo_code: userPromo,
        },
      }),
    );
  } catch (error) {
    if (onError) onError(error?.response?.status);

    yield call(showErrorNotification, error);

    yield put(getPromoDetails.fail());
  }
}

export function* getPromoCodeSaga(action) {
  yield put(getPromoCode.start());

  const { promo_slug, mb_auth_code, email, password } = action.payload;

  const partnerData = yield select(PartnerSelectors.getPartnerData);
  const url = getLink(PROMOTION_GET_CODE, { promo_slug });

  try {
    const { data: respData } = yield axios.post(
      url,
      { mb_auth_code, email, password },
      { params: { partner: partnerData.id } },
    );
    let promoCodes = store.get('promo_codes') || [];

    if (respData.code) {
      promoCodes = [
        ...promoCodes,
        { promo_code: respData.code, promo_company_id: respData.promo_company_id, promo_slug },
      ];

      store.set('promo_codes', promoCodes);
    }

    yield put(
      getPromoCode.success({
        promoCodes,
        data: respData,
      }),
    );
  } catch (error) {
    yield put(
      getPromoCode.fail({
        message: getErrorMessage(error),
      }),
    );
  }
}

export function* checkPromoAuthCodeSaga(action) {
  yield put(checkPromoAuthCode.start());

  const { promo_slug, mb_auth_code, email, password, onSuccess } = action.payload;
  const url = getLink(PROMOTION_CHECK_AUTH_CODE, { promo_slug, code: mb_auth_code });
  const partnerData = yield select(PartnerSelectors.getPartnerData);
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield axios.get(url, {
      params: { partner: partnerData.id },
    });

    yield call(getPromoCodeSaga, {
      payload: { email, password, mb_auth_code, promo_slug },
    });

    yield put(checkPromoAuthCode.success());

    if (onSuccess) onSuccess();

    if (isAuth) {
      yield put(userGetContentCount.init());
    }
  } catch (error) {
    yield put(
      checkPromoAuthCode.fail({
        message: getErrorMessage(error),
      }),
    );
  }
}

export function* getPromoSwimlanesSaga() {
  const promoData = yield select(PromotionSelectors.getPromoData);

  yield call(swimlanesGetSaga, {
    payload: {
      params: { page: PROMOTION, app_id: promoData.app?.id },
    },
  });
}
