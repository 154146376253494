import axios from 'axios';
import { put, select, call, all } from 'redux-saga/effects';

// CONSTANTS
import { WATCH_LIST_SEARCH, WATCH_LIST_FILTERS } from '@/constants/api';
import { appsListStatuses } from '@/constants/app';

// UTILITY
import { showErrorNotification, getPartnerSlug } from '@/utility/saga';
import { getSerializedParams } from '@/utility/routes';

// STORE
import { ShowsAndMoviesActions } from '@/store/actions';
import { AuthSelectors, OnboardingSelectors } from '@/store/selectors';
import { userGetAppsSaga } from './user';
import { swimlanesGetSaga } from './swimlanes';

const {
  getShowsAndMoviesList,
  getShowsAndMoviesFilters,
  getShowsAndMoviesSearch,
} = ShowsAndMoviesActions;

export function* getShowsAndMoviesListSaga(action) {
  yield put(getShowsAndMoviesList.start());

  const {
    params: { appsType, ...restParams },
  } = action.payload;

  const serializedParams = getSerializedParams(restParams);

  try {
    const { data: respData } = yield axios.get(WATCH_LIST_SEARCH, serializedParams);

    yield put(getShowsAndMoviesList.success({ ...respData, page: restParams.page }));
  } catch (error) {
    yield put(getShowsAndMoviesList.fail());
  }
}

export function* getShowsAndMoviesFiltersSaga(action) {
  yield put(getShowsAndMoviesFilters.start());

  const params = action?.payload?.params || {};

  const partner = yield call(getPartnerSlug);
  const serializedParams = getSerializedParams({
    ...params,
    partner,
  });

  try {
    const isAuth = yield select(AuthSelectors.getIsAuth);

    if (isAuth) {
      yield userGetAppsSaga({ payload: { params: { status: appsListStatuses.have, partner } } });
    }

    const { data: respData } = yield axios.get(WATCH_LIST_FILTERS, serializedParams);

    yield put(getShowsAndMoviesFilters.success(respData));
  } catch (error) {
    yield put(getShowsAndMoviesFilters.fail());
  }
}

export function* getShowsAndMoviesSearchSaga(action) {
  yield put(getShowsAndMoviesSearch.start(action.payload));

  const { params } = action.payload;
  const partner = yield call(getPartnerSlug);
  const serializedParams = getSerializedParams({
    ...params,
    partner,
  });

  try {
    if (params.search) {
      const { data: respData } = yield axios.get(WATCH_LIST_SEARCH, serializedParams);

      yield put(getShowsAndMoviesSearch.success({ ...respData, page: 1 }));
    }

    yield put(getShowsAndMoviesSearch.stop());
  } catch (error) {
    yield put(getShowsAndMoviesSearch.fail());
  }
}

export function* getShowsMoviesSectionSaga(action) {
  const { section } = action.payload;
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const selectedApps = yield select(OnboardingSelectors.getSelectedApps);

  const apps = isAuth ? [] : selectedApps;

  try {
    yield call(swimlanesGetSaga, {
      payload: {
        params: { page: 'shows_and_movies', section, app_ids: apps.map(app => app.id) },
      },
    });
  } catch (error) {
    yield call(showErrorNotification, error);
  }
}

export function* getShowsMoviesCollectionsSaga() {
  yield all([
    call(getShowsMoviesSectionSaga, { payload: { section: 1 } }),
    call(getShowsMoviesSectionSaga, { payload: { section: 2 } }),
    call(getShowsMoviesSectionSaga, { payload: { section: 3 } }),
    call(getShowsMoviesSectionSaga, { payload: { section: 4 } }),
  ]);
}
