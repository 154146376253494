import numeral from 'numeral';
import currenciesData from '@/constants/currency';

const getCurrencyInfo = currency => {
  const currencyData = currenciesData.find(data => data.currency === currency);

  return currencyData || {};
};

export const formatPrice = (price, format = '0[.]00') => {
  return numeral(price).format(format);
};

export function formatBalance(price, prefix = '') {
  return formatPrice(price, `${prefix}0.00`);
}

export default getCurrencyInfo;
