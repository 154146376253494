import * as Yup from 'yup';

// CONSTANTS
import * as validationConst from '@/constants/validation';

// UTILITY
import { isRequiredField } from '@/utility/common';

export default (t, data) => {
  return {
    household: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('household', reqQuestions) ? schema.required() : schema;
    }),
    zipcode: Yup.string()
      .min(
        validationConst.MIN_ZIP_CODE_LENGTH,
        t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
      )
      .max(
        validationConst.MAX_ZIP_CODE_LENGTH,
        t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
      )
      .when('$requiredQuestions', (requiredQuestions, schema) => {
        const reqQuestions = requiredQuestions || data.requiredQuestions;

        if (!reqQuestions) return schema;

        return isRequiredField('zipcode', reqQuestions) ? schema.required() : schema;
      }),
    dma_id: Yup.string(),
    county_id_fk: Yup.string(),
    dvr: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('dvr', reqQuestions) ? schema.required() : schema;
    }),
    'already-have': Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('already-have', reqQuestions) ? schema.min(1) : schema;
    }),
    streaming: Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('streaming', reqQuestions) ? schema.min(1) : schema;
    }),
    content: Yup.array().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('content', reqQuestions) ? schema.min(1) : schema;
    }),
    'content-other': Yup.string(),
    sports: Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('sports', reqQuestions) ? schema.required() : schema;
    }),
    'device-included': Yup.string().when('$requiredQuestions', (requiredQuestions, schema) => {
      const reqQuestions = requiredQuestions || data.requiredQuestions;

      if (!reqQuestions) return schema;

      return isRequiredField('device-included', reqQuestions) ? schema.required() : schema;
    }),
    flow: Yup.string(),
  };
};
