import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PlaceIcon from '@material-ui/icons/Place';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

// UTILITY
import { getBillingAddress } from '@/utility/common';

// HOOKS
import { useVirtualCard } from '@/hooks/useVirtualCardData';

// HELP
import FieldWrap from '../FieldWrap';

// ASSETS
import { ReactComponent as MLogoSvg } from '@/assets/images/logo-mybundle-white.svg';
import { ReactComponent as VirtualCardIconSvg } from '@/assets/images/cc-icon.svg';

// STYLES
import { Container } from './styles';

const VirtualCard = ({ wide, size, withoutAnimation, withoutCopy }) => {
  const [t] = useTranslation();
  const { card, playAnimation } = useSelector(({ auth, streamingRewards }) => ({
    card: auth.user?.card,
    playAnimation: !!streamingRewards.playAnimation,
  }));
  const downXs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const isSmallSize = downXs || size === 'small';
  const { cardNumber, cardCVC, expDate, handleInputFieldClick } = useVirtualCard();

  const handleInputClick = name => {
    if (withoutCopy) return;

    handleInputFieldClick(name);
  };

  const isWideMode = wide === null ? playAnimation && !withoutAnimation && !downXs : wide;

  const inputAdornment = !withoutCopy && (
    <InputAdornment position="end">
      <IconButton>
        <FilterNoneIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <Container
      className={clsx('card-container', {
        'card-container--wide': isWideMode,
        'card-container--withoutCopy': withoutCopy,
      })}
    >
      <div
        className={clsx('virtual-card', {
          'virtual-card--small': isSmallSize,
        })}
      >
        <div className="virtual-card__title">{!isWideMode && <MLogoSvg />}</div>
        <div className="virtual-card__mb-icon">
          <i className="icon-mb-icon-2" />
        </div>
        <Grid className="virtual-card__content" spacing={isWideMode ? 2 : 1} container>
          <Grid item xs={8} sm={isSmallSize ? 8 : 7}>
            <FieldWrap onClick={() => handleInputClick('number')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.creditCardNum')}
                value={cardNumber}
                name="number"
                type="text"
                onClick={() => handleInputClick('number')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          <Grid item xs={4} sm={isSmallSize ? 4 : 3}>
            <FieldWrap onClick={() => handleInputClick('expDate')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.expDate_short')}
                defaultValue={expDate}
                onClick={() => handleInputClick('expDate')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          <Grid item xs={3} sm={isSmallSize ? 3 : 2}>
            <FieldWrap onClick={() => handleInputClick('cvc')} withoutCopy={withoutCopy}>
              <TextField
                disabled
                variant="standard"
                label={t('inputs.cvc')}
                value={cardCVC}
                name="cvc"
                type="text"
                onClick={() => handleInputClick('cvc')}
                InputProps={{
                  endAdornment: inputAdornment,
                }}
              />
            </FieldWrap>
          </Grid>
          {card?.billing && (
            <Grid className="virtual-card__billing" item xs={9} sm={isSmallSize ? 9 : 12}>
              <Grid alignItems="center" container spacing={1} className="billing">
                <Grid item xs={12}>
                  <Typography className="billing__label">
                    {t('streamingRewards.card.billing')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} wrap="nowrap" alignItems="center">
                    <Grid className="billing__icon-container" item xs="auto">
                      <Box display="flex">
                        <PlaceIcon className="billing__place-icon" />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrap
                        onClick={() => handleInputClick('billingAddress')}
                        withoutCopy={withoutCopy}
                      >
                        <Box
                          display="flex"
                          alignItems="flex-end"
                          onClick={() => handleInputClick('billingAddress')}
                        >
                          <Typography className="billing__text">
                            {getBillingAddress(card.billing)}
                          </Typography>
                          {!withoutCopy && (
                            <IconButton className="billing__copy-icon">
                              <FilterNoneIcon />
                            </IconButton>
                          )}
                        </Box>
                      </FieldWrap>
                    </Grid>
                    <Grid className="billing__icon-wrap" item xs="auto">
                      <Box display="flex">
                        <VirtualCardIconSvg className="billing__icon" />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Container>
  );
};

VirtualCard.defaultProps = {
  wide: null,
  size: 'default',
  withoutAnimation: false,
  withoutCopy: true,
};

VirtualCard.propTypes = {
  wide: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default'])]),
  withoutAnimation: PropTypes.bool,
  withoutCopy: PropTypes.bool,
};

export default React.memo(VirtualCard);
