import produce from 'immer';

// UTILITY
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

// STORE
import { addToWatchList, removeFromWatchList } from '@/store/actions/watchList';
import { changeContentStatus } from '@/store/actions/content';
import {
  getShowsAndMoviesList,
  cleanShowsAndMoviesList,
  getShowsAndMoviesFilters,
  getShowsAndMoviesSearch,
  cleanShowsAndMoviesSearch,
} from '../actions/showsAndMovies';

const initialState = {
  list: {
    data: [],
    meta: {
      current_page: 1,
      last_page: false,
      per_page: 24,
    },
  },
  filters: null,
  search: {
    data: [],
    string: '',
    meta: {
      current_page: 1,
      last_page: false,
      per_page: 10,
    },
  },
  loadings: {
    list: false,
    filters: true,
    search: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET SHOWS FOR WATCH LIST
    case getShowsAndMoviesList.types.START:
      state.loadings.list = true;
      return state;

    case getShowsAndMoviesList.types.SUCCESS:
      state.list.data =
        action.payload.page === 1
          ? action.payload.data
          : state.list.data.concat(action.payload.data);
      state.list.meta.current_page = action.payload.meta.current_page;
      state.list.meta.last_page = Boolean(action.payload.links.next);
      state.list.meta.per_page = action.payload.meta.per_page;
      state.loadings.list = false;
      return state;

    case getShowsAndMoviesList.types.FAIL:
      state.loadings.list = false;
      return state;

    // CLEAN SHOWS FOR WATCH LIST
    case cleanShowsAndMoviesList.types.INIT:
      state.list = initialState.list;
      state.loadings.list = action.payload.params
        ? action.payload.params.loading
        : initialState.loadings.list;
      return state;

    // GET FILTERS
    case getShowsAndMoviesFilters.types.START:
      state.loadings.filters = true;
      return state;

    case getShowsAndMoviesFilters.types.SUCCESS:
      state.filters = action.payload.data;
      state.loadings.filters = false;
      return state;

    case getShowsAndMoviesFilters.types.FAIL:
      state.loadings.filters = false;
      return state;

    // GET SHOWS AND MOVIES SEARCH
    case getShowsAndMoviesSearch.types.START:
      state.search.string = action.payload.params.search;
      state.loadings.search = true;
      return state;

    case getShowsAndMoviesSearch.types.SUCCESS:
      state.search.data = action.payload.data;
      state.search.meta.current_page = action.payload.meta.current_page;
      state.search.meta.last_page = Boolean(action.payload.links.next);
      state.search.meta.per_page = action.payload.meta.per_page;
      return state;

    case getShowsAndMoviesSearch.types.STOP:
    case getShowsAndMoviesSearch.types.FAIL:
      state.loadings.search = false;
      return state;

    // CLEAN SHOWS AND MOVIES SEARCH CONTENT
    case cleanShowsAndMoviesSearch.types.INIT:
      state.search = initialState.search;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;
      const {
        list: { data },
      } = state;

      state.list.data = data.map(item => {
        if (item.id === content_id) {
          const wlAction = isAdd ? addWatchlists : removeWatchlists;
          const watchlists = wlAction(item.watchlists, watch_lists);

          return { ...item, watchlists, in_watchlist: !!watchlists.length };
        }
        return item;
      });

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;

      if (state.list.data.length) {
        state.list.data = state.list.data.map(item => {
          return updateUserContentStatus(item, content_id, status);
        });
      }

      return state;
    }

    default:
      return state;
  }
});

export default reducer;
