export default {
  title: 'MyBundle generates the best Sling TV Package for you',
  subtitle:
    "Select as many channels as you'd like, and we'll generate the best Sling package that suits your needs.",
  tax: 'tax calculated at checkout',
  taxShort: 'not include tax',
  missedChannel: 'channel is missing',
  popularSection: {
    title: 'Popular Channels',
  },
  selectedChannels: {
    title: 'Selected channels',
    text: 'You’ll get <b>{{range}}</b> of your selected channels with this package.',
  },
  loaders: {
    default: 'We are calculating over a billion combinations\nto find the right one for you',
  },
  noResults: {
    title: 'No Results Found',
    subtitle:
      'There are no bundles that contain any of the channels you selected. Please, edit your selected channels.',
  },
};
