import { all, put, call, select, delay } from 'redux-saga/effects';
import { stringify } from 'query-string';

// CONSTANTS
import {
  WP_GET_POSTS,
  WP_GET_CATEGORIES,
  WP_GET_USER,
  WP_GET_PAGES,
  WP_GET_POSITION_CATEGORIES,
  WP_GET_POSITIONS,
  WP_GET_NEWS_CATEGORIES,
  WP_GET_NEWS,
} from '@/constants/api-wp';
import { axiosWP } from '@/store/configDefaultAPI';

// UTILITY
import { formatExtLinks } from '@/utility/common';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { WPBlogActions } from '@/store/actions';
import { AuthSelectors } from '@/store/selectors';

const {
  getWPPosts,
  getWPFeaturedPost,
  getWPCategories,
  getInsights,
  getWPAuthor,
  getWPCategory,
  getWPPost,
  getWPRelatedPosts,
  getWPPage,
  getCareers,
  getNews,
} = WPBlogActions;

function* formatPostContent(content) {
  const storeUniqueId = yield select(AuthSelectors.getUniqueId);
  const storeAnonymousId = yield select(AuthSelectors.getAnonymousId);
  const unique_id = storeUniqueId || storeAnonymousId;

  return formatExtLinks(content, { unique_id });
}

function* getUpdatedWPData(data) {
  let updatedData = { ...data };
  const content = data?.content?.rendered;

  if (content) {
    const updatedContent = yield formatPostContent(content);

    updatedData = {
      ...updatedData,
      content: {
        ...updatedData.content,
        rendered: updatedContent,
      },
    };
  }

  return updatedData;
}

const getPartnerSlug = ({ app, partner }) => app.partner || partner.data.slug || '';

export function* getWPPostsSaga(action) {
  yield put(getWPPosts.start());

  const partner = yield select(getPartnerSlug);

  let url = WP_GET_POSTS;
  let params = {};

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  try {
    const { data: respData, headers } = yield axiosWP.get(url, {
      params: { _embed: true, per_page: 12, partner, ...params },
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          skipEmptyString: true,
        });
      },
    });

    yield put(
      getWPPosts.success({
        data: respData,
        page: action?.payload?.params?.page || 1,
        totalpages: +headers['x-wp-totalpages'],
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPPosts.fail());
  }
}

export function* getWPFeaturedPostSaga(action) {
  yield put(getWPFeaturedPost.start());

  const partner = yield select(getPartnerSlug);

  let url = WP_GET_POSTS;

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  try {
    const { data: respData } = yield axiosWP.get(url, {
      params: {
        meta_key: '_is_ns_featured_post',
        meta_value: 'yes',
        _embed: true,
        context: 'embed',
        partner,
      },
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          skipEmptyString: true,
        });
      },
    });

    yield put(getWPFeaturedPost.success({ data: respData[0] || {} }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPFeaturedPost.fail());
  }
}

export function* getWPCategoriesSaga(action) {
  yield put(getWPCategories.start());

  const partner = yield select(getPartnerSlug);

  let url = WP_GET_CATEGORIES;
  let params = {
    per_page: 99,
    hide_empty: true,
    partner,
  };

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  if (action?.payload?.params) {
    params = {
      ...params,
      ...action.payload.params,
    };
  }

  try {
    const { data: respData } = yield axiosWP.get(url, {
      params,
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          skipEmptyString: true,
        });
      },
    });

    yield put(getWPCategories.success({ data: respData }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPCategories.fail());
  }
}

export function* getWPInsightsSaga(action) {
  yield put(getInsights.start());

  let params = {};

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  try {
    yield all([
      call(getWPFeaturedPostSaga),
      call(getWPCategoriesSaga),
      call(getWPPostsSaga, { payload: { params } }),
    ]);

    yield put(getInsights.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getInsights.fail());
  }
}

export function* getWPAuthorSaga(action) {
  yield put(getWPAuthor.start());

  let params = {};
  let postUrl = '';

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  if (action?.payload?.url) {
    postUrl = action.payload.url;
  }

  try {
    const { data: respData } = yield axiosWP.get(WP_GET_USER, {
      params,
    });

    if (respData[0]) {
      yield call(getWPPostsSaga, { payload: { url: postUrl, params: { author: respData[0].id } } });
    }

    yield put(getWPAuthor.success({ data: respData[0] || {} }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPAuthor.fail());
  }
}

export function* getWPCategorySaga(action) {
  yield put(getWPCategory.start());

  const partner = yield select(getPartnerSlug);

  const type = action?.payload?.type || 'blog';
  let params = {};
  let url = WP_GET_CATEGORIES;
  let postUrl = WP_GET_POSTS;

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  if (action?.payload?.postUrl) {
    postUrl = action.payload.postUrl;
  }

  try {
    const { data: respData } = yield axiosWP.get(url, {
      params,
    });

    if (respData[0]) {
      const { data: stickyPostsData } = yield axiosWP.get(postUrl, {
        params: {
          _embed: true,
          meta_key: 'category_sticky',
          meta_value: respData[0].id,
          partner,
        },
        paramsSerializer: paramsData => {
          return stringify(paramsData, {
            skipEmptyString: true,
          });
        },
      });

      respData[0].stickyPost = stickyPostsData[0];

      const categoriesSlug = type === 'news' ? 'news_category' : 'categories';

      yield call(getWPPostsSaga, {
        payload: { url: postUrl, params: { [categoriesSlug]: respData[0].id } },
      });
    }

    yield put(getWPCategory.success({ data: respData[0] || {} }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPCategory.fail());
  }
}

export function* getWPPostSaga(action) {
  yield put(getWPPost.start());

  const { slug } = action.payload;
  const partner = yield select(getPartnerSlug);

  let url = WP_GET_POSTS;

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  try {
    const { data: respData } = yield axiosWP.get(url, {
      params: { slug, _embed: true, partner },
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          skipEmptyString: true,
        });
      },
    });

    let postData = {};

    if (respData[0]) {
      postData = yield call(getUpdatedWPData, respData[0]);
    }

    yield put(getWPPost.success({ data: postData }));

    // Delay for waiting run scripts smoothly
    yield delay(200);

    yield put(getWPPost.stop());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPPost.fail());
  }
}

export function* getWPRelatedPostsSaga(action) {
  yield put(getWPRelatedPosts.start());

  const partner = yield select(getPartnerSlug);
  let url = WP_GET_POSTS;
  let params = {};

  if (action?.payload?.url) {
    url = action.payload.url;
  }

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  try {
    const { data: respData } = yield axiosWP.get(url, {
      params: { _embed: true, context: 'embed', per_page: 3, partner, ...params },
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          arrayFormat: 'bracket',
          skipEmptyString: true,
        });
      },
    });

    yield put(getWPRelatedPosts.success({ data: respData }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPRelatedPosts.fail());
  }
}

export function* getWPPageSaga(action) {
  yield put(getWPPage.start());

  const partner = yield select(getPartnerSlug);
  const { slug } = action.payload;

  try {
    const { data: respData } = yield axiosWP.get(WP_GET_PAGES, {
      params: { slug, _embed: true, partner },
      paramsSerializer: paramsData => {
        return stringify(paramsData, {
          skipEmptyString: true,
        });
      },
    });

    let postData = {};

    if (respData[0]) {
      postData = yield call(getUpdatedWPData, respData[0]);
    }

    yield put(getWPPage.success({ data: postData }));

    // Delay for waiting run scripts smoothly
    yield delay(200);

    yield put(getWPPage.stop());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWPPage.fail());
  }
}

export function* getWPCareersSaga() {
  yield put(getCareers.start());

  try {
    yield all([
      call(getWPPageSaga, { payload: { slug: 'careers' } }),
      call(getWPCategoriesSaga, { payload: { url: WP_GET_POSITION_CATEGORIES } }),
      call(getWPPostsSaga, {
        payload: { url: WP_GET_POSITIONS, params: { per_page: 20 } },
      }),
    ]);

    yield put(getCareers.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getCareers.fail());
  }
}

export function* getWPNewsSaga(action) {
  yield put(getNews.start());

  let params = {};

  if (action?.payload?.params) {
    params = action.payload.params;
  }

  try {
    yield all([
      call(getWPFeaturedPostSaga, { payload: { url: WP_GET_NEWS } }),
      call(getWPCategoriesSaga, { payload: { url: WP_GET_NEWS_CATEGORIES } }),
      call(getWPPostsSaga, { payload: { url: WP_GET_NEWS, params } }),
    ]);

    yield put(getNews.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getNews.fail());
  }
}
