import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// ROUTES
import PrivetRoute from '@/routes/help/PrivateRoute';

// STORE
import { AuthActions } from '@/store/actions';

// COMPONENTS
import Header from '@/components/Headers/Header';
import Footer from '@/components/Footer';

// HELP
import BannerNotification from './help/BannerNotification';

// STYLES
import { Wrapper, ProgressWrap, ContentWrap } from '../styles';

export const loader = (
  <ProgressWrap className="loader">
    <CircularProgress />
  </ProgressWrap>
);

const RouterLayout = props => {
  const {
    type,
    component: Component,
    componentProps,
    isAuth,
    protection,
    isIframe,
    headerProps,
    ...rest
  } = props;
  let Structure;

  switch (type) {
    case 'home':
      Structure = (
        <Grid container wrap="nowrap" direction="row">
          <Wrapper item>
            <Suspense fallback={loader}>
              {!isIframe && <Header />}
              <ContentWrap>
                <Component {...componentProps} />
              </ContentWrap>
              {!isIframe && <Footer />}
            </Suspense>
          </Wrapper>
        </Grid>
      );
      break;
    case 'main':
      Structure = (
        <Grid container wrap="nowrap" direction="row">
          <Wrapper item>
            <Suspense fallback={loader}>
              {!isIframe && <Header {...headerProps} />}
              {!isIframe && <BannerNotification />}
              <ContentWrap>
                <Component {...componentProps} />
              </ContentWrap>
              {!isIframe && <Footer className="page-footer" />}
            </Suspense>
          </Wrapper>
        </Grid>
      );
      break;
    case 'main-without-shadow':
      Structure = (
        <Grid container wrap="nowrap" direction="row">
          <Wrapper item>
            <Suspense fallback={loader}>
              {!isIframe && <Header withoutShadow />}
              <ContentWrap>
                <Component {...componentProps} />
              </ContentWrap>
              {!isIframe && <Footer className="page-footer" />}
            </Suspense>
          </Wrapper>
        </Grid>
      );
      break;
    case 'watch-next':
    case 'profile':
    case 'home-auth':
    case 'subscription':
    case 'live-tv':
    case 'sc-claim':
      Structure = (
        <Grid container wrap="nowrap" direction="row">
          <Wrapper item>
            <Suspense fallback={loader}>
              {!isIframe && <Header />}
              <ContentWrap>
                <Component {...componentProps} />
              </ContentWrap>
            </Suspense>
          </Wrapper>
        </Grid>
      );
      break;
    case 'document':
      Structure = (
        <Suspense fallback={loader}>
          <Component {...componentProps} />
        </Suspense>
      );
      break;
    default:
      Structure = (
        <Suspense fallback={loader}>
          <Component {...componentProps} />
        </Suspense>
      );
  }

  return protection ? <PrivetRoute {...rest} isAuth={isAuth} component={Structure} /> : Structure;
};

RouterLayout.defaultProps = {
  type: '',
  protection: false,
  headerProps: {},
  componentProps: {},
};

RouterLayout.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  protection: PropTypes.bool,
  isAuth: PropTypes.bool,
  isIframe: PropTypes.bool.isRequired,
  component: PropTypes.object,
  componentProps: PropTypes.object,
  headerProps: PropTypes.object,
  manageAuthDialogState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, app }) => ({
  isAuth: auth.isAuth,
  isIframe: app.isIframe,
});

const mapDispatchToProps = dispatch => {
  return {
    manageAuthDialogState: (state = true, props) =>
      dispatch(AuthActions.manageAuthDialogState.action({ state, props })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterLayout);
