import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

// CONSTANTS
import { CONTENT_CHANGE_STATUS, CONTENT_MEDIA } from '@/constants/api';
import { contentTypes } from '@/constants/watchList';

// UTILITY
import { getLink } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import {
  ContentActions,
  ShowDetailsActions,
  MovieDetailsActions,
  UserActions,
} from '@/store/actions';
import { AuthSelectors } from '@/store/selectors';

const { getContentMedia, changeContentStatus } = ContentActions;
const { updateMovieMedia } = MovieDetailsActions;
const { updateShowMedia } = ShowDetailsActions;
const { userGetContentCount } = UserActions;

export function* getContentMediaSaga(action) {
  yield put(getContentMedia.start());

  const { content_id, type } = action.payload;
  const url = getLink(CONTENT_MEDIA, { content_id });

  try {
    const { data: respData } = yield axios.get(url);

    if (type === contentTypes.movie) {
      yield put(updateMovieMedia.init({ data: respData }));
    } else if (type === contentTypes.show) {
      yield put(updateShowMedia.init({ data: respData }));
    }

    yield put(getContentMedia.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getContentMedia.fail());
  }
}

export function* changeContentStatusSaga(action) {
  const { content_id, status, callback } = action.payload;

  yield put(changeContentStatus.start({ content_id }));

  const url = getLink(CONTENT_CHANGE_STATUS, { content_id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield axios.post(url, { status });

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    yield put(changeContentStatus.success({ content_id, status }));

    if (callback) callback();

    yield put(changeContentStatus.stop({ content_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(changeContentStatus.fail({ content_id }));
  }
}

export default getContentMediaSaga;
