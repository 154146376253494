import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';

// CONSTANTS
import { ROUTE_ROOT } from '@/constants/routes';

// COMPONENTS
import NavLink from '@/components/NavLink';

// STYLES
import { ListItemStyled } from '../styles';

const defaultProps = {
  color: 'primary',
  children: <div />,
  max: 99,
};

const MenuItem = ({ t, data, count, blink, handleMenuItem, location }) => {
  const children = (
    <>
      <ListItemText primary={t(data.label)} />
      {!!count && <Badge badgeContent={count} {...defaultProps} />}
    </>
  );

  if (data.component) {
    return (
      <ListItemStyled className={`list-item ${blink ? 'blink' : ''}`} button {...data}>
        {children}
      </ListItemStyled>
    );
  }

  const isStreamingChoice = t(data.label) === 'Streaming Choice';

  return (
    <ListItemStyled
      className={clsx('list-item', blink && 'blink', isStreamingChoice && 'streaming-choice')}
      button
      component={NavLink}
      onClick={e => handleMenuItem(e, data)}
      to={data.route}
      end={data.route === ROUTE_ROOT}
      location={location}
    >
      {children}
    </ListItemStyled>
  );
};

MenuItem.defaultProps = {
  count: 0,
  blink: false,
  location: '',
};

MenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  blink: PropTypes.bool,
  data: PropTypes.object.isRequired,
  count: PropTypes.number,
  handleMenuItem: PropTypes.func.isRequired,
  location: PropTypes.string,
};

export default React.memo(MenuItem);
