import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import { UNIVERSAL_SEARCH_GET } from '@/constants/api';

// UTILITY
import { showErrorNotification } from '@/utility/saga';
import { getSerializedParams } from '@/utility/routes';

// STORE
import { PartnerSelectors } from '@/store/selectors';
import { SearchActions } from '@/store/actions';

const { getSearchData } = SearchActions;

export function* getSearchDataSaga(action) {
  yield put(getSearchData.start());

  const { params } = action.payload;
  const partnerData = yield select(PartnerSelectors.getPartnerData);
  const partner = partnerData.slug ? { partner: partnerData.slug } : {};

  const serializedParams = getSerializedParams({
    ...params,
    ...partner,
  });

  try {
    const { data: respData } = yield axios.get(UNIVERSAL_SEARCH_GET, serializedParams);

    yield put(
      getSearchData.success({
        data: respData,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getSearchData.fail());
  }
}

export default getSearchDataSaga;
