import produce from 'immer';

import {
  payFunds,
  cancelRecurringPayment,
  resetState,
  getSRClientSecret,
  addSRFunds,
  retrievePaymentStatus,
} from '../actions/streamingRewardsFunds';

const initialState = {
  clientSecret: '',
  paymentIntent: '',
  loadings: {
    payment: false,
    cancel: false,
    clientSecret: false,
    addFunds: false,
    retrievePaymentStatus: false,
  },
  errors: {
    payment: null,
    cancel: null,
  },
  warnings: {
    payment: null,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // PAY FUNDS
    case payFunds.types.START:
      state.errors.payment = null;
      state.loadings.payment = true;
      return state;

    case payFunds.types.SUCCESS:
      state.loadings.payment = false;
      return state;

    case payFunds.types.FAIL:
      state.errors.payment = action.payload.message;
      state.loadings.payment = false;
      return state;

    // CANCEL RECURRING PAYMENT
    case cancelRecurringPayment.types.START:
      state.errors.cancel = null;
      state.loadings.cancel = true;
      return state;

    case cancelRecurringPayment.types.SUCCESS:
      state.loadings.cancel = false;
      return state;

    case cancelRecurringPayment.types.FAIL:
      state.errors.cancel = action.payload.message;
      state.loadings.cancel = false;
      return state;

    // RESET STATE
    case resetState.type:
      state.clientSecret = '';
      state.paymentIntent = '';
      state.errors = initialState.errors;
      state.warnings = initialState.warnings;
      return state;

    // GET CLIENT SECRET
    case getSRClientSecret.types.START:
      state.clientSecret = '';
      state.paymentIntent = '';
      state.warnings.payment = null;
      state.errors.payment = null;
      state.loadings.clientSecret = true;
      return state;

    case getSRClientSecret.types.SUCCESS:
      state.clientSecret = action.payload.clientSecret;
      state.paymentIntent = action.payload.paymentIntent;
      return state;

    case getSRClientSecret.types.FAIL:
    case getSRClientSecret.types.STOP:
      state.loadings.clientSecret = false;
      return state;

    // ADD FUNDS
    case addSRFunds.types.START:
      state.warnings.payment = null;
      state.errors.payment = null;
      state.loadings.payment = true;
      return state;

    case addSRFunds.types.SUCCESS:
      state.loadings.payment = false;
      return state;

    case addSRFunds.types.FAIL:
      state.loadings.payment = false;

      if (action.payload.warning) {
        state.warnings.payment = action.payload.message;
      } else {
        state.errors.payment = action.payload.message;
      }

      return state;

    // RETRIEVE PAYMENT STATUS
    case retrievePaymentStatus.types.START:
      state.loadings.retrievePaymentStatus = true;
      return state;

    case retrievePaymentStatus.types.SUCCESS:
      state.loadings.retrievePaymentStatus = false;
      return state;

    case retrievePaymentStatus.types.FAIL:
      state.loadings.retrievePaymentStatus = false;

      if (action.payload.warning) {
        state.clientSecret = '';
        state.paymentIntent = '';
        state.warnings.payment = action.payload.message;
      } else {
        state.errors.payment = action.payload.message;
      }

      return state;

    default:
      return state;
  }
});

export default reducer;
