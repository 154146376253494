import * as Yup from 'yup';

// CONSTANTS
import * as validationConst from '@/constants/validation';

// UTILITY
import { isRequiredField } from '@/utility/common';

export default t => {
  return {
    zipcode: Yup.string()
      .min(
        validationConst.MIN_ZIP_CODE_LENGTH,
        t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
      )
      .max(
        validationConst.MAX_ZIP_CODE_LENGTH,
        t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
      )
      .when('$requiredQuestions', (requiredQuestions = [], schema) => {
        return isRequiredField('zipcode', requiredQuestions) ? schema.required() : schema;
      }),
    dma_id: Yup.string(),
    county_id_fk: Yup.string(),
  };
};
