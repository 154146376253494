import produce from 'immer';

// UTILITY
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

import { addToWatchList, removeFromWatchList } from '../actions/watchList';
import { changeContentStatus } from '../actions/content';

import {
  getMovieDetails,
  subscribeToChanges,
  getMovieStreamingData,
  getSimilarMovies,
  updateMovieMedia,
} from '../actions/movieDetails';

const initialState = {
  data: {},
  media: {},
  streamingData: null,
  streamingDataTemp: null,
  similarMovies: null,
  loadings: {
    data: true,
    streamingData: false,
    streamingDataTemp: false,
    similarMovies: false,
    subscribe: false,
  },
  errors: {
    data: null,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET MOVIE DETAILS
    case getMovieDetails.types.START:
      state.loadings.data = true;
      state.media = {};
      return state;

    case getMovieDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getMovieDetails.types.FAIL:
      state.loadings.data = false;
      return state;

    // GET MOVIE STREAMING DATA
    case getMovieStreamingData.types.START: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state[streamingDataKey] = null;
      state.loadings[streamingDataKey] = true;

      return state;
    }

    case getMovieStreamingData.types.SUCCESS: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state[streamingDataKey] = action.payload.data;
      state.loadings[streamingDataKey] = false;

      return state;
    }

    case getMovieStreamingData.types.FAIL: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state.loadings[streamingDataKey] = false;

      return state;
    }

    // GET SIMILAR MOVIES
    case getSimilarMovies.types.START:
      state.similarMovies = null;
      state.loadings.similarMovies = true;
      return state;

    case getSimilarMovies.types.SUCCESS:
      state.similarMovies = action.payload.data;
      state.loadings.similarMovies = false;
      return state;

    case getSimilarMovies.types.FAIL:
      state.loadings.similarMovies = false;
      return state;

    // SUBSCRIBE TO CHANGES
    case subscribeToChanges.types.START:
      state.loadings.subscribe = true;
      return state;

    case subscribeToChanges.types.SUCCESS:
      if (state.data.movie)
        state.data.movie.subscription_status =
          action.payload.isAuth && action.payload.content_id === state.data.movie.id
            ? action.payload.status
            : state.data.movie.subscription_status;
      state.loadings.subscribe = false;
      return state;

    case subscribeToChanges.types.FAIL:
      state.loadings.subscribe = false;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;
      const {
        data: { movie },
        similarMovies,
      } = state;

      if (movie && content_id === movie.id) {
        const wlAction = isAdd ? addWatchlists : removeWatchlists;
        const watchlists = wlAction(movie.watchlists, watch_lists);

        state.data.movie.watchlists = watchlists;
        state.data.movie.in_watchlist = !!watchlists.length;
      }

      state.similarMovies =
        similarMovies &&
        similarMovies.map(item => {
          if (item.id === content_id) {
            const wlAction = isAdd ? addWatchlists : removeWatchlists;
            const watchlists = wlAction(item.watchlists, watch_lists);

            return { ...item, watchlists, in_watchlist: !!watchlists.length };
          }
          return item;
        });

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;
      const {
        data: { movie },
        similarShows,
      } = state;

      if (movie && content_id === movie.id) state.data.movie.user_content_status = status;
      state.similarShows =
        similarShows && similarShows.map(item => updateUserContentStatus(item, content_id, status));

      return state;
    }

    // UPDATE MEDIA
    case updateMovieMedia.types.INIT:
      state.media = action.payload.data;
      return state;

    default:
      return state;
  }
});

export default reducer;
