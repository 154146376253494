import * as Yup from 'yup';

// CONST
import { MAX_WATCHLIST_NAME_LENGTH } from '@/constants/validation';

export default t => {
  return {
    name: Yup.string()
      .required(t('validation.nameRequired'))
      .min(1)
      .max(
        MAX_WATCHLIST_NAME_LENGTH,
        t('validation.maxNameLength', { length: MAX_WATCHLIST_NAME_LENGTH }),
      ),
  };
};
