import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// STYLES
import LoaderContainer from './styles';

const Loader = ({ active, fullFilled, text, position, zIndex, typographyProps, ...props }) => {
  return (
    <>
      {active && (
        <LoaderContainer
          className="loader"
          position={position}
          fullFilled={fullFilled}
          zIndex={zIndex}
          {...props}
        >
          <CircularProgress />
          {text && (
            <Typography className="loader__text" {...typographyProps}>
              {text}
            </Typography>
          )}
        </LoaderContainer>
      )}
    </>
  );
};

Loader.defaultProps = {
  active: false,
  fullFilled: false,
  bgColor: '',
  text: '',
  position: 'absolute',
  zIndex: '',
  typographyProps: {},
};

Loader.propTypes = {
  active: PropTypes.bool,
  fullFilled: PropTypes.bool,
  bgColor: PropTypes.string,
  text: PropTypes.string,
  position: PropTypes.string,
  zIndex: PropTypes.string,
  typographyProps: PropTypes.object,
};

export default React.memo(Loader);
