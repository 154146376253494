import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// UTILITY
import {
  analyticsChooseSearchItem,
  analyticsLeftSearch,
  analyticsNoSearchResults,
  analyticsSearched,
} from '@/utility/analytics';

import { AutocompleteWrapper, SearchItem, NoResults } from './styles';

const AutocompleteF = React.forwardRef((props, ref) => {
  return <Autocomplete {...props} ref={ref} />;
});

const SearchAutoComplete = props => {
  const { loading, inputProps, analyticsLocation, ...restProps } = props;

  const [t] = useTranslation();

  const [open, setOpen] = useState(false);
  const itemWasSelected = useRef(false);

  useEffect(() => {
    if (restProps.options.length) {
      const properties = {
        query: restProps.inputValue,
        numberOfItemsDisplayed: restProps.options.length,
        first_item_type: 'app',
        first_item_name: restProps.options[0].name,
        first_item_id: restProps.options[0].id,
        search_location: analyticsLocation,
      };

      analyticsSearched(properties);
    }
  }, [restProps.options]);

  const isNoResults =
    open && !loading && !restProps.options.length && !!restProps.inputValue.length;

  useEffect(() => {
    if (isNoResults) {
      analyticsNoSearchResults({ query: restProps.inputValue, search_location: analyticsLocation });
    }
  }, [restProps.options, loading]);

  const handleOpen = state => {
    setOpen(state);
  };

  const handleChange = (event, value, reason) => {
    if (reason === 'select-option') {
      itemWasSelected.current = true;
    } else if (reason === 'clear') {
      itemWasSelected.current = false;
    }

    const index = restProps.options.findIndex(option => option.id === value.id);

    const properties = {
      query: restProps.inputValue,
      numberOfItemsDisplayed: restProps.options.length,
      type: 'app',
      title: value.name,
      id: value.id,
      positionOfItem: index + 1,
      search_location: analyticsLocation,
    };

    analyticsChooseSearchItem(properties);
    restProps.onChange(event, value, reason, handleOpen);
  };

  const onOpen = e => {
    itemWasSelected.current = false;
    handleOpen(true);
    restProps.onOpen(e);
  };

  const handleClose = (e, reason) => {
    if (e?.keyCode === 13 && (reason === 'reset' || reason === 'create-option')) {
      return;
    }

    if (reason === 'blur' && !itemWasSelected.current) {
      const properties = {
        query: restProps.inputValue,
        numberOfResults: restProps.options.length,
        search_location: analyticsLocation,
      };

      analyticsLeftSearch(properties);

      if (itemWasSelected.current) itemWasSelected.current = false;
    }

    handleOpen(false);
    restProps.onClose(e, reason);
  };

  return (
    <AutocompleteWrapper>
      <AutocompleteF
        open={open}
        {...restProps}
        onChange={handleChange}
        onOpen={onOpen}
        onClose={handleClose}
        blurOnSelect
        getOptionLabel={option => option.name || ''}
        filterOptions={options => {
          return options;
        }}
        loading={loading}
        forcePopupIcon={false}
        disableCloseOnSelect
        renderOption={option => (
          <SearchItem className="search-item">
            <div className="search-item__main-info">
              <div className="search-item__image">
                <img src={option.thumbnail} alt="" />
              </div>
              <div className="search-item__title">{option.name}</div>
            </div>
          </SearchItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            {...inputProps}
            placeholder={t('inputs.searchApps')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="text-color--default" position="start">
                  <i className="icon-search" />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress className="loading" size={16} /> : null}{' '}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        disablePortal
      />
      {isNoResults && <NoResults>{t('searchAutoComplete.noResults')}</NoResults>}
    </AutocompleteWrapper>
  );
};

SearchAutoComplete.defaultProps = {
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  inputProps: {},
  analyticsLocation: '',
};

SearchAutoComplete.propTypes = {
  loading: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  inputProps: PropTypes.object,
  analyticsLocation: PropTypes.string,
};

export default React.memo(SearchAutoComplete);
