import styled from 'styled-components';

// CONSTANTS
import { ATHENS_GRAY, MAIN_BLACK, SANTAS_GRAY } from '@/constants/colors';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

// COMPONENTS
import SearchAutoComplete from '@/components/Form/SearchAutoComplete';

export const SearchAutoCompleteStyled = styled(SearchAutoComplete)`
  .MuiOutlinedInput-root {
    background-color: ${ATHENS_GRAY};
    border-radius: 8px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${ATHENS_GRAY};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${ATHENS_GRAY};
  }

  .MuiOutlinedInput-input {
    font-size: 16px;
  }

  .MuiAutocomplete {
    &-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
    }

    &-endAdornment {
      top: calc(50% - 16px);
    }
  }
  .MuiInputAdornment {
    &-root {
      color: ${MAIN_BLACK};
    }

    &-marginDense {
      font-size: 20px;
    }
  }

  .MuiSvgIcon {
    &-fontSizeSmall {
      font-size: 15px;
    }
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    color: ${SANTAS_GRAY};
  }

  + .MuiAutocomplete-popper {
    top: calc(100% + 12px);

    ${breakpoints.up('sm')} {
      min-width: 300px;
    }

    .MuiAutocomplete-paper {
      border-radius: 8px;

      .MuiAutocomplete-listbox {
        .MuiAutocomplete-option {
          padding: 0;
          padding-top: 12px;

          .search-item {
            padding-bottom: 12px;
            border-bottom: 1px solid ${p => p.theme.palette.divider};
            margin: 0 10px;
            align-items: flex-start;

            &__image {
              width: 45px;
              min-width: 45px;
              height: auto;
              margin-right: 9px;

              img {
                max-height: none;
              }
            }

            &__title {
              width: auto;
              text-overflow: initial;
              overflow: initial;
              white-space: normal;
              word-break: break-word;
              font-size: 18px;
              line-height: 18px;
              font-weight: 600;
            }

            &__type {
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
            }
          }

          &:last-child {
            .search-item {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;

export default SearchAutoCompleteStyled;
