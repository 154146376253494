import axios from 'axios';
import { put } from 'redux-saga/effects';

// CONSTANTS
import { SHARE_INFO_GET } from '@/constants/api';

// UTILITY
import getErrorMessage from '@/utility/errors';
import { getLink } from '@/utility/routes';

// STORE
import { ShareActions } from '@/store/actions';

const { getShareInfo } = ShareActions;

export function* getShareInfoSaga(action) {
  yield put(getShareInfo.start());

  const { unique_id, cb, onError } = action.payload;
  const url = getLink(SHARE_INFO_GET, {
    unique_id,
  });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(
      getShareInfo.success({
        data: respData,
      }),
    );
  } catch (error) {
    yield put(getShareInfo.fail({ errorMessage: getErrorMessage(error) }));

    if (cb) cb();

    if (onError) onError();

    return;
  }

  if (cb) cb();
}

export default getShareInfoSaga;
