import { call, all, select } from 'redux-saga/effects';

// UTILITY
import { showErrorNotification } from '@/utility/saga';

// STORE
import { AuthSelectors, OnboardingSelectors } from '@/store/selectors';
import { swimlanesGetSaga } from './swimlanes';

export function* getHomeSectionSaga(action) {
  const { section, tab, browsebyservice } = action.payload;
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const selectedApps = yield select(OnboardingSelectors.getSelectedApps);

  const apps = isAuth ? [] : selectedApps;

  try {
    yield call(swimlanesGetSaga, {
      payload: {
        params: {
          page: 'home',
          section,
          tab,
          browsebyservice,
          app_ids: apps.map(app => app.id),
        },
      },
    });
  } catch (error) {
    yield call(showErrorNotification, error);
  }
}

export function* getHomeAuthContentSaga() {
  yield all([
    call(getHomeSectionSaga, { payload: { section: 1 } }),
    call(getHomeSectionSaga, { payload: { section: 2 } }),
    call(getHomeSectionSaga, { payload: { section: 3 } }),
  ]);
}

export function* getHomeNonAuthContentSaga() {
  yield all([call(getHomeSectionSaga, { payload: { section: 2, browsebyservice: 1 } })]);
}

export function* getPartnerHomeAuthContentSaga() {
  yield all([
    call(getHomeSectionSaga, { payload: { section: 1 } }),
    call(getHomeSectionSaga, { payload: { section: 2 } }),
    call(getHomeSectionSaga, { payload: { section: 3 } }),
  ]);
}
