import { createAction } from '@/utility/actions';

import {
  WP_BLOG_GET_INSIGHTS,
  WP_BLOG_GET_POSTS,
  WP_BLOG_GET_POST,
  WP_BLOG_GET_FEATURED_POST,
  WP_BLOG_GET_RELATED_POSTS,
  WP_BLOG_CLEAN_RELATED_POSTS,
  WP_BLOG_GET_CATEGORIES,
  WP_BLOG_GET_AUTHOR,
  WP_BLOG_CLEAR_POSTS,
  WP_BLOG_GET_CATEGORY,
  WP_BLOG_GET_PAGE,
  WP_BLOG_GET_CAREERS,
  WP_BLOG_GET_NEWS,
} from './types';

/**
 * getInsights - params: params(data)
 */
export const getInsights = createAction(WP_BLOG_GET_INSIGHTS);

/**
 * getWPPosts - params: params(data)
 */
export const getWPPosts = createAction(WP_BLOG_GET_POSTS);

/**
 * getWPPost - params: params(slug)
 */
export const getWPPost = createAction(WP_BLOG_GET_POST);

export const clearWPPosts = createAction(WP_BLOG_CLEAR_POSTS);

export const getWPFeaturedPost = createAction(WP_BLOG_GET_FEATURED_POST);

/**
 * getWPRelatedPosts - params: params(data)
 */
export const getWPRelatedPosts = createAction(WP_BLOG_GET_RELATED_POSTS);

export const cleanWPRelatedPosts = createAction(WP_BLOG_CLEAN_RELATED_POSTS);

/**
 * getWPCategory - url(string), params: params(data)
 */
export const getWPCategories = createAction(WP_BLOG_GET_CATEGORIES);

/**
 * getWPAuthor - params: params(data)
 */
export const getWPAuthor = createAction(WP_BLOG_GET_AUTHOR);

/**
 * getWPCategory- params: params(data)
 */
export const getWPCategory = createAction(WP_BLOG_GET_CATEGORY);

/**
 * getWPPage - params: params(slug)
 */
export const getWPPage = createAction(WP_BLOG_GET_PAGE);

/**
 * getCareers - params: params(data)
 */
export const getCareers = createAction(WP_BLOG_GET_CAREERS);

/**
 * getNews - params: params(data)
 */
export const getNews = createAction(WP_BLOG_GET_NEWS);

export default {
  getInsights,
  getWPPosts,
  clearWPPosts,
  getWPPost,
  getWPFeaturedPost,
  getWPRelatedPosts,
  cleanWPRelatedPosts,
  getWPCategories,
  getWPAuthor,
  getWPCategory,
  getWPPage,
  getCareers,
  getNews,
};
