import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, BLACK, MAIN_BLACK } from '@/constants/colors';

const quantum = {
  primary1: '#503296',
  primary2: '#7B8EE1',
  primary3: '#38C6F4',
  secondary1: '#2D005A',
  secondary2: '#D7E1E6',
  secondary3: '#788791',
  secondary4: '#3C414B',
};

const quantumStyles = css`
  /*
* The Typekit service used to deliver this font or fonts for use on websites
* is provided by Adobe and is subject to these Terms of Use
* http://www.adobe.com/products/eulas/tou_typekit. For font license
* information, see the list below.
*
* objektiv-mk2:
*   - http://typekit.com/eulas/00000000000000007735b56e
*   - http://typekit.com/eulas/00000000000000007735b570
*   - http://typekit.com/eulas/00000000000000007735b573
*   - http://typekit.com/eulas/00000000000000007735b576
*   - http://typekit.com/eulas/00000000000000007735b581
*   - http://typekit.com/eulas/00000000000000007735b582
* proxima-nova:
*   - http://typekit.com/eulas/000000000000000077359562
*   - http://typekit.com/eulas/000000000000000077359558
*   - http://typekit.com/eulas/000000000000000077359577
*   - http://typekit.com/eulas/00000000000000007735957a
* proxima-nova-condensed:
*   - http://typekit.com/eulas/00000000000000007735956b
*
* © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
*/
  /*{"last_published":"2021-01-25 19:52:41 UTC"}*/

  @import url('https://p.typekit.net/p.css?s=1&k=pbn2ufo&ht=tk&f=139.140.175.176.147.26977.26978.26979.26980.26983.26984&a=18322601&app=typekit&e=css');

  @font-face {
    font-family: 'proxima-nova';
    src: url('https://use.typekit.net/af/98e3f6/000000000000000077359562/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/98e3f6/000000000000000077359562/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/98e3f6/000000000000000077359562/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'proxima-nova';
    src: url('https://use.typekit.net/af/624cab/000000000000000077359558/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/624cab/000000000000000077359558/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/624cab/000000000000000077359558/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: 'proxima-nova';
    src: url('https://use.typekit.net/af/d45b9a/000000000000000077359577/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/d45b9a/000000000000000077359577/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/d45b9a/000000000000000077359577/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'proxima-nova';
    src: url('https://use.typekit.net/af/6cc429/00000000000000007735957a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/6cc429/00000000000000007735957a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/6cc429/00000000000000007735957a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: 'proxima-nova-condensed';
    src: url('https://use.typekit.net/af/ee884b/00000000000000007735956b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/ee884b/00000000000000007735956b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/ee884b/00000000000000007735956b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/f67502/00000000000000007735b56e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/f67502/00000000000000007735b56e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/f67502/00000000000000007735b56e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/84659b/00000000000000007735b573/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/84659b/00000000000000007735b573/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/84659b/00000000000000007735b573/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/986a34/00000000000000007735b576/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/986a34/00000000000000007735b576/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/986a34/00000000000000007735b576/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/069915/00000000000000007735b581/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/069915/00000000000000007735b581/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/069915/00000000000000007735b581/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'objektiv-mk2';
    src: url('https://use.typekit.net/af/fec100/00000000000000007735b582/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/fec100/00000000000000007735b582/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/fec100/00000000000000007735b582/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
  }

  .tk-proxima-nova {
    font-family: 'proxima-nova', sans-serif;
  }
  .tk-proxima-nova-condensed {
    font-family: 'proxima-nova-condensed', sans-serif;
  }
  .tk-objektiv-mk2 {
    font-family: 'objektiv-mk2', sans-serif;
  }

  .quantum,
  .quantum .partner-quantum,
  .partner-40.modal-content {
    .previous-button {
      background: ${WHITE};
      border: 2px solid ${quantum.primary1};
      color: ${quantum.primary1};
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .MuiButton-root {
      font-weight: bold;
    }

    .MuiButton-root {
      background: ${WHITE};
      border: 2px solid ${quantum.primary1};
      color: ${quantum.primary1};
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      &[class*='Mui-disabled'] {
        color: ${rgba(BLACK, 0.26)} !important;
        background-color: ${rgba(MAIN_BLACK, 0.12)} !important;
      }
    }
    .MuiButton-containedPrimary {
      background-color: ${quantum.primary3} !important;
      border: 0;
    }
    .MuiTab-root {
      background-color: ${quantum.primary3} !important;
      border: none;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
      color: ${WHITE};
    }

    .MuiButton-outlinedSecondary:hover {
      background-color: ${WHITE} !important;
      background: ${WHITE};
      border: 2px solid ${quantum.primary1};
      color: ${quantum.primary1};
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .MuiPagination-ul {
      .MuiPaginationItem-page {
        color: ${WHITE};
        font-weight: 400;
        background-color: ${quantum.primary2};
        &.Mui-selected {
          background-color: ${quantum.primary1};
        }
      }
    }

    &.channelspage {
      .channels.app-bar--shadow.mui-fixed {
        .channel-list span {
          padding-right: 13px;
        }
      }
      .swiper-pagination-bullets {
        display: none !important;
      }
    }

    &.resultspage {
      .internet-option__description {
        color: ${quantum.secondary4};
      }
    }
    .discover-page {
      .marker--el,
      .marker:before {
        color: ${WHITE};
        font-weight: 400;
      }
    }
  }
`;

export default quantumStyles;
