import { css } from 'styled-components';

const wintek = {
  background: '#F2F2F2',
};

const wintekStyles = css`
  /* vietnamese */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Italic'), local('Barlow-Italic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHrv4kjgoGqM7E_Cfs0wH8RnA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Italic'), local('Barlow-Italic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHrv4kjgoGqM7E_Cfs1wH8RnA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Italic'), local('Barlow-Italic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHrv4kjgoGqM7E_Cfs7wH8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHsv4kjgoGqM7E_CfOA5WohvTobdw.woff2)
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHsv4kjgoGqM7E_CfOA5WogvTobdw.woff2)
        format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHsv4kjgoGqM7E_CfOA5WouvTo.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Regular'), local('Barlow-Regular'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHpv4kjgoGqM7E_A8s52Hs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Regular'), local('Barlow-Regular'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHpv4kjgoGqM7E_Ass52Hs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Regular'), local('Barlow-Regular'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHpv4kjgoGqM7E_DMs5.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold'), local('Barlow-Bold'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E3t-4s6FospT4.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold'), local('Barlow-Bold'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E3t-4s6VospT4.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold'), local('Barlow-Bold'),
      url(https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E3t-4s51os.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .partner-wintek,
  .partner-wintek-csr {
    &.resultspage {
      & > .section--grey {
        background-color: ${wintek.background};
      }
    }
  }
`;

export default wintekStyles;
