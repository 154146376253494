import { rgba, darken, lighten } from 'polished';

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const TRANSPARENT = 'transparent';

// Primary
export const SHAMROCK = '#2f9f59';
export const MAIN_BLACK = '#2A2A35';
export const RADICAL_RED = '#FF2C4A';
export const BITTERSWEET = '#FF625F';
export const BITTERSWEET_2 = '#FFE4E8';
export const DANDELION = '#FCD55D';
export const KOURNIKOVA = '#FFDD75';
export const KOURNIKOVA_2 = '#FFB201';
export const SANTAS_GRAY = '#9A9AAA';
export const SANTAS_GRAY_2 = '#9F9FAE';
export const GRAY_SUIT = '#BFBFD1';
export const ATHENS_GRAY = '#F0F0F3';
export const ROYAL_BLUE_2 = '#5759EA';
export const FUCHSIA_BLUE = '#8C4DC8';
export const BLACK_2 = '#050505';
export const MINE_SHAFT = '#2A2A2A';
export const EMPEROR = '#525252';
export const COD_GRAY = '#141414';

export const BRIGHT = ATHENS_GRAY;
export const YELLOW = DANDELION;
export const LIGHTGREY = GRAY_SUIT;
export const DARKGREY = SANTAS_GRAY;
export const PURPLE = ROYAL_BLUE_2;

// Secondary
export const AQUA_HAZE = '#EDF2F5';
export const AQUA_HAZE_2 = '#EEF2F5';
export const PORCELAIN = '#F5F6F7';
export const PORCELAIN_2 = '#E6E9EB';
export const MYSTIC = '#E3E9F0';
export const MISCHKA = '#DFDFE8';
export const HINT_OF_RED = '#FBFAFA';
export const HINT_OF_GREEN = '#F9FFFB';
export const GALLERY = '#ECECEC';
export const CHATHAMS_BLUE = '#124076';
export const TOREA_BAY = '#0F2C97';
export const AZURE_RADIANCE = '#0096FF';
export const SCOOTER = '#35B1DB';
export const ZUMTHOR = '#EBF4FF';
export const WOODSMOKE = '#131415';
export const WOODSMOKE_2 = '#0F1011';
export const SHARK = '#202223';
export const ALTO = '#d8d8d8';
export const ROLLING_STONE = '#747576';
export const EDWARD = '#A0A1A1';
export const SILVER = '#C5C5C5';
export const WATERLOO = '#858691';
export const CATSKILL_WHITE = '#F4F6FA';
export const BURNT_SIENNA = '#EE5962';
export const CARDINAL = '#C32424';
export const RED = '#FF0800';
export const ROYAL_BLUE = '#4E4BEE';
export const VIOLET = '#080836';
export const ALABASTER = '#FBFBFB';
export const PANACHE = '#F4FAF6';
export const GREEN_HAZE = '#01A34D';
export const SEA_GREEN = '#2E9F58';
export const SKEPTIC = '#CBE7D5';
export const SCHOOL_BUS_YELLOW = '#FEE000';
export const SAFFRON = '#F3AE40';
export const CORN = '#F3BA07';
export const SUPERNOVA = '#FFC700';
export const EARLY_DAWN = '#FFFAEB';
export const BUNKER = '#141B1E';
export const MOTVORANGE = '#DE562C';
export const FOG = '#D8D8FF';

export const channelsShapes = [
  ROYAL_BLUE_2,
  BITTERSWEET,
  FUCHSIA_BLUE,
  SHAMROCK,
  MAIN_BLACK,
  DANDELION,
];

// SNACKBAR
const snackbar = {
  bg: MAIN_BLACK,
  text: WHITE,
  btn: rgba(WHITE, 0.8),
  shadow: rgba(MAIN_BLACK, 0.15),
};

// PAPER
const paper = {
  boxShadow: rgba(BLACK, 0.6),
  elevation4: AQUA_HAZE,
  elevation5: rgba(BLACK, 0.06),
  elevation9: rgba(CHATHAMS_BLUE, 0.07),
};

// BUTTONS
const buttons = {
  bgHover: MYSTIC,
  danger: {
    contained: {
      bg: BITTERSWEET,
      text: WHITE,
      bgHover: darken(0.05, BITTERSWEET),
    },
    outlined: {
      bg: WHITE,
      text: BITTERSWEET,
      bgHover: lighten(0.28, BITTERSWEET),
      border: BITTERSWEET,
    },
  },
  grey: {
    contained: {
      bg: PORCELAIN,
      text: MAIN_BLACK,
      bgHover: darken(0.05, PORCELAIN),
    },
    outlined: {
      bg: WHITE,
      text: MAIN_BLACK,
      bgHover: PORCELAIN,
      border: PORCELAIN,
    },
  },
  purple: {
    contained: {
      bg: ROYAL_BLUE_2,
      text: WHITE,
      bgHover: darken(0.05, ROYAL_BLUE_2),
    },
  },
  contained: {
    bg: WHITE,
    color: MAIN_BLACK,
  },
  outlined: {
    border: MYSTIC,
  },
};

// INPUTS
const inputs = {
  border: MYSTIC,
  defaultIcon: MISCHKA,
  filled: {
    bg: PORCELAIN,
    color: MAIN_BLACK,
  },
};

// CHECKBOX
const checkbox = {
  border: SHAMROCK,
  color: WHITE,
};

// RATING
const rating = {
  default: BRIGHT,
  active: YELLOW,
  large: {
    color: BRIGHT,
    bg: TRANSPARENT,
    activeColor: WHITE,
    activeBg: YELLOW,
    shadow: rgba(CHATHAMS_BLUE, 0.07),
    border: MYSTIC,
  },
};

// SKELETON
const skeleton = {
  bg: BRIGHT,
};

// TOOLTIP
const tooltip = {
  bg: MAIN_BLACK,
  text: WHITE,
};

// LIST ITEM
const listItem = {
  bg: BRIGHT,
};

// RadioCard
const radioCard = {
  border: MYSTIC,
  shadow: rgba(CHATHAMS_BLUE, 0.07),
  check: WHITE,
};

// CheckboxCard
const checkboxCard = {
  border: MYSTIC,
  shadow: rgba(CHATHAMS_BLUE, 0.07),
  check: WHITE,
  checkedHover: {
    border: RADICAL_RED,
    iconBg: RADICAL_RED,
  },
};

// AppCard
const appCard = {
  bg: WHITE,
  cost: SANTAS_GRAY,
  hover: {
    shadow: rgba(BLACK, 0.06),
  },
};

// Season Card
const seasonCard = {
  bg: WHITE,
  cost: SANTAS_GRAY,
  years: SANTAS_GRAY,
  hover: {
    shadow: rgba(BLACK, 0.06),
  },
};

// Dialog
const dialog = {
  bg: rgba(MAIN_BLACK, 0.8),
};

// Header
const header = {
  inactive: {
    bg: TRANSPARENT,
    text: WHITE,
  },
  active: {
    bg: WHITE,
    text: MAIN_BLACK,
  },
  counter: {
    color: WHITE,
  },
};

// Footer
const footer = {
  menu: {
    title: WHITE,
    link: EDWARD,
  },
  termsPolicy: {
    bg: WOODSMOKE_2,
    color: ROLLING_STONE,
    link: WHITE,
  },
};

// Breadcrumbs
const breadcrumbs = {
  link: GRAY_SUIT,
};

// Tabs
const tabs = {
  text: MAIN_BLACK,
  bg: AQUA_HAZE_2,
  activeText: WHITE,
};

// ChannelCard
const channelCard = {
  hover: {
    shadow: rgba(BLACK, 0.06),
  },
};

// LinearProgress
const linearProgress = {
  bg: ATHENS_GRAY,
  barBg: DANDELION,
};

// Service Card
const serviceCard = {
  bg: WHITE,
  hover: {
    shadow: rgba(BLACK, 0.06),
  },
};

// Chip
const chip = {
  text: WHITE,
  bg: MAIN_BLACK,
  hoverBg: SHAMROCK,
  outlined: {
    text: MAIN_BLACK,
    bg: TRANSPARENT,
    borderColor: MAIN_BLACK,
  },
  secondary: {
    text: WHITE,
    bg: ROYAL_BLUE_2,
    hoverBg: darken(0.05, ROYAL_BLUE_2),
  },
  primaryLight: {
    text: SHAMROCK,
    bg: SKEPTIC,
    hoverBg: darken(0.05, SKEPTIC),
  },
  danger: {
    text: WHITE,
    bg: CARDINAL,
    hoverBg: darken(0.05, CARDINAL),
  },
  warning: {
    text: WHITE,
    bg: DANDELION,
    hoverBg: darken(0.05, DANDELION),
  },
};

export default {
  snackbar,
  paper,
  buttons,
  inputs,
  checkbox,
  rating,
  skeleton,
  tooltip,
  listItem,
  radioCard,
  checkboxCard,
  appCard,
  seasonCard,
  header,
  footer,
  breadcrumbs,
  tabs,
  channelCard,
  linearProgress,
  serviceCard,
  chip,
  dialog,

  bg: WHITE,
  title: MAIN_BLACK,
  text: MAIN_BLACK,
  textSecondary: SANTAS_GRAY,
  link: BITTERSWEET,
  divider: PORCELAIN_2,
  success: SHAMROCK,
  error: BITTERSWEET,
  warning: KOURNIKOVA,
  primary: SHAMROCK,
  primaryText: WHITE,
  secondary: MAIN_BLACK,
};
