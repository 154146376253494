import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

// STYLES
import { Container } from './styles';

const FieldWrap = ({ children, onClick, withoutCopy }) => {
  const [t] = useTranslation();

  if (withoutCopy) return children;

  return (
    <Tooltip
      title={
        <Container className="cursor cursor--pointer" onClick={onClick}>
          {t('common.clickToCopy')}
        </Container>
      }
      interactive
    >
      {children}
    </Tooltip>
  );
};

FieldWrap.defaultProps = {
  withoutCopy: false,
};

FieldWrap.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  withoutCopy: PropTypes.bool,
};

export default FieldWrap;
