import React, { createElement } from 'react';
import PropTypes from 'prop-types';

// CONSTANTS
import { ROUTE_ROOT } from '@/constants/routes';

// COMPONENTS
import Link from '@/components/Link';

// ASSETS
import { ReactComponent as ReactLogo } from '@/assets/images/logo.svg';

// STYLES
import { LogoContainer } from './styles';

const Logo = ({ location, linkProps, type, ...restProps }) => {
  const { component: LinkComponent, ...restLinkProps } = linkProps;

  return (
    <LogoContainer className="logo" type={type} {...restProps}>
      {createElement(
        LinkComponent || Link,
        {
          to: ROUTE_ROOT,
          location,
          ...restLinkProps,
        },
        <ReactLogo />,
      )}
    </LogoContainer>
  );
};

Logo.defaultProps = {
  location: '',
  linkProps: {},
  type: 'dark',
};

Logo.propTypes = {
  location: PropTypes.string,
  linkProps: PropTypes.object,
  type: PropTypes.string,
};

export default React.memo(Logo);
