import React from 'react';
import useVirtualCardData, { VirtualCardContext } from '@/hooks/useVirtualCardData';

const withVirtualCardData = Component => props => {
  const data = useVirtualCardData();
  return (
    <VirtualCardContext.Provider value={data}>
      <Component {...props} virtualCard={data} />
    </VirtualCardContext.Provider>
  );
};

export default withVirtualCardData;
