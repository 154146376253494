import produce from 'immer';

import { getPlanDetails } from '../actions/planDetails';

const initialState = {
  data: null,
  loadings: {
    data: true,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET APP DETAILS
    case getPlanDetails.types.START:
      state.loadings.data = true;
      return state;

    case getPlanDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getPlanDetails.types.FAIL:
      state.loadings.data = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
