import { createAction } from '@/utility/actions';

import { CHANNEL_GET_DETAILS, CHANNEL_GET_SWIMLANES } from './types';

/**
 * getChannelDetails - slug, onError
 */
export const getChannelDetails = createAction(CHANNEL_GET_DETAILS);

/**
 * getChannelSwimlanes - params
 */
export const getChannelSwimlanes = createAction(CHANNEL_GET_SWIMLANES);

export default {
  getChannelDetails,
  getChannelSwimlanes,
};
