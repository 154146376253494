import { createAction } from '@/utility/actions';

import { VIRTUAL_CARD_BALANCE, VIRTUAL_CARD_BALANCE_GET_DATA } from './types';

/**
 * getVirtualCardPaymentData
 */
export const getVirtualCardBalanceData = createAction(VIRTUAL_CARD_BALANCE_GET_DATA);

/**
 * addVirtualCardPayment - params: data(object)
 */
export const addVirtualCardBalance = createAction(VIRTUAL_CARD_BALANCE);

export default {
  addVirtualCardBalance,
  getVirtualCardBalanceData,
};
