import produce from 'immer';

// CONSTANTS
import { questions } from '@/constants/internetOffers';

import {
  getInternetOffersById,
  getInternetOffers,
  getInternetOffersData,
  saveInternetOffersAnswers,
  cleanInternetOffers,
  subscribeToInternetOffers,
  checkInternetOffersSubscription,
} from '../actions/internetOffers';

const initialState = {
  data: null,
  offers: {},
  time_loaded: 0,
  questions,
  requiredQuestions: [],
  subscription: {
    loading: false,
    subscribed: false,
  },
  loadings: {
    loading: false,
    saveAddress: false,
    offers: false,
    saveAnswers: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET INTERNET OFFERS
    case getInternetOffers.types.START:
      state.time_loaded = 0;
      state.offers = {};
      state.loadings.loading = true;
      state.loadings.saveAddress = true;
      return state;

    case getInternetOffers.types.SUCCESS:
      state.time_loaded = action.payload.time_loaded;
      state.offers = action.payload.offers;
      state.loadings.loading = false;
      return state;

    case getInternetOffers.types.FAIL:
      state.loadings.loading = false;
      state.loadings.saveAddress = false;
      return state;

    // GET INTERNET OFFERS DATA
    case getInternetOffersData.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.saveAddress = false;
      return state;

    // GET INTERNET OFFERS BY ID
    case getInternetOffersById.types.START:
      state.loadings.offers = true;
      return state;

    case getInternetOffersById.types.SUCCESS:
      state.offers = action.payload.offers;
      state.data = { ...state.data, ...action.payload.data };
      state.subscription.subscribed = action.payload.offers.subscribed;
      state.loadings.offers = false;
      return state;

    case getInternetOffersById.types.FAIL:
      state.loadings.offers = false;
      return state;

    // SAVE INTERNET OFFERS ANSWERS
    case saveInternetOffersAnswers.types.START:
      state.loadings.saveAnswers = true;
      return state;

    case saveInternetOffersAnswers.types.SUCCESS:
    case saveInternetOffersAnswers.types.FAIL:
      state.loadings.saveAnswers = false;
      return state;

    // CLEAN INTERNET OFFERS DATA
    case cleanInternetOffers.types.INIT:
      state.data = initialState.data;
      state.offers = initialState.offers;
      return state;

    // SUBSCRIBE TO INTERNET OFFERS
    case subscribeToInternetOffers.types.START:
      state.subscription.loading = true;
      return state;

    case subscribeToInternetOffers.types.SUCCESS:
      state.subscription.subscribed = action.payload;
      state.subscription.loading = false;
      return state;

    case subscribeToInternetOffers.types.FAIL:
      state.subscription.loading = false;
      return state;

    // CHECK INTERNET OFFERS SUBSCRIPTION
    case checkInternetOffersSubscription.types.SUCCESS:
      state.subscription.subscribed = action.payload;
      return state;

    default:
      return state;
  }
});

export default reducer;
