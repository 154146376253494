import { createAction } from '@/utility/actions';

import {
  SHOW_DETAILS_GET,
  SHOW_SIMILAR_GET,
  SHOW_STREAMING_DATA_GET,
  SHOW_UPDATE_MEDIA,
} from './types';

/**
 * getShowDetails - params: show_id, onError
 */
export const getShowDetails = createAction(SHOW_DETAILS_GET);

/**
 * getShowStreamingData - params: show_id, isTemp
 */
export const getShowStreamingData = createAction(SHOW_STREAMING_DATA_GET);

/**
 * getSimilarShows - params: show_id
 */
export const getSimilarShows = createAction(SHOW_SIMILAR_GET);

/**
 * updateShowMedia - data
 */
export const updateShowMedia = createAction(SHOW_UPDATE_MEDIA);

export default {
  getShowDetails,
  getShowStreamingData,
  getSimilarShows,
  updateShowMedia,
};
