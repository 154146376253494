export default {
  title: 'Add funds to my Streaming Rewards Card',
  notification: {
    title: 'Terms',
    desc:
      'By clicking on “Add Funds” you will contribute funds to your MyBundle Streaming Rewards Card from your personal credit or debit card.   The added funds will be combined with your existing rewards to increase your streaming rewards total.',
  },
  transMethod: {
    label: 'Transaction Method',
    oneTimePayment: 'One time payment',
    montlyPayments: 'Monthly payments',
    montlyPaymentsInfo: 'You will be charged Every 30 days.',
  },
  saveCard: 'Save card to MyBundle for future payments',
  saveInfo:
    'Your card will be save on file securly in our payment processor so no need to enter it again for future transactions.',
  selectPaymentMethod: 'Please select the payment method:',
  selectExistPaymentMethod: 'Select from exist payment methods',
  steps: {
    step1: {
      title: 'Funds',
    },
    step2: {
      title: 'Payment',
    },
  },
};
