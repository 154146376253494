import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';

// COMPONENTS
import ScalesIcon from '@/components/Icons/ScalesIcon';
import FilmPIcon from '@/components/Icons/FilmPIcon';

// STYLES
import { Container } from './styles';

const Hero = () => {
  const [t] = useTranslation();
  const downXs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));

  return (
    <Container className="hero">
      <Typography className="hero__title" variant={downXs ? 'h4' : 'h1'} align="center">
        {t('onboarding.hero.title')}
      </Typography>
      <div className="hero__content">
        <div className="hero__section">
          <FilmPIcon className="hero__icon" />
          <Typography className="m-b-15" variant="h6" align="center">
            {t('onboarding.hero.sectionLeft.title')}
          </Typography>
          <Typography align="center">{t('onboarding.hero.sectionLeft.subtitle')}</Typography>
        </div>
        <div className="hero__section">
          <ScalesIcon className="hero__icon" />
          <Typography className="m-b-15" variant="h6" align="center">
            {t('onboarding.hero.sectionRight.title')}
          </Typography>
          <Typography align="center">{t('onboarding.hero.sectionRight.subtitle')}</Typography>
        </div>
      </div>
    </Container>
  );
};

export default React.memo(Hero);
