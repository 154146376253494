import { createAction } from '@/utility/actions';

import { PLAN_DETAILS_GET } from './types';

/**
 * getPlanDetails - params: bundle_id, onError
 */
export const getPlanDetails = createAction(PLAN_DETAILS_GET);

export default { getPlanDetails };
