import produce from 'immer';

import { getIpInfo } from '../actions/ipApi';

const initialState = {
  data: null,
  loading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET IP INFO
    case getIpInfo.types.START:
      state.loading = true;
      return state;

    case getIpInfo.types.SUCCESS:
      state.data = action.payload.data;
      state.loading = false;
      return state;

    case getIpInfo.types.FAIL:
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
