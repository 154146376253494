import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

// CONSTANTS
import { GET_UNIQUE_ID, GET_SKIP_QUESTIONS, ASYNC_INTERNET_OPTIONS } from '@/constants/api';

// UTILITY
import { getLink, getSerializedParams } from '@/utility/routes';
import { analyticsFormSubmissionStarted, customAnalyticsEvent } from '@/utility/analytics';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { WizardActions } from '@/store/actions';
import { PartnerSelectors } from '@/store/selectors';
import { getIPInfoSaga } from './ipApi';
import { getPartnerByDomainSaga } from './partner';

const { getSkipQuestions, getUniqueId, asyncInternetOptions } = WizardActions;

export function* getUniqueIdSaga(action) {
  yield put(getUniqueId.start());

  let slug = action?.payload?.slug || '';
  const isPartnerDomain = yield select(PartnerSelectors.getIsPartnerDomain);

  if (isPartnerDomain && !slug) {
    const partnerData = yield call(getPartnerByDomainSaga);

    slug = partnerData?.partner_slug || '';
  }

  const form_version = action?.payload?.form_version || '';
  const url = slug ? `${GET_UNIQUE_ID}/${slug}` : GET_UNIQUE_ID;

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getUniqueId.success({ unique_id: respData.unique_id }));

    yield call(analyticsFormSubmissionStarted, {
      unique_id: respData.unique_id,
      parent_id: respData.parent_id,
      form_version,
    });

    const ipInfo = yield call(getIPInfoSaga);

    if (ipInfo && ipInfo.status === 'success' && ipInfo.isp) {
      yield call(customAnalyticsEvent, 'Ip Address info', {
        unique_id: respData.unique_id,
        parent_id: respData.parent_id,
        ...ipInfo,
      });
    } else {
      yield call(customAnalyticsEvent, 'Ip info - something went wrong', {
        unique_id: respData.unique_id,
        parent_id: respData.parent_id,
      });
    }
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getUniqueId.fail());
  }
}

export function* getSkipQuestionsSaga(action) {
  yield put(getSkipQuestions.start());

  const { slug, csr = false, params = {} } = action.payload;
  const serializedParams = getSerializedParams(params);
  let url = getLink(GET_SKIP_QUESTIONS, { slug });

  if (csr) {
    url += '/csr';
  }

  try {
    const { data: respData } = yield axios.get(url, serializedParams);

    yield put(
      getSkipQuestions.success({
        partner: respData.partner,
        skip: respData.skip,
        extra: Array.isArray(respData.extra) ? respData.extra : [],
        require: respData.require,
        partner_id: respData.partner_id,
        question_email_required: respData.question_email_required,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getSkipQuestions.fail());
  }
}

export function* asyncInternetOptionsSaga(action) {
  yield put(asyncInternetOptions.start());

  const { unique_id } = action.payload;
  const url = getLink(ASYNC_INTERNET_OPTIONS, { unique_id });

  try {
    yield axios.get(url);

    yield put(asyncInternetOptions.success());
  } catch (error) {
    yield put(asyncInternetOptions.fail());
  }
}
