import produce from 'immer';

import { getInternetOption } from '../actions/internetOption';
import { asyncInternetOptions } from '../actions/wizard';

const initialState = {
  data: null,
  loadings: {
    data: true,
    async: false,
  },
  errors: {
    data: null,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET INTERNET OPTION
    case getInternetOption.types.START:
      state.loadings.data = true;
      state.errors.data = null;
      return state;

    case getInternetOption.types.SUCCESS:
      state.data = action.payload.data.internet_options[0];
      return state;

    case getInternetOption.types.STOP:
      state.loadings.data = false;
      return state;

    case getInternetOption.types.FAIL:
      state.errors.data = action.payload;
      state.loadings.data = false;
      return state;

    // GET ASYNC INTERNET OPTION
    case asyncInternetOptions.types.START:
      state.loadings.async = true;
      return state;

    case asyncInternetOptions.types.SUCCESS:
    case asyncInternetOptions.types.FAIL:
      state.loadings.async = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
