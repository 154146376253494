import { createAction } from '@/utility/actions';

import {
  USER_STREAMING_REWARD_GET,
  USER_PAYMENT_HISTORY_GET,
  USER_PAYMENT_HISTORY_CLEAR,
} from './types';

/**
 * getUserStreamingReward
 */
export const getUserStreamingReward = createAction(USER_STREAMING_REWARD_GET);

/**
 * getUserPaymentHistory
 */
export const getUserPaymentHistory = createAction(USER_PAYMENT_HISTORY_GET);

/**
 * clearUserPaymentHistory
 */
export const clearUserPaymentHistory = createAction(USER_PAYMENT_HISTORY_CLEAR);

export default { getUserStreamingReward, getUserPaymentHistory, clearUserPaymentHistory };
