export default {
  title: 'Tell us the apps that you have',
  searchLabel: 'Search Apps You Need',
  popularApps: 'Popular Apps',
  selectedApps: 'Selected Apps',
  addedApps: 'Added Apps',
  hero: {
    title: 'Already Streaming?',
    sectionLeft: {
      title: 'There is always something to watch.',
      subtitle:
        'Get personalized recommendations for your next TV Show or Movie across ALL your apps.',
    },
    sectionRight: {
      title: "Track how much you're spending.",
      subtitle: 'Add your streaming services to your profile and manage your costs.',
    },
  },
};
