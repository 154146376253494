/**
 * @param {object} state
 * @returns {object}
 */
const getAuthData = ({ auth }) => auth;

/**
 * @param {object} state
 * @returns {bool}
 */
const getIsAuth = ({ auth }) => auth.isAuth;

/**
 * @param {object} state
 * @returns {string}
 */
const getUniqueId = ({ auth }) => auth.unique_id;

/**
 * @param {object} state
 * @returns {string}
 */
const getAnonymousId = ({ auth }) => auth.anonymousId;

/**
 * @param {object} state
 * @returns {object} | {null}
 */
const getUser = ({ auth }) => auth.user;

/**
 * @param {object} user
 * @returns {object}
 */
const getUserContentCount = user => user.content_count;

/**
 * @param {object} state
 * @returns {object}
 */
const getCacheDependencies = ({ auth }) => auth.cacheDependencies;

export default {
  getAuthData,
  getIsAuth,
  getUniqueId,
  getAnonymousId,
  getUser,
  getUserContentCount,
  getCacheDependencies,
};
