export const HOME_PAGE = 'home';
export const MARKETPLACE_PAGE = 'marketplace';
export const SHOW_AND_MOVIES_PAGE = 'shows_and_movies';
export const APP_PAGE = 'app';
export const MOVIES_WATCH_LIST_PAGE = 'movies_watch_list';
export const SHOWS_WATCH_LIST_PAGE = 'shows_watch_list';
export const MY_APPS_PAGE = 'my_apps';
export const CHANNEL_PAGE = 'channel';
export const EMMY_AWARDS_PAGE = 'emmy-awards';
export const CONFIRMATION_ISP = 'isp-confirmation';
export const PROMOTION = 'promotion';
export const STREAMING_REWARDS = 'streaming-rewards';
