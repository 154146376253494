import { createAction } from '@/utility/actions';

import {
  SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_REGISTRATION,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_RENEW,
  SUBSCRIPTION_DETAILS_GET,
  SUBSCRIPTION_DETAILS_CLEAR,
  SUBSCRIPTION_DICTIONARY_GET,
  SUBSCRIPTION_DICTIONARY_UPDATE,
  SUBSCRIPTION_STATUS_GET,
  SUBSCRIPTION_PREVIEW,
  SUBSCRIPTION_TRANSACTIONS_GET,
  SUBSCRIPTION_TRANSACTIONS_CLEAR,
  SUBSCRIPTION_AUTH_DIALOG_MANAGE_STATE,
  SUBSCRIPTION_PASSWORD_CHANGE,
  SUBSCRIPTION_REGISTER_SERVICE,
  SUBSCRIPTION_CLEAR_REGISTER_ERRORS,
} from './types';

/**
 * addSubscriptionPayment - params: data(object)
 */
export const addSubscriptionPayment = createAction(SUBSCRIPTION_PAYMENT);

/**
 * addSubscriptionRegistration - params: data(object)
 */
export const addSubscriptionRegistration = createAction(SUBSCRIPTION_REGISTRATION);

/**
 * registerSubscriptionService - params: data(object)
 */
export const registerSubscriptionService = createAction(SUBSCRIPTION_REGISTER_SERVICE);

/**
 * clearRegisterSubscriptionErrors
 */
export const clearRegisterSubscriptionErrors = createAction(SUBSCRIPTION_CLEAR_REGISTER_ERRORS);

/**
 * cancelSubscription - params: data(object)
 */
export const cancelSubscription = createAction(SUBSCRIPTION_CANCEL);

/**
 * renewSubscription - params: { type: string }
 */
export const renewSubscription = createAction(SUBSCRIPTION_RENEW);

/**
 * getSubscriptionDetails - params: data(Object<{ slug: string }>)
 */
export const getSubscriptionDetails = createAction(SUBSCRIPTION_DETAILS_GET);

/**
 * clearSubscriptionDetails - void
 */
export const clearSubscriptionDetails = createAction(SUBSCRIPTION_DETAILS_CLEAR);

/**
 * getSubscriptionDictionary - params: data(Object<{ slug: string }>)
 */
export const getSubscriptionDictionary = createAction(SUBSCRIPTION_DICTIONARY_GET);

/**
 * updateSubscriptionDictionary -
 * params: data(Object<{ selectedProduct: object, selectedPlan: object }>)
 */
export const updateSubscriptionDictionary = createAction(SUBSCRIPTION_DICTIONARY_UPDATE);

/**
 * getSubscriptionStatus - params: void
 */
export const getSubscriptionStatus = createAction(SUBSCRIPTION_STATUS_GET);

/**
 * getSubscriptionPreview - params: data
 */
export const getSubscriptionPreview = createAction(SUBSCRIPTION_PREVIEW);

/**
 * createSubscriptionTransactions - params: data
 */
export const getSubscriptionTransactions = createAction(SUBSCRIPTION_TRANSACTIONS_GET);

/**
 * createSubscriptionTransactions - params: data
 */
export const clearSubscriptionTransactions = createAction(SUBSCRIPTION_TRANSACTIONS_CLEAR);

/**
 * manageSubsciptionAuthDialogState - params: state, props
 */
export const manageSubscriptionAuthDialogState = createAction(
  SUBSCRIPTION_AUTH_DIALOG_MANAGE_STATE,
);

/**
 * changePassword - params: data
 */
export const changePassword = createAction(SUBSCRIPTION_PASSWORD_CHANGE);

export default {
  getSubscriptionDetails,
  getSubscriptionDictionary,
  updateSubscriptionDictionary,
  getSubscriptionStatus,
  addSubscriptionPayment,
  addSubscriptionRegistration,
  registerSubscriptionService,
  clearRegisterSubscriptionErrors,
  cancelSubscription,
  getSubscriptionPreview,
  getSubscriptionTransactions,
  clearSubscriptionTransactions,
  clearSubscriptionDetails,
  renewSubscription,
  manageSubscriptionAuthDialogState,
  changePassword,
};
