import { createAction } from '@/utility/actions';

import {
  SWIMLANES_GET,
  SWIMLANES_GET_CONTENT,
  SWIMLANES_CLEAR,
  SWIMLANES_GET_POPULAR_CONTENT,
} from './types';

/**
 * swimlanesGet - params
 */
export const swimlanesGet = createAction(SWIMLANES_GET);

export const swimlanesGetContent = createAction(SWIMLANES_GET_CONTENT);
export const swimlanesGetPopularContent = createAction(SWIMLANES_GET_POPULAR_CONTENT);

export const swimlanesClear = createAction(SWIMLANES_CLEAR);

export default {
  swimlanesGet,
  swimlanesGetContent,
  swimlanesGetPopularContent,
  swimlanesClear,
};
