import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'query-string';

// CONSTANTS
import {
  ROUTE_DISCOVER_SEARCH,
  ROUTE_DISCOVER_APP,
  ROUTE_DISCOVER_CHANNEL,
  ROUTE_SEARCH,
  ROUTE_SHOWS_AND_MOVIES_SHOW,
  ROUTE_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_SHOWS_AND_MOVIES_DISCOVER,
} from '@/constants/routes';
import { LOCATIONS_SEARCH_BAR } from '@/constants/locations';

// // UTILITY
import { analyticsInternalLinks } from '@/utility/analytics';
import { getLink, routeEnhance } from '@/utility/routes';

// STORE
import { AppActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// STYLES
import { SearchAutoCompleteStyled } from './styles';

function Search({ autoFocus, onBlur, ...props }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { searchData, searchLoading, searchPrevString, partner } = useSelector(
    ({ app, partner: partnerStore }) => ({
      searchData: app.search.data,
      searchLoading: app.loadings.search,
      searchPrevString: app.search.string,
      partner: partnerStore.data,
    }),
  );
  const [getAppSearch] = useActions([params => AppActions.getAppSearch.action({ params })]);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef();

  useEffect(() => {
    if (ref.current && autoFocus) {
      ref.current.focus();
    }
  }, [ref, autoFocus]);

  const debouncedSearch = useCallback(
    debounce(search => {
      getAppSearch({
        search_string: search.trim(),
        partner: partner?.partner_slug || '',
      });
    }, 500),
    [],
  );

  const handleSearch = (event, value, reason) => {
    if (event?.keyCode === 13 && reason === 'reset') {
      return;
    }

    if (reason === 'input' || reason === 'clear') {
      setSearchValue(value);
      debouncedSearch(value);
    }
  };

  const handleBlur = () => {
    onBlur();
    setSearchValue('');
  };

  const handleSelect = (event, value, reason, handleOpen) => {
    if (reason === 'clear') {
      setSearchValue('');
      return;
    }

    if (reason === 'select-option') {
      let routeEnhanced;
      const options = {
        state: {
          location: LOCATIONS_SEARCH_BAR,
        },
      };

      switch (value.type) {
        case 'app':
          routeEnhanced = routeEnhance(getLink(ROUTE_DISCOVER_APP, { slug: value.slug }), options);

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });

          navigate(routeEnhanced);
          break;
        case 'show':
        case 'movie':
          if (value.type === 'show') {
            routeEnhanced = routeEnhance(
              getLink(ROUTE_SHOWS_AND_MOVIES_SHOW, { show_id: value.slug || value.id }),
              options,
            );
          } else if (value.type === 'movie') {
            routeEnhanced = routeEnhance(
              getLink(ROUTE_SHOWS_AND_MOVIES_MOVIE, { movie_id: value.slug || value.id }),
              options,
            );
          }

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });
          navigate(routeEnhanced);

          break;
        case 'Actor':
        case 'Director':
          routeEnhanced = routeEnhance(
            `${ROUTE_SHOWS_AND_MOVIES_DISCOVER}?crew=${value.id}`,
            options,
          );

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });
          navigate(routeEnhanced);

          break;
        case 'channel':
          routeEnhanced = routeEnhance(
            getLink(ROUTE_DISCOVER_CHANNEL, {
              slug: value.slug || value.id,
            }),
            options,
          );

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });

          navigate(routeEnhanced);
          break;
        case 'keyword':
          routeEnhanced = routeEnhance(
            `${ROUTE_DISCOVER_SEARCH}?keywords=${value.id}&page=1&type=paid,free`,
            options,
          );

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });

          navigate(routeEnhanced);
          break;
        case 'genre':
          routeEnhanced = routeEnhance(
            `${ROUTE_DISCOVER_SEARCH}?genres=${value.slug || value.title}&page=1&type=paid,free`,
            options,
          );

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: routeEnhanced.pathname,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });

          navigate(routeEnhanced);
          break;

        case 'link':
          routeEnhanced = routeEnhance(value.link, options);

          analyticsInternalLinks({
            unique_id: routeEnhanced.state.unique_id,
            from_page_path: routeEnhanced.state.from_page_path,
            from_search_params: routeEnhanced.state.from_search_params,
            to_page_path: value.link,
            to_search_params: routeEnhanced.search,
            partner_id: routeEnhanced.state.partner_id,
            location: routeEnhanced.state.location,
          });

          if (value.link) window.open(value.link, '_self');
          break;
        case 'show more enter':
          routeEnhanced = routeEnhance(
            `${ROUTE_SEARCH}?${stringify({ search: value.value })}`,
            options,
          );

          navigate(routeEnhanced);
          break;
        default:
          break;
      }

      handleOpen(false);
      handleBlur();
    }
  };

  const handleAutocompleteOpen = ({ target: { value } }) => {
    getAppSearch({ search_string: value, partner: partner?.partner_slug || '' });
  };

  return (
    <SearchAutoCompleteStyled
      ref={ref}
      freeSolo
      blurOnSelect
      inputValue={searchValue}
      onInputChange={handleSearch}
      onChange={handleSelect}
      onBlur={handleBlur}
      options={searchData}
      loading={searchLoading}
      openOnFocus
      onOpen={handleAutocompleteOpen}
      searchPrevString={searchPrevString}
      searchPlaceholder={t('inputs.search')}
      analyticsLocation={LOCATIONS_SEARCH_BAR}
      disablePortal
      showYear
      {...props}
    />
  );
}

Search.defaultProps = {
  autoFocus: false,
  onBlur: () => {},
};

Search.propTypes = {
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default React.memo(Search);
