import styled from 'styled-components';
import { Typography, IconButton, Dialog } from '@material-ui/core';

// CONSTANTS
import { WHITE } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;

  font-size: 1.5rem;
`;

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: visible;
  }

  .MuiDialogContent-root {
    padding-bottom: 24px;

    &:first-child {
      padding-top: 60px;
    }
  }
  .registration-title {
    font-size: 2.8rem;
    @media (max-width: ${breakpoints.sm}) {
      font-size: 1.8rem;
    }
  }
`;

export const Hint = styled(Typography)`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  color: ${WHITE};
`;

export default DialogStyled;
