import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import { RESULTS_INTERNET_OPTION, ASYNC_INTERNET_OPTIONS } from '@/constants/api';

// UTILITY
import { getLink } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { InternetOptionActions, WizardActions } from '@/store/actions';

const { getInternetOption } = InternetOptionActions;
const { asyncInternetOptions } = WizardActions;

export function* getInternetOptionSaga(action) {
  yield put(getInternetOption.start());

  const { unique_id, internet_option_id, onError } = action.payload;
  const url = getLink(RESULTS_INTERNET_OPTION, {
    unique_id,
    internet_option_id,
  });

  try {
    const { data: respData } = yield axios.get(url);

    if (respData.message === 'Need to update') {
      yield put(asyncInternetOptions.start());

      const asyncIOUrl = getLink(ASYNC_INTERNET_OPTIONS, { unique_id });

      yield axios.get(asyncIOUrl);

      yield put(getInternetOption.init(action.payload));
    } else {
      yield put(asyncInternetOptions.success());

      yield put(getInternetOption.success({ data: respData }));
    }

    yield put(getInternetOption.stop());
  } catch (error) {
    if (error?.response?.status === 422) {
      if (onError) onError();
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(asyncInternetOptions.fail());

    yield put(getInternetOption.fail(error));
  }
}

export default getInternetOptionSaga;
