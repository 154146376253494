// UTILITY
import { getS3ImageUrl } from '@/utility/content';

const feature1Icon = getS3ImageUrl('/icons/wi-fi.svg');
const feature2Icon = getS3ImageUrl('/icons/target.svg');
const feature3Icon = getS3ImageUrl('/icons/shuttle.svg');

export const features = [
  {
    icon: feature1Icon,
    title: 'internetOffers.features.first.title',
    description: 'internetOffers.features.first.description',
  },
  {
    icon: feature2Icon,
    title: 'internetOffers.features.second.title',
    description: 'internetOffers.features.second.description',
  },
  {
    icon: feature3Icon,
    title: 'internetOffers.features.third.title',
    description: 'internetOffers.features.third.description',
  },
];

export const questions = [
  {
    answers: [
      { text: 'Light Browsing and Email', value: '1' },
      { text: 'Streaming TV and Movies', value: '2' },
      { text: 'Work From Home', value: '3' },
      { text: 'Gaming online', value: '4' },
    ],
    id: 1,
    order: 1,
    question: 'How do you use the internet?',
    slug: 'internet_usage',
    subtitle: 'Pick all that apply.',
    type: 'checkbox',
  },
  {
    answers: [
      { text: '1-2', value: '1' },
      { text: '3-6', value: '2' },
      { text: '7-10', value: '3' },
      { text: 'More than 10', value: '4' },
    ],
    id: 2,
    order: 2,
    question: 'How many devices do you have?',
    slug: 'devices_count',
    subtitle:
      'For example: streaming TV devices, smartphones, tablets, gaming consoles, virtual assistants (Alexa, Google Nest etc.)',
    type: 'radio',
  },
  {
    answers: [
      { text: 'Under 100 MB', value: '1' },
      { text: '100-200 MB', value: '2' },
      { text: '200-500 MB', value: '3' },
      { text: '500 - 1 GB', value: '4' },
      { text: '1 GB', value: '5' },
      { text: 'Don’t know', value: '6' },
    ],
    id: 3,
    order: 3,
    question: 'What is your current download speed?',
    slug: 'download_speed',
    subtitle: '',
    type: 'radio',
  },
];

export default features;
