/**
 * Get IP data
 * @param {*} state
 * @returns {object}
 */
const getIpApiData = ({ ipApi }) => ipApi.data;

export default {
  getIpApiData,
};
