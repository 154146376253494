import axios from 'axios';
import { put, delay, call } from 'redux-saga/effects';

// CONSTANTS
import {
  INTERNET_OFFERS_GET_RESULT,
  INTERNET_OFFERS_SEND_ADDRESS,
  INTERNET_OFFERS_SAVE_ANSWERS,
  INTERNET_OFFERS_SUBSCRIBE,
  INTERNET_OFFERS_CHECK_SUBSCRIPTION,
} from '@/constants/api';

// UTILITY
import store from '@/utility/store';
import { getLink } from '@/utility/routes';
import { analyticsStartLoadOffers } from '@/utility/analytics';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { InternetOffersActions, NotifActions } from '../actions';

const {
  getInternetOffers,
  getInternetOffersData,
  getInternetOffersById,
  saveInternetOffersAnswers,
  subscribeToInternetOffers,
  checkInternetOffersSubscription,
} = InternetOffersActions;
const { pushSuccessNotificationAction } = NotifActions;

function* getInternetOfferResult(data) {
  const url = getLink(INTERNET_OFFERS_GET_RESULT, {
    io_id: data.internet_option.id,
  });
  let hasResults = false;

  do {
    const response = yield axios.get(url);

    if (response.data.status === 1) {
      hasResults = true;
      return response;
    }

    yield delay(2000);
  } while (!hasResults);
}

export function* getInternetOffersSaga(action) {
  yield put(getInternetOffers.start());

  const { address, onSuccess } = action.payload;
  const startTime = new Date().getTime();

  analyticsStartLoadOffers({
    address: address.description,
  });

  try {
    const { data: respData } = yield axios.post(INTERNET_OFFERS_SEND_ADDRESS, { address });

    yield put(getInternetOffersData.success({ data: respData.internet_option }));

    store.set('io_id', respData.internet_option.id);

    if (onSuccess) onSuccess();

    const { data: offersResult } = yield getInternetOfferResult(respData);

    const endTime = new Date().getTime();
    const time_loaded = (endTime - startTime) / 1000;

    yield put(getInternetOffers.success({ offers: offersResult, time_loaded }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getInternetOffers.fail());
  }
}

export function* getInternetOffersByIdSaga(action) {
  yield put(getInternetOffersById.start());

  const { ioId, params } = action.payload;

  const url = getLink(INTERNET_OFFERS_GET_RESULT, {
    io_id: ioId,
  });

  try {
    const { data: respData } = yield axios.get(url, { params });

    yield put(getInternetOffersById.success({ offers: respData, data: { id: ioId } }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getInternetOffersById.fail());
  }
}

export function* saveInternetOffersAnswersSaga(action) {
  yield put(saveInternetOffersAnswers.start());

  const { io_id, data, callback } = action.payload;

  const url = getLink(INTERNET_OFFERS_SAVE_ANSWERS, {
    io_id,
  });

  try {
    yield axios.post(url, data);

    yield put(saveInternetOffersAnswers.success());

    if (callback) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(saveInternetOffersAnswers.fail());
  }
}

export function* subscribeToInternetOffersSaga(action) {
  yield put(subscribeToInternetOffers.start());

  const { email, internet_option_result_id } = action.payload;

  try {
    const { data: respData } = yield axios.post(INTERNET_OFFERS_SUBSCRIBE, {
      email,
      internet_option_result_id,
    });

    yield put(subscribeToInternetOffers.success(respData.subscription));

    yield put(pushSuccessNotificationAction(respData.message));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(subscribeToInternetOffers.fail());
  }
}

export function* checkInternetOffersSubscriptionSaga(action) {
  yield put(checkInternetOffersSubscription.start());

  const { internet_option_result_id } = action.payload;

  try {
    const { data: respData } = yield axios.get(INTERNET_OFFERS_CHECK_SUBSCRIPTION, {
      params: { internet_option_result_id },
    });

    yield put(checkInternetOffersSubscription.success(respData.subscribed));
  } catch (error) {
    yield put(checkInternetOffersSubscription.fail());
  }
}
