import produce from 'immer';

import { getLocations } from '../actions/locations';

const initialState = {
  data: [],
  loading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET PAGE CONTENT
    case getLocations.types.START:
      state.loading = true;
      return state;

    case getLocations.types.SUCCESS:
      state.data = action.payload.data;
      state.loading = false;
      return state;

    case getLocations.types.FAIL:
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
