import produce from 'immer';

import { getCounties, getCountiesByAddress, cleanCountiesState } from '../actions/counties';

const initialState = {
  counties: null,
  internet_option: null,
  zip_code: null,
  loading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET COUNTIES
    case getCounties.types.START:
      state.counties = null;
      state.internet_option = null;
      state.loading = true;
      return state;

    case getCounties.types.SUCCESS:
      state.counties = action.payload.counties;
      state.internet_option = action.payload.internet_option;
      return state;

    case getCounties.types.STOP:
    case getCounties.types.FAIL:
      state.loading = false;
      return state;

    // GET COUNTIES BY ADDRESS
    case getCountiesByAddress.types.START:
      state.counties = null;
      state.loading = true;
      return state;

    case getCountiesByAddress.types.SUCCESS:
      state.counties = action.payload.counties;
      state.zip_code = action.payload.zip_code;
      state.countiesByAddressLoaded = true;
      state.loading = false;
      return state;

    case getCountiesByAddress.types.FAIL:
      state.loading = false;
      return state;

    // CLEAN STATE
    case cleanCountiesState.types.INIT:
      state.counties = initialState.counties;
      state.internet_option = initialState.internet_option;
      state.zip_code = initialState.zip_code;
      return state;

    default:
      return state;
  }
});

export default reducer;
