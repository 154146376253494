import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import {
  CHANNELS_GET_FILTERS,
  GET_CHANNELS,
  GET_CHANNELS_TOP,
  CHANNELS_AUTOCOMPLETE,
  CHANNELS_SUBMIT_FORM,
  CHANNELS_GET_FORM_DATA,
} from '@/constants/api';

// UTILITY
import store from '@/utility/store';
import { getLink } from '@/utility/routes';
import {
  analyticsAnswerSubmittedEmail,
  analyticsQuestionSkipped,
  analyticsFormSubmitted,
  analyticsIdentify,
} from '@/utility/analytics';
import { getIPGeoData, showErrorNotification } from '@/utility/saga';

// STORE
import { ChannelsActions, AuthActions } from '@/store/actions';
import { PartnerSelectors } from '@/store/selectors';

const { updateUniqueId } = AuthActions;
const {
  getChannelFilters,
  getChannels,
  getTopChannels,
  channelsAutocomplete,
  submitForm,
  getFormData,
} = ChannelsActions;

export function* getChannelFiltersSaga() {
  yield put(getChannelFilters.start());

  try {
    const { data: respData } = yield axios.get(CHANNELS_GET_FILTERS);

    yield put(getChannelFilters.success({ data: respData.filters }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getChannelFilters.fail());
  }
}

export function* getChannelsSaga(action) {
  yield put(getChannels.start());

  const { data } = action.payload;

  try {
    const { data: respData } = yield axios.post(GET_CHANNELS, data);

    yield put(getChannels.success({ data: respData.channels }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getChannels.fail());
  }
}

export function* getTopChannelsSaga(action) {
  yield put(getTopChannels.start());

  const { data } = action.payload;

  try {
    const { data: respData } = yield axios.post(GET_CHANNELS_TOP, data);

    yield put(
      getTopChannels.success({
        channels: respData.channels,
        live_local_news: respData.live_local_news || [],
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getTopChannels.fail());
  }
}

export function* channelsAutocompleteSaga(action) {
  yield put(channelsAutocomplete.start());

  const { data } = action.payload;
  const hasSearch = 'search' in data && !!data.search;

  try {
    const { data: respData } = yield axios.post(CHANNELS_AUTOCOMPLETE, data);

    yield put(
      channelsAutocomplete.success({
        data: respData.channels,
        defData: !hasSearch ? respData.channels : [],
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(channelsAutocomplete.fail());
  }
}

export function* submitFormSaga(action) {
  yield put(submitForm.start());

  const { data, callback } = action.payload;
  const ipApiData = yield call(getIPGeoData, false);

  try {
    const { data: respData } = yield axios.post(CHANNELS_SUBMIT_FORM, {
      ...data,
      userIp: ipApiData?.query || '',
    });

    const partner = yield select(PartnerSelectors.getPartnerData);

    if (data.form_new) {
      if (data.email) {
        analyticsAnswerSubmittedEmail({ email: data.email });

        analyticsIdentify(data.email, {
          email: data.email,
          theid: data.unique_id,
        });
      } else {
        const properties = {
          question_slug: 'email',
          partner: partner.slug,
          partner_id: partner.id,
        };

        analyticsQuestionSkipped(properties);
      }
    }

    if (window.analytics && !data.isSmallIframeType) {
      analyticsFormSubmitted();
    }

    store.remove('start-data');
    store.remove('channels-data');
    store.set('unique_id', respData.unique_id);

    yield put(updateUniqueId.init({ unique_id: respData.unique_id }));

    yield put(submitForm.success());

    if (callback) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(submitForm.fail());
  }
}

export function* getFormDataSaga(action) {
  yield put(getFormData.start());

  const { unique_id } = action.payload;
  const url = getLink(CHANNELS_GET_FORM_DATA, { unique_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getFormData.success({ data: respData.form }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getFormData.fail());
  }
}
