export default {
  title: 'Internet Offer',
  internetOptionsLoader: 'We are getting your personalized internet offers',
  reloadPage: 'Sorry, try to reload page',
  hero: {
    actionDescription: 'Sign-up below online or call',
    availability: 'available 24/7',
  },
  plans: {
    title: '{{name}} Plans',
    disclaimer: {
      title: 'Disclaimer',
    },
  },
  disclosure: {
    title: 'Disclosure',
  },
};
