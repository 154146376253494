/**
 * Get watchNext token
 * @param {object} state
 * @returns {string}
 */
const getWNToken = ({ watchNext }) => watchNext.wn_token;

export default {
  getWNToken,
};
