import styled from 'styled-components';

// CONSTANTS
import { GRAY_SUIT, WHITE, MAIN_BLACK } from '@/constants/colors';

export const Container = styled.div`
  text-align: center;
  margin-bottom: 40px;

  .added-apps {
    &__title {
      font-size: 1.4rem;
      line-height: 1.4rem;
      font-weight: 600;
      color: ${GRAY_SUIT};
      text-transform: uppercase;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 20px;
      border-right-width: 1px;
      border-right-color: ${GRAY_SUIT};
      white-space: nowrap;
    }

    &__btn {
      min-width: 260px;
      margin-top: 15px;
    }
  }
`;

export const App = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  margin: 5px;
  border-radius: 8px;
  padding: 5px;
  background-color: ${WHITE};
  cursor: pointer;

  &:before {
    content: '\\e919';
    font-family: 'icomoon';
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${MAIN_BLACK};
    color: ${WHITE};
    z-index: 1;
    transition: 0.5s;
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
`;
