import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// CONSTANTS
import {
  ROUTE_DISCOVER,
  ROUTE_DISCOVER_APP,
  ROUTE_PARTNER_DISCOVER,
  ROUTE_PARTNER_DISCOVER_APP,
  ROUTE_PARTNER_SHOWS_AND_MOVIES,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_SHOW,
  ROUTE_SHOWS_AND_MOVIES,
  ROUTE_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_SHOWS_AND_MOVIES_SHOW,
} from '@/constants/routes';
import { rewardGroups } from '@/constants/streamingRewards';

// UTILITY
import getMatchPathFromArray from '@/utility/path';
import store from '@/utility/store';

// COMPONENTS
import BannerNotClaimedService from '@/components/Banners/BannerNotClaimedService';
import BannerNotClaimedReward from '@/components/Banners/BannerNotClaimedReward';

const allowedRewardsPages = [
  ROUTE_DISCOVER,
  ROUTE_DISCOVER_APP,
  ROUTE_SHOWS_AND_MOVIES,
  ROUTE_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_SHOWS_AND_MOVIES_SHOW,
  ROUTE_PARTNER_DISCOVER,
  ROUTE_PARTNER_DISCOVER_APP,
  ROUTE_PARTNER_SHOWS_AND_MOVIES,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_MOVIE,
  ROUTE_PARTNER_SHOWS_AND_MOVIES_SHOW,
];

const allowedRewardsGroupsNCS = [
  rewardGroups.VIRTUAL_CARDS,
  rewardGroups.VIRTUAL_CARDS_PROMO_CODES,
];

const BannerNotification = () => {
  const location = useLocation();
  const { sRewards, partnerData } = useSelector(({ auth, partner }) => ({
    sRewards: auth?.user?.streaming_rewards || [],
    partnerData: partner.data,
  }));
  const rewardReachedStore = store.get('reached-sr');
  const isPartner = !!partnerData?.slug;
  const isAllowedPage = !!getMatchPathFromArray(location.pathname, allowedRewardsPages);
  const rewardWithoutTrans = sRewards.find(
    r => r?.transactions_count === 0 && allowedRewardsGroupsNCS.includes(r.reward_group),
  );

  // BannerNotClaimedService
  const rewardWithSamePartner = isPartner
    ? rewardWithoutTrans && rewardWithoutTrans?.partner?.slug === partnerData.slug
    : true;

  const showNotClaimedServiceBanner = isAllowedPage && rewardWithoutTrans && rewardWithSamePartner;

  // BannerNotClaimedReward
  const showNotClaimedRewardBanner = isAllowedPage && !!rewardReachedStore && !rewardWithoutTrans;

  if (showNotClaimedServiceBanner) return <BannerNotClaimedService data={rewardWithoutTrans} />;

  if (showNotClaimedRewardBanner) return <BannerNotClaimedReward link={rewardReachedStore || ''} />;

  return null;
};

export default React.memo(BannerNotification);
