export default {
  title: 'Watchlist',
  changeOrderHelp: 'Drag and drop cards to change the order',
  filters: {
    selected: {
      title: 'Showing content available on these apps:',
    },
  },
  emptyState: {
    default: {
      title: 'Your watchlist is empty',
      subtitle: 'Your watchlist is empty. Try to find what to watch on TV shows & Movies page',
    },
    myApps: {
      title:
        "Oops, looks like you don't have any titles in your Watchlist from your apps. No problem!",
      subtitle:
        'You can easily add titles to your watchlist from your apps in the TV Shows and Movies area or add more apps to your profile through the Marketplace.',
    },
    show: {
      wantToWatch: {
        title: 'Your Want to Watch list is Empty',
        subtitle:
          'Keep track of TV Shows you want to watch and are currently watching in your watchlist. Find and add Shows from across your apps in the TV Shows and Movie page.',
      },
      haveWatched: {
        title: 'What TV Shows Have You Watched?',
        subtitle:
          "Add TV Shows you've watched to your watchlist. As you add Shows, we'll give you personalized recommendations based on your past viewing across your apps.",
      },
    },
    movie: {
      wantToWatch: {
        title: 'Your Want to Watch list is Empty',
        subtitle:
          'Keep track of Movies you want to watch in your watchlist. Find and add Movies from across your apps in the TV Shows and Movie page.',
      },
      haveWatched: {
        title: 'What Movies Have You Watched?',
        subtitle:
          "Add Movies you've watched to your watchlist. As you add Movies, we'll give you personalized recommendations based on your past viewing across your apps.",
      },
    },
  },
  moreActionsNav: {
    addToNewList: 'Add to New List',
    renameWatchlist: 'Rename Watchlist',
    deleteWatchlist: 'Delete Watchlist',
    shareWatchlist: 'Share Watchlist',
  },
  renameDialog: {
    title: 'Rename Watchlist',
  },
  deleteDialog: {
    title: 'Are you sure you want to delete this watchlist?',
  },
};
