import { createAction } from '@/utility/actions';

import { EMMY_AWARDS_GET_DETAILS } from './types';

/**
 * getEmmyAwardsDetails
 */
export const getEmmyAwardsDetails = createAction(EMMY_AWARDS_GET_DETAILS);

export default {
  getEmmyAwardsDetails,
};
