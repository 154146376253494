import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import Logo from '@/components/Logo';

// STYLES
import { PoweredByContainer } from './styles';

const PoweredBy = ({ type }) => {
  const [t] = useTranslation();

  return (
    <PoweredByContainer>
      <div className="poweredBy">{t('start.poweredBy')}</div>
      <div className="svgLogo">
        <Logo linkProps={{ component: 'div' }} type={type} />
      </div>
    </PoweredByContainer>
  );
};

PoweredBy.defaultProps = {
  type: 'light',
};

PoweredBy.propTypes = {
  type: PropTypes.string,
};

export default React.memo(PoweredBy);
