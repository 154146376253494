import * as Yup from 'yup';

// CONSTANTS
import * as validationConst from '@/constants/validation';

export default t => {
  return {
    channels: {
      channels: Yup.array().min(1),
      search: Yup.string(),
      genre_search: Yup.string(),
      zipcode: Yup.string()
        .min(
          validationConst.MIN_ZIP_CODE_LENGTH,
          t('validation.minZipCodeLength', { length: validationConst.MIN_ZIP_CODE_LENGTH }),
        )
        .max(
          validationConst.MAX_ZIP_CODE_LENGTH,
          t('validation.maxZipCodeLength', { length: validationConst.MAX_ZIP_CODE_LENGTH }),
        ),
      dma_id: Yup.string(),
      county_id_fk: Yup.string(),
    },
    email: {
      email: Yup.string()
        .when('$questionEmailRequired', (questionEmailRequired, schema) => {
          return questionEmailRequired ? schema.required(t('validation.emailRequired')) : schema;
        })
        .email(t('validation.emailNotValid')),
      terms: Yup.boolean().oneOf([true], 'Must agree to something'),
    },
  };
};
