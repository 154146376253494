export default {
  hero: {
    title: 'MyBundle simplifies streaming TV while saving you time & money',
    subtitle: '',
  },
  heroNonAuth: {
    title: 'We Make Streaming TV\u00A0Easy',
    subtitle: 'MyBundle',
  },
  ridCable: {
    collapsedTitle: 'Live TV',
    title: 'Find the best Live TV alternative for you',
    subtitle: 'Get rid of Cable',
    description:
      '<p>Answer a few questions to receive a personalized live TV recommendation with the channels you actually want–saving you time and money.</p>',
  },
  bestStreamingApps: {
    collapsedTitle: 'Streaming Apps',
    title: 'Discover the best Streaming Apps for\u00A0you',
    subtitle: 'EXPLORE MORE STREAMING',
    description:
      '<p>Manage your streaming costs, access special deals, and explore apps that match your interests.</p>',
  },
  findNewShow: {
    collapsedTitle: 'TV Shows & Movies',
    title: 'Where to stream your favorite Shows & Movies',
    subtitle: 'DISCOVER WHAT TO WATCH',
    description:
      '<p>Receive tailored recommendations across your apps, learn about new titles, and plan what to watch.</p>',
  },
  getTheApp: {
    collapsedTitle: 'Mobile App',
    title: 'Level up your experience with the MyBundle App',
    subtitle: 'DISCOVER WHAT TO WATCH',
    description:
      '<ul><li>Receive and get personalized movie and TV show recommendations on what to watch next.</li><li>Keep track and monitor all your entertainment streaming subscriptions all in one place. </li><li>Create watchlists and share them with your friends and family.</li><ul/>',
  },
  welcome: {
    left: {
      subtitle: 'Get rid of Cable',
      title: 'Find the best Live TV alternative for you',
      button: 'Find My Bundle',
      imagealt: 'Find Streaming Bundle Services & Save Money',
    },
    right: {
      subtitle: 'Discover more streaming',
      title: 'Search for TV shows, movies or services',
      button: 'Explore Marketplace',
      button_short: 'All Services',
    },
    holiday: {
      subtitle: 'HOLIDAY STREAMING SPECIALS',
      title: 'Find the best Streaming Gifts & Deals',
      button: 'Explore Gifts',
    },
  },
  banner: {
    message: 'Tired of your ridiculous cable bill but not sure where to even begin?',
    button: 'Learn More',
  },
  reason: {
    title1: 'Why should you',
    title2: 'cut',
    title3: 'the cord?',
  },
  services: {
    subtitle: 'There are many different types of streaming services to enjoy.',
  },
  statistic: {
    title: 'Millions of people give up Cable and Satellite TV every year',
    subtitle: "We've compiled some statistics to help you get over the hump!",
    items: {
      item1: 'Potential savings per household who switch to Live TV streaming',
      item2: 'To fill out our form and get your personalized recommendation',
      item3: 'Combinations of services we run through to find the right bundle for you.',
      item4: 'Number of streaming services in our Streaming Marketplace',
    },
  },
  whoWeAre: {
    subtitle: 'MEET THE TEAM',
    title: 'Who Are We?',
    text1:
      'MyBundle is simplifying the streaming TV experience. With so many choices of what to watch and where to watch it, the streaming world is overwhelming.  Our platform simplifies all of it so you can get back to watching the channels, shows, and movies you love while saving money & time in the\u00a0process. ',
    text2:
      "So, whether you're looking to cut the cord and save money, find what service your favorite movie is located on, find that next great show to binge, or manage all your streaming services, we're here to help. Watching TV shouldn't be hard. We make it easy",
  },
  createFreeProfile: {
    title: 'Create a Free MyBundle Profile',
    subtitle:
      'Finding what to watch just got easier! Create a free profile to browse and get personalized recommendations for TV shows and movies from across all your streaming services.',
  },
  findYourInternet: {
    title: 'Take a quick quiz to find Best Internet Solution ',
    subtitle:
      "We've partnered with High-Speed broadband providers to help you find the best internet at the best price, so you can stream & save!",
  },
  browseByService: {
    title: 'Browse By Service',
    subtitle: '',
  },
  aboutCoolFeatures: {
    title: 'MyBundle simplifies streaming TV.',
    subtitle: '',
    showsAndMovies: {
      title: 'TV Shows & Movies',
      desc:
        'Receive personalized TV Show & Movie recommendations across your apps, discover new titles, and plan what to watch. ',
      link: {
        title: 'Find What to Watch',
      },
    },
    apps: {
      title: 'App Management',
      desc:
        'Track your streaming expenses, get access to special deals, and find new services that match your interests.  ',
      link: {
        title: 'Find Apps',
      },
    },
  },
};
