import produce from 'immer';

import {
  getChannels,
  getTopChannels,
  getChannelFilters,
  submitForm,
  getFormData,
  channelsAutocomplete,
  channelsSetAutocompleteDefData,
  cleanFormData,
} from '../actions/channels';
import { addEmailToResults } from '../actions/results';

const initialState = {
  channels: [],
  autocompleteDefData: [],
  autocompleteData: [],
  channelsTop: [],
  channelsLocalNews: [],
  filters: [],
  formData: null,
  loadings: {
    channels: false,
    channelsTop: false,
    filters: false,
    submitForm: false,
    formData: false,
    autocompleteData: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET CHANNELS
    case getChannels.types.START:
      state.channels = [];
      state.loadings.channels = true;
      return state;

    case getChannels.types.SUCCESS:
      state.channels = action.payload.data;
      state.loadings.channels = false;
      return state;

    case getChannels.types.FAIL:
      state.loadings.channels = false;
      return state;

    // GET TOP CHANNELS
    case getTopChannels.types.START:
      state.channelsTop = [];
      state.channelsLocalNews = [];
      state.loadings.channelsTop = true;
      return state;

    case getTopChannels.types.SUCCESS:
      state.channelsTop = action.payload.channels;
      state.channelsLocalNews = action.payload.live_local_news;
      state.loadings.channelsTop = false;
      return state;

    case getTopChannels.types.FAIL:
      state.loadings.channelsTop = false;
      return state;

    // GET FILTERS
    case getChannelFilters.types.START:
      state.loadings.filters = true;
      return state;

    case getChannelFilters.types.SUCCESS:
      state.filters = action.payload.data;
      state.loadings.filters = false;
      return state;

    case getChannelFilters.types.FAIL:
      state.loadings.filters = false;
      return state;

    // SUBMIT FORM
    case submitForm.types.START:
      state.loadings.submitForm = true;
      return state;

    case submitForm.types.SUCCESS:
    case submitForm.types.FAIL:
      state.loadings.submitForm = false;
      return state;

    // GET FORM DATA
    case getFormData.types.START:
      state.formData = null;
      state.loadings.formData = true;
      return state;

    case getFormData.types.SUCCESS:
      state.formData = action.payload.data;
      state.loadings.formData = false;
      return state;

    case getFormData.types.FAIL:
      state.loadings.formData = false;
      return state;

    // CLEAN FORM DATA
    case cleanFormData.types.INIT:
      state.formData = initialState.formData;
      state.loadings.formData = initialState.loadings.formData;
      return state;

    // ADD EMAIL TO FORM DATA
    case addEmailToResults.types.SUCCESS:
      state.formData.email = action.payload.email;
      return state;

    // CHANNELS AUTOCOMPLETE
    case channelsAutocomplete.types.START:
      state.loadings.autocompleteData = true;
      return state;

    case channelsAutocomplete.types.SUCCESS:
      state.autocompleteData = action.payload.data;
      if (action.payload.defData?.length) state.autocompleteDefData = action.payload.defData;
      state.loadings.autocompleteData = false;
      return state;

    case channelsAutocomplete.types.FAIL:
      state.loadings.autocompleteData = false;
      return state;

    // SET AUTOCOMPLETE DEF DATA
    case channelsSetAutocompleteDefData.types.INIT:
      state.autocompleteData = state.autocompleteDefData;
      return state;

    default:
      return state;
  }
});

export default reducer;
