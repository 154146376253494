import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// CONSTANTS
import { ROUTE_PROFILE_WL_MOVIES, ROUTE_PROFILE_WL_SHOWS } from '@/constants/routes';
import { LOCATIONS_HEADER } from '@/constants/locations';

// UTILITY
import { externalLink } from '@/utility/analytics';

// HELP
import MenuItem from './help/MenuItem';

// STYLES
import { ListStyled, ListItemStyled } from './styles';

export const CollapsableMenuItem = props => {
  const { t, data } = props;
  const [open, setOpen] = useState(!!data.openByDefault);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemStyled className="list-item" button onClick={handleOpen}>
        <ListItemText primary={t(data.label)} />
        <div className="list-item__collapse-icon">{open ? <ExpandLess /> : <ExpandMore />}</div>
      </ListItemStyled>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <NavList {...props} data={data.submenu} nested />
      </Collapse>
    </>
  );
};

CollapsableMenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export const NavList = props => {
  const { data, content_count, profileDrawerProps, handleMenuItem, nested, location } = props;

  const [t] = useTranslation();

  const handleBlinkRoute = route => {
    let blinkRoute;

    switch (profileDrawerProps.blink) {
      case 'movie':
        blinkRoute = ROUTE_PROFILE_WL_MOVIES;
        break;
      case 'show':
        blinkRoute = ROUTE_PROFILE_WL_SHOWS;
        break;
      default:
        blinkRoute = '';
    }

    return blinkRoute === route;
  };

  const handleExternalLink = ({ href }) => {
    const properties = {
      location: 'header',
      other: 'header external link',
      href,
    };

    externalLink(properties);
  };

  return (
    <ListStyled className={`nav-list ${nested ? 'nav-list--nested' : ''}`}>
      {data.map((item, index) => {
        const blink = handleBlinkRoute(item.route);

        if (item.submenu) {
          return <CollapsableMenuItem {...props} t={t} data={item} key={item.route || index} />;
        }

        if (item.type === 'external') {
          return (
            <ListItemStyled
              className="list-item"
              component={Link}
              href={item.route}
              key={index}
              location={LOCATIONS_HEADER}
              target="_blank"
              color="textPrimary"
              onClick={() => handleExternalLink({ href: item.route })}
            >
              <ListItemText primary={t(item.label)} />
            </ListItemStyled>
          );
        }

        return (
          <MenuItem
            t={t}
            data={item}
            count={content_count[item.badge_key] || 0}
            blink={blink}
            handleMenuItem={handleMenuItem}
            key={item.route || index}
            location={location}
          />
        );
      })}
    </ListStyled>
  );
};

NavList.defaultProps = {
  handleMenuItem: () => {},
  nested: false,
  content_count: {},
  profileDrawerProps: {},
  location: '',
};

NavList.propTypes = {
  data: PropTypes.array.isRequired,
  profileDrawerProps: PropTypes.object,
  content_count: PropTypes.object,
  handleMenuItem: PropTypes.func,
  nested: PropTypes.bool,
  location: PropTypes.string,
};

export default React.memo(NavList);
