import styled from 'styled-components';

// CONSTANTS
import { WHITE } from '@/constants/colors';

// UTILITY
import breakpoints from '@/utility/breakpoints';

// HEADER
export const Wrapper = styled.div`
  .header,
  &.header {
    width: 100%;

    &__app-bar {
      background-color: ${WHITE};
      color: ${p => p.theme.palette.text.primary};
      z-index: ${p => p.theme.zIndex.drawer + 20};
    }

    &__toolbar {
      height: 90px;

      &--main {
        display: flex;
        align-items: center;

        color: inherit;

        width: 100%;
        max-width: 1440px;

        margin: 0 auto;
        padding: 0 30px;

        ${breakpoints.down('md')} {
          padding: 0 15px;
        }
      }

      &--fake {
      }
    }

    &__start-button {
      white-space: nowrap;
    }

    &__phone {
      display: block;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 600;
      margin-top: 5px;
    }
  }
`;

export default Wrapper;
