export default {
  title: 'Marketplace',
  subtitle: '',
  noResults: 'No apps found, try another search!',
  selectedFilters: {
    genres: 'Showing app tagged with:',
    channels: 'Showing apps similar to these channels:',
  },
  filters: {
    searchTitle: 'Advanced Search',
    searchSubTitle: 'Streaming services',
    searchPlaceholder: 'Search by Title',
    genresPlaceholder: 'Genres',
    channelsPlaceholder: 'Similar channels',
  },
  banners: {
    onboarding: {
      title: 'Already have streaming services?',
      subtitle:
        'Add apps that you already have to browse and get personalized recommendations for TV shows and movies from across all your streaming services.',
    },
  },
};
