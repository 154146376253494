export default {
  appHighlight: {
    title: 'Featured App',
  },
  popularOn: {
    title: 'Popular on {{appName}}',
  },
  sling: {
    title: 'Stream the best of TV!',
    subtitle: 'Get Half off your first month!',
    benefits: {
      item1: 'No long-term contracts.',
      item2: 'Record 50 hours of live TV',
      item3: 'Stream at home or on the go.',
    },
    promo: {
      title: 'Half off your first month',
    },
    channels: {
      title: 'All your favorite channels including live sports, news and entertainment.',
    },
  },
  'sling-promo': {
    title: "Get Sling TV today and you'll get Hulu for 3 months on us!",
    subtitle: 'Plus half off your first month of Sling!',
    promo: {
      title: 'SPECIAL OFFER!',
    },
  },
  'sling-airtv': {
    title: 'Sign up for <img/> TV today,<br/>Get a free <a>HD Antenna + AirTV 2</a>.',
    discountText: '50% off your first month of Sling',
    benefits: {
      item1: 'No long-term contracts',
      item2: 'Stream at home or on the go',
      item3: 'Free local channels',
    },
  },
  'sling-promo-peacock': {
    title: "Get Sling TV today and you'll get Peacock Premium for 3 months on us!",
    subtitle: 'Plus half off your first month of Sling!',
    promo: {
      title: 'SPECIAL OFFER!',
    },
  },
  'hulu-live': {
    title: 'Get Hulu + Live TV and watch live sports, news, and events on 85+ top channels!',
    subtitle: 'Plus, you get unlimited access to the Hulu streaming library, Disney+ and ESPN+',
    promo: {
      title: '',
    },
    benefits: {
      item1: 'Hulu Streaming Library',
      item2: 'Endless entertainment with Disney+',
      item3: 'Sports with ESPN+',
    },
    channels: {
      title: 'Includes 85+ top Live TV Channels and unlimited DVR',
    },
  },
};
