import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Hidden, Link, Tooltip } from '@material-ui/core';

// COMPONENTS
import AppsCarousel, {
  ChannelsCarouselSlide as AppsCarouselSlide,
} from '@/components/Carousels/ChannelsCarousel';

// STYLES
import { AppListContainer, AppsContainer, AppsWrapper, App } from './styles';

const AppList = props => {
  const { apps, onAppRemove, onUnselectAll } = props;

  const [t] = useTranslation();

  const options = {
    slidesPerView: 'auto',
    watchOverflow: true,
    spaceBetween: 10,
  };

  const handleUnselectClick = event => {
    event.preventDefault();
    onUnselectAll();
  };

  return (
    <AppListContainer className="app-list">
      <Hidden smDown implementation="js">
        <span className="app-list__title">{t('onboarding.selectedApps')}</span>
      </Hidden>
      {apps.length > 1 ? (
        <Link href="#" className="app-list__link" color="error" onClick={handleUnselectClick}>
          {t('buttons.unselectAll')}
        </Link>
      ) : null}
      <AppsContainer>
        <AppsWrapper>
          <AppsCarousel options={options}>
            {apps.map(app => (
              <AppsCarouselSlide key={app.id}>
                <Tooltip title={app.name} key={app.id}>
                  <App
                    className="app noselect"
                    onClick={event => {
                      onAppRemove(event, app);
                    }}
                  >
                    <img
                      src={app.thumbnail || `https://dummyimage.com/64X64?text=${app.name}`}
                      alt={app.name}
                    />
                  </App>
                </Tooltip>
              </AppsCarouselSlide>
            ))}
          </AppsCarousel>
        </AppsWrapper>
      </AppsContainer>
    </AppListContainer>
  );
};

AppList.propTypes = {
  apps: PropTypes.array.isRequired,
  onAppRemove: PropTypes.func.isRequired,
  onUnselectAll: PropTypes.func.isRequired,
};

export default React.memo(AppList);
