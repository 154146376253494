import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Redirect from '@/components/Redirect';

const PrivateRoute = ({ component, isAuth, showAuthDialog, manageAuthDialogState }) => {
  if (!isAuth && !showAuthDialog) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: '' },
        }}
      />
    );
  }

  if (!isAuth && showAuthDialog) {
    manageAuthDialogState(true, {
      screen: 'login',
      onDialogClose: () => {
        window.location.href = '/';
      },
    });
  }

  return <>{component}</>;
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
  showAuthDialog: PropTypes.bool,
  manageAuthDialogState: PropTypes.func.isRequired,
};

export default PrivateRoute;
