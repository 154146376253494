import { createAction } from '@/utility/actions';

import {
  DISCOVER_GET_LIST,
  DISCOVER_CLEAN_LIST,
  DISCOVER_GET_FILTERS,
  DISCOVER_GET_SEARCH,
  DISCOVER_CLEAN_SEARCH,
  DISCOVER_GET_COLLECTIONS,
} from './types';

/**
 * getDiscoverList - params: params(pagination)
 */
export const getDiscoverList = createAction(DISCOVER_GET_LIST);

export const cleanDiscoverList = createAction(DISCOVER_CLEAN_LIST);

export const getDiscoverFilters = createAction(DISCOVER_GET_FILTERS);

/**
 * getDiscoverSearch - params: params(search)
 */
export const getDiscoverSearch = createAction(DISCOVER_GET_SEARCH);

/**
 * cleanDiscoverSearch
 */
export const cleanDiscoverSearch = createAction(DISCOVER_CLEAN_SEARCH);

/**
 * getDiscoverCollections
 */
export const getDiscoverCollections = createAction(DISCOVER_GET_COLLECTIONS);

export default {
  getDiscoverList,
  cleanDiscoverList,
  getDiscoverFilters,
  getDiscoverSearch,
  getDiscoverCollections,
  cleanDiscoverSearch,
};
