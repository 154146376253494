import produce from 'immer';

import { getSearchParams } from '@/utility/common';
import { getPartnerFromPathname } from '@/utility/partner';
import {
  setMaintenanceMode,
  emailUnsubscribe,
  setPartner,
  manageProfDrawerState,
  setDeviceOrientation,
  setHeaderHeight,
  createFeedback,
  getAppSearch,
  getDictionary,
  requestDemo,
  getDownloadAppLinkViaSMS,
} from '../actions/app';
import { getFormData } from '../actions/channels';
import { getPartner, getPartnerByDomain } from '../actions/partner';

const params = getSearchParams(document.location.search);
const isIframe = params ? params.has('iframe') : false;
const hasIframeTypeParam = params ? params.has('itype') : false;
const hasColorSchemaParam = params ? params.has('c-schema') : '';
const iframeType = isIframe && hasIframeTypeParam ? params.get('itype') : '';
const colorSchema = hasColorSchemaParam ? params.get('c-schema') : '';
const isSimpleView = params ? params.has('tv') : false;
const isInAppView = params ? params.has('in_app') : false; // parameter to hide headers and content on the app details page
const hasPartnerParam = params ? params.has('partner') : false;
const hasNoCacheParam = params ? params.has('no-cache') : false;
const hasYtpcParam = params ? params.has('ytpc') : false;
const ytpc = hasYtpcParam ? params.get('ytpc') : ''; // youtube promocode
const parterFromPathname = getPartnerFromPathname();
const hideLinks = params ? params.has('hidelinks') : false;
let partner = hasPartnerParam ? params.get('partner') : '';

if (parterFromPathname) {
  partner = parterFromPathname;
}

const initialState = {
  isIframe,
  iframeType,
  colorSchema,
  isSimpleView,
  isInAppView,
  maintenanceMode: false,
  hasPartnerParam,
  hasNoCacheParam,
  partner,
  ytpc,
  useSlug: true,
  profileDrawerIsOpen: false,
  profileDrawerProps: {},
  orientation: 'portrait',
  headerHeight: isIframe || isInAppView ? 0 : 90,
  dictionary: null,
  search: {
    data: [],
    string: '',
  },
  loadings: {
    unsubscribe: false,
    search: false,
    dictionary: false,
    requestDemo: false,
    feedback: false,
    sendDownloadLink: false,
  },
  hideLinks,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // SET MAINTENANCE MODE
    case setMaintenanceMode.types.INIT:
      state.maintenanceMode = action.payload;
      return state;

    // EMAIL UNSUBSCRIBE
    case emailUnsubscribe.types.START:
      state.loadings.unsubscribe = true;
      return state;

    case emailUnsubscribe.types.SUCCESS:
    case emailUnsubscribe.types.FAIL:
      state.loadings.unsubscribe = false;
      return state;

    // SET PARTNER
    case setPartner.types.INIT:
      state.partner = action.payload.partner || '';
      state.hasPartnerParam = Boolean(action.payload.partner);
      return state;

    // GET FORM DATA
    case getFormData.types.SUCCESS:
      state.useSlug = !!action.payload.data.partner.settings?.use_slug;
      return state;

    // GET PARTNER
    case getPartner.types.SUCCESS:
    case getPartnerByDomain.types.SUCCESS:
      state.partner = action.payload.partner.partner_slug || '';
      state.useSlug = !!action.payload.partner.settings?.use_slug;
      return state;

    // MANAGE PROFILE DRAWER STATE
    case manageProfDrawerState.types.INIT:
      state.profileDrawerIsOpen = action.payload.state;
      state.profileDrawerProps = action.payload.props || {};
      return state;

    // SET DEVICE ORIENTATION
    case setDeviceOrientation.types.INIT:
      state.orientation = action.payload.orientation;
      return state;

    // SET HEADER HEIGHT
    case setHeaderHeight.types.INIT:
      state.headerHeight = action.payload.headerHeight || state.headerHeight;
      return state;

    // CREATE FEEDBACK
    case createFeedback.types.START:
      state.loadings.feedback = true;
      return state;

    case createFeedback.types.SUCCESS:
    case createFeedback.types.FAIL:
      state.loadings.feedback = false;
      return state;

    // GET SEARCH
    case getAppSearch.types.START:
      state.search.string = action.payload.params.search_string;
      state.loadings.search = true;
      return state;

    case getAppSearch.types.SUCCESS:
      state.search.data = action.payload.data;
      state.loadings.search = false;
      return state;

    case getAppSearch.types.FAIL:
      state.loadings.search = false;
      return state;

    // GET FILTERS
    case getDictionary.types.START:
      state.loadings.dictionary = true;
      return state;

    case getDictionary.types.SUCCESS:
      state.dictionary = action.payload.data;
      state.loadings.dictionary = false;
      return state;

    case getDictionary.types.FAIL:
      state.loadings.dictionary = false;
      return state;

    // REQUEST DEMO
    case requestDemo.types.START:
      state.loadings.requestDemo = true;
      return state;

    case requestDemo.types.SUCCESS:
    case requestDemo.types.FAIL:
      state.loadings.requestDemo = false;
      return state;

    // REQUEST DEMO
    case getDownloadAppLinkViaSMS.types.START:
      state.loadings.sendDownloadLink = true;
      return state;

    case getDownloadAppLinkViaSMS.types.SUCCESS:
    case getDownloadAppLinkViaSMS.types.FAIL:
      state.loadings.sendDownloadLink = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
