import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

// MATERIAL UI
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';

// STORE
import { NotifActions } from '@/store/actions';

const styles = createStyles({
  btn: {
    fontSize: '1.2rem',
    lineHeight: '2rem',
    padding: '1px 5px',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

class SnackbarNotify extends Component {
  displayed = [];

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;

    for (let i = 0; i < newSnacks.length; i += 1) {
      /* eslint-disable */
      if (notExists) continue;
      /* eslint-enable */
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], popNotification } = this.props;

    notifications.forEach(notification => {
      if (this.displayed.includes(notification.key) || !notification.message) return;

      enqueueSnackbar(notification.message, {
        // autoHideDuration: 5000,
        ...notification.options,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        action: this.closeAction,
      });

      this.storeDisplayed(notification.key);

      popNotification(notification.key);
    });
  }

  closeAction = key => {
    const { t, classes } = this.props;

    return (
      <Button
        classes={{ root: classes.btn }}
        color="inherit"
        variant="text"
        onClick={() => closeSnackbar(key)}
      >
        {t('buttons.close')}
      </Button>
    );
  };

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  render() {
    return null;
  }
}

SnackbarNotify.propTypes = {
  t: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  popNotification: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

const mapStateToProps = ({ notifications }) => ({
  notifications: notifications.list,
});

const mapDispatchToProps = dispatch => {
  return {
    popNotification: data => dispatch(NotifActions.popNotification.action(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(styles, { withTheme: true }),
)(SnackbarNotify);
