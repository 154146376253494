import styled from 'styled-components';
import { Grid } from '@material-ui/core';

// CONSTANTS
import colors from '@/constants/colors';

export const Wrapper = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ProgressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  z-index: 1102;
  background: ${colors.background};

  p {
    margin-top: 20px;
  }
`;

export const ContentWrap = styled.div``;
