import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, MAIN_BLACK, BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

const windstream = {
  primary: '#ef6724',
  secondary: '#1b2c83',
  primaryButtonTextColor: '#ffffff',
  secondaryButtonTextColor: '#ffffff',
  buttonHoverBackground: '#ef6724',
  background: '#f7f7f7',
  inputBackground: '#FFFFFF',
  hoverPrimary: '#339469',
  primaryTextColor: '#000000',
  secondarytextcolor: '#505050',
  primaryFont: 'Lato',
  secondaryFont: '',
};

const windstreamStyles = css`
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Lato Italic'), local('Lato-Italic'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAUi-qJCY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Lato Italic'), local('Lato-Italic'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_Gwft.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Lato Regular'), local('Lato-Regular'),
      url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Lato Regular'), local('Lato-Regular'),
      url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Lato Bold'), local('Lato-Bold'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Lato Bold'), local('Lato-Bold'),
      url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  body,
  #root.windstream {
    background-color: ${windstream.background};
    font-family: ${windstream.primaryFont} !important;
  }

  #root.windstream {
    min-height: 300px;
  }
  .MuiIconButton-label {
    svg {
      color: ${windstream.primary};
    }
  }

  /* Bottom Right Notification */
  .MuiSnackbar-anchorOriginBottomRight {
    [class*='MuiSnackbarContent-root'] {
      background-color: ${windstream.primary};
      color: ${windstream.primaryButtonTextColor};
      svg {
        color: ${windstream.primaryButtonTextColor};
      }
    }
  }
  /* Popover Components */
  [class*='MuiPopover-root'] {
    &#menu-devices,
    &#menu-genre_search {
      /* Mui List option */
      [class*='MuiList-root'] {
        [class*='MuiListItem-root'] {
          &:hover {
            color: ${windstream.primary};
          }
        }
        [class*='Mui-selected'] {
          color: ${windstream.primary};
        }

        [class*='touchRipple'] {
          background-color: ${windstream.primary};
        }
      }
    }
  }

  /* Mui Breadcrumbs */
  [class*='MuiBreadcrumbs-root'] {
    [class*='MuiLink-root'] {
      &:hover {
        color: ${windstream.primary};
      }
    }
  }

  /* Mui Circular Progress */
  [class*='MuiCircularProgress'] {
    color: ${windstream.primary};
  }

  /* COMPONENTS */
  /* Footer */
  .footer-wrapper {
    .section {
      &--dark {
        background-color: ${BLACK};
      }
    }
  }

  /* PAGES */
  /* App Details page */
  .app-details-page {
    .hero-container {
      .p1 {
        font-weight: 350;
      }
      .grid-item__back-to-results {
        a {
          background-color: ${windstream.secondary};
          border-color: ${windstream.secondary};
          color: ${windstream.secondaryButtonTextColor};
        }
      }
    }
    .page-content p {
      font-weight: 350;
    }
    .gallery-wrapper {
      .MuiButton-root {
        background-color: ${rgba(windstream.primary, 0.8)};
        border-radius: 50%;
        color: ${windstream.primaryButtonTextColor};
        font-size: 24px;
      }
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail.active,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:hover,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:focus {
        border-color: ${windstream.primary};
      }
    }

    #packages {
      .package-card {
        &--active {
          border-color: ${windstream.primary};
        }
        a.MuiButton-contained:not(.MuiButton-containedPrimary) {
          border-color: ${windstream.secondary};
          background-color: ${windstream.secondary};
          color: ${windstream.secondaryButtonTextColor};
        }

        &:hover {
          .title {
            color: ${windstream.primary};
          }
        }
      }
    }
    #deals {
      .MuiButton-outlinedPrimary {
        border-color: ${windstream.primary};
        background-color: ${windstream.primary};
        color: ${windstream.primaryButtonTextColor};
      }
    }

    .MuiPagination-ul {
      .MuiPaginationItem-page {
        color: ${windstream.primary};
      }
    }
    #channels {
      .filter-wrapper {
        &__count-available {
          color: ${windstream.primary};
        }
      }
    }
  }

  /* All pages */
  body {
    background-color: ${windstream.background};
    font-family: ${windstream.primaryFont};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
      font-family: ${windstream.primaryFont} !important;
    }

    /* MATERIAL UI STYLES */
    /* Mui Buttons */
    /* All Buttons */
    .MuiButton-outlined,
    .MuiButton-root,
    button[class*='MuiButtonBase-root'] {
      font-size: 1.6rem;
      color: ${windstream.primaryButtonTextColor};
      font-weight: bold;
      font-family: ${windstream.primaryFont};
      border: 2px solid ${windstream.primary};
      padding: 17px 35px;
      .icon-close {
        color: ${windstream.primary};
      }

      &[class*='Mui-disabled'] {
        color: ${rgba(BLACK, 0.26)};
        background-color: ${rgba(MAIN_BLACK, 0.12)};
      }

      @media (max-width: ${breakpoints.md}) {
        padding: 17px 25px;
      }

      @media (max-width: ${breakpoints.sm}) {
        padding: 17px 15px;
      }

      @media (max-width: ${breakpoints.xs}) {
        padding: 17px 0px;
      }

      &.next-button,
      &.previous-button {
        padding: 1.3rem 2rem;
      }
    }

    /* Mui Button Contained */
    [class*='MuiButton-contained'] {
      &:not([class*='MuiButton-containedPrimary']):not(.previous-button) {
        background-color: ${windstream.buttonHoverBackground};
        &:hover {
          background-color: ${windstream.primary};
          border-color: ${windstream.primary};
        }
      }
    }

    /* Mui Button Contained Primary */
    [class*='MuiButton-containedPrimary'],
    #root .post-page .primary button {
      background-color: ${windstream.primary};
      &:hover {
        background-color: ${windstream.buttonHoverBackground};
        border-color: ${windstream.primary};
      }
    }

    /* Mui Button Outlined */
    [class*='MuiButton-outlined'] {
      border-color: ${windstream.buttonHoverBackground};
      color: ${windstream.primary};
      &:hover {
      }
    }

    /* Mui Button Outlined Primary */
    [class*='MuiButton-outlinedPrimary'],
    .header-menu-drawer-button {
      border-color: ${windstream.buttonHoverBackground};
      color: ${windstream.primary};
      &:hover {
      }
    }

    /* Mui Autocomplete */
    [class*='MuiAutocomplete-root'] {
      background-color: ${windstream.inputBackground};

      [class*='MuiAutocomplete-endAdornment'] {
        width: auto;
        height: auto;
      }

      [class*='MuiAutocomplete-clearIndicator'] {
        padding: 4px;
      }
    }

    /* Mui Autocomplete option */
    [class*='MuiAutocomplete-option'] {
      [data-reason*='remove-option'] {
        color: ${windstream.primary};
        .icon-check {
          color: ${windstream.primary};
        }
      }
    }

    /* Mui Input */
    [class*='MuiInputBase-root'] {
      background-color: ${windstream.inputBackground};

      &[class*='Mui-focused'] {
        [class*='MuiOutlinedInput'] {
          border-color: ${windstream.primary};
        }
      }
    }

    /* Mui Chip */
    [class*='MuiChip-root'] {
      background-color: ${windstream.primary};
      font-weight: bold;
      border: 2px solid ${windstream.primary};
      color: ${windstream.primaryButtonTextColor};

      [class*='MuiChip-deleteIconColorPrimary'] {
        color: ${BLACK};
      }

      &[class*='MuiChip-clickable'] {
        &:hover,
        &:focus {
          background-color: ${windstream.primary};
        }
      }
    }

    /* Mui Form Label */
    [class*='MuiFormLabel-root'] {
      &[class*='Mui-focused'] {
        &:not([class*='Mui-error']) {
          color: ${windstream.primary};
        }
      }
    }

    /* Mui Form Control */
    [class*='MuiFormControlLabel-root'] {
      /* Mui Checkbox */
      [class*='MuiCheckbox-root'] {
        .icon-correct-symbol {
          color: ${BLACK};
          border-color: ${windstream.primary};
          &:before {
            background-color: ${windstream.buttonHoverBackground};
          }
        }

        &[class*='Mui-checked'] {
          & + [class*='MuiFormControlLabel-label'] {
            color: ${windstream.primary};
          }
          .icon-correct-symbol {
            color: ${windstream.buttonHoverBackground};
            &:before {
              background-color: ${windstream.primary};
            }
          }
        }
      }
    }

    /* Mui Rating */
    [class*='MuiRating-root'] {
      &[class*='MuiRating-readOnly'] {
        color: ${windstream.primary};
      }
      &[class*='sizeLarge'] {
        [class*='MuiRating-icon'] {
          &[class*='MuiRating-iconFilled'] {
            background-color: ${windstream.primary};
          }
        }
      }
    }

    /* Mui Tab */
    .MuiTab-root {
      color: ${BLACK};
      border: 2px solid ${windstream.primary};
      background-color: ${windstream.primary};
      color: ${windstream.primaryButtonTextColor};
    }
    .MuiTab-root.Mui-selected {
      background-color: ${windstream.primary};
    }

    /* COMPONENTS */
    /* Marker */
    .marker--el,
    .marker:before {
      background-color: ${windstream.primary};
    }

    /* Footer */
    footer {
      background-color: ${windstream.background};

      .previous-button {
        background: none;
        color: ${windstream.primaryTextColor};
        font-size: 1.4rem;
        font-weight: 500;
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    /* Header Nav Button */
    .header-menu-drawer-button {
      width: 60px;
      height: 60px;
    }

    /* Info Card */
    .info-card {
      &:hover {
        .title {
          color: ${windstream.primary};
        }
      }
    }

    /* footer links */
    [class*='MuiLink-root'] {
      &:hover {
        color: ${windstream.primary};
      }
    }
  }

  /* Start of Centruy Link iFrame */
  .partner-windstream,
  .partner-centurylink {
    form {
      h1 {
        font-size: 2.375rem;
        font-family: ${windstream.primaryFont} !important;
        font-weight: 500;
        color: ${BLACK};
      }

      p {
        font-size: 1.4rem;
        color: ${windstream.secondarytextcolor};
        font-weight: 350;
      }
    }

    .backtohome {
      a {
        color: ${windstream.primary};
      }
    }
    .internet-option__phone,
    .modal-item__phone {
      color: ${windstream.primary};
    }

    .right-section {
      .stepper__dot--active {
        background-color: ${windstream.primary};
        border-color: ${windstream.primary};
      }

      .stepper__dot--complete {
        border-color: ${windstream.primary};
      }

      .stepper {
        & > span {
          color: ${windstream.primary};
        }
      }
    }

    .toolbar__link {
      padding: 10px 20px;
      &.nextlink {
        color: ${windstream.primaryButtonTextColor};
        background-color: ${windstream.primary};
        padding: 11px 20px;

        &.Mui-disabled {
          color: ${rgba(MAIN_BLACK, 0.4)};
        }
      }
      &.prevlink {
        font-weight: 350;
        color: ${windstream.primaryTextColor};
      }
    }
    .zipcodeinput {
      background-color: ${windstream.inputBackground};

      > div {
      }

      .Mui-focused {
        color: ${windstream.primary} !important;
        fieldset {
          border-color: ${windstream.primary} !important;
        }
      }
    }
    .radio-card {
      background-color: ${windstream.inputBackground};

      &:hover {
        border-color: ${windstream.primary} !important;
      }
      .radio-card__title {
        font-weight: 350 !important;
      }

      &.radio-card--checked {
        &:before {
          background-color: ${windstream.primary};
        }

        .radio-card__title {
          color: ${windstream.primary};
        }

        &:after {
          box-shadow: 0 0 0 2px ${windstream.primary};
        }
      }
    }

    .checkbox-card {
      background-color: ${windstream.inputBackground};

      &:hover {
        border-color: ${windstream.primary} !important;
      }

      .checkbox-card__title {
        font-weight: 350 !important;
      }
      &.checkbox-card--checked {
        &:before {
          background-color: ${windstream.primary};
        }

        .checkbox-card__title {
          color: ${windstream.primary};
        }

        &:after {
          box-shadow: 0 0 0 2px ${windstream.primary};
        }
      }
    }

    .swiper-button-nav {
      background-color: ${windstream.background} !important;

      &.swiper-button-prev:before {
        background: linear-gradient(
          90deg,
          ${rgba(windstream.background, 1)} 0%,
          ${rgba(windstream.background, 0)} 80%
        ) !important;
      }

      &.swiper-button-next:before {
        background: linear-gradient(
          90deg,
          ${rgba(windstream.background, 0)} 0%,
          ${rgba(windstream.background, 1)} 80%
        ) !important;
      }
    }

    //Channel Page
    &.channelspage {
      form > div:not(.page-content) {
        margin: 0 auto;
        padding: 67px 10px;
      }

      .channel-header {
        background-color: ${windstream.background};

        .search-channels,
        .genre-select {
          background-color: ${windstream.inputBackground};
        }

        .search-channels .Mui-focused {
          color: ${windstream.primary};
          fieldset {
            border-color: ${windstream.primary};
          }
        }

        .genre-select.Mui-focused {
          fieldset {
            border-color: ${windstream.primary};
          }
        }
      }
    }

    //email page
    .email-address .m-b-30 {
      background-color: ${windstream.inputBackground};

      .Mui-focused {
        color: ${windstream.primary} !important;

        fieldset {
          border-color: ${windstream.primary} !important;
        }
      }
    }

    .terms-wrapper {
      > span {
        background: ${windstream.inputBackground};
      }
      .icon-correct-symbol:before {
        border: 2px solid ${windstream.primary} !important;
      }
      .Mui-checked .icon-correct-symbol:before {
        background-color: ${windstream.primary};
        border: 2px solid ${windstream.primary} !important;
      }
      p.noselect {
        font-size: 1.2rem;
        a {
          color: ${windstream.primaryTextColor};
        }
      }
    }
    //end email page

    &.resultspage {
      .streaming-section,
      .share-section {
        display: none !important;
      }
      & > .section--grey {
        background-color: ${windstream.background};
        padding-bottom: 70px;
      }
      .p5 {
        font-weight: 350;
      }

      .bundle {
        box-shadow: 2px 2px 2px ${rgba(BLACK, 0.4)};
        border: 2px solid ${windstream.primary};
        .bundle__description {
          font-weight: 350;
        }
        .service__cost {
          font-weight: 500;
        }
      }

      .learn-more-button {
        background-color: ${windstream.primary};
        color: ${windstream.primaryButtonTextColor};
        font-weight: bold;
        border: 2px solid ${windstream.primary};

        &:hover {
          background-color: ${windstream.buttonHoverBackground};
        }
      }

      .channel-list h2 {
        color: ${windstream.primary};
      }

      .channel-list-collapse__title-icon:before {
        color: ${windstream.primaryTextColor};
      }

      .share__item a:hover {
        color: ${windstream.primary} !important;
      }

      .share__item.share--copy-link:hover {
        color: ${windstream.primary};
      }

      .edit-channels-link {
        background-color: ${windstream.primary};
        color: ${windstream.primaryButtonTextColor};
        border: 2px solid ${windstream.primary};
        &:hover {
          background-color: ${windstream.buttonHoverBackground};
        }
      }

      .email-section__btn {
        background-color: ${windstream.primary};

        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }

      .visit-site {
        background-color: ${windstream.primary};
        color: ${windstream.primaryButtonTextColor};
      }

      @media (max-width: ${breakpoints.xs}) {
        header.results {
          display: none !important;
        }

        .channel-list > div {
          box-shadow: none;
        }

        footer.app-bar--shadow {
          box-shadow: none;
          background-color: ${WHITE};

          .swiper-button-nav {
            background-color: ${WHITE} !important;

            &.swiper-button-prev:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 1)} 0%,
                ${rgba(WHITE, 0)} 80%
              ) !important;
            }

            &.swiper-button-next:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 0)} 0%,
                ${rgba(WHITE, 1)} 80%
              ) !important;
            }
          }
        }

        .channel-list__link {
          color: ${windstream.primary};
        }
      }

      &__modal {
        background-color: transparent;

        .modal {
          /* styles for modal */
        }
      }

      .no-results {
        &__button {
          background-color: ${windstream.primary};
          font-size: 1.6rem;
          color: ${windstream.primaryButtonTextColor};
          font-weight: bold;
          font-family: ${windstream.primaryFont};
          border: 2px solid ${windstream.primary};

          &:hover {
            background-color: ${windstream.buttonHoverBackground};
          }

          &.Mui-disabled {
            background-color: ${rgba(MAIN_BLACK, 0.12)};
          }
        }
      }
    }
    // end results page

    .success {
      i {
        color: ${windstream.primary};
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${windstream.primary} !important;
    }

    /* Popover class */
    &.popover-wrapper {
      background-color: transparent;

      .popover-wrapper__dropdown-container {
        /* styles for dropdown container */
      }
    }

    /* Modal Content */
    .modal-content {
      .visit-site {
        background-color: ${windstream.primary};
        color: ${windstream.primaryButtonTextColor};
        border: 2px solid ${windstream.primary};
        padding: 1.3rem 2rem;
        &:hover {
          background-color: ${windstream.buttonHoverBackground};
        }
      }
      .learnmore {
        background-color: ${windstream.secondary};
        color: ${windstream.secondaryButtonTextColor};
        padding: 1.3rem 2rem;
        border: 2px solid ${windstream.secondary};
        &:hover {
          background-color: ${windstream.secondary};
        }
      }
      .detail-item__title {
        .total,
        .servicename {
          color: ${windstream.primary};
        }
      }
    }
  }
`;

export default windstreamStyles;
