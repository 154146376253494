import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Button, IconButton, Link } from '@material-ui/core';
import { rgba } from 'polished';

// CONSTANTS
import breakpoints from '@/constants/breakpoints';
import colors, { BLACK, MAIN_BLACK } from '@/constants/colors';

// UTILITY
import bp from '@/utility/breakpoints';

// HEADER
export const HeaderWrapper = styled.div`
  .header {
    &__search-container {
      margin-left: 15px;
      width: 100%;
    }

    &__search-buttons {
      margin-left: auto !important;

      display: flex;

      align-items: center;
    }
  }

  display: flex;
  width: 100%;

  .header-inactive {
    svg {
      &.oldlogo {
        &.green-logo {
          display: none !important;
        }
        &.white-logo {
          display: block !important;
        }
      }
    }
  }

  .header-appbar {
    &--landscape {
      .header-burger {
        position: fixed;
        top: 22px;
        left: 14px;
        background-color: ${colors.header.active.bg};
      }

      .logo {
        margin-left: 46px;
      }
    }
  }
`;

export const ToolbarCommon = styled(Toolbar)`
  height: 90px;
`;

export const ToolbarStyled = styled(ToolbarCommon)`
  display: flex;
  align-items: center;

  color: inherit;

  width: 100%;
  max-width: 1440px;

  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 0 15px;
  }

  .header__profile-buttons {
    display: flex;
    align-items: center;

    margin-left: 5px;

    .balance-button {
      width: 46px;
      height: 46px;
    }

    .balance-button {
      margin-right: 5px;
    }

    ${bp.up('lg')} {
      margin-left: 16px;

      .balance-button {
        margin-right: 16px;
      }
    }
  }
`;

export const MenuButton = styled(IconButton)`
  .header-burger,
  &.header-burger {
    color: ${p => p.theme.palette.primary.main};
    margin-right: 10px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  > * {
    margin-left: 15px !important;

    &:first-child {
      margin-left: 5px !important;
      @media (min-width: ${breakpoints.md}) {
        margin-left: 25px !important;
      }
    }
  }

  .registration-btn {
    display: inline-block;

    @media (max-width: 1150px) {
      display: none;
    }

    .registration-btn__seperator {
      margin-right: 15px;
    }
  }
`;

export const LoginLink = styled(Link)`
  margin-left: auto;

  color: inherit;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const RoundButton = styled(Button)`
  .round-button,
  &.round-button {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    width: 46px;
    min-width: 46px;
    height: 46px;

    padding: 0 !important;

    &--search {
      border: none;

      width: 50px;
      min-width: 50px;
      height: 50px;

      ${bp.down('sm')} {
        font-size: 2.6rem;
      }

      ${bp.down('xs')} {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }
    }
  }
`;

// HEADER DESKTOP MENU
export const ListDesktop = styled.ul`
  margin: 0 0 0 60px;

  padding: 0;

  white-space: nowrap;

  ${bp.down('md')} {
    margin: 0 0 0 30px;
  }
`;

export const DesktopLink = styled(Link)`
  position: relative;
  display: inline-block;
  color: ${MAIN_BLACK};
  transition: color 0.3s ease;
  margin-right: 20px;
  line-height: 35px;

  ${bp.down(1024)} {
    margin-right: 15px;
  }

  ${bp.down(980)} {
    margin-right: 9px;
  }

  &:hover,
  &.active {
    color: ${p => p.theme.palette.primary.main};
  }

  &:last-child {
    margin-right: 0;
  }

  &.nav-link,
  .nav-link {
    &__icon {
      font-size: 2rem;
      vertical-align: sub;
      transition: transform 0.3s ease-in-out;
    }

    &--with-subnav {
      cursor: default;
    }

    &:hover {
      .subnav {
        opacity: 1;
        visibility: visible;
      }

      .nav-link__icon {
        transform: rotate(180deg);
      }
    }
  }

  .subnav {
    position: absolute;
    top: 100%;
    left: -14px;
    min-width: 220px;
    border-radius: 3px;
    box-shadow: 0 10px 30px ${rgba(BLACK, 0.1)};
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &,
    ul {
      outline: none;
    }
  }
`;

export const useStyles = makeStyles(theme => ({
  // AppBar (fixed block)
  appBar: {
    zIndex: theme.zIndex.drawer + 20,
    left: 'auto',
    right: 'auto',
    paddingRight: '0 !important',
    backgroundColor: colors.header.active.bg,
    color: colors.header.active.text,
    transition: 'background-color 0.3s ease',

    '&.header-inactive': {
      backgroundColor: colors.header.inactive.bg,
      color: colors.header.inactive.text,

      '& .MuiLink-root:hover': {
        color: colors.secondary,
      },
    },
  },

  // Icon Size
  iconSize: {
    fontSize: 22,
  },

  // Drawer Paper
  drawerPaper: {
    width: 300,

    '& .drawer-buttons': {
      paddingTop: 14,
    },
  },
}));
