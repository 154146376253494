import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// HOOKS
import usePrefetch from '@/hooks/usePrefetch';

// UTILITY
import { routeEnhance } from '@/utility/routes';
import {
  analyticsInternalLinks,
  analyticsClickedToApp,
  analyticsSwimlaneSeeAll,
} from '@/utility/analytics';

const Link = React.forwardRef((props, ref) => {
  const {
    to,
    location: analyticsLocation,
    onClick,
    analyticsType,
    analyticsProps,
    withoutIframe,
    ...restProps
  } = props;
  const routeEnhanced = routeEnhance(to, { state: { location: analyticsLocation }, withoutIframe });
  const { handlePrefetch } = usePrefetch(to);

  const handleClick = e => {
    const properties = {
      ...analyticsProps,
      unique_id: routeEnhanced.state.unique_id,
      from_page_path: window.location.pathname,
      from_search_params: window.location.search,
      to_page_path: routeEnhanced.pathname,
      to_search_params: routeEnhanced.search,
      partner_id: routeEnhanced.state.partner_id,
      location: routeEnhanced.state.location,
    };

    switch (analyticsType) {
      case 'internal':
        analyticsInternalLinks(properties);
        break;

      case 'app-card':
        analyticsClickedToApp(properties);
        break;

      case 'swimlane-see-all':
        analyticsSwimlaneSeeAll(properties);
        break;

      default:
        break;
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <RouterLink
      {...restProps}
      onClick={handleClick}
      data-type={analyticsType}
      to={routeEnhanced}
      onMouseEnter={handlePrefetch}
      ref={ref}
    />
  );
});

Link.defaultProps = {
  analyticsType: 'internal',
  analyticsProps: {},
  withoutIframe: false,
};

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  location: PropTypes.string,
  analyticsType: PropTypes.string,
  analyticsProps: PropTypes.object,
  withoutIframe: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(Link);
