import produce from 'immer';

// STORE
import { getShareInfo } from '../actions/share';

const initialState = {
  data: {},
  loading: false,
  errorMessage: null,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET DATA
    case getShareInfo.types.START:
      state.errorMessage = null;
      state.loading = true;
      return state;

    case getShareInfo.types.SUCCESS:
      state.data = action.payload.data;
      state.loading = false;
      return state;

    case getShareInfo.types.FAIL:
      state.errorMessage = action.payload.errorMessage;
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
