import produce from 'immer';

// UTILITY
import {
  updateListStatus,
  shiftSectionMutator,
  updateUserContentStatus,
  addWatchlists,
  removeWatchlists,
} from '@/utility/content';

// STORE
import { addToWatchList, removeFromWatchList } from '@/store/actions/watchList';
import { changeContentStatus } from '@/store/actions/content';
import { appListUpdateAppStatus, appListUpdateAppStatuses } from '../actions/appDetails';
import {
  swimlanesGet,
  swimlanesGetContent,
  swimlanesGetPopularContent,
  swimlanesClear,
} from '../actions/swimlanes';
import { userChangeAppPrice } from '../actions/user';

const initialState = {
  section1: {},
  section2: {},
  section3: {},
  section4: {},
  section5: {},
  popularContent: {},
  loadings: {
    section1: {},
    section2: {},
    section3: {},
    section4: {},
    section5: {},
    popularContent: false,
  },
};

const reducer = produce((state = initialState, action) => {
  const section = `section${action?.payload?.section || 1}`;
  const page = `${action?.payload?.page || 'default'}`;

  switch (action.type) {
    // GET SWIMLANES
    case swimlanesGet.types.START:
      state[section] = initialState[section];
      state.loadings[section] = initialState.loadings[section];
      return state;

    case swimlanesGet.types.SUCCESS: {
      state[section][page] = action.payload.data.length
        ? action.payload.data.reduce((acc, id) => {
            acc[` ${id}`] = {};
            return acc;
          }, {})
        : {};
      state.loadings[section][page] = action.payload.data.reduce((acc, id) => {
        acc[` ${id}`] = true;
        return acc;
      }, {});
      return state;
    }

    case swimlanesGet.types.FAIL:
      state[section] = state[section] && Object.keys(state[section]).length ? state[section] : {};
      state.loadings[section] = {};
      return state;

    // GET SWIMLANES CONTENT
    case swimlanesGetContent.types.START:
      state.loadings[section][page][` ${action.payload.id}`] = true;
      return state;

    case swimlanesGetContent.types.SUCCESS: {
      const pageData = {
        ...state[section][page],
        [` ${action.payload.id}`]: action.payload.data,
      };

      const loadings = {
        ...state.loadings,
        [section]: {
          ...state.loadings[section],
          [page]: {
            ...state.loadings[section][page],
            [` ${action.payload.id}`]: false,
          },
        },
      };
      const pageLoaded = !Object.keys(loadings[section][page]).find(
        key => loadings[section][page][key],
      );

      if (pageLoaded) shiftSectionMutator(pageData);

      state[section][page] = pageData;
      state.loadings = loadings;
      return state;
    }

    case swimlanesGetContent.types.FAIL:
      state.loadings[section] = {
        ...state.loadings[section],
        [page]: {
          ...state.loadings[section][page],
          [` ${action.payload.id}`]: false,
        },
      };

      return state;

    // UPDATE WATCHLISTS
    // UPDATE CONTENT STATUS
    // UPDATE APP LIST STATUSES
    // UPDATE APP LIST STATUS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS:
    case changeContentStatus.types.SUCCESS:
    case appListUpdateAppStatuses.types.INIT:
    case appListUpdateAppStatus.types.INIT: {
      const sections = {};

      for (const key in state) {
        if (key !== 'loadings' && Object.hasOwnProperty.call(state, key)) {
          sections[key] = state[key];
        }
      }

      for (const sectionsKey in sections) {
        if (Object.hasOwnProperty.call(sections, sectionsKey)) {
          const sectionData = sections[sectionsKey];

          for (const sectionDataKey in sectionData) {
            if (Object.hasOwnProperty.call(sectionData, sectionDataKey)) {
              const collections = sectionData[sectionDataKey];

              for (const collectionKey in collections) {
                if (Object.hasOwnProperty.call(collections, collectionKey)) {
                  const collection = collections[collectionKey];

                  switch (collection.type) {
                    case 'content':
                    case 'app_highlight':
                    case 'popular_on_app':
                    case 'most_popular_on_app':
                      {
                        const { content_id, status, watch_lists } = action.payload;

                        collection.data = collection.data.map(item => {
                          if (status !== undefined) {
                            return updateUserContentStatus(item, content_id, status);
                          }

                          if (watch_lists && item.id === content_id) {
                            const isAdd = action.type === addToWatchList.types.SUCCESS;
                            const wlAction = isAdd ? addWatchlists : removeWatchlists;
                            const watchlists = wlAction(item.watchlists, watch_lists);

                            return { ...item, watchlists, in_watchlist: !!watchlists.length };
                          }

                          return item;
                        });
                      }
                      break;

                    case 'multiple-content':
                      {
                        const { content_id, status, watch_lists } = action.payload;

                        collection.data = collection.data.map(swimlane => {
                          swimlane.data = swimlane.data.map(item => {
                            if (status !== undefined) {
                              return updateUserContentStatus(item, content_id, status);
                            }

                            if (watch_lists && item.id === content_id) {
                              const isAdd = action.type === addToWatchList.types.SUCCESS;
                              const wlAction = isAdd ? addWatchlists : removeWatchlists;
                              const watchlists = wlAction(item.watchlists, watch_lists);

                              return { ...item, watchlists, in_watchlist: !!watchlists.length };
                            }

                            return item;
                          });

                          return swimlane;
                        });
                      }
                      break;

                    case 'app':
                      {
                        const { apps = [], id, status } = action.payload;

                        if (!apps.length && typeof id === 'number') {
                          apps.push({ id, status });
                        }

                        for (const app of apps) {
                          collection.data = collection.data.map(item =>
                            updateListStatus(item, app.id, app.status),
                          );
                        }
                      }
                      break;

                    case 'my_apps':
                      {
                        const { apps = [], id, status, data: appData = {} } = action.payload;

                        if (!apps.length && typeof id === 'number') {
                          apps.push({ id, status });
                        }

                        for (const app of apps) {
                          const hasInCollection = !!collection.data.find(
                            item => item.id === app.id,
                          );

                          if (hasInCollection) {
                            collection.data = collection.data
                              .map(item => {
                                if (item.id === app.id && app.status === null) {
                                  return null;
                                }

                                return item;
                              })
                              .filter(item => item !== null);
                          } else if (Object.keys(appData).length) {
                            collection.data.push(appData);
                          }
                        }
                      }
                      break;

                    default:
                      break;
                  }
                }
              }
            }
          }
        }
      }

      break;
    }

    // UPDATE USER APP PRICE
    case userChangeAppPrice.types.SUCCESS: {
      const sections = {};

      for (const key in state) {
        if (key !== 'loadings' && Object.hasOwnProperty.call(state, key)) {
          sections[key] = state[key];
        }
      }

      for (const sectionsKey in sections) {
        if (Object.hasOwnProperty.call(sections, sectionsKey)) {
          const sectionData = sections[sectionsKey];

          for (const sectionDataKey in sectionData) {
            if (Object.hasOwnProperty.call(sectionData, sectionDataKey)) {
              const collections = sectionData[sectionDataKey];

              for (const collectionKey in collections) {
                if (Object.hasOwnProperty.call(collections, collectionKey)) {
                  const collection = collections[collectionKey];

                  switch (collection.type) {
                    case 'my_apps':
                      {
                        const { data: app } = action.payload;

                        collection.data = collection.data.map(item => {
                          if (item.id === app.id) {
                            return { ...item, user_price: app.price };
                          }

                          return item;
                        });
                      }
                      break;

                    default:
                      break;
                  }
                }
              }
            }
          }
        }
      }

      break;
    }

    // GET POPULAR CONTENT SWIMLANE
    case swimlanesGetPopularContent.types.START:
      state.popularContent = initialState.popularContent;
      state.loadings.popularContent = true;
      return state;

    case swimlanesGetPopularContent.types.SUCCESS:
      state.popularContent = action.payload.data;
      state.loadings.popularContent = false;
      return state;

    case swimlanesGetPopularContent.types.FAIL:
      state.loadings.popularContent = false;
      return state;

    // CLEAN CONTENT
    case swimlanesClear.types.INIT:
      state = initialState;
      return state;

    default:
      return state;
  }
});

export default reducer;
