import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterNavLink } from 'react-router-dom';

// HOOKS
import usePrefetch from '@/hooks/usePrefetch';

// UTILITY
import { routeEnhance } from '@/utility/routes';
import { analyticsInternalLinks } from '@/utility/analytics';

const NavLink = React.forwardRef(({ to, location: analyticsLocation, onClick, ...props }, ref) => {
  const routeEnhanced = routeEnhance(to, { state: { location: analyticsLocation } });
  const { handlePrefetch } = usePrefetch(to);

  const handleClick = e => {
    analyticsInternalLinks({
      unique_id: routeEnhanced.state.unique_id,
      from_page_path: window.location.pathname,
      from_search_params: window.location.search,
      to_page_path: routeEnhanced.pathname,
      to_search_params: routeEnhanced.search,
      partner_id: routeEnhanced.state.partner_id,
      location: routeEnhanced.state.location,
    });

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <RouterNavLink
      {...props}
      onClick={handleClick}
      to={routeEnhanced}
      onMouseEnter={handlePrefetch}
      ref={ref}
    />
  );
});

NavLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  location: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(NavLink);
