/**
 * Get selected apps
 * @param {object} state
 * @returns {array}
 */
const getSelectedApps = ({ onboarding }) => onboarding.selectedMyApps;

export default {
  getSelectedApps,
};
