import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import { GET_IP_INFO } from '@/constants/api';

// STORE
import { IpApiActions } from '@/store/actions';
import { IpApiSelectors } from '@/store/selectors';

const { getIpInfo } = IpApiActions;

const ipApiBaseURL = 'https://pro.ip-api.com/';
const ipApiFallbackBaseURL = 'https://ipwho.is/';

async function getIpData() {
  const config = {
    'axios-retry': {
      retries: 1,
      retryDelay: () => 300,
    },
  };

  try {
    const { data: respData } = await axios.get(ipApiBaseURL + GET_IP_INFO, {
      params: {
        key: process.env.REACT_APP_IP_API_KEY,
      },
      ...config,
    });

    return respData;
  } catch (error) {
    const { data: respData } = await axios.get(ipApiFallbackBaseURL, config);

    return {
      as: respData.connection.isp,
      query: respData.ip,
      city: respData.city,
      country: respData.country,
      isp: respData.connection.isp,
      org: respData.connection.org,
      countryCode: respData.country_code,
      region: respData.region_code,
      regionName: respData.region,
      lat: respData.latitude,
      lon: respData.longitude,
      timezone: respData.timezone.id,
      zip: respData.postal,
      status: respData.success ? 'success' : 'fail',
    };
  }
}

export function* getIPInfo() {
  try {
    yield put(getIpInfo.start());

    const data = yield call(getIpData);

    yield put(getIpInfo.success({ data }));

    return data;
  } catch (error) {
    yield put(getIpInfo.fail());
  }
}

export function* getIPInfoSaga() {
  let ipApiData = yield select(IpApiSelectors.getIpApiData) || null;

  if (!ipApiData) {
    ipApiData = yield call(getIPInfo);
  }

  return ipApiData;
}
