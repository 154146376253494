/* eslint-disable indent */
import styled from 'styled-components';

// CONSTANTS
import colors, { ROLLING_STONE, WHITE } from '@/constants/colors';

// UTILITY
import breakpoints from '@/utility/breakpoints';

const { checkboxCard } = colors;

const CheckBoxContainer = styled.label`
  &.checkbox-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: ${p => `${p.height}px` || 'auto'};
    border: 1px solid ${checkboxCard.border};
    border-radius: 6px;
    padding: 25px;
    margin: 12px;
    cursor: pointer;
    box-shadow: 0 1px 3px ${checkboxCard.shadow};
    transition: color, border 0.5s;
    ${p => {
      const backgroundColor = p.theme.overrides.MuiInputBase.root?.backgroundColor;
      return backgroundColor ? { backgroundColor } : null;
    }};

    &:hover {
      border-color: ${p => p.theme.palette.primary.main || colors.primary};

      ${breakpoints.down('xs')} {
        border-color: ${checkboxCard.border};
      }
    }

    &.channels,
    &.apps {
      padding: 12px;
      height: 95px;

      ${breakpoints.down('sm')} {
        padding: 6px;
        height: 75px;
        margin: 5px;
      }
    }

    &.show-channels {
      cursor: default;
    }

    &--checked {
      border-color: ${p => p.theme.palette.primary.main || colors.primary};
      box-shadow: 0 20px 40px ${checkboxCard.shadow};

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 2px ${p => p.theme.palette.primary.main || colors.primary};
        border-radius: 6px;
        transition: 0.5s;
      }

      &:before {
        content: '\\e918';
        position: absolute;
        top: -12px;
        right: -12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'icomoon';
        font-size: 10px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: ${p => p.theme.palette.primary.main || colors.primary};
        color: ${checkboxCard.check};
        z-index: 1;
        transition: 0.5s;
      }
      &.checkbox-card--dont-show-check {
        &:before {
          display: none;
        }

        .checkbox-card__note {
          left: auto;
          right: 2px;
        }
      }

      &:hover {
        border-color: ${p => p.theme.palette.primary.main || colors.primary};

        &:before {
          ${breakpoints.down('sm')} {
            content: '\\e918';
            background-color: ${p => p.theme.palette.primary.main || colors.primary};
          }
        }

        .checkbox-card__title {
          ${breakpoints.up('sm')} {
            color: ${checkboxCard.checkedHover.border};
          }
        }
      }

      .checkbox-card__title {
        color: ${p => p.theme.palette.primary.main || colors.primary};
        transition: 0.5s;
      }
    }

    &--no-select {
      pointer-events: none;
      margin: 0;
    }

    .checkbox-card {
      &__title {
        font-size: ${p => p.fontSize || '1.8rem'};
        line-height: ${p => p.lineHeight || '2.2rem'};
        font-weight: 600;
        text-align: center;
      }

      &__image {
        max-width: 110px;
        max-height: 100%;
        pointer-events: none;

        ${breakpoints.down('xs')} {
          max-width: 100%;
        }

        &--height-auto {
          max-height: none;
          height: auto;
        }

        & + .checkbox-card__title {
          margin-top: 4px;
        }
      }

      &__price {
        position: static;

        .MuiBadge-badge {
          height: 26px;
          font-size: 1.4rem;
          line-height: 2.4rem;
          padding: 0 9px;
          border-radius: 26px;

          ${breakpoints.down('xs')} {
            height: 17px;
            font-size: 0.9rem;
            line-height: 1.6rem;
            padding: 0 6px;
            border-radius: 17px;
          }
        }

        .MuiBadge-anchorOriginTopRightRectangle {
          transform: translate(-8px, 8px);

          ${breakpoints.down('xs')} {
            transform: translate(-5px, 5px);
          }
        }
      }

      &__included {
        position: absolute;
        top: 8px;
        left: 8px;
      }

      &__note {
        position: absolute;
        top: 2px;
        left: 2px;
        font-size: 2rem;
        z-index: 2;
        color: ${ROLLING_STONE};
      }
    }

    input[type='checkbox'] {
      display: none;
    }

    &.streaming {
      height: 120px;
      padding: 15px 21px;
    }

    &.select-apps {
      height: 88px;
      padding: 10px 15px;
    }

    ${breakpoints.down('xs')} {
      &.streaming {
        height: 110px;
        padding: 10px 25px;
      }

      &.content {
        height: 45px;
        padding: 5px;
        margin: 10px;
        .checkbox-card__title {
          font-size: 1.4rem;
        }
      }
    }
  }

  .included {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    font-size: 8px;
    border-radius: 4px;
    border: 1px solid ${p => p.theme.palette.primary.main || colors.primary};
    color: ${p => p.theme.palette.primary.main || colors.primary};
    background-color: ${WHITE};

    ${breakpoints.down('xs')} {
      height: 16px;
      width: 16px;
      border-radius: 3px;
      font-size: 5px;
    }

    &--not {
      border-color: ${p => p.theme.palette.error.main || colors.error};
      color: ${p => p.theme.palette.error.main || colors.error};
    }
  }
`;

export default CheckBoxContainer;
