export default {
  title: 'Stream Free Live TV Anytime on Your TV with <span>MyBundle Free TV</span>',
  subtitle:
    'MyBundle gives you FREE access to live TV. Simply choose a device you want to watch on and start streaming the way you desire.',
  services: {
    title: 'Click to Download on Your Device',
  },
  steps: {
    title: 'How to stream Live TV Channels with <span>MyBundle Free TV</span>?',
    subtitle:
      'Follow the following steps to access MyBundle Live TV Channels and enjoy your shows.',
    step1: {
      title: 'Find \n Compatible Devices',
      subtitle: 'Make sure you’re using one of the compatible devices below',
    },
    step2: {
      title: 'Navigate \n and Search',
      subtitle:
        "Search ‘<span>MyBundle Free TV</span>’ in your device's app store and download or click your device below to download ",
    },
    step3: {
      title: 'Download \n MyBundle Free TV App',
      subtitle:
        'Open ‘<span>MyBundle Free TV</span>’ on your compatible device and start watching. ',
    },
  },
  channels: {
    title: 'Find something for everyone with a <span>diverse</span>\nrange of categories',
    subtitle:
      'Stream a multitude of genres for free, including TV & Movies, Kids, Sports & Automotive, Home & Travel, and en español.',
  },
  info: {
    title: '<span>Easy navigation</span> with Live TV Guide and\nmore essential features',
    subtitle:
      'The MyBundle Free Live TV app has all the features and accessibility you need for streaming – closed captions, text-to-speech, easy navigation, and a traditional Live TV guide.',
  },
  faq: {
    title: 'FAQs',
    item1: {
      question: 'Is MyBundle Free TV really free?',
      answer:
        'Yes, absolutely! MyBundle Free TV is an Ad Supported Service (AVOD), so you can enjoy the content for free.',
    },
    item2: {
      question: 'What devices can I watch MyBundle Free TV on?',
      answer:
        'MyBundle Free TV is on most major streaming platforms, including Roku, Amazon Fire, Android TV, Samsung Smart TV, and LG.',
    },
    item3: {
      question: 'How to stream MyBundle Live TV?',
      answer:
        'To watch MyBundle Free TV, make sure you are using one of the compatible devices above or click here to start watching in your browser. Then navigate to your app store and search for ‘MyBundle Free TV’. Download the app and start watching - no sign in necessary. ',
    },
    item4: {
      question: 'What is MyBundle?',
      answer:
        'We’re here to make streaming TV simpler. Whether it’s helping you cut the cord on cable, watch free live TV with us, or find your next favorite TV shows or movies across your streaming services.',
    },
  },
};
