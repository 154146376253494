import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// CONSTANTS
import { ROUTE_STREAMING_CHOICE_CLAIM, ROUTE_STREAMING_REWARDS_CLAIM } from '@/constants/routes';
import { LOCATIONS_STREAMING_REWARDS_BANNER } from '@/constants/locations';

// UTILITY
import { isSCType } from '@/utility/streamingRewards';
import { getLink } from '@/utility/routes';

// COMPONENTS
import BannerNotification from '@/components/Banners/BannerNotification';
import RouterLink from '@/components/Link';

const BannerNotClaimedService = ({ data }) => {
  const [t] = useTranslation();

  function getSRRoute() {
    const rewardUrlType = data.url_type;
    const rewardSlug = data.slug;
    const isSC = isSCType(rewardUrlType);
    const claimUrl = isSC ? ROUTE_STREAMING_CHOICE_CLAIM : ROUTE_STREAMING_REWARDS_CLAIM;

    return getLink(claimUrl, { slug: rewardSlug });
  }

  if (!data?.slug) return null;

  return (
    <BannerNotification
      title={t('bannerNotClaimedService.title')}
      btnText={t('buttons.useMyCreditsNow')}
      btnProps={{
        component: RouterLink,
        to: getSRRoute(),
        analyticsProps: {
          location: LOCATIONS_STREAMING_REWARDS_BANNER,
        },
      }}
    />
  );
};

BannerNotClaimedService.propTypes = {
  data: PropTypes.object.isRequired,
};

export default React.memo(BannerNotClaimedService);
