import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';

// UTILITY
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';
import { getSearchParams } from '@/utility/common';

// STORE
import { AppActions, AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// COMPONENTS
import PasswordInput from '@/components/Form/PasswordInput';

// STYLES
import { TitleContainer } from './styles';

const UpdatePassword = ({ data, titleProps }) => {
  const [t] = useTranslation();

  const [manageProfDrawerState, resetPassUpdate] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    (submitData, callback) => AuthActions.resetPassUpdate.action({ data: submitData, callback }),
  ]);

  function handleSubmit(values, { setSubmitting }) {
    const params = getSearchParams(document.location.search);
    const token = params.get('reset_password');

    resetPassUpdate({ ...values, token }, () => manageProfDrawerState(true));
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={setYupValidationSchema('updatePasswordSchema', t)}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, isSubmitting }) => (
        <Form>
          <TitleContainer {...titleProps}>
            <Typography className="m-b-10" variant="h3" align="center">
              {data.title || t('authDrawer.updatePassword.title')}
            </Typography>
            <Typography
              className="subtitle p5"
              variant="body1"
              align="center"
              color="textSecondary"
            >
              {t('authDrawer.updatePassword.subtitle')}
            </Typography>
          </TitleContainer>
          <PasswordInput
            className="m-b-20"
            label={t('inputs.password')}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            size="small"
            autoComplete="new-password"
          />
          <Button
            type="submit"
            color="primary"
            size="small"
            fullWidth
            disabled={!isValid && !isSubmitting}
          >
            {t('buttons.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

UpdatePassword.defaultProps = {
  data: {},
  titleProps: {},
};

UpdatePassword.propTypes = {
  data: PropTypes.object,
  titleProps: PropTypes.object,
};

export default React.memo(UpdatePassword);
