export default {
  title1: 'Explore Streaming Options With Starry',
  title2: 'Internet & MyBundle',
  signUpLink: 'Sign up for Starry today',
  section2: {
    text: 'Interested in cutting the cord? Find which streaming service is right for you.',
  },
  section3: {
    text:
      "Starry offers lightning-fast, in-home internet for just $50/month. With speeds of up to 200 Mbps, Starry is great for working/learning from home, video chatting, and streaming across multiple devices, even during peak times. There are no hidden fees, unwanted bundles, or contracts, and you'll receive exceptional 24/7 customer support right from your Starry app.",
  },
};
