import styled from 'styled-components';

export const Header = styled.header`
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: 'proxima-nova';
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #header {
    height: 104px;
    background: #503296;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  #header ul li img {
    height: 43px;
    width: auto;
    vertical-align: middle;
  }

  #header ul li {
    list-style-type: none;
    display: inline-block;
    color: #ffffff;
    padding: 0 20px;
    font-size: 23px;
    line-height: 104px;
  }

  #header ul.left li a {
    list-style-type: none;
    display: inline-block;
    color: #ffffff;
    padding: 0 20px;
    font-size: 23px;
    line-height: 104px;
  }

  #header ul li a {
    color: #ffffff;
    padding: 0 20px;
    font-size: 18px;
    text-decoration: none;
  }

  #header ul li a.btn {
    background-color: #35c7f4;
    border: none;
    color: #503296;
    text-align: center;
    font-size: 18px;
    width: 200px;
    border-radius: 25px;
    padding: 10px;
    display: block;
    line-height: 30px;
  }

  #header ul {
    float: left;
  }

  #header ul.right {
    float: right;
    margin-right: 162px;
  }

  #header ul.third {
    display: none;
    width: 100%;
    background: #fff;
  }

  @media (max-width: 1400px) {
    #header ul.right {
      margin-right: 10px;
    }
  }

  @media (max-width: 1240px) {
    #header {
      height: auto;
      display: block;
    }

    #header ul li {
      display: none;
    }

    #header ul {
      float: none;
    }

    #header ul li.has_img {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    #header ul.third {
      display: block;
    }

    #header ul.third li {
      background: #fff;
      display: block;
      padding: 10px;
    }

    #header ul.third li a {
      padding: 10px;
      margin: 10px auto;
      text-align: center;
      width: 335px;
    }
  }
`;

export default Header;
