import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContent } from '@material-ui/core';

// UTILITY
import { analyticsSignUpModalShown } from '@/utility/analytics';

// COMPONENTS
import Loader from '@/components/Loader';
import Registration from '@/components/Auth/Registration';
import Login from '@/components/Auth/Login';
import ForgotPassword from '@/components/Auth/ForgotPassword';

// STORE
import { AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// STYLES
import { DialogStyled, CloseIcon } from './styles';

const DialogAuth = () => {
  const [t] = useTranslation();
  const { authDialogIsOpen, authDialogProps, loading, partner } = useSelector(
    ({ auth, partner: partnerStore }) => ({
      authDialogIsOpen: auth.authDialogIsOpen,
      authDialogProps: auth.authDialogProps,
      loading: auth.loading,
      partner: partnerStore.data,
    }),
  );

  const [authDialogClose] = useActions([
    dialogProps => AuthActions.manageAuthDialogState.action({ state: false, props: dialogProps }),
  ]);
  const [curScreen, setCurScreen] = useState(authDialogProps.screen || 'registration');
  const [screenProps, setScreenProps] = useState({});
  const drawerProps = { ...authDialogProps, ...screenProps };
  const { disableClose = false } = authDialogProps;

  const authDescription = partner?.text_overwrites?.auth_description;
  const authLogin = partner?.text_overwrites?.auth_login;
  const registrationTitle =
    authDialogProps.registrationTitle || authDialogProps.title || t('authDialog.title');
  const registrationDescription =
    authDialogProps.registrationDescription ||
    authDialogProps.description ||
    authDescription ||
    t('authDialog.description', { context: authDialogProps.type });
  const loginTitle = authDialogProps.loginTitle;
  const loginDescription = authDialogProps.loginDescription || authLogin;

  useEffect(() => {
    if (authDialogProps.screen) setCurScreen(authDialogProps.screen);
  }, [authDialogProps.screen]);

  function handleChangeScreen(screen, newProps) {
    setScreenProps(newProps || {});
    setCurScreen(screen);
  }

  function handleDialogOpen() {
    if (authDialogProps.analyticsData) {
      analyticsSignUpModalShown(authDialogProps.analyticsData);
    }
  }

  function handleDialogClose(e, reason) {
    if (reason === 'backdropClick' && disableClose) return;

    authDialogClose({ ...authDialogProps, screen: 'registration' });

    if (authDialogProps.onDialogClose) authDialogProps.onDialogClose();

    setTimeout(() => {
      handleChangeScreen('registration');
    }, 100);
  }

  return (
    <DialogStyled
      fullWidth
      maxWidth="xs"
      open={authDialogIsOpen}
      onClose={handleDialogClose}
      disableEnforceFocus
      disableEscapeKeyDown={disableClose}
      TransitionProps={{ onEntered: handleDialogOpen }}
    >
      <DialogContent>
        {!disableClose && (
          <CloseIcon size="medium" aria-label="close" onClick={handleDialogClose}>
            <i className="icon-close" />
          </CloseIcon>
        )}
        {curScreen === 'login' && (
          <Login
            titleProps={{ marginBottom: '12px' }}
            onScreenChange={handleChangeScreen}
            viewType="dialog"
            data={{ title: loginTitle, description: loginDescription, ...drawerProps }}
          />
        )}
        {curScreen === 'registration' && (
          <Registration
            data={{
              ...drawerProps,
              description: registrationDescription,
              title: registrationTitle,
            }}
            titleProps={{ marginBottom: '12px' }}
            onScreenChange={handleChangeScreen}
            viewType="dialog"
          />
        )}
        {curScreen === 'forgot' && (
          <ForgotPassword
            titleProps={{ marginBottom: '12px' }}
            onScreenChange={handleChangeScreen}
          />
        )}
      </DialogContent>
      <Loader active={loading} />
    </DialogStyled>
  );
};

export default React.memo(DialogAuth);
