export default {
  titlePart1: 'Take a quick quiz to find',
  titlePart2: 'Best Internet Solution',
  inputDesc:
    'Enter your address to find best internet offers from our High-Speed Internet partners in your area.',
  helpText:
    'We need your address to find out which plans are available in your area. \n We will never share or sell your address.',
  loader: 'We are getting your personalized internet offers',
  yourAddress: 'Your address',
  features: {
    first: {
      title: 'Best broadband providers',
      description:
        "We've partnered with High-Speed broadband providers to help you find the best internet.",
    },
    second: {
      title: 'Based on what you need',
      description:
        'We’ll find exactly what you need based on your needs, at the best price, so you can stream & save!',
    },
    third: {
      title: 'Fast and reliable',
      description:
        'Having a fast and reliable internet service is the foundation for a great TV experience',
    },
  },
  disclaimer: {
    title: 'Disclosure',
    authorized: 'MyBundle is an authorized sales agent of CenturyLink',
  },
  results: {
    title: 'We recommend the following plan for you',
    otherPlans: 'Other Plan Solutions',
  },
  noResults: {
    title: 'Get notified if there are future offers in your area',
    description: 'No offers found for your address',
  },
};
