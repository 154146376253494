import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Button } from '@material-ui/core';

// UTILITY
import { customAnalyticsEvent } from '@/utility/analytics';

function reload() {
  window.location.reload(true);
}

const ErrorBoundaryView = ({ info }) => {
  useEffect(() => {
    customAnalyticsEvent('Error Page Loaded', {
      the_error: info,
    });
  }, []);

  return (
    <div className="page-content">
      <Grid container spacing={3}>
        <Grid item xs={12} className="container-form">
          <Paper className="container">
            <Typography variant="h4" className="m-b-10">
              Something went wrong
            </Typography>
            <Typography variant="subtitle1" className="m-b-15">
              Press the "Reload page" button for a reload current page. We apologize for the
              inconvenience.
            </Typography>
            <div className="group-buttons">
              <Button variant="contained" color="primary" onClick={reload}>
                Reload page
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

ErrorBoundaryView.propTypes = {
  info: PropTypes.any,
  error: PropTypes.any,
};

export default ErrorBoundaryView;
