import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import {
  GET_PARTNER,
  PARTNER_BY_DOMAIN,
  INTERNET_PARTNER_OFFER_GET_DATA,
  INTERNET_PARTNER_OFFER_CLAIM,
} from '@/constants/api';

// UTILITY
import { getLink } from '@/utility/routes';
import { setPartnerFavicon } from '@/utility/partner';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { PartnerActions, NotifActions } from '@/store/actions';
import { PartnerSelectors } from '@/store/selectors';

const {
  getPartner,
  getPartnerByDomain,
  getPartnerInternetOffer,
  claimPartnerInternetOffer,
} = PartnerActions;
const { pushSuccessNotificationAction } = NotifActions;

export function* getPartnerSaga(action) {
  yield put(getPartner.start());

  const { slug, onError } = action.payload;
  const url = getLink(GET_PARTNER, { slug });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getPartner.success(respData));

    if (!respData.partner.partner_slug) {
      if (onError) onError();
    }

    setPartnerFavicon(respData.partner);

    yield put(getPartner.stop());

    return respData.partner;
  } catch (error) {
    yield put(getPartner.fail());
  }
}

export function* getPartnerByDomainSaga() {
  const domain = yield select(PartnerSelectors.getDomain);
  const isPartnerDomain = yield select(PartnerSelectors.getIsPartnerDomain);
  const isSiteUrl = process.env.REACT_APP_SITE_URL === window.location.origin || !isPartnerDomain;

  if (isSiteUrl) return;

  yield put(getPartnerByDomain.start());

  try {
    const { data: respData } = yield axios.get(PARTNER_BY_DOMAIN, {
      params: { domain },
    });

    if (respData.partner) {
      yield put(getPartnerByDomain.success(respData));
    }

    yield put(getPartnerByDomain.stop());

    return respData.partner;
  } catch (error) {
    yield put(getPartnerByDomain.fail());
  }
}

export function* getPartnerInternetOfferSaga(action) {
  yield put(getPartnerInternetOffer.start());

  const { partner_id } = action.payload;

  try {
    const { data: respData } = yield axios.get(INTERNET_PARTNER_OFFER_GET_DATA, {
      params: { partner: partner_id },
    });

    yield put(getPartnerInternetOffer.success({ data: respData.offer }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getPartnerInternetOffer.fail());
  }
}

export function* claimPartnerInternetOfferSaga(action) {
  yield put(claimPartnerInternetOffer.start());

  const { offer_id } = action.payload;

  const url = getLink(INTERNET_PARTNER_OFFER_CLAIM, {
    offer_id,
  });

  try {
    const { data: respData } = yield axios.post(url);

    yield put(claimPartnerInternetOffer.success({ data: respData.data }));

    if (respData.message) {
      yield put(pushSuccessNotificationAction(respData.message));
    }
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(claimPartnerInternetOffer.fail());
  }
}
