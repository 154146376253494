export default {
  title: 'MyBundle simplifies streaming TV',
  subtitle: 'MyBundle helps you cut the cord while connecting you with better content.',
  searchfilter: 'Search For TV Shows, Movies, Apps or Genre',
  banners: {
    registration: {
      title: 'Welcome to MyBundle!',
      subtitle:
        'The average Live TV streaming bundle is 50% less than traditional cable TV. Find the best alternative for you. Take a short quiz to get your bundle that fits your needs.',
    },
    regular: {
      title: 'Still paying your cable provider?',
      subtitle:
        'The average Live TV streaming bundle is 50% less than your old cable TV. Stop getting charged for channels you never watch! Take a short quiz to get your bundle that fits your needs.',
    },
    fmb: {
      title: 'My Bundle Results',
      subtitle:
        'Click "See Results" button to check your latest results of bundles based on your preferences.',
    },
    onboarding: {
      title: 'Already have streaming services?',
      subtitle:
        'Add apps that you already have to browse and get personalized recommendations for TV shows and movies from across all your streaming services.',
    },
  },
  popularContentByMyApps: {
    title: 'Popular on Your Apps',
  },
  popularContentOnFreeApps: {
    title: 'Trending on Free Apps',
  },
};
