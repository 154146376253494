import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import ExpandMore from '@material-ui/icons/ExpandMore';

// CONSTANTS
import { LOCATIONS_HEADER } from '@/constants/locations';
import { ROUTE_ROOT } from '@/constants/routes';

// UTILITY
import { externalLink } from '@/utility/analytics';

// COMPONENTS
import NavLink from '@/components/NavLink';

// STYLES
import { ListDesktop, DesktopLink } from '../styles';

const SubMenuItem = props => {
  const { t, data } = props;

  const handleExternalLink = ({ href }) => {
    const properties = {
      location: 'header',
      other: 'header external link',
      href,
    };

    externalLink(properties);
  };

  return (
    <DesktopLink className="nav-link nav-link--with-subnav" component="div">
      {t(data.label)}
      <ExpandMore className="nav-link__icon" />
      <Paper className="nav-link__subnav subnav" elevation={8}>
        <MenuList>
          {data.submenu.map((subItem, index) => {
            if (subItem.type === 'external') {
              return (
                <MenuItem
                  component={Link}
                  href={subItem.route}
                  key={index}
                  location={LOCATIONS_HEADER}
                  target="_blank"
                  color="textPrimary"
                  onClick={() => handleExternalLink({ href: subItem.route })}
                >
                  {t(subItem.label)}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                component={NavLink}
                to={subItem.route}
                key={index}
                location={LOCATIONS_HEADER}
              >
                {t(subItem.label)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </DesktopLink>
  );
};

SubMenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const DesktopMenu = ({ data, ...restProps }) => {
  const [t] = useTranslation();

  return (
    <ListDesktop {...restProps}>
      {data.map((el, index) => {
        if (el.submenu) {
          return <SubMenuItem t={t} data={el} key={el.route} />;
        }

        if (el.component) {
          return (
            <DesktopLink key={index} {...el}>
              {t(el.label)}
            </DesktopLink>
          );
        }

        return (
          <DesktopLink
            className="link"
            to={el.route}
            key={index}
            component={NavLink}
            location={LOCATIONS_HEADER}
            end={el.route === ROUTE_ROOT}
          >
            {t(el.label)}
          </DesktopLink>
        );
      })}
    </ListDesktop>
  );
};

DesktopMenu.propTypes = {
  data: PropTypes.array.isRequired,
};

export default React.memo(DesktopMenu);
