import axios from 'axios';
import { put } from 'redux-saga/effects';

// CONSTANTS
import { GET_LOCATIONS } from '@/constants/api';

// STORE
import { LocationsActions } from '@/store/actions';

const { getLocations } = LocationsActions;

export function* getLocationsSaga() {
  yield put(getLocations.start());

  try {
    const {
      data: { locations },
    } = yield axios.get(GET_LOCATIONS);

    yield put(getLocations.success({ data: locations }));
  } catch (error) {
    yield put(getLocations.fail());
  }
}

export default getLocationsSaga;
