import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';

// UTILITY
import { routeEnhance, getLink } from '@/utility/routes';
import { analyticsInternalLinks } from '@/utility/analytics';

const Redirect = ({ to, analyticsLocation: location, external, replaceParams, ...props }) => {
  let url = to;

  if (external) {
    window.location.href = url;
    return null;
  }

  if (replaceParams) {
    const params = useParams();

    url = getLink(url, params);
  }

  const routeEnhanced = routeEnhance(url, { state: { location } });

  useEffect(() => {
    analyticsInternalLinks({
      unique_id: routeEnhanced.state.unique_id,
      from_page_path: window.location.pathname,
      from_search_params: window.location.search,
      to_page_path: routeEnhanced.pathname,
      to_search_params: routeEnhanced.search,
      partner_id: routeEnhanced.state.partner_id,
      location: routeEnhanced.state.location,
    });
  }, []);

  return <Navigate {...props} to={routeEnhanced} replace />;
};

Redirect.defaultProps = {
  analyticsLocation: '',
  external: false,
  replaceParams: false,
};

Redirect.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  analyticsLocation: PropTypes.string,
  external: PropTypes.bool,
  replaceParams: PropTypes.bool,
};

export default React.memo(Redirect);
