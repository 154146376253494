import { createAction } from '@/utility/actions';

import {
  AUTH_LOGOUT,
  AUTH_LOGIN,
  AUTH_LOGIN_CLEAN_ERROR,
  AUTH0_LOGIN,
  AUTH_SOCIAL_LOGIN,
  AUTH_LOGIN_WITH_FIREBASE_TOKEN,
  AUTH_REGISTER_USER,
  AUTH_RP_SEND_EMAIL,
  AUTH_UPDATE_UNIQUE_ID,
  AUTH_DRAWER_OPEN,
  AUTH_DRAWER_CLOSE,
  AUTH_RP_UPDATE,
  AUTH_DIALOG_MANAGE_STATE,
  AUTH_SET_ANONYMOUS_ID,
} from './types';

/**
 * login - params: data, callback(optional)
 */
export const login = createAction(AUTH_LOGIN);

export const cleanLoginError = createAction(AUTH_LOGIN_CLEAN_ERROR);

/**
 * auth0Login - params: data, callback(optional)
 */
export const auth0Login = createAction(AUTH0_LOGIN);

/**
 * authSocialLogin - params: providerId (google | facebook | apple), data, callback(optional)
 */
export const authSocialLogin = createAction(AUTH_SOCIAL_LOGIN);

/**
 * registerUser - params: data, callback
 */
export const registerUser = createAction(AUTH_REGISTER_USER);

/**
 * logout - callback
 */
export const logout = createAction(AUTH_LOGOUT);

/**
 * resetPassSendEmail - params: data
 */
export const resetPassSendEmail = createAction(AUTH_RP_SEND_EMAIL);

/**
 * resetPassUpdate - params: data
 */
export const resetPassUpdate = createAction(AUTH_RP_UPDATE);

/**
 * updateUniqueId - params: unique_id
 */
export const updateUniqueId = createAction(AUTH_UPDATE_UNIQUE_ID);

/**
 * authDrawerOpen - params: data
 */
export const authDrawerOpen = createAction(AUTH_DRAWER_OPEN);

export const authDrawerClose = createAction(AUTH_DRAWER_CLOSE);

/**
 * manageAuthDialogState - params: state, props
 */
export const manageAuthDialogState = createAction(AUTH_DIALOG_MANAGE_STATE);

/**
 * setAnonymousId - id
 */
export const setAnonymousId = createAction(AUTH_SET_ANONYMOUS_ID);

/**
 * loginWithFirebaseToken
 */
export const loginWithFirebaseToken = createAction(AUTH_LOGIN_WITH_FIREBASE_TOKEN);

export default {
  login,
  auth0Login,
  authSocialLogin,
  registerUser,
  logout,
  resetPassSendEmail,
  resetPassUpdate,
  updateUniqueId,
  authDrawerOpen,
  authDrawerClose,
  manageAuthDialogState,
  cleanLoginError,
  setAnonymousId,
  loginWithFirebaseToken,
};
