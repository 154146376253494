// CONSTANTS
import { multipleAppsRewardTypes, urlTypes } from '@/constants/streamingRewards';

// UTILITY
import { formatDate } from '@/utility/date';

export const getAmount = balance => {
  const updBalance = +balance;
  let amount = '';

  if (updBalance >= 0 && updBalance <= 5) {
    amount = '5';
  } else if (updBalance > 5 && updBalance <= 10) {
    amount = '10';
  } else if (updBalance > 10 && updBalance <= 20) {
    amount = '20';
  } else if (updBalance > 20 && updBalance <= 40) {
    amount = '40';
  } else if (updBalance > 40) {
    amount = '40+';
  }

  return amount;
};

export function checkMultipleAppsRewardType(rewardData) {
  return !!(
    rewardData && multipleAppsRewardTypes.includes(rewardData.reward_type || rewardData.type)
  );
}

export function isSCType(urlType) {
  return urlType === urlTypes.STREAMING_CHOICE;
}

export function getCopiesByUrlType(urlType, defCopies) {
  if (!defCopies || !urlType) return '';

  const isSC = isSCType(urlType);

  if (isSC) {
    return defCopies.replace('streamingRewards', 'streamingChoice');
  }

  return defCopies.replace('streamingChoice', 'streamingRewards');
}

export function getClaimedRewardBySlug(userClaimedRewards, rSlug) {
  const uCRewards = userClaimedRewards || [];

  return uCRewards.find(r => r.slug === rSlug);
}

export function getRefreshDate(value) {
  return value ? formatDate(value, 'LLL d y') : null;
}

export default getAmount;
