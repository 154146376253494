export default {
  household: {
    title: 'Which best describes your household?',
    subtitle: '',
    variants: {
      justMe: 'Just Me',
      multipleAdults: 'Multiple Adults',
      adultsAndChildren: 'Adults & Children',
      veryLargeFamily: 'Very Large Family',
    },
  },
  zipcode: {
    title: 'What is your ZIP Code?',
    titleWow: 'Find the best Streaming TV package for you!',
    title2Wow: 'Get started by entering your zip code below.',
    subtitle:
      'Your ZIP Code allows us to offer you your local channels as well as filter results for your area.',
    subtitleWow:
      'Answer a few questions about your viewing habits and our recommendation tool will generate personalized Streaming TV plans based on your needs.',
    disclaimerWow:
      '*Your ZIP Code allows us to offer local channels as well as filter results for your area.',
  },
  counties: {
    title: 'Which county do you live in?',
    subtitle: '',
  },
  countiesCSR: {
    title: 'Which county do you live in?',
    subtitle: 'Please Answer Zipcode question to see if county is needed',
    noCountyNeeded: 'No County needed',
  },
  address: {
    title: 'Interested in a new Internet Provider?',
    subtitle: 'Enter your address for offers from one of our High-Speed internet partners.',
    optional: '(optional)',
  },
  dvr: {
    title: 'Would you like to record shows (DVR)?',
    subtitle:
      'Some streaming services include recording of shows in the "cloud" (no\u00A0physical\u00A0DVR\u00A0box\u00A0needed.)',
  },
  alreadyHave: {
    title: 'Which of these services do you already have?',
    subtitle: 'If none, hit next to continue.',
  },
  streaming: {
    title: 'How have you been streaming until now?',
    subtitle:
      'Pick all that apply.  If you do not currently use a streaming service, click next to continue.',
    noneAnyRecommendations: "I'm Not Sure/Other",
    smartTv: 'Smart TV',
    androidTv: 'Android TV',
    appleTv: 'Apple TV',
  },
  content: {
    title: 'What kind of content do you watch?',
    subtitle: 'You can choose more than one genre.',
    subtitle2: 'Also, you can write your own content kind on other option',
    nonFictionReplace: 'Nonfiction',
  },
  sports: {
    title: 'Are live sports important to you?',
    subtitle: '',
  },
  cableProvider: {
    title: 'Are you {{article}} {{name}} internet customer?',
    subtitle: '',
  },
  device_included: {
    title:
      'Do you want a device and traditional remote included with your new Live TV streaming service?',
    subtitle: '',
    variants: {
      yes: 'Yes',
      no: 'No',
      notSure: 'I’m Not Sure',
    },
  },
  agree_device_contract: {
    title: 'Are you ok with signing a two year contract for a video service?',
    subtitle: '',
    variants: {
      yes: 'Yes',
      no: 'No',
    },
  },
  poweredBy: 'Powered By',
};
