export const authActions = {
  signedUp: 'Signed Up',
  loggedIn: 'Logged In',
};

export const triggered = {
  app: 'An app',
  show: 'show',
  movie: 'movie',
};

export const actions = {
  addToApps: 'Add To Apps',
  addToWatchlist: 'Add To Watchlist',
  changeContentStatus: 'Change content status',
};
