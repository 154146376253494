import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// STYLES
import CheckBoxContainer from './styles';

const CheckBoxCard = props => {
  const {
    name,
    text,
    value,
    imageSrc,
    imageHeightAuto,
    title,
    checkedValue,
    handleChange,
    handleBlur,
    height,
    textProps,
    segmentText,
    icons,
    noCheck,
    withLazyLoad,
    noSelect,
    price,
    showIncluded,
    included,
    textLocation,
    note,
    ...restProps
  } = props;
  const isChecked = checkedValue.includes(value);

  const { isIframe } = useSelector(store => ({
    isIframe: store.app.isIframe,
  }));

  const img = imageSrc ? (
    <img
      className={clsx(
        'checkbox-card__image',
        imageHeightAuto && 'checkbox-card__image--height-auto',
      )}
      src={imageSrc}
      alt={title || text || value}
    />
  ) : null;

  const content = (
    <CheckBoxContainer
      height={height}
      {...restProps}
      className={clsx(
        'checkbox-card noselect',
        isChecked && 'checkbox-card--checked',
        name,
        restProps.className,
        noCheck && 'checkbox-card--dont-show-check',
        noSelect && 'checkbox-card--no-select',
      )}
    >
      {showIncluded && (
        <span className={clsx('checkbox-card__included included', !included && 'included--not')}>
          <i className={`icon-${included ? 'check' : 'close'}`} />
        </span>
      )}
      {!!note && (
        <Tooltip title={note} PopperProps={{ style: { maxWidth: '250px' } }} placement="top">
          <ErrorOutlineIcon className="checkbox-card__note" />
        </Tooltip>
      )}
      {!!price && (
        <Badge className="checkbox-card__price" badgeContent={price} color="secondary" max={1000} />
      )}
      {withLazyLoad && !isIframe ? <LazyLoad once>{img}</LazyLoad> : img}
      {!!text && (
        <span className={`checkbox-card__title ${textLocation}title`} {...textProps}>
          {text}
        </span>
      )}
      {!noSelect && (
        <input
          type="checkbox"
          name={name}
          data-text={segmentText || text || ''}
          checked={isChecked}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {icons}
    </CheckBoxContainer>
  );

  return title ? (
    <Tooltip title={title}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};

CheckBoxCard.defaultProps = {
  title: '',
  text: '',
  textProps: {},
  handleChange: () => {},
  handleBlur: () => {},
  imageSrc: '',
  checkedValue: [],
  height: 130,
  segmentText: '',
  imageHeightAuto: false,
  icons: null,
  withLazyLoad: false,
  noSelect: false,
  price: '',
  showIncluded: false,
  included: true,
  textLocation: 'bottom',
  note: '',
};

CheckBoxCard.propTypes = {
  text: PropTypes.string,
  textProps: PropTypes.object,
  checkedValue: PropTypes.array,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageSrc: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  segmentText: PropTypes.string,
  imageHeightAuto: PropTypes.bool,
  icons: PropTypes.element,
  noCheck: PropTypes.bool,
  withLazyLoad: PropTypes.bool,
  noSelect: PropTypes.bool,
  price: PropTypes.string,
  showIncluded: PropTypes.bool,
  included: PropTypes.bool,
  textLocation: PropTypes.string,
  note: PropTypes.string,
};

export default React.memo(CheckBoxCard);
