import produce from 'immer';

import { getVirtualCardBalanceData, addVirtualCardBalance } from '../actions/virtualCardBalance';

const initialState = {
  data: {},
  loadings: {
    data: false,
    buy: false,
  },
  errors: {
    buy: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET VIRTUAL CARD PRODUCTS DATA
    case getVirtualCardBalanceData.types.START:
      state.loadings.data = true;
      return state;

    case getVirtualCardBalanceData.types.SUCCESS:
      state.data = { ...state.data, ...action.payload.data };
      state.loadings.data = false;
      return state;

    case getVirtualCardBalanceData.types.FAIL:
      state.loadings.data = false;
      return state;

    // BUY VIRTUAL CARD PRODUCT
    case addVirtualCardBalance.types.START:
      state.errors.buy = false;
      state.loadings.buy = true;
      return state;

    case addVirtualCardBalance.types.SUCCESS:
      state.errors.buy = false;
      state.loadings.buy = false;
      return state;

    case addVirtualCardBalance.types.FAIL:
      state.errors.buy = true;
      state.loadings.buy = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
