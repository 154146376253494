import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;

  font-size: 1.5rem;
`;

export default null;
