import React from 'react';
import Icon from '@material-ui/core/Icon';

// ASSETS
import { ReactComponent as ScalesIconSvg } from '@/assets/images/icons/scales.svg';

function ScalesIcon(props) {
  return <Icon {...props} component={ScalesIconSvg} />;
}

export default React.memo(ScalesIcon);
