import { createAction } from '@/utility/actions';

import {
  RESULTS_GET_RESULTS,
  RESULTS_ADD_EMAIL,
  RESULTS_SEND_TOKEN,
  RESULTS_APP_BY_IDS,
  RESULTS_CHANNELS_BY_SERVICE_IDS,
  CLEAR_RESULTS_CHANNELS_BY_SERVICE_IDS,
} from './types';

/**
 * getResults - params: unique_id
 */
export const getResults = createAction(RESULTS_GET_RESULTS);

/**
 * addEmailToResults - params: unique_id, email
 */
export const addEmailToResults = createAction(RESULTS_ADD_EMAIL);

/**
 * sendTokenForGetResults - params: email
 */
export const sendTokenForGetResults = createAction(RESULTS_SEND_TOKEN);

/**
 * getAppByIds - params: app_ids
 */
export const getAppByIds = createAction(RESULTS_APP_BY_IDS);

/**
 * getChannelsByServicesIds - params: ids
 */
export const getChannelsByServicesIds = createAction(RESULTS_CHANNELS_BY_SERVICE_IDS);

export const clearChannelsByServicesIds = createAction(CLEAR_RESULTS_CHANNELS_BY_SERVICE_IDS);

export default {
  getResults,
  addEmailToResults,
  sendTokenForGetResults,
  getAppByIds,
  getChannelsByServicesIds,
  clearChannelsByServicesIds,
};
