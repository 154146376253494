import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Languages
import en from './languages/en';

export const resources = { en };

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  load: 'unspecific',
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false,
  },

  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'span', 'p'],
  },
});
/**
 *@type { import("react-i18next").TFunction }
 */
export const t = i18n.t.bind(i18n);

export function getCurrentLang() {
  return i18n.language;
}

if (process.env.REACT_APP_FAST_REFRESH && process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./languages/en', () => {
    // eslint-disable-next-line
    const en = require('./languages/en')?.default;

    i18n.init({
      resources: { en },
      lng: 'en',
      load: 'unspecific',
      ns: ['translations'],
      defaultNS: 'translations',

      interpolation: {
        escapeValue: false,
      },

      react: {
        wait: true,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'span', 'p'],
      },
    });
  });
}

export default i18n;
