import { css } from 'styled-components';
import breakpoints from '@/constants/breakpoints';

const hyperfiberStyles = css`
  /* latin */
  @font-face {
    font-family: 'Solway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuLlgpnccR.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Solway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCmhXo8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Solway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCudlkpnccR.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Solway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuPl8pnccR.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Solway';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuIlwpnccR.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }
  .hyperfiber {
    .partner-home-page {
      .welcome:before {
        background-image: url('https://media.mybundle.tv/cp-uploads/blue-background.png');
      }
      .welcome__left {
        .welcome__title {
          color: #92c156;
        }
        .MuiButton-containedPrimary {
          border: 2px solid white;
          font-size: 2rem;
        }
      }
      .welcome__img {
        img {
          max-width: 100%;
        }
      }
      @media (max-width: ${breakpoints.sm}) {
        .welcome__img {
          text-align: center;
          margin-bottom: 0;
          img {
            max-width: 90%;
          }
        }
      }
    }
    .fmbpage {
      header {
        .logo-partner {
          a {
            background: url('https://d2suv5gemfchwa.cloudfront.net/cp-uploads/hyper-fiber-Logo-Template.png');
            height: 50px;
            width: 200px;
            background-position: center;
            background-size: contain;
            img {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default hyperfiberStyles;
