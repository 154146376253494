import { createAction } from '@/utility/actions';

import {
  PROMOMOTION_GET_DETAILS,
  PROMOMOTION_GET_CODE,
  PROMOMOTION_CHECK_CODE,
  PROMOMOTION_CLEAN_STATE,
  PROMOMOTION_CHECK_AUTH_CODE,
  PROMOTION_GET_SWIMLANES,
} from './types';

/**
 * getPromoDetails - params: promo_slug
 */
export const getPromoDetails = createAction(PROMOMOTION_GET_DETAILS);

/**
 * getPromoCode - params: promo_slug
 */
export const getPromoCode = createAction(PROMOMOTION_GET_CODE);

/**
 * checkPromoCode - params: promo_slug, promocode
 */
export const checkPromoCode = createAction(PROMOMOTION_CHECK_CODE);

/**
 * checkPromoAuthCode - params: promo_slug, promocode
 */
export const checkPromoAuthCode = createAction(PROMOMOTION_CHECK_AUTH_CODE);

export const getPromoSwimlanes = createAction(PROMOTION_GET_SWIMLANES);

export const cleanPromoState = createAction(PROMOMOTION_CLEAN_STATE);

export default {
  getPromoDetails,
  getPromoCode,
  checkPromoCode,
  cleanPromoState,
  checkPromoAuthCode,
  getPromoSwimlanes,
};
