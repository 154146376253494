// -- Endpoints --

// WP POSTS
export const WP_GET_POSTS = 'posts';
export const WP_GET_POSITIONS = 'positions';
export const WP_GET_NEWS = 'news';

// WP PAGES
export const WP_GET_PAGES = 'pages';

// CATEGORIES
export const WP_GET_CATEGORIES = 'categories';
export const WP_GET_POSITION_CATEGORIES = 'position_category';
export const WP_GET_NEWS_CATEGORIES = 'news_category';

export const WP_GET_USER = 'users';
