export default {
  title: 'Contact Us',
  subtitle: 'We’re MyBundle. We’re here to help.',
  description:
    'We make streaming TV simpler. Whether it’s helping you cut the cord on cable, find your next favorite TV show or movie across your streaming services or help you sign up and manage your subscriptions, we know you may have questions - after all, if finding the right services or content was easy then we’d be doing something else!',
  info: {
    item1: {
      title: 'Questions for MyBundle? We’d be happy to answer them.',
      description:
        'Please email us at <a href="mailto:info@mybundle.tv">info@mybundle.tv</a> to get in touch if you’re interested to know more about MyBundle. \n \n If you need help with something, email us at <a href="mailto:help@mybundle.tv">help@mybundle.tv</a>. We’ll typically answer them within 48 hours.',
    },
    item2: {
      title: 'Are you a Broadband Provider interested in partnering with us?',
      description:
        'We’d love to find some time to share how our partnerships work and how we can help you drive your business. If you’re interested, please schedule a meeting <meetLink>here</meetLink> with one of our representatives to learn more. \n \n If you have questions, email us at <a href="mailto:partners@mybundle.tv">partners@mybundle.tv</a>.',
    },
    item3: {
      title: 'Are you a Streaming Service interested in working with us?',
      description:
        'Interested in learning how we can help you gain access to our 200+ Broadband Partners and acquire new subscribers? Reach out to <a href="mailto:skessler@mybundle.tv">skessler@mybundle.tv</a> if you have any questions or would like to know more.',
    },
  },
  addInfo: {
    item1: {
      title: 'Common Questions',
      description: 'Check out FAQs section to quickly find answers to common questions.',
      link: 'Browse FAQs ->',
    },
    item2: {
      title: 'Helpful Resources',
      description:
        'Read articles and guides on cord-cutting, streaming services, and the latest trends in internet, cable, and streaming.',
      link: 'Explore Blogs ->',
    },
  },
};
