import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import { ONBOARDING_APPS, ONBOARDING_APPS_COMPLETE } from '@/constants/api';

// UTILITY
import { getPartnerSlug } from '@/utility/saga';
import { getSerializedParams } from '@/utility/routes';

// STORE
import { OnboardingActions, NotifActions } from '@/store/actions';

// LOCALIZATION
import { t } from '@/locale/i18n';

const { getOnboardingApps, onboardingComplete } = OnboardingActions;
const {
  pushSuccessNotificationAction,
  setNotificationOptions,
  clearNotificationOptions,
} = NotifActions;

export function* getOnboardingAppsSaga() {
  yield put(getOnboardingApps.start());

  const partner = yield call(getPartnerSlug);
  const serializedParams = getSerializedParams({ partner });

  try {
    const { data: respData } = yield axios.get(ONBOARDING_APPS, serializedParams);

    yield put(
      getOnboardingApps.success({
        data: respData.data.popular_apps,
        user_apps: respData.data.user_apps,
      }),
    );
  } catch (error) {
    yield put(getOnboardingApps.fail());
  }
}

export function* onboardingCompleteSaga() {
  yield put(onboardingComplete.start());

  try {
    const { data: respData } = yield axios.post(ONBOARDING_APPS_COMPLETE);

    yield put(onboardingComplete.success());

    yield put(
      pushSuccessNotificationAction(
        respData.message || t('notification.successfullyCompleteOnboarding'),
        { autoHideDuration: undefined },
      ),
    );
  } catch (error) {
    yield put(onboardingComplete.fail());
  }
}

export function* onboardingStateWatchSaga(action) {
  const { state } = action.payload;

  if (state) {
    yield put(
      setNotificationOptions.init({
        styles: {
          containerAnchorOriginBottomRight: {
            marginBottom: '70px',
          },
        },
      }),
    );
  } else {
    yield put(clearNotificationOptions.init());
  }
}
