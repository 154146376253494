import { appsTypesFilter } from './app';

export const contentStatuses = {
  currentlyWatching: 1,
  notInterested: 2,
  watched: 3,
  wantToWatch: 4, // need to remove (replaced to watchlists)
};

export const contentTypes = {
  show: 1,
  movie: 2,
};

export const ContentType = {
  SHOW: 'show',
  MOVIE: 'movie',
  SHOW_ID: 1,
  MOVIE_ID: 2,
};

export const DisplayType = {
  DEFAULT: 'default',
  PAGE: 'page',
  DIALOG: 'dialog',
  TOOLTIP: 'tooltip',
};

export const ServiceButtonType = {
  WATCH_NOW: 'watch_now',
  SIGN_UP: 'sign_up',
};

export const appsTypes = [...appsTypesFilter].sort((a, b) => a.order - b.order);

export const wlAccess = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  SHARED: 'shared',
};

export default { contentStatuses, contentTypes, DisplayType, ContentType };
