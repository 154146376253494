import { createAction } from '@/utility/actions';

import { CONTENT_GET_MEDIA, CONTENT_CHANGE_STATUS } from './types';

/**
 * getContentMedia - content_id, type
 */
export const getContentMedia = createAction(CONTENT_GET_MEDIA);

/**
 * changeContentStatus - content_id, status
 */
export const changeContentStatus = createAction(CONTENT_CHANGE_STATUS);

export default {
  getContentMedia,
  changeContentStatus,
};
