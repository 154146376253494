import styled from 'styled-components';
import { rgba } from 'polished';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

// CONSTANTS
import colors, { WHITE, BLACK, SANTAS_GRAY } from '@/constants/colors';

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-item {
    &__main-info {
      display: flex;
      align-items: center;
      margin-right: 10px;
      overflow: hidden;
      flex-grow: 1;
    }

    &__image {
      display: flex;
      align-items: center;
      height: 24px;
      width: 45px;
      margin-right: 15px;

      img {
        max-width: 100%;
        max-height: 24px;
      }
    }

    &__title-section {
      width: 100%;
    }

    &__title {
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__year {
      color: ${p => p.theme.palette.text.secondary};
    }

    &__type {
      text-transform: capitalize;
      color: ${SANTAS_GRAY};
      flex-shrink: 0;
    }
  }
`;

export const NoResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${WHITE};
  cursor: default;
  overflow: hidden;
  font-size: 1.4rem;
  box-shadow: 0 20px 30px ${rgba(BLACK, 0.1)};
  font-weight: 500;
  line-height: 1.4rem;
  border-radius: 0 0 3px 3px;
  padding: 16px 15px;
  color: ${colors.text};
`;

export const StyledPaper = styled(Paper)`
  margin: 0 -1px 0 1px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  right: 9px !important;
`;

export default SearchItem;
