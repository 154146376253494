import { createAction } from '@/utility/actions';

import {
  PARTNER_GET,
  PARTNER_CLEAN,
  PARTNER_BY_DOMAIN,
  PARTNER_INTERNET_OFFER_CLAIM,
  PARTNER_INTERNET_OFFER_GET,
} from './types';

/**
 * getPartner - params: slug
 */
export const getPartner = createAction(PARTNER_GET);

export const cleanPartner = createAction(PARTNER_CLEAN);

export const getPartnerByDomain = createAction(PARTNER_BY_DOMAIN);

/**
 * getPartnerInternetOffer - params: {partnerId:number}
 */
export const getPartnerInternetOffer = createAction(PARTNER_INTERNET_OFFER_GET);

/**
 * claimPartnerInternetOffer - params: {offerId: number}
 */
export const claimPartnerInternetOffer = createAction(PARTNER_INTERNET_OFFER_CLAIM);

export default {
  getPartner,
  cleanPartner,
  getPartnerByDomain,
  getPartnerInternetOffer,
  claimPartnerInternetOffer,
};
