import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';

// CONSTANTS
import { PROMOTIONS_GET_DICTIONARY, PROMOTIONS_GET_LIST } from '@/constants/api';
import { promotionTypes } from '@/constants/promotions';

// UTILITY
import { showErrorNotification } from '@/utility/saga';

// STORE
import { PromotionsActions } from '@/store/actions';
import { PromotionsSelectors } from '@/store/selectors';
import { getSerializedParams } from '@/utility/routes';

const { getPromoDictionary, getPromoContent, getBlackFridayContent } = PromotionsActions;

export function* getPromoDictionarySaga(action) {
  yield put(getPromoDictionary.start());

  const { params = {} } = action.payload;

  const serializedParams = getSerializedParams(params);

  try {
    const { data: respData } = yield axios.get(PROMOTIONS_GET_DICTIONARY, serializedParams);

    yield put(getPromoDictionary.success({ data: respData.data }));

    return respData.data;
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getPromoDictionary.fail());

    return null;
  }
}

export function* getPromoContentSaga(action) {
  yield put(getPromoContent.start());

  const { params = {} } = action.payload;

  const serializedParams = getSerializedParams(params);

  try {
    const { data: respData } = yield axios.get(PROMOTIONS_GET_LIST, serializedParams);

    yield put(
      getPromoContent.success({
        ...respData,
        page: params.page || respData?.meta?.current_page,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getPromoContent.fail());
  }
}

export function* getBlackFridayContentSaga(action) {
  yield put(getBlackFridayContent.start());

  const { params = {} } = action.payload;

  try {
    const dictionary = yield select(PromotionsSelectors.getPromoDictionary);

    if (!dictionary.sort_options) {
      yield call(getPromoDictionarySaga, {
        payload: { params: { ...params, type: promotionTypes.blackFriday } },
      });
    }

    yield call(getPromoContentSaga, {
      payload: { params: { ...params, type: promotionTypes.blackFriday } },
    });

    yield put(getBlackFridayContent.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getBlackFridayContent.fail());
  }
}
