import React from 'react';
import { useSelector } from 'react-redux';

// CONSTANTS
import {
  ROUTE_START,
  ROUTE_PROFILE_MY_RESULTS,
  ROUTE_WATCH_NEXT,
  ROUTE_STREAMING_REWARDS,
  ROUTE_STREAMING_CHOICE,
  ROUTE_PAYMENT_HISTORY,
  ROUTE_PROFILE_WATCHLISTS,
} from '@/constants/routes';
import { profileList } from '@/constants/menus';
import { LOCATIONS_PROFILE_SIDE_BAR } from '@/constants/locations';
import { urlTypes } from '@/constants/streamingRewards';

// UTILITY
import { analyticsStartFormLink } from '@/utility/analytics';
import { getLink } from '@/utility/routes';

// COMPONENTS
import NavList from '@/components/NavList';

const ProfileMenu = props => {
  const { content_count, rewardData, partner, partnerSettings } = useSelector(
    ({ auth, partner: partnerStore }) => ({
      content_count: auth?.user?.content_count || {},
      rewardData: auth?.user?.eligible_streaming_reward || {},
      partner: partnerStore.data,
      partnerSettings: partnerStore.data?.settings || {},
    }),
  );

  const isPartner = !!Object.keys(partner).length;
  const exclude = [];
  let updatedProfileList = [...profileList];
  let use_fmb = true;
  let useShowMovies = true;

  if (partner.partner_slug) {
    use_fmb = !!partnerSettings.use_fmb;
    useShowMovies = !!partnerSettings.use_authorizing && !!partnerSettings.search_movies_shows;
  }

  if (content_count.fmbd_results === 0) {
    updatedProfileList = updatedProfileList.map(item => {
      if (item.label === 'common.menu.myResults') {
        return { ...item, label: 'common.menu.findMyBundle', route: ROUTE_START };
      }

      return item;
    });
  }

  updatedProfileList = updatedProfileList.map(item => {
    // Update watchlists label (depends on wlCount)
    if (item.key === 'my-list') {
      item.submenu = item.submenu.map(subItem => {
        if (subItem.key === 'watchlists') {
          const wlCount = content_count?.watchlists_ids?.length || 2;

          return { ...subItem, label: `common.menu.${wlCount > 1 ? 'watchLists' : 'watchList'}` };
        }

        return subItem;
      });

      return item;
    }

    return item;
  });

  if (partner?.text_overwrites?.profile_link_text_mybundle_results) {
    updatedProfileList = updatedProfileList.map(item => {
      if (item.label === 'common.menu.myResults') {
        return { ...item, label: partner.text_overwrites.profile_link_text_mybundle_results };
      }

      return item;
    });
  }

  if (content_count.watch_next_results === 0) {
    updatedProfileList = updatedProfileList.map(item => {
      if (item.label === 'common.menu.bingeResults') {
        return { ...item, label: 'common.menu.createBingeResults', route: ROUTE_WATCH_NEXT };
      }

      return item;
    });
  }

  if (
    rewardData.slug &&
    ((isPartner && partner.slug === rewardData.partner) ||
      (!isPartner && rewardData.partner === null))
  ) {
    const isChoice = rewardData.url_type === urlTypes.STREAMING_CHOICE;

    updatedProfileList.push({
      label:
        rewardData.profile_menu_name ||
        `common.menu.${isChoice ? 'streamingChoice' : 'streamingRewards'}`,
      route: getLink(isChoice ? ROUTE_STREAMING_CHOICE : ROUTE_STREAMING_REWARDS, {
        slug: rewardData.slug,
      }),
    });

    if (rewardData.slug === 'multiple-apps') {
      updatedProfileList.push({
        label: 'common.menu.paymentHistory',
        route: ROUTE_PAYMENT_HISTORY,
      });
    }
  }

  if (!use_fmb) {
    exclude.push(ROUTE_START);
    exclude.push(ROUTE_PROFILE_MY_RESULTS);
  }

  if (!useShowMovies) {
    exclude.push(ROUTE_PROFILE_WATCHLISTS);
  }

  if (exclude.length) {
    updatedProfileList = updatedProfileList.filter(item => {
      return !exclude.includes(item.route);
    });
  }

  function handleItemClick(e, data) {
    if (data.route === ROUTE_START) {
      analyticsStartFormLink({
        location: 'Profile Nav',
        type: 'Link',
      });
    }
  }

  return (
    <NavList
      data={updatedProfileList}
      handleMenuItem={handleItemClick}
      location={LOCATIONS_PROFILE_SIDE_BAR}
      content_count={content_count}
      {...props}
    />
  );
};

export default React.memo(ProfileMenu);
