import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// HOC
import withRouter from '@/hoc/withRouter';

// CONSTANTS
import {
  ROUTE_PROFILE,
  ROUTE_ROOT,
  ROUTE_START,
  ROUTE_RESULTS,
  ROUTE_RESULTS_WITH_TOKEN,
  ROUTE_PARTNER_RESULTS,
  ROUTE_PARTNER_RESULTS_WITH_TOKEN,
} from '@/constants/routes';
import { LOCATIONS_PROFILE_SIDE_BAR } from '@/constants/locations';

// UTILITY
import { analyticsInternalLinks, analyticsStartFormLink } from '@/utility/analytics';
import store from '@/utility/store';
import { routeEnhance } from '@/utility/routes';

// HOOKS
import useActions from '@/hooks/useActions';

// STORE
import { AppActions, AuthActions } from '@/store/actions';

// COMPONENTS
import CommonDrawer from '../CommonDrawer';

// HELP
import ProfileMenu from './help/ProfileMenu';

// STYLES
import { LogoutItem, useStyles } from './styles';

const ProfileDrawer = ({ router: { navigate, routerMatch } }) => {
  const [t] = useTranslation();
  const { profileDrawerIsOpen, unique_id } = useSelector(({ app, auth }) => ({
    profileDrawerIsOpen: app.profileDrawerIsOpen,
    unique_id: auth.unique_id,
  }));
  const [manageProfDrawerState, logout] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    callback => AuthActions.logout.action({ callback }),
  ]);
  const isProfileRoute = routerMatch({ path: ROUTE_PROFILE });
  const isResultsRoute =
    routerMatch({ path: ROUTE_RESULTS }) ||
    routerMatch({ path: ROUTE_RESULTS_WITH_TOKEN }) ||
    routerMatch({ path: ROUTE_PARTNER_RESULTS }) ||
    routerMatch({
      path: ROUTE_PARTNER_RESULTS_WITH_TOKEN,
    });
  const classes = useStyles();

  function handleStartClick() {
    const storageStartData = store.get('start-data');
    const storageChannelsData = store.get('channels-data');

    if (unique_id) {
      if (storageStartData) {
        store.remove('start-data');
      }

      if (storageChannelsData) {
        store.remove('channels-data');
      }
    }

    analyticsStartFormLink({
      location: 'Nav',
      type: 'Link',
    });
  }

  function handleItemClick(e, data) {
    if (data.route === ROUTE_START) {
      handleStartClick();
    }

    manageProfDrawerState(false);
  }

  function handleLogoutClick() {
    manageProfDrawerState(false);

    logout();

    setTimeout(() => {
      if (isProfileRoute) {
        const routeEnhanced = routeEnhance(ROUTE_ROOT, {
          state: {
            location: LOCATIONS_PROFILE_SIDE_BAR,
          },
        });

        analyticsInternalLinks({
          unique_id: routeEnhanced.state.unique_id,
          from_page_path: window.location.pathname,
          from_search_params: window.location.search,
          to_page_path: routeEnhanced.pathname,
          to_search_params: routeEnhanced.search,
          partner_id: routeEnhanced.state.partner_id,
          location: routeEnhanced.state.location,
        });

        navigate(routeEnhanced, { replace: true });
      }
    }, 50);
  }

  return (
    <CommonDrawer
      open={profileDrawerIsOpen}
      onClose={() => manageProfDrawerState(false)}
      classes={{ paper: classes.drawerPaper }}
      title={t('header.profile.title')}
      ModalProps={{
        keepMounted: true,
        disableEnforceFocus: true,
      }}
    >
      <ProfileMenu handleMenuItem={handleItemClick} />
      {!isResultsRoute && (
        <LogoutItem component="div" onClick={handleLogoutClick}>
          {t('buttons.logout')}
        </LogoutItem>
      )}
    </CommonDrawer>
  );
};

ProfileDrawer.propTypes = {
  router: PropTypes.object.isRequired,
};

export default React.memo(withRouter(ProfileDrawer));
