import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export const ListStyled = styled(List)`
  .nav-list,
  &.nav-list {
    &--nested {
      .list-item {
        padding-left: ${p => p.theme.spacing(4)}px;
      }
    }
  }
`;

export const ListItemStyled = styled(ListItem)`
  .list-item,
  &.list-item {
    color: inherit;
    transition: color 0.3s ease;

    &:hover,
    &.active {
      background-color: ${p => p.theme.palette.primary.main};
      color: ${p => p.theme.palette.primary.contrastText};
    }

    &__collapse-icon {
      position: absolute;
      right: 12px;
    }

    &.blink {
      animation: blinker 1s linear infinite;
      animation-iteration-count: 5;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }

  .MuiBadge-badge {
    font-size: 12px;
    height: 24px;
    min-width: 24px;
    border-radius: 12px;
  }
`;

export default ListItemStyled;
