import { createAction } from '@/utility/actions';

import { CONFIRMATION_ISP } from './types';

/**
 * getConfirmationISPData - params:
 */
export const getConfirmationISPData = createAction(CONFIRMATION_ISP);

export default {
  getConfirmationISPData,
};
