import axios from 'axios';
import { put, call, delay } from 'redux-saga/effects';

// CONSTANTS
import {
  STREAMING_REWARDS_FUNDS_PAYMENT_CANCEL,
  STREAMING_REWARDS_FUNDS_PAYMENT_STATUS,
  STREAMING_REWARDS_FUNDS_PAYMENT,
  STREAMING_REWARDS_FUNDS_RECURRING_PAYMENT_CANCEL,
  STREAMING_REWARDS_GET_CLIENT_SECRET,
  STREAMING_REWARDS_FUNDS_GET_PAYMENT_STATUS,
} from '@/constants/api';
import { TransactionStatus } from '@/constants/subscription';
import {
  processingMessage,
  paymentMethodMessage,
  defErrorMessage,
} from '@/constants/streamingRewards';

// UTILITY
import getErrorMessage from '@/utility/errors';
import { getLink } from '@/utility/routes';
import { getIPGeoData, showErrorNotification } from '@/utility/saga';
import bugsnagClient from '@/utility/bugsnagClient';

// STORE
import { NotifActions, StreamingRewardsFundsActions, UserActions } from '@/store/actions';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  payFunds,
  cancelRecurringPayment,
  getSRClientSecret,
  addSRFunds,
  retrievePaymentStatus,
} = StreamingRewardsFundsActions;
const { userGetCards, userGetSRWidgetData } = UserActions;
const { pushSuccessNotificationAction, pushErrorNotificationAction } = NotifActions;

function* confirmPayment3DSecure({ payload, transaction_id, confirmCardPayment }) {
  const { client_secret } = payload;

  try {
    yield call(confirmCardPayment, client_secret);
  } catch (error) {
    const url = getLink(STREAMING_REWARDS_FUNDS_PAYMENT_CANCEL, { transaction_id });

    yield axios.delete(url);

    throw new Error(t('subscription.notifications.payment_error_desc'));
  }
}

function* pollSubscriptionStatus(transaction_id) {
  const delayTimeMs = 2000;
  const maxCountApiCalls = 30;
  let countApiCalls = 1;

  while (countApiCalls < maxCountApiCalls) {
    const url = getLink(STREAMING_REWARDS_FUNDS_PAYMENT_STATUS, { transaction_id });

    const {
      data: { status, balance },
    } = yield axios.get(url, {
      cache: { ignoreCache: true },
    });

    if (status !== TransactionStatus.PENDING) {
      return { balance, status };
    }

    countApiCalls += 1;

    yield delay(delayTimeMs);
  }

  return {};
}

// params - transaction_id | payment_intent, payment_method
function* pollPaymentStatus(params) {
  const delayTimeMs = 3000;
  const maxCountApiCalls = 40;
  let countApiCalls = 1;

  while (countApiCalls < maxCountApiCalls) {
    const { data: respData } = yield axios.get(STREAMING_REWARDS_FUNDS_GET_PAYMENT_STATUS, {
      cache: { ignoreCache: true },
      params,
    });

    if (respData.status !== TransactionStatus.PENDING) {
      return {
        status: respData.status,
        amount: respData.amount || '',
        next_payment: respData.next_payment || '',
      };
    }

    countApiCalls += 1;

    yield delay(delayTimeMs);
  }

  return { status: TransactionStatus.PENDING };
}

export function* payFundsSaga(action) {
  const {
    paymentData,
    createPaymentMethod,
    confirmCardPayment,
    cardReqParams,
    callback,
  } = action.payload;
  // !IMPORTANT: if card won't change
  const hasCard = !!paymentData.payment_method_id;

  yield put(payFunds.start());

  try {
    const ipApiData = yield call(getIPGeoData);

    paymentData.userIp = ipApiData.query;

    if (!hasCard) {
      const payment = yield call(createPaymentMethod);

      paymentData.payment_method_id = payment.id;
    }

    const {
      data: { payload, transaction_id, next_payment },
    } = yield axios.post(STREAMING_REWARDS_FUNDS_PAYMENT, paymentData);

    if (payload?.need_to_confirm) {
      yield call(confirmPayment3DSecure, {
        transaction_id,
        payload,
        confirmCardPayment,
      });
    }

    if (paymentData.save) {
      yield put(userGetCards.init(cardReqParams));
    }

    yield call(pollSubscriptionStatus, transaction_id);

    yield put(payFunds.success());

    yield put(userGetSRWidgetData.init());

    if (callback?.call) callback({ paymentData, nextPaymentDate: next_payment });
  } catch (error) {
    yield put(payFunds.fail({ message: getErrorMessage(error) }));
  }
}

export function* cancelRecurringPaymentSaga(action) {
  const { callback } = action.payload;

  yield put(cancelRecurringPayment.start());

  try {
    yield axios.post(STREAMING_REWARDS_FUNDS_RECURRING_PAYMENT_CANCEL);

    yield put(cancelRecurringPayment.success());

    yield put(pushSuccessNotificationAction(t('notification.successfullyCanceledNextMthPayment')));

    if (callback?.call) callback();
  } catch (error) {
    yield put(cancelRecurringPayment.fail({ message: getErrorMessage(error) }));

    yield put(pushErrorNotificationAction(error));
  }
}

export function* getSRClientSecretSaga(action) {
  const { data: paymentData } = action.payload;

  yield put(getSRClientSecret.start());

  try {
    const ipApiData = yield call(getIPGeoData);

    paymentData.userIp = ipApiData.query;

    const { data: respData } = yield axios.get(STREAMING_REWARDS_GET_CLIENT_SECRET, {
      params: paymentData,
    });

    yield put(
      getSRClientSecret.success({
        clientSecret: respData.clientSecret,
        paymentIntent: respData.paymentIntent,
      }),
    );

    // delay for better ui that stripe form has time to load
    yield delay(1000);

    yield put(getSRClientSecret.stop());
  } catch (error) {
    bugsnagClient.notify(new Error("Can't get client secret", { cause: error }), event => {
      event.severity = 'warning';
    });

    yield call(showErrorNotification, error);

    yield put(getSRClientSecret.fail());
  }
}

export function* retrievePaymentStatusSaga(action) {
  const { params, onSuccess, onPending, savePM = true } = action.payload;

  yield put(retrievePaymentStatus.start());

  try {
    const { status, amount, next_payment } = yield call(pollPaymentStatus, params);

    switch (status) {
      case 'paid':
        yield put(retrievePaymentStatus.success());

        if (savePM) {
          yield put(userGetCards.init());
        }

        yield put(userGetSRWidgetData.init());

        if (onSuccess?.call) onSuccess({ amount, nextPaymentDate: next_payment });
        break;
      case 'pending':
        if (onPending?.call) onPending();

        yield put(retrievePaymentStatus.fail({ warning: true, message: processingMessage }));
        break;
      case 'failed':
      default:
        throw new Error(defErrorMessage);
    }

    return { status, amount, next_payment };
  } catch (error) {
    yield put(retrievePaymentStatus.fail({ message: getErrorMessage(error) }));
  }
}

export function* addSRFundsSaga(action) {
  const { confirmPayment, onSuccess, onPending } = action.payload;

  yield put(addSRFunds.start());

  try {
    const paymentIntent = yield call(confirmPayment);

    switch (paymentIntent.status) {
      case 'succeeded':
      case 'processing': {
        const paymentStatusData = yield call(retrievePaymentStatusSaga, {
          payload: {
            params: {
              payment_intent: paymentIntent.id,
              payment_method: paymentIntent.payment_method,
            },
          },
        });

        if (paymentStatusData.status === 'paid') {
          if (onSuccess?.call)
            onSuccess({
              amount: paymentStatusData.amount,
              nextPaymentDate: paymentStatusData.next_payment,
            });
        }

        if (paymentStatusData.status === 'pending') {
          if (onPending?.call) onPending();

          yield put(addSRFunds.fail({ warning: true, message: processingMessage }));

          break;
        }

        yield put(addSRFunds.success());

        break;
      }
      case 'requires_payment_method':
        throw new Error(paymentMethodMessage);
      default:
        throw new Error(defErrorMessage);
    }
  } catch (error) {
    yield put(addSRFunds.fail({ message: getErrorMessage(error) }));
  }
}

export function* addSRFundsByPMSaga(action) {
  const { paymentData, confirmCardPayment, onSuccess, onPending } = action.payload;
  yield put(payFunds.start());

  try {
    const ipApiData = yield call(getIPGeoData);

    paymentData.userIp = ipApiData.query;

    const {
      data: { payload, transaction_id },
    } = yield axios.post(STREAMING_REWARDS_FUNDS_PAYMENT, paymentData);

    if (payload?.need_to_confirm) {
      yield call(confirmPayment3DSecure, {
        transaction_id,
        payload,
        confirmCardPayment,
      });
    }

    const paymentStatusData = yield call(retrievePaymentStatusSaga, {
      payload: {
        params: {
          transaction_id,
          payment_method: paymentData.payment_method_id,
        },
        savePM: paymentData.save,
      },
    });

    if (paymentStatusData.status === 'paid') {
      if (onSuccess?.call)
        onSuccess({
          amount: paymentStatusData.amount,
          nextPaymentDate: paymentStatusData.next_payment,
        });
    }

    if (paymentStatusData.status === 'pending') {
      if (onPending?.call) onPending();
    }

    yield put(payFunds.success());
  } catch (error) {
    yield put(payFunds.fail({ message: getErrorMessage(error) }));
  }
}
