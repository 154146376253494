import { createAction } from '@/utility/actions';

import { PAGES_GET_PAGES_CONTENT } from './types';

/**
 * getPagesContent - params: pageSlugs (comma-separated string) (optional)
 */
export const getPagesContent = createAction(PAGES_GET_PAGES_CONTENT);

export default {
  getPagesContent,
};
