import produce from 'immer';

// UTILITY
import store from '@/utility/store';

import {
  getOnboardingApps,
  onboardingComplete,
  onboardingSetState,
  onboardingUpdateSelectedApps,
} from '../actions/onboarding';

import { registerUser } from '../actions/auth';

const selectedMyApps = store.get('my_apps') || [];

const initialState = {
  apps: [],
  selectedMyApps,
  user_apps: {},
  isOpen: false,
  onboardingProps: {},
  loadings: {
    apps: true,
    complete: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET APPS
    case getOnboardingApps.types.START:
      state.loadings.apps = true;
      return state;

    case getOnboardingApps.types.SUCCESS:
      state.apps = action.payload.data;
      state.user_apps = action.payload.user_apps;
      state.loadings.apps = false;
      return state;

    case getOnboardingApps.types.FAIL:
      state.loadings.apps = false;
      return state;

    // ONBOARDING COMPLETE
    case onboardingComplete.types.START:
      state.loadings.complete = true;
      return state;

    case onboardingComplete.types.SUCCESS:
    case onboardingComplete.types.FAIL:
      state.loadings.complete = false;
      return state;

    // SET ONBOARDING STATE
    case onboardingSetState.types.INIT:
      state.isOpen = action.payload.state;
      state.onboardingProps = action?.payload?.props || {};
      return state;

    // UPDATE ONBOARDING SELECTED APPS
    case onboardingUpdateSelectedApps.types.INIT:
      state.selectedMyApps = action.payload.data;
      return state;

    // CLEAN ONBOARDING SELECTED APPS
    case registerUser.types.SUCCESS:
      state.selectedMyApps = [];
      return state;

    default:
      return state;
  }
});

export default reducer;
