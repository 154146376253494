import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

const metronet = {
  secondary: '#246ba3',
};

const metronetStyles = theme => css`
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web Light'), local('TitilliumWeb-Light'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* titillium-web-300italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 300;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web Light Italic'), local('TitilliumWeb-LightItalic'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-300italic.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* titillium-web-italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 400;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web Italic'), local('TitilliumWeb-Italic'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-italic.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* titillium-web-regular - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-regular.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* titillium-web-600 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* titillium-web-600italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 600;
    src: url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.eot`}); /* IE9 Compat Modes */
    src: local('Titillium Web SemiBold Italic'), local('TitilliumWeb-SemiBoldItalic'),
      url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.eot?#iefix`})
        format('embedded-opentype'),
      /* IE6-IE8 */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.woff2`})
        format('woff2'),
      /* Super Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.woff`})
        format('woff'),
      /* Modern Browsers */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.ttf`})
        format('truetype'),
      /* Safari, Android, iOS */
        url(${`${process.env.PUBLIC_URL}/fonts/Titillium/titillium-web-v8-latin-600italic.svg#TitilliumWeb`})
        format('svg'); /* Legacy iOS */
  }
  /* Form Page */
  .partner-metronet {
    &.wizard-form {
      .backtohome a {
        display: none;
      }
      .toolbar__link {
        &.prevlink {
          font-weight: 350;
          color: ${metronet.secondary};
        }
      }
      @media screen and (max-width: ${breakpoints.sm}) {
        header {
          background: ${theme?.background};
        }
        .right-section {
          > div {
            flex-wrap: nowrap;
            white-space: nowrap;
          }
        }
      }
    }

    &.formpage,
    &.channelspage {
      form {
        h1 {
          font-size: 2.375rem;
          font-weight: 500;
        }
        p {
          font-size: 1.4rem;
          color: ${theme?.secondarytextcolor};
          font-weight: 350;
        }
      }
      .next-button {
        font-size: 1.6rem;
        &.Mui-disabled {
          background-color: ${theme?.primary && rgba(theme.primary, 0.75)};
        }
      }
      .previous-button {
        background: ${metronet.secondary} !important;
        color: ${WHITE} !important;
        border-color: ${metronet.secondary} !important;
      }

      .radio-card {
        border-radius: 0;
        .radio-card__title {
          color: ${metronet.secondary};
          font-weight: 400;
        }
        &.radio-card--checked {
          .radio-card__title {
            color: ${theme?.primary};
          }
          &:after {
            border-radius: 0 !important;
          }
        }
      }
      .checkbox-card {
        border-radius: 0 !important;
        .checkbox-card__title {
          font-weight: 400 !important;
          color: ${metronet.secondary};
        }
        &.checkbox-card--checked {
          .checkbox-card__title {
            color: ${theme?.primary};
          }
          &:after {
            border-radius: 0 !important;
          }
        }
      }
      .page-content.mail-step {
        padding-bottom: 80px;
      }
    }
    &.channelspage form > div:not(.page-content) {
      padding: 67px 10px;
    }
    &.resultspage {
      & > .section--grey {
        padding-bottom: 70px;
        @media screen and (max-width: ${breakpoints.sm}) {
          padding-bottom: 120px;
        }
      }
      .p5 {
        font-weight: 350;
      }
      .bundle {
        box-shadow: 2px 2px 2px ${rgba(BLACK, 0.4)};
        border: 2px solid ${theme?.primary};
        border-radius: 0;
        .bundle__description {
          font-weight: 350;
        }
        .service__cost {
          font-weight: 500;
        }
      }
    }
    .modal-content {
      .learnmore {
        background-color: ${metronet.secondary};
        border-radius: 0;
        color: ${WHITE};
        border: none;
        &:hover {
          background-color: ${metronet.secondary};
        }
      }
    }
  }
  .metronet {
    .app-details-page {
      .hero-container {
        .p1 {
          font-weight: 350;
        }
        .grid-item__back-to-results {
          a {
            background-color: ${metronet.secondary};
            color: ${WHITE};
          }
        }
      }
      .page-content p {
        font-weight: 350;
      }
      #packages {
        .package-card {
          a.MuiButton-contained:not(.MuiButton-containedPrimary) {
            background-color: ${metronet.secondary};
            color: ${WHITE};
          }
        }
      }
    }
  }
`;

export default metronetStyles;
