export default {
  title: '<span>Take Charge</span> \n of Your Entertainment',
  subtitle:
    'An easy way to discover and manage all your streaming subscriptions in one place with MyBundle',
  getLinkInputTitle: 'Get a link to download the app',
  subcrBenefits: {
    title: 'No more Entertainment Subscriptions stress',
    item1: {
      text: 'Keep track of and manage all your subscriptions in one place',
    },
    item2: {
      text: 'Manage your Streaming Choice Card and streamline your entertainment budget',
    },
    item3: {
      text: 'Get notifications on where your favorite shows are coming back and where to watch',
    },
  },
  contentBenefits: {
    title: 'Discover TV Shows & Movies with endless possibilities, wherever, whenever',
    item1: {
      text: 'Explore the top TV Shows & Movies across various streaming services in one place',
    },
    item2: {
      text: 'Generate multiple watchlists and share them with your friends',
    },
    item3: {
      text: 'Get and give recommendations from your friends on what to watch next',
    },
  },
  downloadApp: {
    title: 'Scan the code to get MyBundle App',
    titleMob: 'Get MyBundle App',
    links: {
      title: 'Get the App Now',
    },
  },
};
