import React from 'react';
import PropTypes from 'prop-types';

// UTILITY
import { clearCache } from '@/utility/cache';

// STORE
import { axiosPublic } from '@/store/configDefaultAPI';

import packageJson from '../../package.json';

global.appVersion = packageJson.version;

// version from response - first param, local version second param
export const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        clearCache();
      },
    };
  }

  async componentDidMount() {
    try {
      await axiosPublic
        .get('meta.json')
        .then(response => response.data)
        .then(meta => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;

          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

          if (shouldForceRefresh) {
            this.setState({ loading: false, isLatestVersion: false });
          } else {
            // eslint-disable-next-line no-console
            console.log(
              `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
            );
            this.setState({ loading: false, isLatestVersion: true });
          }
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error while receiving meta.json', e);
    }
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;

    return children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.func,
};

export default CacheBuster;
