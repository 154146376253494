/* eslint-disable indent */
import styled from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { GRAY_SUIT, CHATHAMS_BLUE, WHITE, RADICAL_RED, AQUA_HAZE } from '@/constants/colors';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

export const AppListContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  ${breakpoints.down('xs')} {
    flex-direction: column-reverse;
  }

  .app-list {
    &__title {
      font-size: 1.4rem;
      line-height: 1.4rem;
      font-weight: 600;
      color: ${GRAY_SUIT};
      text-transform: uppercase;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 20px;
      border-right-width: 1px;
      border-right-color: ${GRAY_SUIT};
      white-space: nowrap;
    }

    &__link {
      white-space: nowrap;

      ${breakpoints.down('xs')} {
        text-transform: capitalize;
        padding: 5px 5px 20px;
      }
    }
  }
`;
export const AppsContainer = styled.div`
  display: flex;
  width: calc(100% - 210px);

  ${breakpoints.down('sm')} {
    width: calc(100% - 90px);
  }

  ${breakpoints.down('xs')} {
    width: 100%;
    box-shadow: 0 1px 0 ${AQUA_HAZE};
  }
`;

export const AppsWrapper = styled.div`
  position: relative;
  overflow: hidden;

  margin-left: 20px;

  ${breakpoints.down('xs')} {
    margin-left: 0;
  }
`;

export const App = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 3px;
  padding: 5px;
  transition: box-shadow 0.4s;
  margin: 10px 8px;

  &:not(.app--read-only):hover {
    cursor: pointer;
    box-shadow: 0 20px 40px ${rgba(CHATHAMS_BLUE, 0.14)};

    &:before {
      content: '\\e919';
      position: absolute;
      top: -8px;
      right: -8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'icomoon';
      font-size: 10px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: ${RADICAL_RED};
      color: ${WHITE};
      z-index: 1;
      transition: 0.5s;
    }
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
`;
