import { createAction } from '@/utility/actions';

import {
  USER_EDIT,
  USER_GET_RESULTS,
  USER_CLEAN_RESULTS,
  USER_APPS_AUTOCOMPLETE,
  USER_GET_APPS,
  USER_UPDATE_APPS,
  USER_REMOVE_APP,
  USER_GET_RECOMMENDATIONS,
  USER_CLEAN_RECOMMENDATIONS,
  USER_UPDATE_FIRST_NAME,
  USER_GET_CONTENT_COUNT,
  USER_UPDATE_CONTENT_COUNT,
  USER_CHANGE_APP_PRICE,
  USER_GET_RECOMMENDED_APPS,
  USER_GET_MY_APPS_COLLECTIONS,
  USER_GET_IO_BY_UNIQUE_ID,
  USER_CHANGE_PASSWORD,
  USER_GET_CARDS,
  USER_SET_DEFAULT_CARD,
  USER_ADD_CARD,
  USER_ADD_PAYMENT_METHOD,
  USER_REMOVE_PAYMENT_METHOD,
  USER_STREAMING_REWARD_WIDGET_GET,
  USER_STREAMING_REWARD_BALANCE_UPDATE,
  USER_RETRIEVE_PAYMENT_METHOD_STATUS,
} from './types';

/**
 * userEditProfile - params: data, callback
 */
export const userEditProfile = createAction(USER_EDIT);

/**
 * userGetResults - params
 */
export const userGetResults = createAction(USER_GET_RESULTS);

export const userCleanResults = createAction(USER_CLEAN_RESULTS);

/**
 * userAppsAutocomplete - params: params(search)
 */
export const userAppsAutocomplete = createAction(USER_APPS_AUTOCOMPLETE);

/**
 * userGetApps - params
 */
export const userGetApps = createAction(USER_GET_APPS);

/**
 * userChangeAppPrice - data
 */
export const userChangeAppPrice = createAction(USER_CHANGE_APP_PRICE);

/**
 * userUpdateApps - apps(array)
 */
export const userUpdateApps = createAction(USER_UPDATE_APPS);

/**
 * userRemoveApp - app_id
 */
export const userRemoveApp = createAction(USER_REMOVE_APP);

/**
 * userGetRecommendations - params
 */
export const userGetRecommendations = createAction(USER_GET_RECOMMENDATIONS);

/**
 * userCleanRecommendations
 */
export const userCleanRecommendations = createAction(USER_CLEAN_RECOMMENDATIONS);

/**
 * userUpdateFirstName - first_name
 */
export const userUpdateFirstName = createAction(USER_UPDATE_FIRST_NAME);

export const userGetContentCount = createAction(USER_GET_CONTENT_COUNT);

/**
 * userUpdateContentCount - data(object)
 */
export const userUpdateContentCount = createAction(USER_UPDATE_CONTENT_COUNT);

/**
 * userGetRecommendedApps - {}
 */
export const userGetRecommendedApps = createAction(USER_GET_RECOMMENDED_APPS);

/**
 * userGetCards - params: void
 */
export const userGetCards = createAction(USER_GET_CARDS);

/**
 * userSetDefaultCard - payment_method_id
 */
export const userSetDefaultCard = createAction(USER_SET_DEFAULT_CARD);

/**
 * userGetSRWidgetData - params: void
 */
export const userGetSRWidgetData = createAction(USER_STREAMING_REWARD_WIDGET_GET);

/**
 * userUpdateSRBalance - params: void
 */
export const userUpdateSRBalance = createAction(USER_STREAMING_REWARD_BALANCE_UPDATE);

/**
 * userAddCard - params: stripe, card, zipcode, callback
 */
export const userAddCard = createAction(USER_ADD_CARD);

/**
 * userAddPaymentMethod - confirmSetup, defaultPM, onSuccess, onPending
 */
export const userAddPaymentMethod = createAction(USER_ADD_PAYMENT_METHOD);

/**
 * retrievePaymentMethodStatus - params - payment_method_id
 */
export const retrievePaymentMethodStatus = createAction(USER_RETRIEVE_PAYMENT_METHOD_STATUS);

/**
 * userRemovePaymentMethod - card_id, last4
 */
export const userRemovePaymentMethod = createAction(USER_REMOVE_PAYMENT_METHOD);

/**
 * userGetMyAppsCollections
 */
export const userGetMyAppsCollections = createAction(USER_GET_MY_APPS_COLLECTIONS);

/**
 * getIOByUniqueId - unique_id
 */
export const getIOByUniqueId = createAction(USER_GET_IO_BY_UNIQUE_ID);

/**
 * changePassword - params: data, callback(optional)
 */
export const userChangePassword = createAction(USER_CHANGE_PASSWORD);

export default {
  userEditProfile,
  userGetResults,
  userCleanResults,
  userAppsAutocomplete,
  userGetApps,
  userChangeAppPrice,
  userUpdateApps,
  userRemoveApp,
  userGetRecommendations,
  userCleanRecommendations,
  userUpdateFirstName,
  userGetContentCount,
  userUpdateContentCount,
  userGetRecommendedApps,
  userGetCards,
  userSetDefaultCard,
  userAddCard,
  userAddPaymentMethod,
  retrievePaymentMethodStatus,
  userRemovePaymentMethod,
  userGetSRWidgetData,
  userGetMyAppsCollections,
  getIOByUniqueId,
  userChangePassword,
  userUpdateSRBalance,
};
