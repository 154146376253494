import { css } from 'styled-components';

const frontierStyles = theme => css`
  @font-face {
    font-display: fallback;
    font-family: MovotoDMSans;
    font-style: normal;
    font-weight: 300;
    src: local('DMSans'), local('DMSans-Regular'), local('sans-serif'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/DMSans-Regular.woff2) format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: MovotoDMSans;
    font-style: normal;
    font-weight: 400;
    src: local('DMSans'), local('DMSans-Medium'), local('sans-serif'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/DMSans-Medium.woff2) format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: MovotoDMSans;
    font-style: normal;
    font-weight: 700;
    src: local('DMSans'), local('DMSans-Bold'), local('sans-serif'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/DMSans-Bold.woff2) format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: TWKLausanne;
    font-style: normal;
    font-weight: 300;
    src: local('TWKLausanne-300'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/TWKLausanne-300.woff2) format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: TWKLausanne;
    font-style: normal;
    font-weight: 400;
    src: local('TWKLausanne-500'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/TWKLausanne-500.woff2) format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: TWKLausanne;
    font-style: normal;
    font-weight: 700;
    src: local('TWKLausanne-750'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/TWKLausanne-750.woff2) format('woff2');
  }

  @font-face {
    font-display: block;
    font-family: fonticon;
    font-style: 300;
    font-weight: 300;
    src: url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/fonticon.ttf) format('truetype'),
      url(https://pi2.movoto.com/1.9.41/css/vstyles/fonts/fonticon.woff) format('woff'),
      url(https://pi2.movoto.com/1.9.41/novaimgs/vimages/fonticon.svg#fonticon) format('svg');
  }

  body:has(.color-schema--partner1):has(.formpage) {
    background-color: ${theme?.partner_1_bgcolor} !important;
    font-family: ${theme?.partner_1_fonts} !important;

    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_1_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_1_bgcolor} !important;
    }
  }
  .color-schema--partner1 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_1_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_1_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_1_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_1_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_1_color_1} !important ;
          background-color: ${theme?.partner_1_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner2):has(.formpage) {
    background-color: ${theme?.partner_2_bgcolor} !important;
    font-family: ${theme?.partner_2_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_2_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_2_bgcolor} !important;
    }
  }
  .color-schema--partner2 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_2_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_2_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_2_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_2_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_2_color_1} !important ;
          background-color: ${theme?.partner_2_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner3):has(.formpage) {
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_3_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_3_bgcolor} !important;
    }
  }
  .color-schema--partner3 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_3_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_3_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_3_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_3_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_3_color_1} !important ;
          background-color: ${theme?.partner_3_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner4):has(.formpage) {
    background-color: ${theme?.partner_4_bgcolor} !important;
    font-family: ${theme?.partner_4_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_4_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_4_bgcolor} !important;
    }
  }
  .color-schema--partner4 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_4_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_4_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_4_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_4_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_4_color_1} !important ;
          background-color: ${theme?.partner_4_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner5):has(.formpage) {
    background-color: ${theme?.partner_5_bgcolor} !important;
    font-family: ${theme?.partner_5_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_5_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_5_bgcolor} !important;
    }
  }
  .color-schema--partner5 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_5_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_5_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_5_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_5_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_5_color_1} !important ;
          background-color: ${theme?.partner_5_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner6):has(.formpage) {
    background-color: ${theme?.partner_6_bgcolor} !important;
    font-family: ${theme?.partner_6_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_6_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_6_bgcolor} !important;
    }
  }
  .color-schema--partner6 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_6_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_6_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_6_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_6_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_6_color_1} !important ;
          background-color: ${theme?.partner_6_color_1} !important ;
        }
      }
    }
  }

  body:has(.color-schema--partner7):has(.formpage) {
    background-color: ${theme?.partner_7_bgcolor} !important;
    font-family: ${theme?.partner_7_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_7_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_7_bgcolor} !important;
    }
  }
  .color-schema--partner7 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_7_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_7_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_7_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_7_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_7_color_1} !important ;
          background-color: ${theme?.partner_7_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner8):has(.formpage) {
    background-color: ${theme?.partner_8_bgcolor} !important;
    font-family: ${theme?.partner_8_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_8_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_8_bgcolor} !important;
    }
  }
  .color-schema--partner8 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_8_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_8_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_8_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_8_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_8_color_1} !important ;
          background-color: ${theme?.partner_8_color_1} !important ;
        }
      }
    }
  }

  body:has(.color-schema--partner9):has(.formpage) {
    background-color: ${theme?.partner_9_bgcolor} !important;
    font-family: ${theme?.partner_9_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_9_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_9_bgcolor} !important;
    }
  }
  .color-schema--partner9 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_9_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_9_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_9_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_9_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_9_color_1} !important ;
          background-color: ${theme?.partner_9_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner10):has(.formpage) {
    background-color: ${theme?.partner_10_bgcolor} !important;
    font-family: ${theme?.partner_10_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_10_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_10_bgcolor} !important;
    }
  }
  .color-schema--partner10 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_10_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_10_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_10_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_10_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_10_color_1} !important ;
          background-color: ${theme?.partner_10_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner11):has(.formpage) {
    background-color: ${theme?.partner_11_bgcolor} !important;
    font-family: ${theme?.partner_11_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_11_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_11_bgcolor} !important;
    }
  }
  .color-schema--partner11 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_11_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_11_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_11_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_11_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_11_color_1} !important ;
          background-color: ${theme?.partner_11_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner12):has(.formpage) {
    background-color: ${theme?.partner_12_bgcolor} !important;
    font-family: ${theme?.partner_12_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_12_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_12_bgcolor} !important;
    }
  }
  .color-schema--partner12 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_12_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_12_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_12_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_12_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_12_color_1} !important ;
          background-color: ${theme?.partner_12_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner13):has(.formpage) {
    background-color: ${theme?.partner_13_bgcolor} !important;
    font-family: ${theme?.partner_13_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_13_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_13_bgcolor} !important;
    }
  }
  .color-schema--partner13 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_13_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_13_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_13_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_13_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_13_color_1} !important ;
          background-color: ${theme?.partner_13_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner14):has(.formpage) {
    background-color: ${theme?.partner_14_bgcolor} !important;
    font-family: ${theme?.partner_14_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_14_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_14_bgcolor} !important;
    }
  }
  .color-schema--partner14 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_14_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_14_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_14_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_14_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_14_color_1} !important ;
          background-color: ${theme?.partner_14_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner15):has(.formpage) {
    background-color: ${theme?.partner_15_bgcolor} !important;
    font-family: ${theme?.partner_15_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_15_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_15_bgcolor} !important;
    }
  }
  .color-schema--partner15 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_15_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_15_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_15_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_15_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_15_color_1} !important ;
          background-color: ${theme?.partner_15_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner16):has(.formpage) {
    background-color: ${theme?.partner_16_bgcolor} !important;
    font-family: ${theme?.partner_16_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_16_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_16_bgcolor} !important;
    }
  }
  .color-schema--partner16 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_16_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_16_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_16_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_16_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_16_color_1} !important ;
          background-color: ${theme?.partner_16_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner17):has(.formpage) {
    background-color: ${theme?.partner_17_bgcolor} !important;
    font-family: ${theme?.partner_17_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_17_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_17_bgcolor} !important;
    }
  }
  .color-schema--partner17 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_17_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_17_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_17_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_17_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_17_color_1} !important ;
          background-color: ${theme?.partner_17_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner18):has(.formpage) {
    background-color: ${theme?.partner_18_bgcolor} !important;
    font-family: ${theme?.partner_18_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_18_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_18_bgcolor} !important;
    }
  }
  .color-schema--partner18 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_18_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_18_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_18_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_18_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_18_color_1} !important ;
          background-color: ${theme?.partner_18_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner19):has(.formpage) {
    background-color: ${theme?.partner_19_bgcolor} !important;
    font-family: ${theme?.partner_19_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_19_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_19_bgcolor} !important;
    }
  }
  .color-schema--partner19 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_19_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_19_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_19_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_19_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_19_color_1} !important ;
          background-color: ${theme?.partner_19_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner20):has(.formpage) {
    background-color: ${theme?.partner_20_bgcolor} !important;
    font-family: ${theme?.partner_20_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_20_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_20_bgcolor} !important;
    }
  }
  .color-schema--partner20 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_20_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_20_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_20_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_20_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_20_color_1} !important ;
          background-color: ${theme?.partner_20_color_1} !important ;
        }
      }
    }
  }
  body:has(.color-schema--partner21):has(.formpage) {
    background-color: ${theme?.partner_21_bgcolor} !important;
    font-family: ${theme?.partner_21_fonts} !important;
    .MuiTypography-h4,
    .MuiTypography-subtitle1,
    .MuiTypography-h4,
    .MuiButton-root,
    .MuiInputBase-input {
      font-family: ${theme?.partner_21_fonts} !important;
    }
    footer {
      background-color: ${theme?.partner_21_bgcolor} !important;
    }
  }
  .color-schema--partner21 {
    .formpage {
      .MuiButton-containedPrimary,
      .radio-card--checked:before,
      .checkbox-card--checked:before {
        background-color: ${theme?.partner_21_color_1} !important ;
      }
      .radio-card--checked:after,
      .checkbox-card--checked:after {
        box-shadow: 0 0 0 2px ${theme?.partner_21_color_1} !important ;
      }
      .radio-card--checked .radio-card__title,
      .checkbox-card--checked .checkbox-card__title,
      .text-color--primary,
      [class*='MuiCircularProgress'],
      .MuiFormLabel-root.Mui-focused {
        color: ${theme?.partner_21_color_1} !important ;
      }
      .radio-card:hover,
      .checkbox-card:hover,
      .checkbox-card:active,
      .radio-card:active,
      [class*='MuiOutlinedInput'] {
        border-color: ${theme?.partner_21_color_1} !important ;
      }
      .marker {
        &--el,
        &:before {
          color: ${theme?.partner_21_color_1} !important ;
          background-color: ${theme?.partner_21_color_1} !important ;
        }
      }
    }
  }
`;

/*
partner_1_color_1
partner_1_color_2
partner_1_bgcolor

*/
export default frontierStyles;
