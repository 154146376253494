import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// STORE
import { AuthThirdPartyActions, UserActions } from '@/store/actions';

// CONSTANTS
import { AUTH_REGISTER_CURIOSITY_STREAM } from '@/constants/api';

// UTILITY
import getErrorMessage from '@/utility/errors';
import { showErrorNotification } from '@/utility/saga';

const { userGetContentCount } = UserActions;

const { registerCuriosityStreamUser } = AuthThirdPartyActions;

export function* registerCuriosityStreamSaga(action) {
  yield put(registerCuriosityStreamUser.start());

  const { data, callback } = action.payload;

  try {
    yield axios.post(AUTH_REGISTER_CURIOSITY_STREAM, data);

    yield put(registerCuriosityStreamUser.success());

    yield put(userGetContentCount.init());

    if (callback) callback();
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(registerCuriosityStreamUser.fail({ errorMessage }));

    yield call(showErrorNotification, error);
  }
}

export default registerCuriosityStreamSaga;
