import produce from 'immer';

import {
  getResults,
  addEmailToResults,
  sendTokenForGetResults,
  getAppByIds,
  getChannelsByServicesIds,
  clearChannelsByServicesIds,
} from '../actions/results';

const initialState = {
  results: {},
  emailSent: false,
  apps: [],
  channels: [],
  loadings: {
    results: false,
    addEmail: false,
    sendToken: false,
    apps: false,
    channels: true,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET RESULTS
    case getResults.types.START:
      state.results = {};
      state.loadings.results = true;
      return state;

    case getResults.types.SUCCESS:
      state.results = action.payload.data;
      state.loadings.results = false;
      return state;

    case getResults.types.FAIL:
      state.loadings.results = false;
      return state;

    // ADD EMAIL
    case addEmailToResults.types.START:
      state.loadings.addEmail = true;
      return state;

    case addEmailToResults.types.SUCCESS:
    case addEmailToResults.types.FAIL:
      state.loadings.addEmail = false;
      return state;

    // SEND TOKEN FOR GET RESULTS
    case sendTokenForGetResults.types.START:
      state.emailSent = false;
      state.loadings.sendToken = true;
      return state;

    case sendTokenForGetResults.types.SUCCESS:
      state.emailSent = true;
      state.loadings.sendToken = false;
      return state;

    case sendTokenForGetResults.types.FAIL:
      state.loadings.sendToken = false;
      return state;

    // GET APP BY IDS
    case getAppByIds.types.START:
      state.loadings.apps = true;
      return state;

    case getAppByIds.types.SUCCESS:
      state.apps = action.payload.data;
      state.loadings.apps = false;
      return state;

    case getAppByIds.types.FAIL:
      state.loadings.apps = false;
      return state;

    // GET SERVICES CHANNELS BY IDS
    case getChannelsByServicesIds.types.START:
      state.loadings.channels = true;
      return state;

    case getChannelsByServicesIds.types.SUCCESS:
      state.channels = action.payload.channels;
      state.loadings.channels = false;
      return state;

    case getChannelsByServicesIds.types.FAIL:
      state.loadings.channels = false;
      state.channels = initialState.channels;
      return state;

    // CLEAR SERVICES CHANNELS BY IDS
    case clearChannelsByServicesIds.types.INIT:
      state.loadings.channels = initialState.loadings.channels;
      state.channels = initialState.channels;
      return state;

    default:
      return state;
  }
});

export default reducer;
