import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import { SBP_GET_PACKAGE } from '@/constants/api';

// UTILITY
import { getSerializedParams } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { SlingBestPackageActions } from '@/store/actions';

const { getSlingBestPackage } = SlingBestPackageActions;

export function* getSlingBestPackageSaga(action) {
  yield put(getSlingBestPackage.start());

  const { params } = action.payload;
  const serializedParams = getSerializedParams(params || {});

  try {
    const { data: respData } = yield axios.get(SBP_GET_PACKAGE, serializedParams);

    yield put(
      getSlingBestPackage.success({
        data: respData,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getSlingBestPackage.fail());
  }
}

export default getSlingBestPackageSaga;
