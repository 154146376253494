import styled from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import {
  WHITE,
  BLACK,
  BLACK_2,
  MINE_SHAFT,
  EMPEROR,
  COD_GRAY,
  MAIN_BLACK,
} from '@/constants/colors';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

const animationTime = '1s';

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 394px;
  transition: max-width ${animationTime};
  z-index: 1;

  .virtual-card {
    position: relative;
    text-align: left;
    background: ${BLACK_2};
    background: linear-gradient(
      107.6deg,
      ${rgba(BLACK_2, 0.93)} 13.11%,
      ${rgba(MINE_SHAFT, 0.97)} 35.37%,
      ${EMPEROR} 60.32%,
      ${rgba(COD_GRAY, 0.88)} 88.07%
    );

    box-shadow: 0 20px 40px 0 ${rgba(BLACK, 0.15)};
    border-radius: 16px;
    overflow: hidden;

    input {
      cursor: pointer;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      padding: 20px 24px;
      color: ${WHITE};
      line-height: 1;

      .MuiSvgIcon-root {
        height: 32px;
        width: 32px;
      }

      .MuiInputAdornment-root {
        height: 23px;
      }
    }

    &__content {
      position: relative;
      padding: 16px 24px;
      transition: padding ${animationTime};
      z-index: 2;

      .MuiInputAdornment-root {
        opacity: 0;
      }

      .MuiFormControl-root {
        &:hover {
          .MuiInputAdornment-root {
            opacity: 1;
          }
        }
      }
    }

    &__mb-icon {
      display: none;
    }

    .MuiFormLabel-root {
      white-space: nowrap;
    }

    .MuiFormLabel-root.MuiInputLabel-root,
    .MuiInputBase-root,
    .MuiSvgIcon-root {
      color: ${WHITE};
      background-color: transparent;

      input,
      label {
        color: ${WHITE};
      }
    }

    .MuiInput-underline.Mui-disabled:before {
      border: 0;
    }

    .MuiInputBase-root {
      font-size: 1.4rem;
      font-weight: 600;
    }

    .MuiInputBase-input {
      padding: 10px 0;
      /* 3 characters min-width for input */
      min-width: 3ch;
    }

    .MuiInputAdornment-root {
      margin-right: -8px;
      margin-left: -8px;
    }

    .billing {
      color: ${WHITE};
      margin-top: 10px;

      &:hover {
        .billing__copy-icon {
          opacity: 1;
        }
      }

      &__text {
        white-space: pre-line;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 400;
        cursor: pointer;
      }

      &__icon {
        width: 40px;
        height: 40px;

        margin: auto 0 -8px auto;
      }

      &__place-icon {
        width: 40px;
        height: 40px;
        background-color: ${rgba(WHITE, 0.2)};
        padding: 10px;
        border-radius: 50%;
        line-height: 1;
      }

      &__label {
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
      }

      &__copy-icon {
        opacity: 0;
        padding: 4px 8px;
      }
    }

    .save-to-wallet-form {
      position: absolute;
      left: 100%;
      bottom: 0;
      width: 100%;
      margin-left: 15px;

      ${breakpoints.up(1380)} {
        margin-left: 30px;
      }

      button {
        ${breakpoints.down(1380)} {
          &.MuiButton-root {
            padding: 1.3rem;
            min-width: auto;
          }

          .btn__text {
            display: none;
          }
        }
      }
    }

    &.virtual-card--small {
      .virtual-card {
        &__title {
          padding: 12px;

          .MuiSvgIcon-root {
            height: 25px;
            width: 25px;
          }
        }

        &__content {
          padding: 12px;

          .MuiInputAdornment-root {
            opacity: 1;
          }
          .MuiInputAdornment-root {
            opacity: 1;
          }
        }

        &__mb-icon {
          max-height: 120px;
        }
      }

      .billing {
        margin-top: 0;

        &__text {
          margin-left: -8px;
        }

        &__icon {
          margin: auto 0 -8px auto;
        }

        &__place-icon {
          display: none;
        }

        &__copy-icon {
          opacity: 1;
          padding: 4px;
        }
      }
    }
  }

  &.card-container {
    &--wide {
      max-width: 800px;

      .virtual-card {
        display: flex;
        background: linear-gradient(
          107.6deg,
          ${rgba(BLACK_2, 0.73)} 12.77%,
          ${rgba(MINE_SHAFT, 0.97)} 35.06%,
          ${rgba(EMPEROR, 0.87)} 60.27%,
          ${rgba(COD_GRAY, 0.88)} 88.37%
        );

        &__title {
          position: absolute;
          right: 0;
          height: 100%;
          padding: 5px;
          align-items: center;
          justify-content: center;
          width: 48px;
          border-radius: 0 16px 16px 0;
          background-color: ${rgba(MAIN_BLACK, 0.43)};

          h6 {
            display: none;
          }

          .MuiSvgIcon-root {
            height: 30px;
            width: 30px;
          }
        }

        &__content {
          position: static;
          max-width: calc(60% - 20px);
          padding: 25px 0 25px 20px;

          ${breakpoints.down(860)} {
            max-width: 60%;
          }
        }

        &__mb-icon {
          display: block;
          width: 43px;
          padding: 25px 0 25px 20px;
          font-size: 2.4rem;
          color: ${WHITE};

          ${breakpoints.down(860)} {
            display: none;
          }
        }

        &__billing {
          position: absolute;
          top: 12px;
          right: 0;
          max-width: calc(38% - 48px);
          margin-right: 48px;

          .billing {
            margin-top: 0;

            &__icon-container {
              ${breakpoints.down(760)} {
                display: none;
              }
            }

            &__copy-icon {
              position: absolute;
              top: 8px;
              right: 0;
            }
          }
        }

        .MuiInput-underline.Mui-disabled:before {
          border-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: ${WHITE};
        }
      }

      .billing__icon-wrap {
        display: none;
      }
    }

    &--withoutCopy {
      .virtual-card {
        input,
        .billing__text {
          cursor: default;
        }
      }
    }
  }
`;

export default Container;
