import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

// STORE
import { AppActions } from '@/store/actions';

// COMPONENTS
import MyBundleHeader from '@/components/Headers/MyBundleHeader';
import PartnerHeader, { HEADER_TRANSITION_TIME } from '@/components/Headers/PartnerHeader';
import PartnerFakeHeader from '@/components/Headers/PartnerFakeHeader';
import QuantumHeader from '@/components/Headers/QuantumHeader';
import useActions from '@/hooks/useActions';

const Header = props => {
  const { isInAppView, partner, partnerSettings, partnerLoading } = useSelector(
    ({ app, partner: partnerStore }) => ({
      isInAppView: app.isInAppView,
      partner: partnerStore.data,
      partnerSettings: partnerStore.data?.settings || {},
      partnerLoading: partnerStore.loadings.partner,
    }),
  );
  const [setHeaderHeight] = useActions([
    headerHeight => AppActions.setHeaderHeight.action({ headerHeight }),
  ]);
  const ref = useRef();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(([entry]) => {
        if (entry) {
          const rect = entry.contentRect;

          setHeaderHeight(rect.height);
        }
      }, HEADER_TRANSITION_TIME + 200),
    );

    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();

      setHeaderHeight(height);

      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  if (partnerLoading || isInAppView) {
    return null;
  }

  if (partner && partner.slug && partner.slug !== 'start-now') {
    if (partnerSettings.use_custom_header) {
      switch (partner.slug) {
        case 'quantum':
          return <QuantumHeader ref={ref} />;

        default:
          break;
      }
    }

    if (partnerSettings.is_fake) {
      return <PartnerFakeHeader {...props} ref={ref} />;
    }

    return <PartnerHeader {...props} ref={ref} />;
  }

  return <MyBundleHeader {...props} innerRef={ref} />;
};

Header.defaultProps = {
  mainNavigation: true,
  backPartnerLink: true,
};

Header.propTypes = {
  mainNavigation: PropTypes.bool,
  backPartnerLink: PropTypes.bool,
};

export default React.memo(Header);
