import styled from 'styled-components';
import { rgba } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// CONSTANTS
import { WHITE, BLACK } from '@/constants/colors';

// UTILITY
import breakpoints from '@/utility/breakpoints';

export const HEADER_TRANSITION_TIME = 400;

// HEADER
export const Wrapper = styled.div`
  .header,
  &.header {
    width: 100%;

    &--shrunk {
      .logo-partner {
        img {
          max-height: 32px;
        }
      }
    }

    &__app-bar {
      background-color: ${WHITE};
      color: ${p => p.theme.palette.text.primary};
      z-index: ${p => p.theme.zIndex.drawer + 20};

      &--landscape {
        .header__burger-button {
          margin-top: 50px;
          z-index: 1212;
          position: fixed;
          top: 22px;
          left: 14px;
        }

        .logo-partner {
          margin-left: 46px;
        }
      }
    }

    &__toolbar {
      height: 90px;

      transition: all ${HEADER_TRANSITION_TIME}ms ease;

      &--main {
        display: flex;
        align-items: center;

        color: inherit;

        width: 100%;
        max-width: 1440px;

        margin: 0 auto;
        padding: 0 30px;

        ${breakpoints.down('md')} {
          padding: 0 15px;
        }

        ${breakpoints.down('sm')} {
          justify-content: space-between;
        }
      }

      &--min {
        height: 60px;
        min-height: 60px;

        .header {
          &__burger-button,
          &__search-button-container button,
          &__profile-buttons .profile-button,
          &__profile-buttons .balance-button {
            width: 44px;
            min-width: 44px;
            height: 44px;

            ${breakpoints.down(375)} {
              width: 40px;
              min-width: 40px;
              height: 40px;
            }
          }
        }
      }

      ${breakpoints.up(1440)} {
        .header__profile-buttons {
          .balance-button {
            margin-right: 16px;
          }
        }
      }

      &--fake {
      }
    }

    &__toolbar-partner {
      height: 60px;
      background-color: ${p => p.theme.palette.subheader.background};
      color: ${p => p.theme.palette.subheader.color};
      position: relative;

      transition: all ${HEADER_TRANSITION_TIME}ms ease;

      &::after,
      &::before {
        background-color: ${p => p.theme.palette.subheader.background};
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
      }

      &::after {
        right: 100%;
      }
      &::before {
        left: 100%;
      }

      &--main {
        width: 100%;
        max-width: 1440px;

        margin: 0 auto;
        padding: 0 30px;
        ${breakpoints.down('md')} {
          padding: 0 15px;
        }
      }

      &--fake {
      }

      &--min {
        height: 44px;
        min-height: 44px;

        & > .MuiGrid-container {
          margin: -6px;

          .MuiGrid-item {
            padding: 6px;
          }
        }
      }
    }

    &__burger-button {
      color: ${p => p.theme.palette.primary.main};
      margin-right: 10px;
      margin-left: 0;
      font-size: 22px;
    }

    &__desktop-nav {
      margin: 0 0 0 60px;

      padding: 0;

      ${breakpoints.down('md')} {
        margin: 0 0 0 30px;
      }

      a {
        ${breakpoints.down('md')} {
          margin-right: 15px;
        }
      }
    }

    &__search-container {
      margin-left: auto;
      padding-left: 16px;
      width: 100%;

      ${breakpoints.up('md')} {
        max-width: 400px;
      }
    }

    &__search-button-container {
      margin-right: 0 !important;
    }

    &__search-button {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      width: 50px;
      min-width: 50px;
      height: 50px;

      padding: 0 !important;

      border: none;

      ${breakpoints.down('sm')} {
        font-size: 26px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      width: 100%;

      ${breakpoints.down('sm')} {
        margin-left: 0;
        min-width: 50px;
        width: auto;
      }

      > *:not(:last-child) {
        margin-right: 16px;
      }

      &-signup {
        ${breakpoints.down('md')} {
          display: none;
        }
      }
    }

    &__profile-buttons {
      display: flex;
      align-items: center;

      .balance-button {
        margin-right: 5px;
      }

      .profile-button {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;

        width: 50px;
        min-width: 50px;

        padding: 0 !important;
      }
    }

    &__auth-button {
      margin-left: auto;

      color: inherit;
      transition: color 0.3s ease;
      cursor: pointer;

      &:hover {
        color: ${p => p.theme.palette.primary.main};
      }
    }

    &__logout-button {
      color: ${p => p.theme.palette.error.main};
      transition: color 0.3s ease;
      text-transform: capitalize;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: ${p => p.theme.palette.primary.main};
        color: ${p => p.theme.palette.primary.contrastText};
      }
    }

    &__back-to-button {
      white-space: nowrap;
    }

    .logo-partner {
      img {
        max-width: 175px;
      }
    }
  }
`;

// HEADER DESKTOP MENU
export const ListDesktop = styled.ul`
  margin: 0 0 0 60px;
  padding: 0;
`;

export const DesktopLink = styled(Link)`
  position: relative;
  display: inline-block;
  color: inherit;
  transition: color 0.3s ease;
  margin-right: 15px;
  line-height: 35px;

  &:hover,
  &.active {
    color: ${p => p.theme.palette.primary.main};
  }

  &.nav-link,
  .nav-link {
    &__icon {
      font-size: 2rem;
      vertical-align: sub;
      transition: transform 0.3s ease-in-out;
    }

    &--with-subnav {
      cursor: default;
    }

    &:hover {
      .subnav {
        opacity: 1;
        visibility: visible;
      }

      .nav-link__icon {
        transform: rotate(180deg);
      }
    }
  }

  .subnav {
    position: absolute;
    top: 100%;
    left: -14px;
    min-width: 220px;
    border-radius: 3px;
    box-shadow: 0 10px 30px ${rgba(BLACK, 0.1)};
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &,
    ul {
      outline: none;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  // Drawer Paper
  drawerPaper: {
    width: 300,

    '& .drawer-buttons': {
      paddingTop: 14,
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
}));
