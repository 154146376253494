import * as Yup from 'yup';

// CONST
import { MAX_PASSWORD_LENGTH_EPIX, MIN_PASSWORD_LENGTH } from '@/constants/validation';

export default (t, { validationSlug }) => {
  const maxPasswordLength = validationSlug === 'epix' ? MAX_PASSWORD_LENGTH_EPIX : Infinity;

  return {
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .min(MIN_PASSWORD_LENGTH, t('validation.passwordLength', { length: MIN_PASSWORD_LENGTH }))
      .max(
        maxPasswordLength,
        t('validation.passwordLengthMax', { length: MAX_PASSWORD_LENGTH_EPIX }),
      ),

    terms: Yup.boolean().oneOf([true], 'Must agree to something'),
  };
};
