import { css } from 'styled-components';

const vermontelStyles = theme => css`
  .vermontel {
    .partner-home-page {
      .welcome {
        background-color: white;
        &:before {
          background-color: white;
        }
        .welcome__title,
        .welcome__subtitle,
        .welcome__left .subtitle-secondary,
        .welcome__left h2.MuiTypography-root.m-b-30 {
          color: ${theme?.primary};
        }
        .MuiButton-containedPrimary {
          background-color: #ff6600;
          text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
        }
      }
    }
  }
`;

export default vermontelStyles;
