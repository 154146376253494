import { createAction } from '@/utility/actions';
import getErrorMessage from '@/utility/errors';

import {
  NOTIFICATION_POP_NOTIFY,
  NOTIFICATION_PUSH_NOTIFY,
  NOTIFICATION_SET_OPTIONS,
  NOTIFICATION_CLEAR_OPTIONS,
} from './types';

/**
 * popNotification - params: key
 */
export const popNotification = createAction(NOTIFICATION_POP_NOTIFY);

/**
 * pushNotification - params: options
 */
export const pushNotification = createAction(NOTIFICATION_PUSH_NOTIFY);

/**
 * pushErrorNotificationAction - params: error, options
 */
export const pushErrorNotificationAction = (error, options = {}) => {
  return pushNotification.action({
    message: getErrorMessage(error),
    options: {
      variant: 'error',
      ...options,
    },
  });
};

/**
 * pushSuccessNotificationAction - params: message, options
 */
export const pushSuccessNotificationAction = (message, options = {}) =>
  pushNotification.action({
    message,
    options: {
      variant: 'success',
      ...options,
    },
  });

/**
 * setNotificationOptions - params: options
 */
export const setNotificationOptions = createAction(NOTIFICATION_SET_OPTIONS);

/**
 * clearNotificationOptions - params: options
 */
export const clearNotificationOptions = createAction(NOTIFICATION_CLEAR_OPTIONS);

export default {
  popNotification,
  pushNotification,
  pushErrorNotificationAction,
  pushSuccessNotificationAction,
  setNotificationOptions,
  clearNotificationOptions,
};
