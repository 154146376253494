import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const hasFirebaseKey = !!firebaseConfig.apiKey;

const app = hasFirebaseKey && initializeApp(firebaseConfig);
export const auth = !!app && getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
export const signInWithGoogle = async (redirect = false) => {
  if (!(auth || googleProvider)) return null;

  // To select account each time need to set "select_account" to provider
  // .setCustomParameters({
  //       prompt: 'select_account',
  //     }),

  if (redirect) {
    return signInWithRedirect(auth, googleProvider);
  }

  return signInWithPopup(auth, googleProvider);
};
export const signInWithFacebook = async (redirect = false) => {
  if (!(auth || facebookProvider)) return null;

  if (redirect) {
    return signInWithRedirect(auth, facebookProvider.addScope('public_profile,email'));
  }

  return signInWithPopup(auth, facebookProvider.addScope('public_profile,email'));
};
export const signInWithApple = (redirect = false) => {
  if (!(auth || appleProvider)) return null;

  if (redirect) {
    return signInWithRedirect(auth, appleProvider.addScope('email'));
  }

  return signInWithPopup(auth, appleProvider.addScope('email'));
};

export const getFirebaseAuthResult = () => {
  if (!auth) return null;

  return getRedirectResult(auth);
};

export const getSocialSignInFnByProvider = (providerId, redirect = false) => {
  switch (providerId) {
    case 'google':
      return () => signInWithGoogle(redirect);
    case 'facebook':
      return () => signInWithFacebook(redirect);
    case 'apple':
      return () => signInWithApple(redirect);
    default:
      return null;
  }
};
