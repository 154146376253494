import produce from 'immer';

import { getPagesContent } from '../actions/pages';

const initialState = {
  content: {},
  loading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET PAGE CONTENT
    case getPagesContent.types.START:
      state.loading = true;
      return state;

    case getPagesContent.types.SUCCESS:
      state.content = { ...state.content, ...action.payload.data };
      state.loading = false;
      return state;

    case getPagesContent.types.FAIL:
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
