import { css } from 'styled-components';

const glofiberStyles = css`
  .resultspage .internet-option {
    background: linear-gradient(to right, #6f4d9f, #6f4d9f, #6f4d9f, #bb90c5);
    color: #ffffff;
    .internet-option__content {
      background: none;
    }
    .internet-option__content {
      background: none;
      .markdown {
        color: #ffffff;
        font-weight: normal;
      }
      .internet-option__price {
        display: none !important;
      }
      .internet-option__image {
        min-width: 240px;
      }
    }
  }
`;

export default glofiberStyles;
