import { takeEvery, takeLatest, fork, spawn } from 'redux-saga/effects';

// Action Types
import {
  AnalyticsActions,
  AppActions,
  AuthActions,
  AuthThirdPartyActions,
  UserActions,
  PagesActions,
  WizardActions,
  CountiesActions,
  ChannelActions,
  ChannelsActions,
  ResultsActions,
  DiscoverActions,
  AppDetailsActions,
  WPBlogActions,
  PlanDetailsActions,
  MovieDetailsActions,
  ShowDetailsActions,
  InternetOptionActions,
  PartnerActions,
  InternetOffersActions,
  WatchNextActions,
  WatchListActions,
  OnboardingActions,
  SubscriptionActions,
  ShowsAndMoviesActions,
  HomeActions,
  SwimlanesActions,
  PromotionActions,
  PromotionsActions,
  EmmyAwardsActions,
  SearchActions,
  ConfirmationISPActions,
  StreamingRewardsActions,
  ShareActions,
  PaymentHistoryActions,
  VirtualCardBalanceActions,
  StreamingRewardsFundsActions,
  ChannelLineupActions,
  SlingBestPackageActions,
  ContentActions,
} from '@/store/actions';

// Saga functions
import { analyticsSendToFivetranSaga, analyticsSendToMailchimpSaga } from './analytics';
import {
  healthCheckSaga,
  emailUnsubscribeSaga,
  createFeedbackSaga,
  getAppSearchSaga,
  getDictionarySaga,
  requestDemoSaga,
  getDownloadAppLinkViaSMSSaga,
} from './app';
import {
  authCheckTokenSaga,
  registerSaga,
  authSaga,
  auth0Saga,
  authSocialLoginSaga,
  logoutSaga,
  resetPassSendEmailSaga,
  resetPassUpdateSaga,
  setUserToBugsnag,
} from './auth';
import { registerCuriosityStreamSaga } from './authThirdParty';
import {
  userEditProfileSaga,
  userGetResultsSaga,
  userAppsAutocompleteSaga,
  userGetAppsSaga,
  userGetRecommendationsSaga,
  userUpdateFirstNameSaga,
  userGetContentCountSaga,
  userChangeAppPriceSaga,
  userGetRecommendedAppsSaga,
  userAddCardSaga,
  userGetMyAppsCollectionsSaga,
  userChangePasswordSaga,
  userGetCardsSaga,
  userSetDefaultCardSaga,
  userGetSRWidgetDataSaga,
  userRemovePaymentMethodSaga,
  userAddPaymentMethodSaga,
  retrievePaymentMethodStatusSaga,
} from './user';
import { getPagesContentSaga } from './pages';
import { getSkipQuestionsSaga, getUniqueIdSaga, asyncInternetOptionsSaga } from './wizard';
import {
  getPartnerSaga,
  getPartnerByDomainSaga,
  getPartnerInternetOfferSaga,
  claimPartnerInternetOfferSaga,
} from './partner';
import { getCountiesSaga, getCountiesByAddressSaga } from './counties';
import { getChannelDetailsSaga, getChannelSwimlanesSaga } from './channel';
import {
  getChannelFiltersSaga,
  getChannelsSaga,
  getTopChannelsSaga,
  submitFormSaga,
  getFormDataSaga,
  channelsAutocompleteSaga,
} from './channels';
import {
  getResultsSaga,
  addEmailToResultsSaga,
  sentTokenForGetResultsSaga,
  getAppByIdsSaga,
  getChannelsByServicesIdsSaga,
} from './results';
import {
  getDiscoverListSaga,
  getDiscoverFiltersSaga,
  getDiscoverSearchSaga,
  getDiscoverCollectionsSaga,
} from './discover';
import {
  getAppDetailsSaga,
  getAppChannelsSaga,
  getAppReviewsSaga,
  addAppReviewSaga,
  getAppExtraLinkSaga,
  appListUpdateStatusSaga,
  appListRemoveStatusSaga,
} from './appDetails';
import {
  getWPInsightsSaga,
  getWPPostsSaga,
  getWPPostSaga,
  getWPRelatedPostsSaga,
  getWPAuthorSaga,
  getWPCategorySaga,
  getWPPageSaga,
  getWPCareersSaga,
  getWPNewsSaga,
} from './wpBlog';
import { getPlanDetailsSaga } from './planDetails';
import {
  getMovieDetailsSaga,
  getMovieStreamingDataSaga,
  subscribeToChangesSaga,
  getSimilarMoviesSaga,
} from './movieDetails';
import { getShowDetailsSaga, getShowStreamingDataSaga, getSimilarShowsSaga } from './showDetails';
import { getInternetOptionSaga } from './internetOption';
import { getLocationsSaga } from './locations';
import {
  getInternetOffersSaga,
  getInternetOffersByIdSaga,
  saveInternetOffersAnswersSaga,
  subscribeToInternetOffersSaga,
  checkInternetOffersSubscriptionSaga,
} from './internetOffers';
import {
  startWNFromSaga,
  submitWNFormSaga,
  getWNResultsSaga,
  getMoreWNShowsSaga,
  submitWNVoteSaga,
  addEmailToWNResultsSaga,
  suggestionsAutocompleteWNSaga,
  addWNSuggestionSaga,
  addToWNVotelistSaga,
  removeFromWNVotelistSaga,
  saveWNVotelistSaga,
  deleteWNVotelistSaga,
} from './watchNext';
import {
  addToWatchListSaga,
  addToDefWatchListSaga,
  removeFromWatchListSaga,
  watchListGetCollectionsSaga,
  getWatchListSaga,
  getWatchListContentSaga,
  copyWatchListContentSaga,
  getWatchlistsSaga,
  updateWatchListSaga,
  deleteWatchListSaga,
  createWatchListSaga,
  getContentAddNewWatchListSaga,
  addContentsToWatchListsSaga,
} from './watchList';
import {
  getShowsAndMoviesListSaga,
  getShowsAndMoviesFiltersSaga,
  getShowsAndMoviesSearchSaga,
  getShowsMoviesCollectionsSaga,
} from './showsAndMovies';
import {
  getOnboardingAppsSaga,
  onboardingCompleteSaga,
  onboardingStateWatchSaga,
} from './onboarding';
import {
  addSubscriptionPaymentSaga,
  addSubscriptionRegistrationSaga,
  cancelSubscriptionSaga,
  renewSubscriptionSaga,
  getSubscriptionDetailsSaga,
  getSubscriptionDictionarySaga,
  getSubscriptionPreviewSaga,
  getSubscriptionTransactionsSaga,
  subscriptionChangePasswordSaga,
  registerSubscriptionServiceSaga,
} from './subscription';
import {
  getHomeSectionSaga,
  getHomeAuthContentSaga,
  getHomeNonAuthContentSaga,
  getPartnerHomeAuthContentSaga,
} from './home';
import {
  swimlanesGetContentSaga,
  swimlanesGetSaga,
  swimlanesGetPopularContentSaga,
} from './swimlanes';
import {
  getPromoDetailsSaga,
  getPromoCodeSaga,
  checkPromoAuthCodeSaga,
  getPromoSwimlanesSaga,
} from './promotion';
import { getPromoContentSaga, getBlackFridayContentSaga } from './promotions';
import { getEmmyAwardsDetailsSaga } from './emmyAwards';
import { getSearchDataSaga } from './search';
import { getIPInfoSaga } from './ipApi';
import { confirmationISPSaga } from './confirmationISP';
import {
  getStreamingRewardsSaga,
  checkStreamingRewardsSaga,
  claimStreamingRewardsSaga,
  getVirtualCardExpandSaga,
  getSRClaimInstructionSaga,
  getSRImageAppsSaga,
  getSRAppsSearchSaga,
  completeRedeemSRSaga,
} from './streamingRewards';
import {
  payFundsSaga,
  cancelRecurringPaymentSaga,
  getSRClientSecretSaga,
  addSRFundsSaga,
  addSRFundsByPMSaga,
  retrievePaymentStatusSaga,
} from './streamingRewardsFunds';
import { getShareInfoSaga } from './share';
import { userGetStreamingRewardSaga, userGetPaymentHistorySaga } from './paymentHistory';
import { virtualCardAddProductSaga, getVirtualCardDataSaga } from './virtualCardBalance';
import {
  cLSearchSaga,
  getCLChannelsSaga,
  getCLDictionarySaga,
  getCLReplacementChannelsSaga,
  getCLTopChannelsSaga,
} from './channelLineup';
import { getSlingBestPackageSaga } from './slingBestPackage';
import { changeContentStatusSaga } from './content';

export default function* rootSaga() {
  yield spawn(getPartnerByDomainSaga);
  yield fork(getIPInfoSaga);

  yield fork(getLocationsSaga);
  yield fork(getDictionarySaga);
  yield fork(authCheckTokenSaga);
  yield fork(setUserToBugsnag);

  // ANALYTICS
  yield takeEvery(AnalyticsActions.analyticsSendToFivetran.types.INIT, analyticsSendToFivetranSaga);
  yield takeLatest(
    AnalyticsActions.analyticsSendToMailchimp.types.INIT,
    analyticsSendToMailchimpSaga,
  );

  // APP
  yield takeEvery(AppActions.healthCheck.types.INIT, healthCheckSaga);
  yield takeEvery(AppActions.emailUnsubscribe.types.INIT, emailUnsubscribeSaga);
  yield takeEvery(AppActions.createFeedback.types.INIT, createFeedbackSaga);
  yield takeLatest(AppActions.getAppSearch.types.INIT, getAppSearchSaga);
  yield takeEvery(AppActions.requestDemo.types.INIT, requestDemoSaga);
  yield takeEvery(AppActions.getDownloadAppLinkViaSMS.types.INIT, getDownloadAppLinkViaSMSSaga);

  // Auth
  yield takeEvery(AuthActions.login.types.INIT, authSaga);
  yield takeEvery(AuthActions.auth0Login.types.INIT, auth0Saga);
  yield takeEvery(AuthActions.authSocialLogin.types.INIT, authSocialLoginSaga);
  yield takeEvery(AuthActions.registerUser.types.INIT, registerSaga);
  yield takeEvery(AuthActions.logout.types.INIT, logoutSaga);
  yield takeEvery(AuthActions.resetPassSendEmail.types.INIT, resetPassSendEmailSaga);
  yield takeEvery(AuthActions.resetPassUpdate.types.INIT, resetPassUpdateSaga);
  yield takeEvery(AuthActions.setAnonymousId.types.INIT, setUserToBugsnag);

  // AUTH THIRD PARTY
  yield takeEvery(
    AuthThirdPartyActions.registerCuriosityStreamUser.types.INIT,
    registerCuriosityStreamSaga,
  );

  // USER
  yield takeEvery(UserActions.userEditProfile.types.INIT, userEditProfileSaga);
  yield takeEvery(UserActions.userGetResults.types.INIT, userGetResultsSaga);
  yield takeEvery(UserActions.userAppsAutocomplete.types.INIT, userAppsAutocompleteSaga);
  yield takeEvery(UserActions.userGetApps.types.INIT, userGetAppsSaga);
  yield takeEvery(UserActions.userGetRecommendations.types.INIT, userGetRecommendationsSaga);
  yield takeEvery(UserActions.userUpdateFirstName.types.INIT, userUpdateFirstNameSaga);
  yield takeLatest(UserActions.userGetContentCount.types.INIT, userGetContentCountSaga);
  yield takeLatest(UserActions.userChangeAppPrice.types.INIT, userChangeAppPriceSaga);
  yield takeEvery(UserActions.userGetRecommendedApps.types.INIT, userGetRecommendedAppsSaga);
  yield takeLatest(UserActions.userGetMyAppsCollections.types.INIT, userGetMyAppsCollectionsSaga);
  yield takeLatest(UserActions.userChangePassword.types.INIT, userChangePasswordSaga);
  yield takeLatest(UserActions.userGetCards.types.INIT, userGetCardsSaga);
  yield takeLatest(UserActions.userSetDefaultCard.types.INIT, userSetDefaultCardSaga);
  yield takeEvery(UserActions.userAddCard.types.INIT, userAddCardSaga);
  yield takeEvery(
    UserActions.retrievePaymentMethodStatus.types.INIT,
    retrievePaymentMethodStatusSaga,
  );
  yield takeEvery(UserActions.userAddPaymentMethod.types.INIT, userAddPaymentMethodSaga);
  yield takeEvery(UserActions.userRemovePaymentMethod.types.INIT, userRemovePaymentMethodSaga);
  yield takeLatest(UserActions.userGetSRWidgetData.types.INIT, userGetSRWidgetDataSaga);

  // PAYMENT HISTORY
  yield takeEvery(
    PaymentHistoryActions.getUserStreamingReward.types.INIT,
    userGetStreamingRewardSaga,
  );
  yield takeEvery(
    PaymentHistoryActions.getUserPaymentHistory.types.INIT,
    userGetPaymentHistorySaga,
  );

  // PAGES
  yield takeEvery(PagesActions.getPagesContent.types.INIT, getPagesContentSaga);

  // WIZARD
  yield takeEvery(WizardActions.getSkipQuestions.types.INIT, getSkipQuestionsSaga);
  yield takeEvery(WizardActions.getUniqueId.types.INIT, getUniqueIdSaga);
  yield takeEvery(WizardActions.asyncInternetOptions.types.INIT, asyncInternetOptionsSaga);

  // PARTNER
  yield takeEvery(PartnerActions.getPartner.types.INIT, getPartnerSaga);
  yield takeEvery(PartnerActions.getPartnerByDomain.types.INIT, getPartnerByDomainSaga);
  yield takeEvery(PartnerActions.getPartnerInternetOffer.types.INIT, getPartnerInternetOfferSaga);
  yield takeEvery(
    PartnerActions.claimPartnerInternetOffer.types.INIT,
    claimPartnerInternetOfferSaga,
  );

  // COUNTIES
  yield takeEvery(CountiesActions.getCounties.types.INIT, getCountiesSaga);
  yield takeEvery(CountiesActions.getCountiesByAddress.types.INIT, getCountiesByAddressSaga);

  // CHANNEL
  yield takeEvery(ChannelActions.getChannelDetails.types.INIT, getChannelDetailsSaga);
  yield takeEvery(ChannelActions.getChannelSwimlanes.types.INIT, getChannelSwimlanesSaga);

  // CHANNELS
  yield takeEvery(ChannelsActions.getChannelFilters.types.INIT, getChannelFiltersSaga);
  yield takeEvery(ChannelsActions.getChannels.types.INIT, getChannelsSaga);
  yield takeEvery(ChannelsActions.getTopChannels.types.INIT, getTopChannelsSaga);
  yield takeEvery(ChannelsActions.submitForm.types.INIT, submitFormSaga);
  yield takeEvery(ChannelsActions.getFormData.types.INIT, getFormDataSaga);
  yield takeEvery(ChannelsActions.channelsAutocomplete.types.INIT, channelsAutocompleteSaga);

  // RESULTS
  yield takeEvery(ResultsActions.getResults.types.INIT, getResultsSaga);
  yield takeEvery(ResultsActions.addEmailToResults.types.INIT, addEmailToResultsSaga);
  yield takeEvery(ResultsActions.sendTokenForGetResults.types.INIT, sentTokenForGetResultsSaga);
  yield takeEvery(ResultsActions.getAppByIds.types.INIT, getAppByIdsSaga);
  yield takeEvery(ResultsActions.getChannelsByServicesIds.types.INIT, getChannelsByServicesIdsSaga);

  // DISCOVER
  yield takeLatest(DiscoverActions.getDiscoverList.types.INIT, getDiscoverListSaga);
  yield takeLatest(DiscoverActions.getDiscoverFilters.types.INIT, getDiscoverFiltersSaga);
  yield takeLatest(DiscoverActions.getDiscoverSearch.types.INIT, getDiscoverSearchSaga);
  yield takeLatest(DiscoverActions.getDiscoverCollections.types.INIT, getDiscoverCollectionsSaga);

  // APP DETAILS
  yield takeLatest(AppDetailsActions.getAppDetails.types.INIT, getAppDetailsSaga);
  yield takeLatest(AppDetailsActions.getAppChannels.types.INIT, getAppChannelsSaga);
  yield takeLatest(AppDetailsActions.getAppReviews.types.INIT, getAppReviewsSaga);
  yield takeLatest(AppDetailsActions.addAppReview.types.INIT, addAppReviewSaga);
  yield takeLatest(AppDetailsActions.getExternalLink.types.INIT, getAppExtraLinkSaga);
  yield takeEvery(AppDetailsActions.appListUpdateStatus.types.INIT, appListUpdateStatusSaga);
  yield takeEvery(AppDetailsActions.appListRemoveStatus.types.INIT, appListRemoveStatusSaga);

  // WP BLOG
  yield takeLatest(WPBlogActions.getInsights.types.INIT, getWPInsightsSaga);
  yield takeLatest(WPBlogActions.getWPPosts.types.INIT, getWPPostsSaga);
  yield takeLatest(WPBlogActions.getWPPost.types.INIT, getWPPostSaga);
  yield takeLatest(WPBlogActions.getWPRelatedPosts.types.INIT, getWPRelatedPostsSaga);
  yield takeLatest(WPBlogActions.getWPAuthor.types.INIT, getWPAuthorSaga);
  yield takeLatest(WPBlogActions.getWPCategory.types.INIT, getWPCategorySaga);
  yield takeLatest(WPBlogActions.getWPPage.types.INIT, getWPPageSaga);
  yield takeLatest(WPBlogActions.getCareers.types.INIT, getWPCareersSaga);
  yield takeLatest(WPBlogActions.getNews.types.INIT, getWPNewsSaga);

  // PLAN DETAILS
  yield takeLatest(PlanDetailsActions.getPlanDetails.types.INIT, getPlanDetailsSaga);

  // MOVIE DETAILS
  yield takeLatest(MovieDetailsActions.getMovieDetails.types.INIT, getMovieDetailsSaga);
  yield takeLatest(MovieDetailsActions.getMovieStreamingData.types.INIT, getMovieStreamingDataSaga);
  yield takeLatest(MovieDetailsActions.getSimilarMovies.types.INIT, getSimilarMoviesSaga);
  yield takeLatest(MovieDetailsActions.subscribeToChanges.types.INIT, subscribeToChangesSaga);

  // SHOW DETAILS
  yield takeLatest(ShowDetailsActions.getShowDetails.types.INIT, getShowDetailsSaga);
  yield takeLatest(ShowDetailsActions.getShowStreamingData.types.INIT, getShowStreamingDataSaga);
  yield takeLatest(ShowDetailsActions.getSimilarShows.types.INIT, getSimilarShowsSaga);

  // INTERNET OPTIONS
  yield takeLatest(InternetOptionActions.getInternetOption.types.INIT, getInternetOptionSaga);

  // INTERNET OFFERS
  yield takeEvery(InternetOffersActions.getInternetOffers.types.INIT, getInternetOffersSaga);
  yield takeEvery(
    InternetOffersActions.getInternetOffersById.types.INIT,
    getInternetOffersByIdSaga,
  );
  yield takeEvery(
    InternetOffersActions.saveInternetOffersAnswers.types.INIT,
    saveInternetOffersAnswersSaga,
  );
  yield takeEvery(
    InternetOffersActions.subscribeToInternetOffers.types.INIT,
    subscribeToInternetOffersSaga,
  );

  yield takeEvery(
    InternetOffersActions.checkInternetOffersSubscription.types.INIT,
    checkInternetOffersSubscriptionSaga,
  );

  // WATCH NEXT
  yield takeEvery(WatchNextActions.startWatchNextFrom.types.INIT, startWNFromSaga);
  yield takeEvery(WatchNextActions.submitWatchNextForm.types.INIT, submitWNFormSaga);
  yield takeEvery(WatchNextActions.getWatchNextResults.types.INIT, getWNResultsSaga);
  yield takeLatest(WatchNextActions.getMoreWatchNextShows.types.INIT, getMoreWNShowsSaga);
  yield takeEvery(WatchNextActions.submitWatchNextVote.types.INIT, submitWNVoteSaga);
  yield takeEvery(WatchNextActions.addEmailToWNResults.types.INIT, addEmailToWNResultsSaga);
  yield takeEvery(
    WatchNextActions.suggestionsAutocompleteWN.types.INIT,
    suggestionsAutocompleteWNSaga,
  );
  yield takeEvery(WatchNextActions.addWatchNextSuggestion.types.INIT, addWNSuggestionSaga);
  yield takeEvery(WatchNextActions.addToWatchNextVotelist.types.INIT, addToWNVotelistSaga);
  yield takeEvery(
    WatchNextActions.removeFromWatchNextVotelist.types.INIT,
    removeFromWNVotelistSaga,
  );
  yield takeEvery(WatchNextActions.saveWatchNextVotelist.types.INIT, saveWNVotelistSaga);
  yield takeEvery(WatchNextActions.deleteWNVotelist.types.INIT, deleteWNVotelistSaga);

  // WATCH LIST
  yield takeEvery(WatchListActions.addToWatchList.types.INIT, addToWatchListSaga);
  yield takeEvery(WatchListActions.addToDefWatchList.types.INIT, addToDefWatchListSaga);
  yield takeEvery(WatchListActions.removeFromWatchList.types.INIT, removeFromWatchListSaga);
  yield takeLatest(
    WatchListActions.watchListGetCollections.types.INIT,
    watchListGetCollectionsSaga,
  );
  yield takeLatest(WatchListActions.getWatchList.types.INIT, getWatchListSaga);
  yield takeLatest(WatchListActions.getWatchListContent.types.INIT, getWatchListContentSaga);
  yield takeLatest(WatchListActions.copyWatchListContent.types.INIT, copyWatchListContentSaga);
  yield takeLatest(WatchListActions.getWatchlists.types.INIT, getWatchlistsSaga);
  yield takeLatest(WatchListActions.updateWatchlist.types.INIT, updateWatchListSaga);
  yield takeLatest(WatchListActions.deleteWatchlist.types.INIT, deleteWatchListSaga);
  yield takeLatest(WatchListActions.createWatchlist.types.INIT, createWatchListSaga);
  yield takeLatest(
    WatchListActions.getContentAddNewWatchList.types.INIT,
    getContentAddNewWatchListSaga,
  );
  yield takeLatest(
    WatchListActions.addContentsToWatchLists.types.INIT,
    addContentsToWatchListsSaga,
  );

  // SHOWS AND MOVIES
  yield takeLatest(
    ShowsAndMoviesActions.getShowsAndMoviesList.types.INIT,
    getShowsAndMoviesListSaga,
  );
  yield takeLatest(
    ShowsAndMoviesActions.getShowsAndMoviesFilters.types.INIT,
    getShowsAndMoviesFiltersSaga,
  );
  yield takeLatest(
    ShowsAndMoviesActions.getShowsAndMoviesSearch.types.INIT,
    getShowsAndMoviesSearchSaga,
  );
  yield takeLatest(
    ShowsAndMoviesActions.getShowsMoviesCollections.types.INIT,
    getShowsMoviesCollectionsSaga,
  );

  // ONBOARDING
  yield takeEvery(OnboardingActions.getOnboardingApps.types.INIT, getOnboardingAppsSaga);
  yield takeEvery(OnboardingActions.onboardingComplete.types.INIT, onboardingCompleteSaga);
  yield takeEvery(OnboardingActions.onboardingSetState.types.INIT, onboardingStateWatchSaga);

  // SUBSCRIPTION
  yield takeEvery(
    SubscriptionActions.addSubscriptionPayment.types.INIT,
    addSubscriptionPaymentSaga,
  );
  yield takeEvery(SubscriptionActions.cancelSubscription.types.INIT, cancelSubscriptionSaga);
  yield takeEvery(SubscriptionActions.renewSubscription.types.INIT, renewSubscriptionSaga);
  yield takeEvery(
    SubscriptionActions.getSubscriptionDetails.types.INIT,
    getSubscriptionDetailsSaga,
  );
  yield takeEvery(
    SubscriptionActions.getSubscriptionDictionary.types.INIT,
    getSubscriptionDictionarySaga,
  );
  yield takeEvery(
    SubscriptionActions.addSubscriptionRegistration.types.INIT,
    addSubscriptionRegistrationSaga,
  );
  yield takeEvery(
    SubscriptionActions.registerSubscriptionService.types.INIT,
    registerSubscriptionServiceSaga,
  );
  yield takeEvery(
    SubscriptionActions.getSubscriptionTransactions.types.INIT,
    getSubscriptionTransactionsSaga,
  );
  yield takeEvery(
    SubscriptionActions.getSubscriptionPreview.types.INIT,
    getSubscriptionPreviewSaga,
  );
  yield takeEvery(SubscriptionActions.changePassword.type, subscriptionChangePasswordSaga);

  // HOME
  yield takeLatest(HomeActions.getHomeSection.types.INIT, getHomeSectionSaga);
  yield takeEvery(HomeActions.getHomeAuthContent.types.INIT, getHomeAuthContentSaga);
  yield takeEvery(HomeActions.getHomeNonAuthContent.types.INIT, getHomeNonAuthContentSaga);
  yield takeEvery(HomeActions.getPartnerHomeAuthContent.types.INIT, getPartnerHomeAuthContentSaga);

  // SWIMLANES
  yield takeEvery(SwimlanesActions.swimlanesGet.types.INIT, swimlanesGetSaga);
  yield takeEvery(SwimlanesActions.swimlanesGetContent.types.INIT, swimlanesGetContentSaga);
  yield takeEvery(
    SwimlanesActions.swimlanesGetPopularContent.types.INIT,
    swimlanesGetPopularContentSaga,
  );

  // PROMOTION
  yield takeEvery(PromotionActions.getPromoDetails.types.INIT, getPromoDetailsSaga);
  yield takeEvery(PromotionActions.getPromoCode.types.INIT, getPromoCodeSaga);
  yield takeEvery(PromotionActions.checkPromoAuthCode.types.INIT, checkPromoAuthCodeSaga);
  yield takeEvery(PromotionActions.getPromoSwimlanes.types.INIT, getPromoSwimlanesSaga);

  // PROMOTIONS
  yield takeEvery(PromotionsActions.getPromoContent.types.INIT, getPromoContentSaga);
  yield takeEvery(PromotionsActions.getBlackFridayContent.types.INIT, getBlackFridayContentSaga);

  // EMMY AWARD
  yield takeEvery(EmmyAwardsActions.getEmmyAwardsDetails.types.INIT, getEmmyAwardsDetailsSaga);

  // SEARCH
  yield takeEvery(SearchActions.getSearchData.types.INIT, getSearchDataSaga);

  // CONFIRMATION ISP
  yield takeEvery(ConfirmationISPActions.getConfirmationISPData.types.INIT, confirmationISPSaga);

  // STREAMING REWARDS
  yield takeEvery(StreamingRewardsActions.getStreamingRewards.types.INIT, getStreamingRewardsSaga);
  yield takeEvery(
    StreamingRewardsActions.checkStreamingRewards.types.INIT,
    checkStreamingRewardsSaga,
  );
  yield takeEvery(
    StreamingRewardsActions.claimStreamingRewards.types.INIT,
    claimStreamingRewardsSaga,
  );
  yield takeEvery(
    StreamingRewardsActions.getSRClaimInstruction.types.INIT,
    getSRClaimInstructionSaga,
  );
  yield takeEvery(StreamingRewardsActions.getImageApps.types.INIT, getSRImageAppsSaga);
  yield takeEvery(StreamingRewardsActions.getSRAppsSearch.types.INIT, getSRAppsSearchSaga);
  yield takeEvery(StreamingRewardsActions.completeRedeemSR.types.INIT, completeRedeemSRSaga);

  // STREAMING REWARDS FUNDS
  yield takeLatest(StreamingRewardsFundsActions.payFunds.types.INIT, payFundsSaga);
  yield takeLatest(
    StreamingRewardsFundsActions.cancelRecurringPayment.types.INIT,
    cancelRecurringPaymentSaga,
  );
  yield takeEvery(StreamingRewardsFundsActions.getSRClientSecret.types.INIT, getSRClientSecretSaga);
  yield takeEvery(StreamingRewardsFundsActions.addSRFunds.types.INIT, addSRFundsSaga);
  yield takeEvery(StreamingRewardsFundsActions.addSRFundsByPM.types.INIT, addSRFundsByPMSaga);
  yield takeEvery(
    StreamingRewardsFundsActions.retrievePaymentStatus.types.INIT,
    retrievePaymentStatusSaga,
  );

  // SR PAYMENT
  yield takeEvery(
    VirtualCardBalanceActions.addVirtualCardBalance.types.INIT,
    virtualCardAddProductSaga,
  );
  yield takeEvery(
    VirtualCardBalanceActions.getVirtualCardBalanceData.types.INIT,
    getVirtualCardDataSaga,
  );

  // VIRTUAL CARD
  yield takeEvery(
    StreamingRewardsActions.getVirtualCardExpandedData.types.INIT,
    getVirtualCardExpandSaga,
  );

  // SHARE INFO
  yield takeEvery(ShareActions.getShareInfo.types.INIT, getShareInfoSaga);

  // CHANNEL LINEUP
  yield takeEvery(ChannelLineupActions.getCLDictionary.types.INIT, getCLDictionarySaga);
  yield takeEvery(ChannelLineupActions.getCLTopChannels.types.INIT, getCLTopChannelsSaga);
  yield takeEvery(ChannelLineupActions.cLSearch.types.INIT, cLSearchSaga);
  yield takeEvery(ChannelLineupActions.getCLChannels.types.INIT, getCLChannelsSaga);
  yield takeEvery(
    ChannelLineupActions.getCLReplacementChannels.types.INIT,
    getCLReplacementChannelsSaga,
  );

  // SLING BEST PACKAGE
  yield takeEvery(SlingBestPackageActions.getSlingBestPackage.types.INIT, getSlingBestPackageSaga);

  // CONTENT
  yield takeEvery(ContentActions.changeContentStatus.types.INIT, changeContentStatusSaga);

  // PAYMENT HISTORY
  // yield takeEvery(PaymentHistoryActions.getPaymentHistory.types.INIT, getPaymentHistorySaga);
}
