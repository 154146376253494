import produce from 'immer';

import { getPromoDictionary, getPromoContent } from '../actions/promotions';

const initialState = {
  list: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 16,
    },
  },
  dictionary: {},
  loadings: {
    list: true,
    dictionary: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET PROMO DICTIONARY
    case getPromoDictionary.types.START:
      state.loadings.ddictionaryata = true;
      return state;

    case getPromoDictionary.types.SUCCESS:
      state.dictionary = action.payload.data;
      state.loadings.dictionary = false;
      return state;

    case getPromoDictionary.types.FAIL:
      state.loadings.dictionary = false;
      return state;

    // GET CONTENT
    case getPromoContent.types.START:
      state.loadings.list = true;
      return state;

    case getPromoContent.types.SUCCESS:
      state.list.data =
        action.payload.page === 1
          ? action.payload.data
          : state.list.data.concat(action.payload.data);
      state.list.meta.current_page = action.payload.meta.current_page;
      state.list.meta.last_page = action.payload.meta.last_page;
      state.list.meta.per_page = action.payload.meta.per_page;
      state.loadings.list = false;
      return state;

    case getPromoContent.types.FAIL:
      state.loadings.list = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
