import React from 'react';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import { useLocation } from 'react-router-dom';

// CONSTANTS
import { ROUTE_CHANNELS, ROUTE_CHANNELS_WITH_ID } from '@/constants/routes';

import { StyledMaterialDesignContent } from './styles';

const SnackbarProvider = props => {
  const location = useLocation();
  let styles = {};

  if (
    location.pathname.includes(ROUTE_CHANNELS) ||
    location.pathname.includes(ROUTE_CHANNELS_WITH_ID)
  ) {
    styles = { containerAnchorOriginBottomRight: 'm-b-80' };
  }

  return (
    <SnackbarProviderBase
      classes={{ ...styles }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      {...props}
    />
  );
};

SnackbarProvider.propTypes = {};

export default React.memo(SnackbarProvider);
