import * as Yup from 'yup';

// CONST
import {
  MIN_PASSWORD_LENGTH,
  MIN_ZIP_CODE_LENGTH,
  MAX_ZIP_CODE_LENGTH,
  MAX_PASSWORD_LENGTH,
} from '@/constants/validation';
import { specSymbolRegEx, letterRegEx } from '@/constants/regexp';

function getEmailShema(t, isRequired) {
  const schema = Yup.string().email(t('validation.emailNotValid'));

  return isRequired ? schema.required(t('validation.emailRequired')) : schema;
}

function getPasswordSchema(t, isRequired) {
  const schema = Yup.string()
    .min(MIN_PASSWORD_LENGTH, t('validation.passwordLength', { length: MIN_PASSWORD_LENGTH }))
    .max(MAX_PASSWORD_LENGTH, t('validation.passwordLengthMax', { length: MAX_PASSWORD_LENGTH }))
    .matches(letterRegEx, t('validation.passwordLetter'))
    .matches(specSymbolRegEx, t('validation.passwordSpec'));

  return isRequired ? schema.required(t('validation.passwordRequired')) : schema;
}

function getZipSchema(t, isRequired) {
  const schema = Yup.string()
    .min(MIN_ZIP_CODE_LENGTH, t('validation.minZipCodeLength', { length: MIN_ZIP_CODE_LENGTH }))
    .max(MAX_ZIP_CODE_LENGTH, t('validation.maxZipCodeLength', { length: MAX_ZIP_CODE_LENGTH }));

  return isRequired ? schema.required(t('validation.zipCodeRequired')) : schema;
}

function getTermsSchema(t, isRequired) {
  const schema = Yup.bool()
    .required()
    .oneOf([true]);

  return isRequired ? schema.required(t('validation.zipCodeRequired')) : schema;
}

const validationSchemas = {
  email: getEmailShema,
  password: getPasswordSchema,
  zip: getZipSchema,
  terms: getTermsSchema,
};

export default (t, { account_fields }) => {
  return account_fields.reduce((currSchema, { name, required }) => {
    const getSchema = validationSchemas[name];

    if (getSchema) {
      currSchema[name] = getSchema(t, required);
    }

    return currSchema;
  }, {});
};
