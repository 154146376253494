import { createAction } from '@/utility/actions';

import { AUTH_REGISTER_CURIOSITY_STREAM_USER } from './types';

/**
 * registerCuriosityStreamUser - params: data, callback
 */
export const registerCuriosityStreamUser = createAction(AUTH_REGISTER_CURIOSITY_STREAM_USER);

export default {
  registerCuriosityStreamUser,
};
