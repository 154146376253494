import styled from 'styled-components';

// CONSTANTS
import breakpoints from '@/constants/breakpoints';

export const LogoContainer = styled.div`
  > * {
    display: block;
    padding: 10px 0;
  }
  svg {
    display: block;

    .cls-2 {
      fill: ${p => {
        switch (p.type) {
          case 'light':
            return '#ffffff';
          case 'dark':
          default:
            return '#231f20';
        }
      }};
    }

    @media (max-width: ${breakpoints.xs}) {
      width: 150px;
    }
  }
`;

export default LogoContainer;
