import { css } from 'styled-components';
import { rgba } from 'polished';
import { WHITE, SHAMROCK, RADICAL_RED, BLACK } from '@/constants/colors';

const frontierStyles = theme => css`
  @font-face {
    font-family: 'object-sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(https://fastly-tundra.telecom.stratusapps.io/FTR/migration/fonts/object-sans/PPObjectSans-Regular.woff2);
  }
  @font-face {
    font-family: 'object-sans';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(https://fastly-tundra.telecom.stratusapps.io/FTR/migration/fonts/object-sans/PPObjectSans-Medium.woff2);
  }
  @font-face {
    font-family: 'object-sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(https://fastly-tundra.telecom.stratusapps.io/FTR/migration/fonts/object-sans/PPObjectSans-Bold.woff2);
  }

  body .partner-frontier.formpage,
  body .partner-frontier.channelspage,
  body .partner-frontier.resultspage,
  body .partner-frontier.formpage,
  body .partner-frontier.channelspage,
  body .partner-frontier.resultspage {
    header {
      background: ${theme?.subheaderBackground};
      color: ${theme?.subheaderTextColor};
      &.modal__header {
        background: ${WHITE};
        color: ${theme?.primary};
      }
      .MuiTypography-colorPrimary {
        color: ${theme?.subheaderTextColor};
      }
      .stepper__dot {
        border-color: ${theme?.subheaderTextColor};
      }
      .stepper__dot--active {
        background-color: ${theme?.subheaderTextColor};
      }
    }
    footer {
      .MuiButton-root {
        border-radius: 25px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .previous-button {
        border: 2px solid ${theme?.primaryTextColor};
      }
    }
  }
  .formpage form,
  .channelspage form {
    h1.main-title,
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
    .MuiTypography-subtitle2,
    .MuiTypography-subtitle1 {
      font-size: 18px;
      line-height: 26px;
    }
    h2.title-second,
    h2 {
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
    }
    .radio-card,
    .checkbox-card {
      border: 1px solid ${theme?.primaryTextColor};
    }
  }

  .resultspage {
    h4.results-subtitle {
      font-size: 42px;
      line-height: 50px;
    }
    .results-section .bundle {
      &.bundle--highlighted {
        border-color: ${theme?.primary};
      }
      .bundle__highlight-title {
        top: 10px;
        color: ${theme?.primary};
      }
      .learn-more-button {
        border-radius: 25px;
      }
    }
    .suggestion {
      display: none;
    }
  }
  .swimlane--highlighted .swimlane__title {
    display: none;
  }
  .channel-lineup {
    .channel-lineup__search.section.section--grey {
      background-color: ${WHITE};

      .swiper-carousel .swiper-slide--with-replacement {
        border-color: ${theme?.primary};
        background-color: ${theme?.primary};
      }
    }
    .checkbox-card {
      border-radius: 12px;
      .MuiBadge-anchorOriginTopRightRectangle {
        transform: (0px, 5px);
      }
    }
    .MuiBadge-colorSecondary {
      color: ${BLACK};
      background-color: ${rgba(WHITE, 0)};
      display: none;
    }
    .included {
      border-color: ${SHAMROCK};
      color: ${SHAMROCK};
    }
    .included--not {
      border-color: ${RADICAL_RED};
      color: ${RADICAL_RED};
    }
  }
`;

export default frontierStyles;
