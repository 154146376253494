import { css } from 'styled-components';

// CONSTANTS
import breakpoints from '@/constants/breakpoints';

const commonStyles = css`
  #root {
    min-height: 300px;
  }

  .wizard-form {
    header {
      @media (max-width: ${breakpoints.md}) and (min-width: ${breakpoints.xs}) {
        div.toolbar > div > div {
          flex-grow: 0;
          max-width: 33.333333%;
          flex-basis: 33.333333%;

          &.right-section {
            .stepper__dot:not(:last-of-type) {
              margin-right: 5px;
            }
            > div > div {
              padding: 16px 8px;
            }
          }
        }
      }

      .right-section {
        @media (max-width: ${breakpoints.sm}) {
          > div > div {
            padding-left: 4px;
            padding-right: 4px;
          }
        }
      }
    }

    .toolbar__link,
    .toolbar__link.nextlink {
      border-radius: 0;
      padding: 11px 20px;
    }
  }

  //Channel Page
  &.channelspage {
    form > div:not(.page-content) {
      margin: 0 auto;
      padding: 15px 0 67px;
      @media (max-width: ${breakpoints.xs}) {
        padding-top: 80px;
      }
    }
  }

  &.resultspage {
    .streaming-section,
    .share-section {
      display: none !important;
    }

    @media (max-width: ${breakpoints.xs}) {
      header.results {
        display: none !important;
      }
      .channel-list > div {
        box-shadow: none;
      }
    }
  }

  /* Modal content */
  .modal-content {
    .visit-site {
      font-weight: bold;
      padding: 1.3rem 2rem;
    }
    .learnmore {
      padding: 1.3rem 2rem;
      font-weight: bold;
    }
  }
`;

export default commonStyles;
