import produce from 'immer';

// CONSTANTS
import { appsListStatuses } from '@/constants/app';

// UTILITY
import {
  addWatchlists,
  removeWatchlists,
  updateListStatus,
  updateUserContentStatus,
} from '@/utility/content';

// STORE
import { appListRemoveStatus, appListUpdateStatus } from '../actions/appDetails';
import { changeContentStatus } from '../actions/content';
import { addToWatchList, removeFromWatchList } from '../actions/watchList';
import { getSearchData, cleanSearchData } from '../actions/search';

const initialState = {
  data: [],
  loadings: {
    data: false,
    statusesLoadings: {},
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET DATA
    case getSearchData.types.START:
      state.loadings.data = true;
      return state;

    case getSearchData.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getSearchData.types.FAIL:
      state.loadings.data = false;
      return state;

    // CLEAN SEARCH DATA
    case cleanSearchData.types.INIT:
      state.data = initialState.data;
      return state;

    // UPDATE/REMOVE APP LIST STATUS
    case appListUpdateStatus.types.START:
    case appListRemoveStatus.types.START:
      state.loadings.statusesLoadings[action.payload.app_id] = true;
      return state;

    case appListUpdateStatus.types.SUCCESS:
    case appListRemoveStatus.types.SUCCESS: {
      const isRemove = action.type === appListRemoveStatus.types.SUCCESS;
      const listStatus = isRemove ? null : appsListStatuses.have;
      const curAppId = action.payload.app_id;

      state.data = state.data.map(searchItem => {
        if (searchItem.type === 'app') {
          searchItem.data = searchItem.data.map(app => {
            return updateListStatus(app, curAppId, listStatus);
          });

          return searchItem;
        }

        return searchItem;
      });
      state.loadings.statusesLoadings[action.payload.app_id] = false;
      return state;
    }

    case appListUpdateStatus.types.FAIL:
    case appListRemoveStatus.types.FAIL:
      state.loadings.statusesLoadings[action.payload.app_id] = false;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;

      state.data = state.data.map(searchItem => {
        if (searchItem.type === 'show' || searchItem.type === 'movie') {
          searchItem.data = searchItem.data.map(item => {
            if (item.id === content_id) {
              const wlAction = isAdd ? addWatchlists : removeWatchlists;
              const watchlists = wlAction(item.watchlists, watch_lists);

              return { ...item, watchlists, in_watchlist: !!watchlists.length };
            }

            return item;
          });

          return searchItem;
        }
        return searchItem;
      });

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;

      state.data = state.data.map(searchItem => {
        if (searchItem.type === 'show' || searchItem.type === 'movie') {
          searchItem.data = searchItem.data.map(item => {
            return updateUserContentStatus(item, content_id, status);
          });

          return searchItem;
        }

        return searchItem;
      });
      return state;
    }

    default:
      return state;
  }
});

export default reducer;
