import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import { PLAN_DETAILS } from '@/constants/api';

// UTILITY
import { getLink } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { PlanDetailsActions } from '@/store/actions';
import { AppSelectors } from '@/store/selectors';

const { getPlanDetails } = PlanDetailsActions;

export function* getPlanDetailsSaga(action) {
  yield put(getPlanDetails.start());

  const { bundle_id, onError } = action.payload;
  const partner = yield select(AppSelectors.getPartner);
  let url = getLink(PLAN_DETAILS, { bundle_id });

  if (partner) {
    url += `/${partner}`;
  }

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getPlanDetails.success(respData));
  } catch (error) {
    if (error?.response?.status === 422) {
      if (onError) onError();
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getPlanDetails.fail());
  }
}

export default getPlanDetailsSaga;
