export default {
  welcome: {
    title: 'Get the Live TV you love powered by {{partner_name}}',
    subtitle:
      'Just answer a few questions, select the channels you watch and we’ll show you the best streaming packages at the best price for you!',
    imagealt: 'Find the best Live TV streaming for you',
  },
  acpQualification: {
    title: 'Affordable Connectivity Program (ACP) Qualification',
    step1: 'Based on <span>household</span> income',
    step2:
      'If <b>you</b> and <b>your child</b> or <b>dependent</b> participate in certain government assistance programs such as SNAP, Medicaid, WIC, or <span>other programs</span>.',
    step3: 'If <b>you</b> or <b>anyone</b> in your household already receives a Lifeline benefit.',
    button: 'Check Your Qualification',
  },
  streamingMarketplace: {
    title: 'Even More Streaming',
    subtitle:
      'Explore and sign up for a wide range of over 100+ paid and free streaming services that match your interest! There are so many options out there, we make it easy to find the best services and deals for you in our Streaming Marketplace.',
  },
  showsMovies: {
    title: 'Personalized Streaming TV Guide',
    subtitle:
      'By creating a free Streaming Profile, you get the following features across all your streaming services:',
    features: {
      item1: 'Personalized Show & Movie Recommendations',
      item2: 'Search Where to Watch Specific Titles',
      item3: 'Manage your Streaming Expenses',
      item4: 'Create Watchlists & Track What You’re Watching',
    },
  },
  appSection: {
    title: 'Take Charge of Your Entertainment ',
    subtitle: 'Level up your experience with the latest release of MyBundle app.',
    features: {
      item1: 'Explore top movies & TV shows across various streaming services in one place',
      item2: 'Generate multiple watchlists and share them with your family and friends',
      item3: 'Get and give recommendations from your friends on what to watch next',
    },
  },
  liveTV: {
    title: 'Watch Free Live TV',
    subtitle: 'Tune in for Hit Movies, TV Shows, News, Sports, Kids and more. All completely free!',
    features: {
      item1: 'Personalized Show & Movie Recommendations',
      item2: 'Search Where to Watch Specific Titles',
      item3: 'Manage your Streaming Expenses',
      item4: 'Create Watchlists & Track What You’re Watching',
    },
  },
  questions: {
    title: 'Frequently Asked Questions',
    subtitle: '',
    question1: {
      question: 'What is Streaming TV? ',
      answer:
        'Put simply, streaming TV is entertainment transmitted over the internet instead of a cable or satellite box. With our reliable internet, you can stream your favorite TV channels, shows, movies, documentaries, and more – live or on demand. Using a streaming device or smart TV, you can watch services on your TV, or you can stream them on your computer or mobile devices. ',
    },
    question2: {
      question: 'What is MyBundle? ',
      answer:
        "We've partnered with MyBundle to help simplify your streaming TV experience because we know it can be confusing. MyBundle's suite of free tools help you eliminate all the work needed to find the streaming services that have the content you actually want. Whether it's finding a replacement for cable or satellite with the channels you need, discovering new streaming services that match your interests, or deciding what to watch next – MyBundle can help. (And save you hundreds of dollars and hours in the process!)",
    },
    question3: {
      question: 'How does Find My Bundle work?',
      answer:
        'Answer a few questions about your lifestyle and viewing preferences. Input your zip code. Select your must-have live TV channels, and Find My Bundle will present the best streaming TV service(s) for you to replace cable TV. Enter your email if you’d like a copy of your results, or go ahead and sign-up for the service(s) that best matches your needs and budget right there. ',
    },
    question4: {
      question: 'What’s a Smart TV or streaming device? Do I need one? ',
      answer:
        'If you’d like to enjoy streaming content on your TV, you’ll need a Smart TV or streaming device. A lot of newer TVs come equipped Smart TV capabilities, which allow you to connect your TV to your Wi-Fi network or modem and access streaming service apps. Alternatively, a streaming device such as a Roku, Amazon’s Firestick, Apple TV, or Google Chromecast, will turn your TV into a Smart TV. With a simple interface, streaming devices allow you to use streaming apps and watch your favorite shows and movies. ',
    },
    question5: {
      question: 'Why should I switch to Streaming? ',
      answer:
        'Cable TV is constantly getting more expensive and often includes more channels than you actually watch. With Streaming, you can enjoy the same content and channels you’re used to in a more affordable package. Beyond live TV, with streaming you can access low-cost or free streaming services that offer movies, shows, and documentaries that match your interests. Streaming also provides you the ability to watch your favorite content wherever and however you want.  ',
    },
  },
  sellInternet: {
    title:
      'Don’t forget to sign-up for {{partner_name}} internet or check if you’re eligible for an upgrade.',
  },
};
