import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// STYLES
import StyledTextField from './styles';

const PasswordInput = React.forwardRef(({ handleIconClick, ...props }, ref) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => {
    setShow(!show);
    handleIconClick();
  };

  return (
    <StyledTextField
      {...props}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={show ? 'text' : 'password'}
      ref={ref}
    />
  );
});

PasswordInput.defaultProps = {
  handleIconClick: () => {},
};

PasswordInput.propTypes = {
  handleIconClick: PropTypes.func,
};

export default React.memo(PasswordInput);
