export const addPMProcessingMessage = 'Adding your payment method is processing.';
export const addPMRequirePMMessage =
  'Adding your payment method was not successful, please try again.';
export const addPMDefErrorMessage = 'Something went wrong.';

export const PaymentMethodStatus = {
  ADDED: 'added',
  PENDING: 'pending',
  FAILED: 'failed',
};
