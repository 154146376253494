import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const StyledTextField = styled(TextField)`
  position: relative;

  .MuiInputAdornment-root {
    position: absolute;
    bottom: 50%;
    margin-top: -22px;
    right: 0;

    button {
      &:hover {
        background-color: transparent;
      }
    }

    svg {
      font-size: 1.8rem;
    }
  }

  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }
`;

export default StyledTextField;
