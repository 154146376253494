import axios from 'axios';
import { call, put, delay, select } from 'redux-saga/effects';

// CONSTANTS
import {
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_DICTIONARY,
  SUBSCRIPTION_ID,
  SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_REGISTRATION,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_RENEW,
  SUBSCRIPTION_PRODUCT_TRANSACTIONS,
  SUBSCRIPTION_TRANSACTIONS,
  SUBSCRIPTION_PREVIEW,
  SUBSCRIPTION_CHANGE_PASSWORD,
  SUBSCRIPTION_REGISTER_SERVICE,
  SUBSCRIPTION_REG_SERVICE_STATUS,
  SUBSCRIPTION_STATUS,
} from '@/constants/api';
import { SubscriptionStatus, PaymentVariant } from '@/constants/subscription';

// UTILITY
import getErrorMessage, { CustomError } from '@/utility/errors';
import { getLink, getSerializedParams } from '@/utility/routes';
import { showErrorNotification, getIPGeoData } from '@/utility/saga';
import bugsnagClient from '@/utility/bugsnagClient';

// STORE
import { NotifActions, SubscriptionActions, UserActions, AuthActions } from '@/store/actions';
import { AuthSelectors, AppSelectors, SubscriptionSelectors } from '@/store/selectors';
import { setAuth } from './auth';

// LOCALIZATION
import { t } from '@/locale/i18n';

const { login } = AuthActions;
const {
  addSubscriptionPayment,
  addSubscriptionRegistration,
  registerSubscriptionService,
  cancelSubscription,
  getSubscriptionDetails,
  getSubscriptionDictionary,
  getSubscriptionPreview,
  getSubscriptionTransactions,
  renewSubscription,
  manageSubscriptionAuthDialogState,
  updateSubscriptionDictionary,
  changePassword,
} = SubscriptionActions;
const { userGetCards, userGetSRWidgetData, userGetContentCount } = UserActions;
const { pushSuccessNotificationAction, pushErrorNotificationAction } = NotifActions;

const isSubAuthError = status => ['subscription_already_exists', 'token_expired'].includes(status);

function* getHCaptcha() {
  try {
    if (window.hcaptcha) {
      const res = yield call(window.hcaptcha.execute, { async: true });

      return res.response;
    }

    throw new CustomError(t('notification.hCaptchaMissing'));
  } catch (error) {
    const err = new CustomError(t('notification.encProblem'), { cause: error });

    bugsnagClient.notify(err);

    throw err;
  }
}

export function* handleSubscriptionAuthError(action, error) {
  const status = error.data.payload?.status || error.data.status;
  const isSubscriptionExists = status === 'subscription_already_exists';
  const dictionary = yield select(SubscriptionSelectors.getSubscriptionDictionary);

  yield put(
    manageSubscriptionAuthDialogState.init({
      state: true,
      props: {
        action,
        variant: isSubscriptionExists ? 'register' : 'login',
        errorMessage: t(
          `subscription.notifications.status.${status}`,
          {
            app: dictionary?.app?.name || 'App',
          },
          getErrorMessage(error),
        ),
      },
    }),
  );
}

export function* getSubscriptionDetailsSaga(action) {
  yield put(getSubscriptionDetails.start());

  const { slug, onError } = action.payload;
  const url = getLink(SUBSCRIPTION_DETAILS, { slug });
  const ytpc = yield select(AppSelectors.getYtpc);

  const serializedParams = getSerializedParams({
    ytpc,
  });

  try {
    const { data: respData } = yield axios.get(url, {
      ...serializedParams,
      cache: {
        exclude: { paths: [url] },
      },
    });

    yield put(getSubscriptionDetails.success({ data: respData }));
  } catch (error) {
    if (error?.response?.status === 422) {
      if (onError) onError();
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getSubscriptionDetails.fail());
  }
}

export function* getSubscriptionDictionarySaga(action) {
  yield put(getSubscriptionDictionary.start());

  const { slug, plan_id, addons, params = {} } = action.payload;
  const url = getLink(SUBSCRIPTION_DICTIONARY, { slug });

  try {
    const { data: respData } = yield axios.get(url, {
      params,
      cache: {
        ignoreCache: true,
      },
    });

    yield put(getSubscriptionDictionary.success({ data: respData, plan_id, addons }));
  } catch (error) {
    const { onError } = action.payload;
    const status = error?.response?.status;

    if (status === 422 || status === 404) {
      if (onError) onError();
    } else {
      yield put(getSubscriptionDictionary.fail());

      yield put(pushErrorNotificationAction(error));
    }
  }
}

function* confirmPayment3DSecure(data, confirmPaymentReq) {
  const { client_secret, id } = data;

  try {
    yield call(confirmPaymentReq, client_secret);
  } catch (error) {
    const url = getLink(SUBSCRIPTION_ID, { id });

    yield axios.delete(url);

    throw new CustomError(t('subscription.notifications.payment_error_desc'));
  }
}

function* pollSubscriptionStatus(type, params = {}, confirmPaymentReq) {
  const delayTimeMs = 3000;
  const maxCountApiCalls = 40;
  let countApiCalls = 1;
  let isConfirmed = false;

  while (countApiCalls < maxCountApiCalls) {
    const url = getLink(SUBSCRIPTION_STATUS, { type });

    const {
      data: { status, payload },
    } = yield axios.get(url, {
      cache: { ignoreCache: true },
      params,
    });

    if (payload?.need_to_confirm && !isConfirmed) {
      yield call(confirmPayment3DSecure, payload, confirmPaymentReq);

      isConfirmed = true;
    }

    if (![SubscriptionStatus.PENDING, SubscriptionStatus.INCOMPLETE].includes(status)) {
      return { status, payload };
    }

    countApiCalls += 1;

    yield delay(delayTimeMs);
  }

  if (countApiCalls === maxCountApiCalls) {
    bugsnagClient.notify(
      new Error(
        `Long pending subscription status. Params - ${JSON.stringify(params)}, type - ${type}`,
      ),
    );
  }

  return { status: SubscriptionStatus.PENDING };
}

export function* addSubscriptionPaymentSaga(action) {
  yield put(addSubscriptionPayment.start());

  const {
    paymentVariant,
    paymentData,
    createPaymentMethod,
    confirmCardPayment,
    callback,
  } = action.payload;
  const isNeedHCaptcha = paymentData.type === 'sling-tv' || paymentData.type === 'sling-latino';
  let { payment_method_id } = action.payload;

  // !IMPORTANT: if card won't change
  const hasCard = !!payment_method_id;

  try {
    const ipApiData = yield call(getIPGeoData);

    const reqData = { userIp: ipApiData.query };

    if (isNeedHCaptcha) {
      const hCaptcha = yield call(getHCaptcha);

      Object.assign(reqData, { hCaptcha });
    }

    if (!hasCard) {
      const payment = yield call(createPaymentMethod);
      payment_method_id = payment.id;
    }

    const {
      data: { subscription_id, transaction_id, end_date: subscription_end_date },
    } = yield axios.post(SUBSCRIPTION_PAYMENT, {
      ...paymentData,
      ...reqData,
      payment_method_id,
    });

    if (!hasCard) {
      yield put(userGetCards.init());
    }

    const { payload: paymentInfo } = yield call(
      pollSubscriptionStatus,
      paymentData.type,
      { subscription_id, transaction_id },
      confirmCardPayment,
    );

    if (paymentVariant === PaymentVariant.VIRTUAL_CARD) {
      yield put(userGetSRWidgetData.init());
    }

    yield put(
      addSubscriptionPayment.success({
        transaction_id,
        subscription_id,
        subscription_end_date,
        paymentInfo,
      }),
    );

    yield put(userGetContentCount.init());

    if (callback?.call) callback();
  } catch (error) {
    const serverErr = error.response;

    if (!serverErr) {
      bugsnagClient.notify(error);
    }

    if (serverErr && isSubAuthError(serverErr.data.payload?.status || serverErr.data.status)) {
      yield call(handleSubscriptionAuthError, action, serverErr);
    }

    yield put(addSubscriptionPayment.fail({ message: getErrorMessage(error, true) }));
  }
}

export function* addSubscriptionRegistrationSaga(action) {
  yield put(addSubscriptionRegistration.start());

  const { registrationData, callback, onError } = action.payload;

  try {
    const isNeedHCaptcha =
      registrationData.type === 'sling-tv' || registrationData.type === 'sling-latino';
    const isNeedLocationData = registrationData.type === 'edye';

    const ipApiData = yield call(getIPGeoData);

    const reqData = { userIp: ipApiData.query };

    if (isNeedLocationData) {
      const { country, city } = yield call(getIPGeoData);

      Object.assign(reqData, { country, city });
    }

    if (isNeedHCaptcha) {
      const hCaptcha = yield call(getHCaptcha);

      Object.assign(reqData, { hCaptcha });
    }

    const { data: respData } = yield axios.post(SUBSCRIPTION_REGISTRATION, {
      ...registrationData,
      ...reqData,
    });

    const isAuth = yield select(AuthSelectors.getIsAuth);

    if (!isAuth) {
      yield setAuth(respData);
      yield put(
        login.success({
          user: respData.user,
        }),
      );
    }

    yield put(addSubscriptionRegistration.success(respData.payload));

    yield put(userGetContentCount.init());

    if (callback?.call) callback();
  } catch (error) {
    const serverErr = error.response;

    if (!serverErr) {
      bugsnagClient.notify(error);
    }

    yield put(addSubscriptionRegistration.fail({ message: getErrorMessage(error, true) }));

    if (onError?.call) onError(error);
  }
}

export function* cancelSubscriptionSaga(action) {
  yield put(cancelSubscription.start());

  const { subscription_id, callback } = action.payload;
  const url = getLink(SUBSCRIPTION_CANCEL, { subscription_id });

  try {
    const {
      data: { user_subscription: subscription },
    } = yield axios.post(url);

    yield put(cancelSubscription.success({ subscription }));

    if (callback?.call) callback();
  } catch (error) {
    yield put(cancelSubscription.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* renewSubscriptionSaga(action) {
  yield put(renewSubscription.start());

  const { data, subscription_id, callback } = action.payload;
  const url = getLink(SUBSCRIPTION_RENEW, { subscription_id });

  try {
    const {
      data: { user_subscription: subscription },
    } = yield axios.post(url, data);

    yield put(renewSubscription.success({ subscription }));

    if (callback?.call) callback();
  } catch (error) {
    yield put(renewSubscription.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* getSubscriptionTransactionsSaga(action) {
  yield put(getSubscriptionTransactions.start());

  const { id, ...params } = action.payload || {};

  try {
    const url = id ? getLink(SUBSCRIPTION_PRODUCT_TRANSACTIONS, { id }) : SUBSCRIPTION_TRANSACTIONS;
    const serializedParams = getSerializedParams(params);

    const { data: respData } = yield axios.get(url, {
      ...serializedParams,
      cache: {
        exclude: { paths: [url] },
      },
    });

    yield put(getSubscriptionTransactions.success(respData));
  } catch (error) {
    yield put(getSubscriptionTransactions.fail());

    yield put(pushErrorNotificationAction(error));
  }
}

export function* getSubscriptionPreviewSaga(action) {
  yield put(getSubscriptionPreview.start());

  const { data } = action.payload || {};

  try {
    const { data: respData } = yield axios.post(SUBSCRIPTION_PREVIEW, data);

    yield put(getSubscriptionPreview.success(respData));

    yield put(updateSubscriptionDictionary.init({ data: { totalPrice: respData.total } }));
  } catch (error) {
    yield put(getSubscriptionPreview.fail());

    const err = error.response;

    if (err) {
      if (isSubAuthError(err.data.status)) {
        yield call(handleSubscriptionAuthError, action, err);
      } else {
        yield call(showErrorNotification, error);
      }
    }
  }
}

export function* subscriptionChangePasswordSaga(action) {
  yield put(changePassword.start());

  const { data, subscription_id, callback } = action.payload;
  const url = getLink(SUBSCRIPTION_CHANGE_PASSWORD, { subscription_id });

  try {
    const { data: respData } = yield axios.post(url, data);

    yield put(changePassword.success());

    yield put(pushSuccessNotificationAction(respData.message));

    if (callback?.call) callback();
  } catch (error) {
    yield put(changePassword.fail(getErrorMessage(error)));
  }
}

function* pollRegSubscriptionServiceStatus(type, params = {}) {
  const delayTimeMs = 2000;
  const maxCountApiCalls = 30;
  let countApiCalls = 1;
  let respStatus;

  while (countApiCalls < maxCountApiCalls) {
    const url = getLink(SUBSCRIPTION_REG_SERVICE_STATUS, { type });

    const {
      data: { status, payload },
    } = yield axios.get(url, {
      cache: { ignoreCache: true },
      params,
    });

    if (![SubscriptionStatus.PENDING, SubscriptionStatus.INCOMPLETE].includes(status)) {
      return payload;
    }

    countApiCalls += 1;

    respStatus = status;

    yield delay(delayTimeMs);
  }

  if (
    countApiCalls === maxCountApiCalls &&
    [SubscriptionStatus.PENDING, SubscriptionStatus.INCOMPLETE].includes(respStatus)
  ) {
    throw new Error('Your subscription not ready try again');
  }
}

export function* registerSubscriptionServiceSaga(action) {
  yield put(registerSubscriptionService.start());

  const { registrationData, callback, onError } = action.payload;

  try {
    yield axios.post(SUBSCRIPTION_REGISTER_SERVICE, registrationData);

    const respData = yield call(pollRegSubscriptionServiceStatus, registrationData.type, {
      plan_id: registrationData.plan_id,
    });

    yield put(registerSubscriptionService.success(respData));

    if (callback?.call) callback();
  } catch (error) {
    const serverErr = error.response;

    if (!serverErr) {
      bugsnagClient.notify(error);
    }

    yield put(registerSubscriptionService.fail({ message: getErrorMessage(error, true) }));

    if (onError?.call) onError(error);
  }
}
