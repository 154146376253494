import produce from 'immer';

// STORE
import { getSlingBestPackage, clearSBPResults } from '../actions/slingBestPackage';

const initialState = {
  results: {},
  loadings: {
    results: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET DATA
    case getSlingBestPackage.types.START:
      state.loadings.results = true;
      return state;

    case getSlingBestPackage.types.SUCCESS:
      state.results = action.payload.data;
      state.loadings.results = false;
      return state;

    case getSlingBestPackage.types.FAIL:
      state.loadings.results = false;
      return state;

    // CLEAR RESULTS
    case clearSBPResults.types.INIT:
      state.results = initialState.results;
      return state;

    default:
      return state;
  }
});

export default reducer;
