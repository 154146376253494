export default {
  selectedChannels: 'Selected channels',
  channels: {
    title: 'Select the channels you need.',
    title2: 'Enter your ZIP Code to get your full channel list.',
    subtitle:
      "You can choose as many channels as you'd like, but the fewer channels you select the more you'll save!",
    trySearchingSearchBox:
      "Didn't find what you were looking for? More channels can be found in the Search Box above.",
    lookingForMore: 'Looking for more channels? Use the filters above or view more channels',
  },
  localNewsSection: {
    title: 'Live Local News Channels',
  },
  popularSection: {
    title: 'Popular Channels',
  },
  filters: {
    searchLabel: 'Find More Channels',
    genreLabel: 'Filter Channels By Genre',
    filterByGenres: 'Filter By Genres',
  },
  email: {
    title: 'Please enter your email address',
    subtitlePart1:
      'We know you may not be ready to sign up for your new streaming services right away.',
    subtitlePart2:
      'We can send you a copy of your results so they are always there when you are ready.',
    terms: "I agree to MyBundle's",
    tooltip: 'You must agree to privacy policy and terms & conditions',
  },
  lessChannels: {
    title: 'Less Channels - More Savings!',
    subtitle:
      "Selecting a long list of channels might increase the cost of your  bundles. If you're looking to save, make sure to think through what channels you truly <b>need</b> vs those you just <b>want</b>. After seeing your results, you can click Edit Channels to either add or delete channels to get updated recommendations.",
  },
  loaders: {
    default: '',
    questions: '',
    submitting: 'We are calculating over a billion combinations\nto find the right one for you',
  },
};
