import * as Yup from 'yup';

export default t => {
  return {
    rating: Yup.number().required(t('validation.ratingRequired')),
    title: Yup.string(),
    details: Yup.string(),
    name: Yup.string(),
    email: Yup.string().email(t('validation.emailNotValid')),
  };
};
