import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField, Typography, Button, Link } from '@material-ui/core';
import { Formik, Form } from 'formik';

// UTILITY
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';

// STORE
import { AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// STYLES
import { TitleContainer } from './styles';

const ForgotPassword = ({ data, titleProps, onScreenChange }) => {
  const [t] = useTranslation();

  const { unique_id, partnerData, recommendation_token } = useSelector(
    ({ auth, partner, watchNext }) => ({
      unique_id: auth.unique_id,
      partnerData: partner.data,
      recommendation_token: watchNext.wn_token,
    }),
  );

  const [resetPassSendEmail] = useActions([
    submitData => AuthActions.resetPassSendEmail.action({ data: submitData }),
  ]);

  function handleSubmit(values, { setSubmitting }) {
    resetPassSendEmail({
      email: values.email,
      partner_id: partnerData.id || null,
      unique_id: unique_id || null,
      recommendation_token: recommendation_token || null,
    });

    setSubmitting(false);
  }

  function handleChangeScreen(e, screen) {
    e.preventDefault();

    onScreenChange(screen);
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={setYupValidationSchema('forgotPasswordSchema', t)}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, isSubmitting }) => (
        <Form>
          <TitleContainer {...titleProps}>
            <Typography className="m-b-10" variant="h3" align="center">
              {data.title || t('authDrawer.forgot.title')}
            </Typography>
            <Typography
              className="subtitle p5"
              variant="body1"
              align="center"
              color="textSecondary"
            >
              {t('authDrawer.forgot.subtitle')}
            </Typography>
          </TitleContainer>
          <TextField
            className="m-b-20"
            label={t('inputs.email')}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            size="small"
          />
          <Button
            type="submit"
            color="primary"
            size="small"
            fullWidth
            disabled={!isValid && !isSubmitting}
          >
            {t('buttons.send')}
          </Button>
          <div className="m-t-10 text-right">
            <Link href="#" onClick={e => handleChangeScreen(e, 'login')}>
              {t('common.backTo')} {t('buttons.login')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ForgotPassword.defaultProps = {
  data: {},
  titleProps: {},
  onScreenChange: () => {},
};

ForgotPassword.propTypes = {
  data: PropTypes.object,
  titleProps: PropTypes.object,
  onScreenChange: PropTypes.func,
};

export default React.memo(ForgotPassword);
