import styled from 'styled-components';

// UTILITY
import { breakpoints } from '@/utility/breakpoints';

export const Container = styled.div`
  &.section {
    padding: 14px 16px;

    ${breakpoints.down('xs')} {
      padding: 19px 16px;
    }
  }

  .banner-notification {
    &__content {
      max-width: 1380px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      ${breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__title {
      margin-right: 16px;

      ${breakpoints.down('xs')} {
        margin-right: 0;
      }
    }

    &__btn {
      flex-shrink: 0;

      ${breakpoints.down('xs')} {
        margin-top: 16px;
      }
    }
  }
`;

export default Container;
