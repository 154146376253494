// CONSTANTS
import { mybundleRoutes, partnerRoutes } from '@/constants/routes';

// UTILITY
import { getFavicon } from '@/utility/common';
import { getMatchPathFromArray } from '@/utility/path';

export const formatPathToArray = path => path.split('/').filter(Boolean);

export const generateExpectedPartnerRoute = (route, partner) => {
  return ['', partner, ...formatPathToArray(route)].join('/');
};

export const getPartnerMatchPath = link => {
  return getMatchPathFromArray(link, partnerRoutes);
};

export const getPartnerFromPathname = (string = document.location.pathname) => {
  const link = string.split('?')[0] || '';
  let data = null;

  const mybundleRoute = mybundleRoutes.find(path => {
    return formatPathToArray(path)[0] === formatPathToArray(link)[0];
  });

  if (!mybundleRoute) {
    data = getPartnerMatchPath(link);

    // Check the first part of the pathname to set it as a partner slug
    //
    // NOTE: it need for send request to backend and check partner data.
    // If we receive the data, and it contains styles, then apply styles
    // for the "Not Found" page
    //
    if (!data) {
      const arrayPath = formatPathToArray(link);

      if (arrayPath.length > 1) {
        data = getPartnerMatchPath(`/${arrayPath[0]}`);
      }
    }
  }

  return data?.params.partner || '';
};

export const setPartnerFavicon = partner => {
  const partnerLength = Object.keys(partner).length;
  const hasPartnerMeta = partnerLength && partner.meta;
  const faviconExtension =
    hasPartnerMeta && partner.meta.icon
      ? partner.meta.icon.substr(partner.meta.icon.lastIndexOf('.') + 1)
      : '';

  if (faviconExtension) {
    const favicon = getFavicon();

    switch (faviconExtension) {
      case 'ico':
        favicon.href = partner.meta.icon;
        favicon.type = 'image/x-icon';
        break;
      case 'png':
        favicon.href = partner.meta.icon;
        favicon.type = 'image/png';
        break;
      case 'gif':
        favicon.href = partner.meta.icon;
        favicon.type = 'image/gif';
        break;
      default:
        break;
    }
  }

  return null;
};

export function getDomainData() {
  const domain = window.location.hostname;
  const originPath = window.location.origin;
  // const domain = 'd11s84691i6y6c.cloudfront.net';

  const whiteHostnames = [
    'localhost',
    'localsite.com',
    'www-app-dev.mybundle.tv',
    'app-dev-901881.mybundle.tv',
    'www-app-stg.mybundle.tv',
    'app-stg-744688.mybundle.tv',
    'www.mybundle.tv',
    'mybundle.tv',
  ];

  const isPartnerDomain = !whiteHostnames.includes(domain);

  return { isPartnerDomain, domain, originPath };
}
