import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';

// CONSTANTS
import { GET_DISCOVER, GET_DISCOVER_FILTERS, GET_DISCOVER_SEARCH } from '@/constants/api';

// UTILITY
import { getSerializedParams } from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { AuthSelectors, AppSelectors, OnboardingSelectors } from '@/store/selectors';
import { DiscoverActions } from '@/store/actions';
import { swimlanesGetSaga } from './swimlanes';

const { getDiscoverList, getDiscoverFilters, getDiscoverSearch } = DiscoverActions;

export function* getDiscoverListSaga(action) {
  yield put(getDiscoverList.start());

  const { params } = action.payload;
  const ytpc = yield select(AppSelectors.getYtpc);
  const serializedParams = getSerializedParams({
    ...params,
    ytpc,
  });

  try {
    const { data: respData } = yield axios.get(GET_DISCOVER, serializedParams);

    yield put(getDiscoverList.success({ ...respData, page: params.page }));

    yield put(getDiscoverList.stop());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getDiscoverList.fail());
  }
}

export function* getDiscoverFiltersSaga() {
  yield put(getDiscoverFilters.start());

  try {
    const { data: respData } = yield axios.get(GET_DISCOVER_FILTERS);

    // format genres array
    if (Array.isArray(respData.data.genres)) {
      respData.data.genres = respData.data.genres.map(genre => ({
        ...genre,
        id: genre.genre_slug.toLowerCase(),
      }));
    }

    yield put(getDiscoverFilters.success(respData));
  } catch (error) {
    yield put(getDiscoverFilters.fail());
  }
}

export function* getDiscoverSearchSaga(action) {
  yield put(getDiscoverSearch.start(action.payload));

  const { params } = action.payload;

  try {
    let respData = { data: [] };

    if (params.search_string) {
      respData = yield axios.get(GET_DISCOVER_SEARCH, { params }).then(res => res.data);
    }

    yield put(getDiscoverSearch.success(respData));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getDiscoverSearch.fail());
  }
}

export function* getMarketplaceSectionSaga(action) {
  const { section } = action.payload;
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const selectedApps = yield select(OnboardingSelectors.getSelectedApps);

  const apps = isAuth ? [] : selectedApps;
  try {
    yield call(swimlanesGetSaga, {
      payload: { params: { page: 'marketplace', section, app_ids: apps.map(app => app.id) } },
    });
  } catch (error) {
    yield call(showErrorNotification, error);
  }
}

export function* getDiscoverCollectionsSaga() {
  yield all([
    call(getMarketplaceSectionSaga, { payload: { section: 1 } }),
    call(getMarketplaceSectionSaga, { payload: { section: 2 } }),
    call(getMarketplaceSectionSaga, { payload: { section: 3 } }),
  ]);
}
