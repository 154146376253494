import * as Yup from 'yup';

// CONSTANTS
import { MIN_ZIP_CODE_LENGTH, MAX_ZIP_CODE_LENGTH } from '@/constants/validation';

export default t => {
  return {
    default: {
      product_id: Yup.string().required('Must agree to something'),
      fullname: Yup.string().required(t('validation.fullNameRequired')),
      ccnumber: Yup.string().required(t('validation.cardNumRequired')),
      ccexp: Yup.string().required(t('validation.cardExpRequired')),
      cvc: Yup.string().required(t('validation.cardCvcRequired')),
      zipcode: Yup.string()
        .min(MIN_ZIP_CODE_LENGTH, t('validation.minZipCodeLength', { length: MIN_ZIP_CODE_LENGTH }))
        .max(MAX_ZIP_CODE_LENGTH, t('validation.maxZipCodeLength', { length: MAX_ZIP_CODE_LENGTH }))
        .required(t('validation.zipCodeRequired')),
    },
    have: {
      product_id: Yup.string().required('Must agree to something'),
    },
  };
};
