export default {
  share: {
    twitter: "I found an interesting post on @mybundletv. Look it's here!",
  },
  dialogs: {
    findMyBundle: {
      title: 'Tired of your Cable TV Bill?',
      subtitle:
        'The average Live TV streaming bundle is 50% less than your cable TV bills. Stop getting charged for channels you never watch!  Answer a few questions and get a customized bundle recommendation.',
    },
    findInternetOffers: {
      title: 'Interested in a new Internet Provider?',
      subtitle:
        "We've partnered with High-Speed broadband providers to help you find the best internet at the best price, so you can stream & save!",
    },
  },
};
