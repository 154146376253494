import { createAction } from '@/utility/actions';

import { SEARCH_GET_DATA, SEARCH_CLEAN_DATA } from './types';

/**
 * getSearchData - params
 */
export const getSearchData = createAction(SEARCH_GET_DATA);

export const cleanSearchData = createAction(SEARCH_CLEAN_DATA);

export default {
  getSearchData,
  cleanSearchData,
};
