import { enableES5, setAutoFreeze } from 'immer';

const env = process.env.NODE_ENV;
const isProd = env === 'production';

if (isProd) {
  setAutoFreeze(false);
}

enableES5();
