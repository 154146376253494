import { createAction } from '@/utility/actions';

import {
  CHANNEL_LINEUP_DICTIONARY,
  CHANNEL_LINEUP_GET_TOP,
  CHANNEL_LINEUP_SEARCH,
  CHANNEL_LINEUP_GET_CHANNELS,
  CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS,
  CHANNEL_LINEUP_CLEAN_STATE,
} from './types';

/**
 * getCLDictionary - slug
 */
export const getCLDictionary = createAction(CHANNEL_LINEUP_DICTIONARY);

/**
 * getCLTopChannels - slug
 */
export const getCLTopChannels = createAction(CHANNEL_LINEUP_GET_TOP);

/**
 * cLSearch - slug, params
 */
export const cLSearch = createAction(CHANNEL_LINEUP_SEARCH);

/**
 * getCLChannels - slug, params
 */
export const getCLChannels = createAction(CHANNEL_LINEUP_GET_CHANNELS);

/**
 * getCLReplacementChannels - slug
 */
export const getCLReplacementChannels = createAction(CHANNEL_LINEUP_GET_REPLACEMENTS_CHANNELS);
/**
 * cleanCLState
 */
export const cleanCLState = createAction(CHANNEL_LINEUP_CLEAN_STATE);

export default {
  getCLDictionary,
  getCLTopChannels,
  cLSearch,
  getCLChannels,
  getCLReplacementChannels,
  cleanCLState,
};
