import { createAction } from '@/utility/actions';

import {
  HOME_AUTH_GET_CONTENT,
  HOME_NON_AUTH_GET_CONTENT,
  HOME_PARTNER_AUTH_GET_CONTENT,
  HOME_AUTH_CLEAN_CONTENT,
  HOME_GET_SECTION_CONTENT,
} from './types';

/**
 * getHomeAuthContent
 */
export const getHomeAuthContent = createAction(HOME_AUTH_GET_CONTENT);

/**
 * getHomeNonAuthContent
 */
export const getHomeNonAuthContent = createAction(HOME_NON_AUTH_GET_CONTENT);

/**
 * getPartnerHomeAuthContent
 */
export const getPartnerHomeAuthContent = createAction(HOME_PARTNER_AUTH_GET_CONTENT);

/**
 * cleanHomeAuthContent
 */
export const cleanHomeAuthContent = createAction(HOME_AUTH_CLEAN_CONTENT);

/**
 * getHomeSection
 */
export const getHomeSection = createAction(HOME_GET_SECTION_CONTENT);

export default {
  getHomeAuthContent,
  getHomeNonAuthContent,
  getPartnerHomeAuthContent,
  cleanHomeAuthContent,
  getHomeSection,
};
