import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog, DialogContent } from '@material-ui/core';

// STYLES
import { CloseIcon } from './styles';

/**
 * @param { import("@material-ui/core/Dialog").DialogProps & { hideCloseIcon?:boolean } }
 */
function Dialog({ children, hideCloseIcon = false, onEnter, ...props }) {
  function addBodyClass(state = false) {
    if (props.container && !props.disableScrollLock && state) {
      window.document.body.classList.add('overflow-hidden');

      return;
    }

    window.document.body.classList.remove('overflow-hidden');
  }

  function handleOnEnter() {
    addBodyClass(true);

    onEnter();
  }

  return (
    <MuiDialog
      TransitionProps={{
        onEnter: handleOnEnter,
        onExit: () => addBodyClass(false),
      }}
      {...props}
    >
      <DialogContent>
        {!hideCloseIcon && (
          <CloseIcon
            className="dialog__close"
            size="medium"
            aria-label="close"
            onClick={props.onClose}
          >
            <i className="icon-close" />
          </CloseIcon>
        )}
        {children}
      </DialogContent>
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  disableScrollLock: false,
  onClose: () => {},
  onEnter: () => {},
};

Dialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
  container: PropTypes.any,
  disableScrollLock: PropTypes.bool,
  onEnter: PropTypes.func,
};

export default React.memo(Dialog);
