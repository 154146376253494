export default {
  section2: {
    title: 'Streaming Services grow with MyBundle.',
    description1:
      'We partner with Streaming Services to help you find and reach the right consumers at the best moment of discovery, leading to more conversions, retention, and quality customers through our suite of consumer tools, subscription management, and broadband partnership program.',
    description2: 'Get in touch with us today:',
    description3: {
      part1: 'or schedule an',
      part2: 'introductory',
      part3: 'call.',
    },
  },
  section3: {
    title: 'More Internet Customers = More Streamers',
    description1:
      'Our current Broadband Partners have over 5.7 million customers and we are adding new partners every month. As a Streaming Partner, you get access & exposure to our rapidly-growing MyBundle and Broadband Partner user base. The MyBundle tools listed below are used by our Broadband Partners to encourage their customers to purchase streaming services and simplify the management process. ',
  },
  section4: {
    title: 'Streaming Marketplace',
    description1:
      'We introduce consumers to new services and help them find & sign-up for those that match their interests. We offer numerous targeted promotional opportunities across our own site, our Broadband partner sites, and email/social marketing campaigns.',
    description2: ' Each Service Shingle features: ',
    bullet1: {
      text: 'Short & Long Descriptions',
    },
    bullet2: {
      text: 'Special Offers/MyBundle Deals',
    },
    bullet3: {
      text: 'Interface Screenshots',
    },
    bullet4: {
      text: 'Promotional Videos',
    },
    bullet5: {
      text: 'Key Features',
    },
    bullet6: {
      text: 'Device Compatibility',
    },
    cta: 'Watch Demo',
  },
  section5: {
    title: 'Subscription Management',
    description1:
      'With their MyBundle Profile, consumers will soon be able to bundle services and integrate their payments into one bill to better manage their streaming experience. ',
    bullet1: {
      text:
        'The easier it is for a consumer to sign up for a service, the higher the likelihood of conversion. ',
    },
    bullet2: {
      text:
        'MyBundle is the bill, but the rest of the relationship between the customer and you remains the same.',
    },
    bullet3: {
      text: 'Consumers sign-in & view content on your app, giving you control of user data. ',
    },
    cta: 'Watch Demo',
  },
  section6: {
    title: 'Find My Bundle',
    description1:
      'Our algorithm provides personalized Live TV Streaming bundle recommendations for consumers looking to cut the cord and switch to streaming. ',
    bullet1: {
      text:
        'While geared towards Live TV streaming services, we suggest On-Demand Streaming Services that match a user’s genre & channel interests.',
    },
    bullet2: {
      text: 'Allows for marketing campaigns based on a user’s self-selected interests.',
    },
    cta: 'Watch Demo',
  },
  section7: {
    title: 'Interested?',
    description1:
      'To learn more about our Streaming partnership solutions or get started, please send us an email at ',
    description2: {
      part1: 'or schedule an',
      part2: 'introductory call.',
    },
  },
  section8: {
    title: 'MyBundle Profiles',
    description1:
      'By creating a Profile and selecting their current services, users get access to a personalized Streaming Hub where they can discover and locate content from across their apps, easily search for what apps have specific TV Shows and Movies, and build custom watchlists. ',
    bullet1: {
      text: "Allows for the inclusion of your Streaming Service's content library. ",
    },
    bullet2: {
      text:
        "Targeted marketing opportunities for services with similar content to a user's current subscriptions. ",
    },
    bullet3: {
      text: 'Promote new Shows, Movies, Documentaries, and more to our user base. ',
    },
  },
};
