export const appsListStatuses = {
  have: 1,
  want: 2,
};

export const appsTypesFilter = [
  { id: 0, name: 'My Apps', order: 2 },
  { id: 1, name: 'Choose Apps', order: 4 },
  { id: 2, name: 'All Apps', order: 1 },
  { id: 3, name: 'Free Apps', order: 3 },
];

export const exitModalSessionStorageKey = 'exitModalSessionStorageKey';

export default appsListStatuses;
