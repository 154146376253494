export default {
  title: 'Get Your Promo Code!',
  subtitle:
    'Watch all your favorite family-friendly channels and shows with this special 14 day free trial of FRNDLY TV offer from EPB.',
  redeemed: {
    title: 'You have already used your promo code.',
    subtitle: 'It seems like you have already claimed and used a promocode.',
  },
  infoDialog: {
    title: 'How to activate a promo code',
  },
  featuresTitle: 'Frndly TV features',
  instructions: {
    copy: 'Copy the promo code.',
    add:
      'Add the product to the cart and go to checkout, in the checkout window under the order amount you will see a link "Enter promo code".',
    apply: 'Enter the promo code in the field that appears and click on the "Apply" button.',
  },
  curiosityStream: {
    title: 'Create your Curiosity Stream account',
    description:
      'A full year of Curiosity Stream completely FREE. Courtesy of {{partner_name}}.  Stream 1000s of documentaries all on demand.',
    noPromoCodes: 'This promotion is no longer available.',
    authTitle: 'Free year of Curiosity Stream',
    authDescription:
      'Create your free MyBundle profile to get a full year of Curiosity Stream completely FREE',
    devices: 'Available devices',
    bottomText: 'Curiosity Stream has thousands of documentaries',
    meta: {
      title: 'Get a free year of Curiosity Stream',
      description: 'Get a free year of Curiosity Stream from MyBundle TV',
      descriptionPartner:
        'Get a free year of Curiosity Stream from {{partner_name}} and MyBundle TV',
    },
    termsAndConditions: {
      title: 'Terms and Conditions',
      subtitle:
        'Available while supplies last.  Must be 18 years of age or older. Must be an active internet customer in good standing at time of redemption.  Limit one per household.   May require the creation of a free MyBundle profile prior to redemption.  Use of the Curiosity Stream service is subject the Curiosity Stream Terms of Use. Customers with an active CuriosityStream subscription are not eligible.',
    },
    subscriptionMessage:
      'Your account is ready to go. Check the info below for how to watch Curiosity Stream on your devices.',
  },

  epix: {
    title: 'Our Gift to You. Enjoy Twelve Months of EPIX NOW',
    successTitle: 'Congratulations, you have a year of EPIX NOW ',
    successButton: 'Start watching EPIX NOW',
    mainFeatures: {
      feature1: {
        title: '1000s of Movies',
        desc: 'From fan favorites to the latest blockbusters, EPIX NOW has them all.',
      },
      feature2: {
        title: 'Hit Original Series',
        desc: 'Watch new episodes weekly or binge anytime, anywhere.',
      },
    },
    features: {
      title: 'EPIX NOW features',
      feature1: {
        title: 'Live TV or On Demand - No Ads',
        desc: "Watch what's airing now or stream on demand - all commercial free.",
      },
      feature2: {
        title: 'Watch Offline',
        desc: 'Download full episodes and movies with the EPIX NOW mobile apps.',
      },
      feature3: {
        title: 'Stream on your Favorite Devices',
        desc: 'EPIX NOW available on iPhone, iPad, Apple TV, Android TV, Amazon Fire TV and Roku',
      },
    },
    details: `Customer must provide a valid email address for EPIX NOW code email; code must be activated
        within 90 days from email receipt to redeem offer. Your account will be authorized to
        receive programming upon your first login. After the 12 consecutive month promotional period
        expires, your EPIX NOW programming package will end. CenturyLink may change, cancel, or
        substitute offers and services, or vary them by service area, at its sole discretion without
        notice.`,
    copy:
      'By clicking "Create Account" you agree to the <t>Terms of Use</t>, <p>Privacy Policy</p>, and to receive updates, alerts and promotions from EPIX NOW.',
  },
};
