import styled from 'styled-components';

// CONSTANTS
import { WHITE, PORCELAIN } from '@/constants/colors';

// COMPONENTS
import Dialog from '@/components/Dialogs/Dialog';

export const DialogStyled = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0;
  }

  .add-to-wl {
    &__content {
      padding: 40px 32px 32px;
      min-height: 350px;
    }

    &__wl-list {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin-bottom: 15px;

        .MuiFormControlLabel-label {
          font-size: 1.6rem;
          line-height: 2.4rem;
          margin-left: 16px;
        }
      }
    }
  }

  .create-wl {
    &__btn {
      border-radius: 0;
      padding: 18px 32px;
      font-weight: 400;

      .MuiButton-label {
        justify-content: flex-start;
      }

      .MuiButton-startIcon {
        margin-left: 0;
        margin-right: 16px;
      }
    }

    .MuiCollapse-root {
      background-color: ${PORCELAIN};
    }

    .MuiCollapse-wrapper {
      padding: 0 32px 20px;
    }

    .MuiInputBase-input {
      padding: 15px 11px;
      background-color: ${WHITE};
    }
  }
`;

export default DialogStyled;
