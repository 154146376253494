import produce from 'immer';

// UTILITY
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

import { addToWatchList, removeFromWatchList } from '../actions/watchList';

import {
  getShowDetails,
  getShowStreamingData,
  getSimilarShows,
  updateShowMedia,
} from '../actions/showDetails';
import { subscribeToChanges } from '../actions/movieDetails';
import { changeContentStatus } from '../actions/content';

const initialState = {
  data: {},
  media: {},
  streamingData: null,
  streamingDataTemp: null,
  similarShows: null,
  loadings: {
    data: true,
    streamingData: false,
    streamingDataTemp: false,
    similarShows: false,
  },
  errors: {
    data: null,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET SHOW DETAILS
    case getShowDetails.types.START:
      state.loadings.data = true;
      state.media = {};
      return state;

    case getShowDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getShowDetails.types.FAIL:
      state.loadings.data = false;
      return state;

    // GET SHOW STREAMING DATA
    case getShowStreamingData.types.START: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state[streamingDataKey] = null;
      state.loadings[streamingDataKey] = true;

      return state;
    }

    case getShowStreamingData.types.SUCCESS: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state[streamingDataKey] = action.payload.data;
      state.loadings[streamingDataKey] = false;

      return state;
    }

    case getShowStreamingData.types.FAIL: {
      let streamingDataKey = 'streamingData';
      if (action.payload.isTemp) streamingDataKey = 'streamingDataTemp';

      state.loadings[streamingDataKey] = false;

      return state;
    }

    // GET SIMILAR SHOWS
    case getSimilarShows.types.START:
      state.similarShows = null;
      state.loadings.similarShows = true;
      return state;

    case getSimilarShows.types.SUCCESS:
      state.similarShows = action.payload.data;
      state.loadings.similarShows = false;
      return state;

    case getSimilarShows.types.FAIL:
      state.loadings.similarShows = false;
      return state;

    // SUBSCRIBE TO CHANGES
    case subscribeToChanges.types.SUCCESS:
      if (state.data.show)
        state.data.show.subscription_status =
          action.payload.isAuth && action.payload.content_id === state.data.show.id
            ? action.payload.status
            : state.data.show.subscription_status;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;
      const {
        data: { show },
        similarShows,
      } = state;

      if (show && content_id === show.id) {
        const wlAction = isAdd ? addWatchlists : removeWatchlists;
        const watchlists = wlAction(show.watchlists, watch_lists);

        state.data.show.watchlists = watchlists;
        state.data.show.in_watchlist = !!watchlists.length;
      }

      state.similarMovies =
        similarShows &&
        similarShows.map(item => {
          if (item.id === content_id) {
            const wlAction = isAdd ? addWatchlists : removeWatchlists;
            const watchlists = wlAction(item.watchlists, watch_lists);

            return { ...item, watchlists, in_watchlist: !!watchlists.length };
          }
          return item;
        });

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;
      const {
        data: { show },
        similarShows,
      } = state;

      if (show && content_id === show.id) state.data.show.user_content_status = status;
      state.similarShows =
        similarShows && similarShows.map(item => updateUserContentStatus(item, content_id, status));

      return state;
    }

    // UPDATE MEDIA
    case updateShowMedia.types.INIT:
      state.media = action.payload.data;
      return state;

    default:
      return state;
  }
});

export default reducer;
