export default {
  title: 'Get Ready to Stream!',
  subtitle: 'Get ready to stream and save! \n Below are your personalized bundles.',
  subtitleWow: 'Get ready to stream and save! Below are your personalized recommendations.',
  subtitle_century_link: 'TV Made Simple. Below are Your Personalized Bundles.',
  subSubtitle:
    'MyBundle may be compensated when you purchase through our links, but always strive to recommend the right services for you.',
  subSubtitleEpb_yes:
    "Congratulations! Your fiber-fast EPB internet is ready to help power your cord-cutting experience! And if you're not streaming on the World-Famous Gig, make sure to upgrade and triple your speed for just $10 more per month. Power all your streaming devices simultaneously without data caps or added fees!",
  subSubtitleEpb_no:
    "If you're looking for the ultimate streaming experience, only EPB Fi-Speed internet can power all your devices at once without buffering, lag or data caps. Choose 300 Mbps for $57.99/month or 1000 Mbps (The Gig) for just $10 more ($67.99/month).",
  emailFormTitle:
    'If you would like a copy of your results and a link with your saved answers, please enter your email address:',
  locastDescription:
    'Locast offers streaming of broadcast channels in your area for a $5 monthly donation.',
  digitalAntennaDescription:
    'If a suitable signal is available, a digital antenna + Tablo DVR delivers local channels and recording capabilities.',
  thirdBundleTitle: 'Choose Your Own',
  huluAdTitle: 'BLACK FRIDAY SPECIAL',
  huluAdSubTitle: '66% off and cancel any time',
  popularServicesTitle: 'Some popular free and paid streaming services with great content for you:',
  popularServicesTitleV2: 'Popular Streaming Services With Great Content For You',
  linkSuccessfullySent: "We've sent you an email with your bundle results. Thanks for using",
  internetOptionsLoader:
    'We are calculating over a billion combinations\nto find the right one for you',
  yourChannels: 'Your Channels',
  seeAllChannels: 'See All Channels',
  seeChannelsOrSimContent: 'See Channels or Similar Content',
  availableChannels: 'Available Channels',
  missingChannels: 'Missing Channels',
  highlightTitle: 'Recommended for you',
  agreeWithContract:
    'You answered that you would like a device and traditional remote. The service that provides this comes with a two year contact. <button>To see that result click here.</button>',
  createAccount: {
    text: 'Create a profile to save your results',
    inputlabel: 'Create Your Password',
  },
  streaming: {
    title: 'Looking for More Streaming Apps?',
    subtitle:
      'There is more to streaming than Netflix and Prime Video. Use our streaming marketplace to filter and find the perfect streaming apps for you.',
  },
  share: {
    title: 'Share MyBundle',
    subtitle: 'Know someone who would be interested? Share below!',
    pageTitle:
      '<span>{{service}}</span> is the best bundle for me to cut the cord. What about you?',
  },
  details: {
    ofYourChannels: 'of your channels',
    totalChannels: 'total channels',
    channels: 'Channels',
    dvr: 'cloud DVR',
    cloudDvr: 'Cloud DVR',
    totalStreams: 'total streams',
    streams: 'Streams',
    price: 'Price',
    priceTax: '+taxes and fees',
  },
  modal: {
    titlePart1: 'Want to learn more?',
    titlePart2: 'Click below for more info.',
    services: 'Video Services',
    recommendedDevice: 'Recommended Device',
    internetOption: 'Internet Option',
    otherServices: 'Other Services',
    totalChannels: 'Total channels',
    selected: 'Selected',
    availableAddons: 'Available Add-ons',
    total: 'Total',
    totalBundleCost: 'Total bundle cost including internet and video.',
    simContentTitle: 'These services have the same or similar content as the channels you selected',
    starry: {
      description: 'Some Text on the internet option in the modal',
      btn: 'Get Starry',
    },
    locast: {
      description: 'optional donation to remove extra ads',
    },
    becauseYouSelected: 'Because you selected',
  },
  noResults: {
    title: 'No Results Found',
    subtitle:
      'There are no bundles that contain any of the channels you selected in your area. Make you have the right zip code / edit your selected channels. Click below to find some streaming apps.',
  },
  emptyState: {
    title: "Whoops, looks like you are somewhere you don't belong!",
    subtitlePart1: 'Click here to go back to our',
    subtitlePart2: 'If this continues please let us know at',
    formTitle: 'Looking for results you already submmitted?',
  },
  traditionalTv: {
    title: 'Traditional TV',
    description:
      'Not ready to stream? Here is the Traditional TV package that most meets your need.',
    box: 'Box Included',
    tvs: 'TVs',
  },
  htc: {
    title: 'HTC TV MAX',
    description:
      'Our in-home streaming option: the feel of traditional cable TV without the wires and boxes.',
    box: 'Box Included',
    tvs: 'total streams',
  },
  sling: {
    title: 'Antenna For Locals',
    description: 'With the right Antenna reception, this bundle is a great fit for you.',
    box: 'Box Included',
  },
  national: {
    desc:
      '<b>{{name}}</b> included in this bundle is the National Feed. If your local channel is important, click the button below to generate results with the local feed.',
    btnText: 'View with Local Feed',
    title: 'This bundle includes the national feed of {{name}}.',
    linkText: 'Update results to require local feed',
  },
  loaders: {
    default: 'We are calculating over a billion combinations\nto find the right one for you',
    internetOptions:
      'We are calculating over a billion combinations\nto find the right one for you',
  },
  specOffer: {
    title: 'Special Offer!',
    subtitle: 'Includes 3 months of Peacock Premium on us!',
  },
  directTV: {
    notAgreeWithContract:
      'I am not interested in committing to a two-year contract.\n<button>Please show me other results</button>',
    features: {
      title1: 'DirectTV Gemini Device',
      title2: 'Features',
      list:
        '<ul><li>First Leased Device Included, $7/mo for each additional</li><li>Live channels and local TV stations</li><li>Sports Guide/Sports Zone</li><li>Unlimited Cloud DVR recordings</li><li>Voice Control</li></ul>',
    },
    priceInfo: {
      title: 'Price guaranteed for first 2 years',
      text:
        'For 24 mo. agrement, an Advanced Receiver Service Fee $15/mo. is additional and applies. Autopay and Paperless Bill are required. RSN fee from $3.99-$15.99 may apply for Choice and above. There is a $7/mo. fee for each additional TV connection on your account.\n \n *Early agreement termination fee applies ($20/mo.) & additional fee(s) may apply if equip. not returned. New approved residential customers. Non-qualified customers must purchase additional device up front. Credit card required (except MA & PA). Price incl. TV Package & equip. fees for first TV. (Charges may apply for each additional connected TV. Restrictions apply.',
    },
    promotions: {
      title: 'Get free streaming subscriptions when you sign up today',
      text: 'You can get Max, SHOWTIME®, STARZ®, MGM+™ and Cinemax® for the first 3 months.',
    },
    limitedTimeOffer: {
      title: 'Get up to $400 Visa Reward Card when you sign up today',
      text:
        'Get $100 Reward Card with ENTERTAINMENT, $200 with CHOICE, $300 with ULTIMATE or $400 with PREMIER package.',
      details:
        '<p>$10 off for 24 Months: New customers only. Offer not avaliable to DIRECTV or U-verse TV customers switching to DIRECTV via Internet. Requires purchase and maintaining CHOICE and above. Customer must activate service and account must remain in good standing to receive all credits. You will lose this offer and/or any portion of this offer if you cancel your service. Returning customers who disconnected service within previous 12 months are not eligible for this offer. LIMIT ONE OFFER PER DIRECTV CONNECT VIA INTERNET ACCOUNT. May not be combined with certain offers. Offer may be changed or discontinued at any time.</p>',
    },
  },
  slingAirTV: {
    features: {
      title: 'Sling TV',
      feature1: 'Flexible channels lineups with no long-term contracts',
      feature2: 'Record & save live TV episodes with Sling’s cloud DVR',
      feature3: 'Stream the live sports, news and entertainment you love',
      feature4: 'No annual contracts',
      feature5: 'Customize with extras',
    },
    exclusiveOffer: {
      label: 'MyBundle Exclusive',
      title: 'Get free AirTV 2 + HD Antenna device when you sign up today',
      text:
        'This is a <span>$180 value</span> available only through MyBundle! You can seamlessly integrate free local channels such as ABC, CBS, FOX, NBC, The CW and more with AirTV2 device alongside your favorite Sling channels for all-in-one access, and connect one HD antenna to all of your streaming network.',
      features: {
        feature1: 'Free shipping',
        feature2: 'Free contracts',
        feature3: 'Free local channels',
      },
      info: '*Free Local Channel availability varies based on location.',
    },
  },
};
