import { createAction } from '@/utility/actions';

import { ANALYTICS_SEND_TO_FIVETRAN, ANALYTICS_SEND_TO_MAILCHIMP } from './types';

/**
 * analyticsSendToFivetran - params: data
 */
export const analyticsSendToFivetran = createAction(ANALYTICS_SEND_TO_FIVETRAN);

/**
 * analyticsSendToMailchimp - params: data
 */
export const analyticsSendToMailchimp = createAction(ANALYTICS_SEND_TO_MAILCHIMP);

export default {
  analyticsSendToFivetran,
  analyticsSendToMailchimp,
};
