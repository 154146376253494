/* eslint-disable */
import engine from 'store/src/store-engine';
import allStorages from 'store/storages/all';

const plugins = process.env.REACT_APP_DEBUG_STORE ? [require('store/plugins/observe')] : [];

// create store
export const store = engine.createStore(allStorages, plugins);

if (process.env.REACT_APP_DEBUG_STORE) {
  console.log('Init store logs');

  store.each((_, key) => {
    store.observe(key, (prev, current) => {
      console.log('--------');
      console.log(`${key} is changed`);
      console.log('From: ', prev);
      console.log('To: ', current);
    });
  });
}

export const setContentCount = data => {
  store.set('content_count', data);
};

export default store;
