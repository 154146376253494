import { createAction } from '@/utility/actions';

import {
  CHANNELS_GET,
  CHANNELS_TOP_GET,
  CHANNELS_GET_FILTERS,
  CHANNELS_SUBMIT_FORM,
  CHANNELS_GET_FORM_DATA,
  CHANNELS_AUTOCOMPLETE,
  CHANNELS_SET_AUTOCOMPLETE_DEF_DATA,
  CHANNELS_CLEAN_FORM_DATA,
} from './types';

/**
 * getChannels - params: data(dma_id_fk | search | filters)
 */
export const getChannels = createAction(CHANNELS_GET);

/**
 * getTopChannels - params: data(dma_id_fk)
 */
export const getTopChannels = createAction(CHANNELS_TOP_GET);

export const getChannelFilters = createAction(CHANNELS_GET_FILTERS);

export const submitForm = createAction(CHANNELS_SUBMIT_FORM);

/**
 * getFormData - params: unique_id
 */
export const getFormData = createAction(CHANNELS_GET_FORM_DATA);

/**
 * channelsAutocomplete - params: data(dma_id_fk | search)
 */
export const channelsAutocomplete = createAction(CHANNELS_AUTOCOMPLETE);

/**
 * channelsSetAutocompleteDefData
 */
export const channelsSetAutocompleteDefData = createAction(CHANNELS_SET_AUTOCOMPLETE_DEF_DATA);

export const cleanFormData = createAction(CHANNELS_CLEAN_FORM_DATA);

export default {
  getChannels,
  getTopChannels,
  getChannelFilters,
  submitForm,
  getFormData,
  channelsAutocomplete,
  channelsSetAutocompleteDefData,
  cleanFormData,
};
