import * as Yup from 'yup';

// CONSTANTS
import { MAX_APP_COST } from '@/constants/validation';
import { priceRegEx } from '@/constants/regexp';

const reg_tens = new RegExp(priceRegEx);

export default t => {
  // return {
  //   price: Yup.number()
  //     .max(MAX_APP_COST, t('validation.priceMaxLength', { max_price: MAX_APP_COST }))
  //     .required(t('validation.priceRequired')),
  // };
  return {
    price: Yup.mixed()
      .test(
        'matches',
        t('validation.priceNotValid'),
        value => value && reg_tens.test(value.toString()),
      )
      .test(
        'max',
        t('validation.priceMustBeSmaller', { MAX_APP_COST }),
        value => Number(value) <= Number(MAX_APP_COST),
      )
      .required(t('validation.priceRequired')),
  };
};
