import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { TextField, Typography, Button, Link, IconButton, Collapse } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// CONSTANTS
import { authActions } from '@/constants/analytics';

// UTILITY
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';
import { analyticsAuthFromModal } from '@/utility/analytics';

// STORE
import { AppActions, AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// COMPONENTS
import PasswordInput from '@/components/Form/PasswordInput';
import SocialLogin from '@/components/Auth/help/SocialLogin';

// STYLES
import { TitleContainer, StyledAlert } from './styles';

const Login = props => {
  const { onScreenChange, data, titleProps, viewType, showSocialLogin } = props;

  const [t] = useTranslation();

  const { unique_id, error, partnerData, recommendation_token } = useSelector(
    ({ auth, partner, watchNext }) => ({
      unique_id: auth.unique_id,
      error: auth.errors?.login || '',
      recommendation_token: watchNext.wn_token,
      partnerData: partner.data,
    }),
  );

  const [manageProfDrawerState, login, cleanLoginError] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    (submitData, callback) => AuthActions.login.action({ data: submitData, callback }),
    AuthActions.cleanLoginError.action,
  ]);

  const textOverwrites = partnerData.text_overwrites || {};

  function onLoginSuccess(callbackData) {
    if (data.callback) data.callback(callbackData);
    const profDrawerState = 'profDrawerState' in data ? data.profDrawerState : true;

    manageProfDrawerState(profDrawerState);

    if (viewType === 'dialog') {
      analyticsAuthFromModal(authActions.loggedIn, {
        user_id: callbackData.user_id,
        partner_id: partnerData.id || 0,
      });
    }
  }

  function handleSubmit(values, { setSubmitting }) {
    login({ ...values, unique_id, recommendation_token }, onLoginSuccess);

    setSubmitting(false);
  }

  function handleChangeScreen(e, screen, screenProps) {
    e.preventDefault();

    onScreenChange(screen, screenProps);
    cleanLoginError();
  }

  return (
    <Formik
      initialValues={{ email: data.email || '', password: '' }}
      validationSchema={setYupValidationSchema('loginSchema', t)}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, isSubmitting }) => (
        <Form className="login-form-pw">
          <Collapse in={!!error}>
            <StyledAlert
              className="secondary m-b-20"
              icon={false}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  disableRipple
                  onClick={cleanLoginError}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography className="p4 m-b-10" color="inherit">
                {textOverwrites.auth_not_registred_email || t('notification.notRegisteredUser')}
              </Typography>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                fullWidth
                onClick={e => handleChangeScreen(e, 'registration', { email: values.email })}
              >
                {t('buttons.createAccount')}
              </Button>
            </StyledAlert>
          </Collapse>
          <TitleContainer {...titleProps}>
            <Typography className="m-b-10" variant="h3" align="center">
              {data.title || t('authDrawer.login.title')}
            </Typography>
            {!!data.description && (
              <Typography className="p5 m-t-10" align="center">
                {data.description}
              </Typography>
            )}
          </TitleContainer>
          <TextField
            className="m-b-15"
            label={t('inputs.email')}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            size="small"
          />
          <PasswordInput
            className="m-b-5"
            label={t('inputs.password')}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            size="small"
          />
          <div className="m-b-25 text-right">
            <Link href="#" onClick={e => handleChangeScreen(e, 'forgot')}>
              {t('buttons.forgotPassword')}
            </Link>
          </div>
          {showSocialLogin && (
            <SocialLogin
              submitData={{
                skip_app_onboarding: data.skip_app_onboarding || !!data.callback,
                ...(data?.registerData ?? {}),
              }}
              onSuccess={onLoginSuccess}
            />
          )}
          <Button
            type="submit"
            color="primary"
            size="small"
            fullWidth
            disabled={!isValid && !isSubmitting}
          >
            {t('buttons.continue')}
          </Button>
          <Typography
            className="subtitle p4 m-t-20"
            variant="body1"
            align="center"
            color="textSecondary"
          >
            {t('authDrawer.login.subtitle')}&nbsp;
            <Link href="#" onClick={e => handleChangeScreen(e, 'registration')}>
              {t('buttons.registration')}
            </Link>
          </Typography>
        </Form>
      )}
    </Formik>
  );
};

Login.defaultProps = {
  data: {},
  titleProps: {},
  viewType: 'drawer',
  onScreenChange: () => {},
  showSocialLogin: true,
};

Login.propTypes = {
  data: PropTypes.object,
  titleProps: PropTypes.object,
  viewType: PropTypes.string, // dialog || drawer
  onScreenChange: PropTypes.func,
  showSocialLogin: PropTypes.bool,
};

export default React.memo(Login);
