import { createAction } from '@/utility/actions';

import {
  APP_DETAILS_GET,
  APP_CHANNELS_GET,
  APP_DETAILS_CLEAN,
  APP_GET_REVIEWS,
  APP_ADD_REVIEW,
  APP_GET_EXTERNAL_LINK,
  APP_LIST_UPDATE_STATUS,
  APP_LIST_UPDATE_APP_STATUS,
  APP_LIST_UPDATE_APP_STATUSES,
  APP_LIST_REMOVE,
} from './types';

/**
 * getAppDetails - params: slug
 */
export const getAppDetails = createAction(APP_DETAILS_GET);

/**
 * getAppDetails - params: slug
 */
export const getAppChannels = createAction(APP_CHANNELS_GET);

export const cleanAppDetails = createAction(APP_DETAILS_CLEAN);

/**
 * getAppReviews - params: slug, pagination(page, per_page)
 */
export const getAppReviews = createAction(APP_GET_REVIEWS);

/**
 * addAppReview - params: app_id, data
 */
export const addAppReview = createAction(APP_ADD_REVIEW);

/**
 * getExternalLink - params: slug, partner(optional), onError
 */
export const getExternalLink = createAction(APP_GET_EXTERNAL_LINK);

/**
 * appListUpdateStatus - params: data(apps(array), replace(1,2,all,null) - optional), callback
 */
export const appListUpdateStatus = createAction(APP_LIST_UPDATE_STATUS);

/**
 * appListRemoveStatus - params: app_id, callback
 */
export const appListRemoveStatus = createAction(APP_LIST_REMOVE);

/**
 * appListUpdateAppStatus - params: app_id, status
 */
export const appListUpdateAppStatus = createAction(APP_LIST_UPDATE_APP_STATUS);

/**
 * appListUpdateAppStatuses - params: apps(array - id, status)
 */
export const appListUpdateAppStatuses = createAction(APP_LIST_UPDATE_APP_STATUSES);

export default {
  getAppDetails,
  getAppChannels,
  cleanAppDetails,
  getAppReviews,
  addAppReview,
  getExternalLink,
  appListUpdateStatus,
  appListRemoveStatus,
  appListUpdateAppStatus,
  appListUpdateAppStatuses,
};
