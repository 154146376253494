import React from 'react';
import { useTranslation } from 'react-i18next';

// STYLES
import { Header } from './styles';

const QuantumHeader = React.forwardRef((props, ref) => {
  const [t] = useTranslation();

  return (
    <Header ref={ref}>
      <div id="header">
        <ul className="left">
          <li className="has_img">
            <a href="https://fiber.q.com/fiber/s/welcome">
              <img
                src="https://fiber.q.com/fiber/resource/1607662013000/QuantumFiberBuyflowLogo"
                alt="logo"
              />
            </a>
          </li>
          <li>
            <a href="https://fiber.q.com/fiber/s/why-quantum-fiber">
              {t('header.quantum.whyQuantum')}
            </a>
          </li>
          <li>
            <a href="https://fiber.q.com/fiber/s/what-is-fiber">
              {t('header.quantum.whatIsFiber')}
            </a>
          </li>
        </ul>
        <ul className="right">
          <li>
            <a
              className="btn"
              href="https://fiber.q.com/fiber/s/buy-flow-landing?c__layout=lightning&c__target=c%3AfiberBuyflowEnglish"
            >
              {t('buttons.checkAvailability')}
            </a>
          </li>
          <li>
            <a href="https://fiber.q.com/fiber/s/login/">{t('buttons.signIn')}</a>
          </li>
          <li>
            <a href="https://fiber.q.com/fiber/s/guest-learn-more">{t('common.help')}</a>
          </li>
        </ul>
        <ul className="third">
          <li>
            <a className="btn" href="#">
              {t('buttons.checkAvailability')}
            </a>
          </li>
        </ul>
      </div>
    </Header>
  );
});

export default React.memo(QuantumHeader);
