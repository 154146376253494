import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// CONSTANTS
import { ROUTE_ROOT } from '@/constants/routes';

// COMPONENTS
import RouterLink from '@/components/Link';

// STYLES
import { LogoContainer } from './styles';

const LogoPartner = ({ to, location, ...restProps }) => {
  const partnerData = useSelector(({ partner }) => partner.data);

  return (
    <LogoContainer className="logo-partner" {...restProps}>
      <RouterLink to={to} location={location}>
        <img src={partnerData.logo} alt={partnerData.partner_name} />
      </RouterLink>
    </LogoContainer>
  );
};

LogoPartner.defaultProps = {
  to: ROUTE_ROOT,
  location: '',
};

LogoPartner.propTypes = {
  to: PropTypes.string,
  location: PropTypes.string,
};

export default React.memo(LogoPartner);
