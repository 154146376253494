import { createAction } from '@/utility/actions';

import { INTERNET_OPTION_GET } from './types';

/**
 * getInternetOption - params: unique_id, internet_option_id, onError
 */
export const getInternetOption = createAction(INTERNET_OPTION_GET);

export default { getInternetOption };
