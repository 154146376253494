import axios from 'axios';
import { put } from 'redux-saga/effects';

// CONSTANTS
import { USER_STREAMING_REWARD_GET, USER_PAYMENT_HISTORY_GET } from '@/constants/api';

// STORE
import { PaymentHistoryActions } from '@/store/actions';

const { getUserStreamingReward, getUserPaymentHistory } = PaymentHistoryActions;

export function* userGetStreamingRewardSaga(action) {
  yield put(getUserStreamingReward.start());

  const { onError } = action.payload;

  try {
    const { data: respData } = yield axios.get(USER_STREAMING_REWARD_GET);

    yield put(getUserStreamingReward.success({ data: respData }));
  } catch (error) {
    if (error?.response?.status === 404) {
      if (onError) onError();
    }

    yield put(getUserStreamingReward.fail());
  }
}

export function* userGetPaymentHistorySaga(action) {
  yield put(getUserPaymentHistory.start());

  const { page } = action.payload;

  try {
    const { data: respData } = yield axios.get(USER_PAYMENT_HISTORY_GET, {
      params: { page },
    });

    yield put(getUserPaymentHistory.success({ data: respData.data, meta: respData.meta }));
  } catch (error) {
    yield put(getUserPaymentHistory.fail());
  }
}
