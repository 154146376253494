import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Grid, Button, Link } from '@material-ui/core';
import clsx from 'clsx';

// CONSTANTS
import { ROUTE_START, ROUTE_RESULTS } from '@/constants/routes';
import { LOCATIONS_HEADER } from '@/constants/locations';

// UTILITY
import { analyticsStartFormLink } from '@/utility/analytics';

// COMPONENTS
import LogoPartner from '@/components/LogoPartner';
import RouterLink from '@/components/Link';

// STYLES
import { Wrapper } from './styles';

const PartnerFakeHeader = React.forwardRef((props, ref) => {
  const [t] = useTranslation();

  const { orientation, partner, unique_id } = useSelector(state => ({
    orientation: state.app.orientation,
    unique_id: state.auth.unique_id,
    partner: state.partner.data,
  }));
  const isPortrait = orientation === 'portrait';

  const handleStartClick = () => {
    analyticsStartFormLink({
      location: 'Center',
      type: 'Button',
    });
  };

  return (
    <Wrapper className="header page-header" ref={ref}>
      <AppBar className={clsx('header__app-bar')} position={isPortrait ? 'fixed' : 'static'}>
        <Toolbar className="header__toolbar header__toolbar--main">
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <LogoPartner location={LOCATIONS_HEADER} />
            </Grid>
            <Grid item>
              <Button
                className="header__start-button"
                color="primary"
                component={RouterLink}
                to={unique_id ? ROUTE_RESULTS : ROUTE_START}
                onClick={handleStartClick}
              >
                {unique_id ? t('buttons.backToResults') : t('buttons.getStarted')}
              </Button>
              {partner.phone && (
                <Link
                  className="header__phone"
                  color="primary"
                  href={`tel:${partner.phone}`}
                  align="center"
                >
                  {t('buttons.call', {
                    phone: partner.phone,
                  })}
                </Link>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        <div id="p-header-bottom-content" />
      </AppBar>
      {isPortrait && <Toolbar className="header__toolbar header__toolbar--fake" />}
    </Wrapper>
  );
});

export default React.memo(PartnerFakeHeader);
