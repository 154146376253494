import axios from 'axios';
import { all, put, call } from 'redux-saga/effects';

// CONSTANTS
import { GET_CHANNEL } from '@/constants/api';

// UTILITY
import getLink from '@/utility/routes';
import { showErrorNotification } from '@/utility/saga';

// UTILITY
import store from '@/utility/store';

// STORE
import { swimlanesGetSaga } from '@/store/saga/swimlanes';
import { ChannelActions } from '../actions';

const { getChannelDetails } = ChannelActions;

export function* getChannelSwimlanesSaga(action) {
  const { params = {} } = action.payload;

  yield all([
    call(swimlanesGetSaga, {
      payload: {
        params: { page: 'channel', section: 1, ...params },
      },
    }),
  ]);
}

export function* getChannelDetailsSaga(action) {
  yield put(getChannelDetails.start());

  const { slug, onError } = action.payload;
  const url = getLink(GET_CHANNEL, { slug });

  try {
    const { data: respData } = yield axios.get(url);

    const dma_id = yield store.get('dma_id');
    const zipcode = yield store.get('zipcode');
    const params = {
      channel_id: respData.channel_id,
    };

    if (respData.show_form && dma_id) {
      params.dma_id = dma_id;
      params.zip = zipcode;
    }

    yield call(getChannelSwimlanesSaga, {
      payload: {
        params,
      },
    });

    yield put(getChannelDetails.success({ data: respData }));
  } catch (error) {
    if (error?.response?.status === 422) {
      if (onError) onError();
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getChannelDetails.fail());
  }
}
