import * as Yup from 'yup';

// CONST
import { MAX_COMMENT_LENGTH } from '@/constants/validation';

export default t => {
  return {
    feedback: Yup.string()
      .required(t('validation.commentRequired'))
      .max(MAX_COMMENT_LENGTH, t('validation.maxCommentLength', { length: MAX_COMMENT_LENGTH })),
  };
};
