import { put, call } from 'redux-saga/effects';

// CONSTANTS
import { CONFIRMATION_ISP } from '@/constants/swimlanes';

// STORE
import { ConfirmationISPActions } from '@/store/actions';
import { swimlanesGetSaga } from '@/store/saga/swimlanes';

// UTILITY
import { showErrorNotification } from '@/utility/saga';

const { getConfirmationISPData } = ConfirmationISPActions;

export function* confirmationISPSaga() {
  yield put(getConfirmationISPData.start());

  try {
    yield call(swimlanesGetSaga, {
      payload: {
        params: { page: CONFIRMATION_ISP, section: 1 },
      },
    });

    yield put(getConfirmationISPData.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getConfirmationISPData.fail());
  }
}

export default confirmationISPSaga;
