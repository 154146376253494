import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField, Typography, Button, Link, Checkbox } from '@material-ui/core';
import { Formik, Form } from 'formik';

// CONSTANTS
import { authActions } from '@/constants/analytics';
import { ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS } from '@/constants/routes';

// UTILITY
import { analyticsAuthFromModal } from '@/utility/analytics';
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';

// STORE
import { AppActions, AuthActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';

// COMPONENTS
import PasswordInput from '@/components/Form/PasswordInput';
import RouterLink from '@/components/Link';

// STYLES
import { TitleContainer, TermsContainer } from './styles';
import SocialLogin from '@/components/Auth/help/SocialLogin/SocialLogin';

const Registration = props => {
  const { onScreenChange, data, titleProps, viewType, showSocialLogin } = props;

  const [t] = useTranslation();

  const { partnerData } = useSelector(({ partner }) => ({
    partnerData: partner.data,
  }));

  const [manageProfDrawerState, registerUser] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    (submitData, callback) => AuthActions.registerUser.action({ data: submitData, callback }),
  ]);

  const submitData = {
    skip_app_onboarding: data.skip_app_onboarding || !!data.callback,
    ...(data?.registerData ?? {}),
  };

  function onRegSuccess(callbackData) {
    if (data.callback) data.callback(callbackData);
    const profDrawerState = 'profDrawerState' in data ? data.profDrawerState : true;

    manageProfDrawerState(profDrawerState);

    if (viewType === 'dialog') {
      analyticsAuthFromModal(authActions.signedUp, {
        user_id: callbackData.user_id,
        partner_id: partnerData.id || 0,
      });
    }
  }

  function handleSubmit(values, { setSubmitting }) {
    registerUser(
      {
        ...values,
        ...submitData,
      },
      onRegSuccess,
    );
    setSubmitting(false);
  }

  function handleChangeScreen(e, screen) {
    e.preventDefault();

    onScreenChange(screen);
  }

  return (
    <Formik
      initialValues={{ email: data.email || '', password: '', terms: true }}
      validationSchema={setYupValidationSchema('registrationSchema', t)}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, isSubmitting }) => (
        <Form className="register-form-pw" autoComplete="off">
          <input type="email" name="email" style={{ display: 'none' }} />
          <input type="password" name="password" style={{ display: 'none' }} />
          <TitleContainer {...titleProps}>
            <Typography className="m-b-10 registration-title" variant="h3" align="center">
              {data.title || t('authDrawer.registration.title')}
            </Typography>
            {!!data.description && (
              <Typography className="p5 m-t-10" align="center">
                {data.description}
              </Typography>
            )}
          </TitleContainer>
          <TextField
            className="m-b-15"
            label={t('inputs.email')}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            size="small"
            autoComplete="email"
          />
          <PasswordInput
            className="m-b-10"
            label={t('inputs.password')}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            size="small"
            autoComplete="new-password"
          />
          <TermsContainer className="m-b-30">
            <Checkbox
              className="m-r-10"
              name="terms"
              onChange={handleChange}
              checked={values.terms}
            />
            <Typography className="p5">
              {t('authDrawer.registration.termsMiddle')}{' '}
              <Link
                className="p5"
                component={RouterLink}
                to={ROUTE_TERMS_AND_CONDITIONS}
                variant="body1"
                target="_blank"
                underline="always"
              >
                {t('buttons.termsOfService')}
              </Link>
              &nbsp;
              {t('common.and')}{' '}
              <Link
                className="p5"
                component={RouterLink}
                to={ROUTE_PRIVACY_POLICY}
                variant="body1"
                target="_blank"
                underline="always"
              >
                {t('buttons.privacyPolicy')}
              </Link>
              .
            </Typography>
          </TermsContainer>
          {showSocialLogin && (
            <SocialLogin
              submitData={{ ...submitData, terms: values.terms }}
              onSuccess={onRegSuccess}
            />
          )}
          <Button
            type="submit"
            color="primary"
            size="small"
            fullWidth
            disabled={!isValid && !isSubmitting}
          >
            {t('buttons.continue')}
          </Button>
          <Typography
            className="subtitle p4 m-t-20"
            variant="body1"
            align="center"
            color="textSecondary"
          >
            {t('authDrawer.registration.subtitle')}&nbsp;
            <Link href="#" onClick={e => handleChangeScreen(e, 'login')}>
              {t('buttons.login')}
            </Link>
          </Typography>
        </Form>
      )}
    </Formik>
  );
};

Registration.defaultProps = {
  data: {},
  titleProps: {},
  viewType: 'drawer',
  onScreenChange: () => {},
  showSocialLogin: true,
};

Registration.propTypes = {
  data: PropTypes.object,
  titleProps: PropTypes.object,
  viewType: PropTypes.string, // dialog || drawer
  onScreenChange: PropTypes.func,
  showSocialLogin: PropTypes.bool,
};

export default React.memo(Registration);
