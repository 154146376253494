import styled from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { DARKGREY, ALABASTER } from '@/constants/colors';

// COMPONENTS
import { SwiperCarousel } from '@/components/Carousels/SwiperCarousel';

const ChannelsCarouselStyled = styled(SwiperCarousel)`
  .swiper-container {
    padding: 0 20px;
  }

  .swiper-slide {
    width: 50px;

    &:last-of-type {
      width: 70px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-nav {
    position: absolute;
    height: 100%;
    bottom: 0;
    top: auto;
    transform: translateY(0);

    padding: 20px;

    background-color: ${p => p.theme.palette.background.default};

    &:after {
      z-index: 1;
      font-size: 1.8rem;
      color: ${DARKGREY};
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 35px;
    }
  }

  .swiper-button-prev {
    left: -10px;
    padding-right: 0;

    &:after {
      content: '\\e917';
    }

    &:before {
      left: 100%;
      background: linear-gradient(
        90deg,
        ${p => rgba(p.theme.palette.background.default, 1)} 0%,
        ${p => rgba(p.theme.palette.background.default, 0)} 80%
      );
    }
  }

  .swiper-button-next {
    right: -10px;
    padding-left: 0;

    &:after {
      content: '\\e905';
    }

    &:before {
      right: 100%;
      background: linear-gradient(
        90deg,
        ${p => rgba(p.theme.palette.background.default, 0)} 0%,
        ${p => rgba(p.theme.palette.background.default, 1)} 80%
      );
    }
  }

  &.lifestyle-channels {
    .swiper-container {
      padding: 0 20px;
      margin: 0 -20px;
    }

    .swiper-button-nav {
      background-color: ${ALABASTER};

      &.swiper-button-prev:before {
        background: linear-gradient(90deg, ${rgba(ALABASTER, 1)} 0%, ${rgba(ALABASTER, 0)} 80%);
      }

      &.swiper-button-next:before {
        background: linear-gradient(90deg, ${rgba(ALABASTER, 0)} 0%, ${rgba(ALABASTER, 1)} 80%);
      }
    }
  }
`;

export default ChannelsCarouselStyled;
