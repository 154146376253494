/**
 * Get promo data
 * @param {object} state
 * @returns {object}
 */
const getPromoData = ({ promotion }) => promotion.data;

/**
 * Get promo codes
 * @param {object} state
 * @returns {array}
 */
const getPromoCodes = ({ promotion }) => promotion.promoCodes;

export default {
  getPromoData,
  getPromoCodes,
};
