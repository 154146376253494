export default {
  title: 'TV Shows & Movies',
  filters: {
    searchTitle: 'Advanced Search',
    searchPlaceholder: 'Search by Title',
    genresPlaceholder: 'Filter By Genres',
    appsPlaceholder: 'Filter By Apps',
    types: {
      show: 'TV Shows',
      movie: 'Movies',
    },
  },
  noResults: "We didn't find anything with those filters. Try adjusting your filters.",
  emptySearchResults:
    'We could not find <q>{{query}}</q> in your Apps or other filters selected.\nClick below to reset filters and search across all Streaming Services.',
  emptySearchResultsWithoutFilters:
    "Sorry we couldn't find any results for <q>{{query}}</q>. Try a different spelling? If that doesn't work our team will see if we can get to the bottom of it.",
  emptyMyAppsResults:
    "Looks like you haven't assigned any apps to your account, click below to let us know which apps you have access to",
  emptyNotOnlyMyAppsResults:
    "We didn't find anything with those filters. Try searching in all apps or adjusting your search",
  resetSearch: 'Try to reset filters and search in all apps.',
  selectedFilters: {
    genres: 'Showing content tagged with:',
    apps: 'Showing content available on these apps:',
  },
};
