import { createAction } from '@/utility/actions';

import { SBP_GET_PACKAGE, SBP_CLEAR_RESULTS } from './types';

/**
 * getSlingBestPackage - params: channel_ids -> array of channel ids (required),
 *                               dma_id -> dma id (not required)
 */
export const getSlingBestPackage = createAction(SBP_GET_PACKAGE);

/**
 * clearSBPResults
 */
export const clearSBPResults = createAction(SBP_CLEAR_RESULTS);

export default {
  getSlingBestPackage,
  clearSBPResults,
};
