import produce from 'immer';

import { getChannelDetails } from '../actions/channel';

const initialState = {
  data: {},
  loading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET CHANNEL
    case getChannelDetails.types.START:
      state.data = {};
      state.loading = true;
      return state;

    case getChannelDetails.types.SUCCESS:
      state.data = action.payload.data;
      state.loading = false;
      return state;

    case getChannelDetails.types.FAIL:
      state.loading = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
