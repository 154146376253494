import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Thumbs, Controller, Virtual } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';

// STYLES
import { SwiperStyled } from './styles';

SwiperCore.use([Pagination, Navigation, Thumbs, Controller, Virtual]);

function SwiperCarousel({
  options: { customNavigation, ...carouselOptions },
  className,
  children,
}) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const navButtonsRef = React.useMemo(() => {
    return {
      prevEl: customNavigation?.prevEl ?? prevRef?.current,
      nextEl: customNavigation?.nextEl ?? nextRef?.current,
    };
  }, [customNavigation, prevRef, nextRef]);

  const options = {
    navigation: navButtonsRef,
    ...carouselOptions,
  };

  return (
    <SwiperStyled className={clsx('swiper-carousel', className)}>
      {options.navigation && !customNavigation && (
        <ButtonBase ref={prevRef} className="swiper-button-nav swiper-button-prev" disableRipple />
      )}
      {options.navigation && !customNavigation && (
        <ButtonBase ref={nextRef} className="swiper-button-nav swiper-button-next" disableRipple />
      )}
      <Swiper
        navigation={navButtonsRef}
        onInit={instance => {
          if (instance.params.navigation) {
            instance.params.navigation.prevEl = prevRef.current;
            instance.params.navigation.nextEl = nextRef.current;
            instance.navigation.update();
          }
        }}
        {...options}
      >
        {children}
      </Swiper>
    </SwiperStyled>
  );
}

SwiperCarousel.defaultProps = {
  options: {},
};

SwiperCarousel.propTypes = {
  options: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export { SwiperSlide, SwiperCarousel };
export default SwiperCarousel;
