export default {
  bingeResults: 'Ask My Friends Results',
  bannerSubText:
    "Who better to ask than your friends. We'll generate a list of shows based on your interests and your friends vote to help you decide!",
  createBingeResults: 'Looking for your next show to watch?',
  recommendationList: {
    title: 'Ask My Friends List',
  },
  steps: {
    item1: {
      title: 'Apps',
      subtitle: 'Streaming Apps you use',
    },
    item2: {
      title: 'Content type & genre',
      subtitle: "Specify what you're in the mood for",
    },
    item3: {
      title: 'Choose Multiple content',
      subtitle: 'Add shows and movies to your list',
    },
    item4: {
      title: 'Review & share',
      subtitle: 'Ask your friends to vote on what to watch next',
    },
  },
  services: {
    title: 'What Apps do you use?',
    subtitle: 'You can add more than one platform to generate more shows',
  },
  contentType: {
    title: 'Content type',
  },
  genres: {
    title: 'What type of genre are you looking for?',
    subtitle: '',
  },
  deleteDialog: {
    title: 'Are you sure you want to delete this recommendation list?',
  },
};
