import styled from 'styled-components';
import { rgba } from 'polished';

const LoaderContainer = styled.div`
  position: ${p => p.position};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${p => {
    const themeBg = p.theme.palette.background.default;

    if (p.bgColor) return p.bgColor;

    return p.fullFilled ? themeBg : rgba(themeBg, 0.8);
  }};
  z-index: ${p => p.zIndex || 1101};

  .loader {
    &__text {
      margin: 15px 10px 0;
      text-align: center;

      -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
    }
  }
`;

export default LoaderContainer;
