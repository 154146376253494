import produce from 'immer';

import { getContentMedia, changeContentStatus } from '../actions/content';

const initialState = {
  loadings: {
    media: false,
    actions: {},
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET CONTENT MEDIA
    case getContentMedia.types.START:
      state.loadings.media = true;
      return state;

    case getContentMedia.types.SUCCESS:
    case getContentMedia.types.FAIL:
      state.loadings.media = false;
      return state;

    // GET CONTENT MEDIA
    case changeContentStatus.types.START:
      state.loadings.actions[action.payload.content_id] = true;
      return state;

    case changeContentStatus.types.SUCCESS:
    case changeContentStatus.types.FAIL:
      state.loadings.actions[action.payload.content_id] = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
