import { Component } from 'react';
import PropTypes from 'prop-types';

// HOC
import withRouter from '@/hoc/withRouter';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const {
      router: { location },
    } = this.props;

    if (location.pathname !== prevProps.router.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

ScrollToTop.propTypes = {
  router: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
