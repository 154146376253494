import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Divider, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// STYLES
import { DrawerStyled } from './styles';

const CommonDrawer = ({ title, className, onClose, children, ...restProps }) => (
  <DrawerStyled
    className={clsx('drawer', className)}
    onClose={onClose}
    anchor="right"
    ModalProps={{
      keepMounted: true,
    }}
    {...restProps}
  >
    {title && (
      <>
        <div className="drawer__header">
          <Typography variant="h5">{title}</Typography>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </>
    )}
    {children}
  </DrawerStyled>
);

CommonDrawer.defaultProps = {
  title: '',
  className: '',
};

CommonDrawer.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default React.memo(CommonDrawer);
