import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// STYLES
import { Container } from './styles';

const BannerNotification = props => {
  const { className, title, titleProps, btnText, btnProps } = props;

  return (
    <Container className={clsx('banner-notification section section--dark', className)}>
      <div className="banner-notification__content">
        {!!title && (
          <Typography className="banner-notification__title" variant="body2" {...titleProps}>
            {title}
          </Typography>
        )}
        {!!btnText && (
          <Button
            className="banner-notification__btn"
            color="secondary"
            size="medium"
            {...btnProps}
          >
            {btnText}
          </Button>
        )}
      </div>
    </Container>
  );
};

BannerNotification.defaultProps = {
  className: '',
  title: '',
  titleProps: {},
  btnText: '',
  btnProps: {},
};

BannerNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  titleProps: PropTypes.object,
  btnText: PropTypes.string,
  btnProps: PropTypes.object,
};

export default React.memo(BannerNotification);
