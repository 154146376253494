import { createAction } from '@/utility/actions';

import {
  WIZARD_GET_SKIP_QUESTIONS,
  WIZARD_RESET_SKIP_QUESTIONS,
  WIZARD_GET_UNIQUE_ID,
  WIZARD_ASYNC_INTERNET_OPTIONS,
} from './types';

/**
 * getSkipQuestions - params: slug, csr (true|false), params
 */
export const getSkipQuestions = createAction(WIZARD_GET_SKIP_QUESTIONS);

export const resetSkipQuestions = createAction(WIZARD_RESET_SKIP_QUESTIONS);

/**
 * getUniqueId - params: slug, form_version
 */
export const getUniqueId = createAction(WIZARD_GET_UNIQUE_ID);

/**
 * asyncInternetOptions - params: unique_id
 */
export const asyncInternetOptions = createAction(WIZARD_ASYNC_INTERNET_OPTIONS);

export default {
  getSkipQuestions,
  resetSkipQuestions,
  getUniqueId,
  asyncInternetOptions,
};
