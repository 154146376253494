import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const withMediaQuery = (queries = {}) => Component => props => {
  const mediaProps = {};
  Object.keys(queries).forEach(key => {
    mediaProps[key] = useMediaQuery(queries[key]);
  });
  return <Component {...mediaProps} {...props} />;
};

export default withMediaQuery;
