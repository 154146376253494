export default {
  hero: {
    share: {
      twitter: 'Check out {{appName}} on @mybundletv.',
    },
    disclaimer: 'MyBundle may be compensated when you purchase\u00A0through\u00A0our\u00A0link.',
    anchor: {
      package: '{{count}} Package',
      packages: '{{count}} Packages',
      deal: '{{count}} Deal',
      deals: '{{count}} Deals',
    },
    subscription: {
      title: 'Subscription',
      description:
        'After subscribing to Locast, the application will appear in "My Apps" section, where you can change the price of the application.',
    },
  },
  packages: {
    title: '{{app_name}} Plans',
    subtitle:
      'There are multiple {{app_name}} packages that you can choose from based on your watching preferences.',
  },
  subscription: {
    plans: 'Subscription plans',
    trial_period: 'Trial period: {{days}} days',
  },
  about: {
    title: 'What is {{app_name}}?',
    devices: 'Devices',
    genres: 'Genres',
    keyFeature: 'Key Feature',
    keyFeatures: 'Key Features',
    prosCons: 'Pros & Cons',
    ratings: 'Ratings',
    cordCut: {
      title: 'Ready to cut the cord and cut costs?',
      subtitle: 'Find your customized streaming bundle today.',
    },
  },
  gallery: {
    title: 'Gallery',
  },
  addons: {
    title: 'Addons',
    subtitle: '',
  },
  channels: {
    title: 'Channels',
    subtitle:
      'Choose a base package and add-on packs to see different combinations of channels available.',
  },
  deals: {
    title: 'Deals',
    subtitle: 'Here are some Special Deals available right now:',
  },
  popularShows: {
    title: 'Popular Shows',
    subtitle: '',
  },
  similarContent: {
    title: 'Similiar Content to These Channels',
    subtitle: 'This service could replace some of the TV channels you are used to.',
    liveTV: {
      title: 'Live TV Replacement:',
      subtitle:
        "You can watch a live TV channel (content that's broadcasted in real time) from the highlighted channels listed.",
    },
    onDemand: {
      title: 'Content from these Channels:',
      subtitle:
        "Watch content from the highlighted channels listed after it's been broadcasted or from a digital library at any time.",
    },
    similarGenre: {
      title: 'Similar Genre:',
      subtitle:
        'The service offers content comparable in genre to the highlighted channels listed.',
    },
  },
  similarShows: {
    title: 'Popular Shows',
  },
  ratingsReviews: {
    title1: 'MyBundle User',
    title2: 'Ratings & Reviews',
    writeReview: 'Write Your Review',
    countRatings: '{{count}} ratings across multiple sites',
    emptyState: 'No written reviews, yet. Be the first!',
  },
  otherApps: {
    title: 'Other Apps You May Like',
  },
  writeReview: {
    title: 'Write Review',
    everUsed: 'Ever used {{appName}}? Leave a review',
    subtitle: '',
  },
  backToResults: {
    visitSiteTitle: {
      title: 'Ready to Sign up for {{appName}}?',
    },
    marketplace: {
      title: 'Discover new streaming services',
    },
    backToTheResults: {
      title: 'Looking for Live TV?',
    },
    startPartnerForm: {
      title: 'Looking for Live TV?',
    },
  },
};
