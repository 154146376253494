import * as Yup from 'yup';

// CONST
import { MIN_PASSWORD_LENGTH } from '@/constants/validation';

export default t => {
  return {
    old_password: Yup.string()
      .min(MIN_PASSWORD_LENGTH, t('validation.passwordLength', { length: MIN_PASSWORD_LENGTH }))
      .required(t('validation.oldPasswordRequired')),
    new_password: Yup.string()
      .min(MIN_PASSWORD_LENGTH, t('validation.passwordLength', { length: MIN_PASSWORD_LENGTH }))
      .required(t('validation.newPasswordRequired'))
      .test('passwords-match', t('validation.newPasswordMatch'), function checkPass(value) {
        return this.parent.old_password !== value;
      }),
  };
};
