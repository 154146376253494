import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { browserName, isMobile, osName, mobileVendor } from 'react-device-detect';

// CONSTANTS
import { SEGMENT_WEBHOOK, USER_CHECK_FIELD } from '@/constants/api';

// UTILITY
import { getLink, getReferrer } from '@/utility/routes';
import bugsnag from '@/utility/bugsnagClient';
import sendMailchimpUserData from '@/utility/mailchimp';
import { getUtcDate } from '@/utility/date';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { axiosFivetran } from '@/store/configDefaultAPI';
import { AuthSelectors } from '@/store/selectors';
import { AnalyticsActions } from '@/store/actions';

import packageJson from '../../../package.json';

const { analyticsSendToFivetran, analyticsSendToMailchimp } = AnalyticsActions;

const fivetranKey = process.env.REACT_APP_SEGMENT_WEBHOOK_KEY;

export function* analyticsSendToFivetranSaga(action) {
  if (fivetranKey) {
    yield put(analyticsSendToFivetran.start());

    const { data } = action.payload;
    const url = getLink(SEGMENT_WEBHOOK, { key: fivetranKey });
    const segment_id = yield select(AuthSelectors.getAnonymousId);
    const referrer = getReferrer();
    const device = isMobile ? `mobile - ${mobileVendor}` : `desktop - ${osName}`;

    const updatedData = {
      segment_id: segment_id || '',
      back_or_front: 'frontend',
      utc_time: getUtcDate(),
      browser: browserName,
      device,
      app_version: packageJson.version,
      page_url: data.page_url || window.location?.pathname || '',
      referrer: data.referrer || referrer,
      query_params: window.location.search,
      ...data,
    };

    try {
      yield axiosFivetran.post(url, updatedData);

      yield put(analyticsSendToFivetran.success());
    } catch (error) {
      yield put(analyticsSendToFivetran.fail());

      bugsnag.notify(
        new Error('Unable to send statistics via webhook', { cause: error }),
        event => {
          event.severity = 'info';
          event.addMetadata('data', { ...updatedData, page: window.location?.pathname });
        },
      );
    }
  }
}

export function* analyticsSendToMailchimpSaga(action) {
  yield put(analyticsSendToMailchimp.start());

  const { data } = action.payload;

  try {
    const {
      data: { exist },
    } = yield axios.post(USER_CHECK_FIELD, { field: 'email', value: data.formData.email });

    sendMailchimpUserData({ ...data, exist });

    yield put(analyticsSendToMailchimp.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(analyticsSendToMailchimp.fail());
  }
}
