import produce from 'immer';

import {
  getCLDictionary,
  getCLTopChannels,
  cLSearch,
  getCLChannels,
  getCLReplacementChannels,
  cleanCLState,
} from '../actions/channelLineup';

const initialState = {
  dictionary: {},
  channelsTop: [],
  searchData: null,
  channels: [],
  replacementChannels: [],
  loadings: {
    dictionary: false,
    channelsTop: false,
    searchData: false,
    channels: false,
    replacementChannels: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET DICTIONARY
    case getCLDictionary.types.START:
      state.loadings.dictionary = true;
      return state;

    case getCLDictionary.types.SUCCESS:
      state.dictionary.service_name = action.payload.service_name;
      state.dictionary.genres = action.payload.genres;
      state.dictionary.addons = action.payload.addons;
      state.loadings.dictionary = false;
      return state;

    case getCLDictionary.types.FAIL:
      state.loadings.dictionary = false;
      return state;

    // GET TOP CHANNELS
    case getCLTopChannels.types.START:
      state.channelsTop = [];
      state.loadings.channelsTop = true;
      return state;

    case getCLTopChannels.types.SUCCESS:
      state.channelsTop = action.payload.channels;
      state.loadings.channelsTop = false;
      return state;

    case getCLTopChannels.types.FAIL:
      state.loadings.channelsTop = false;
      return state;

    // SEARCH CHANNELS
    case cLSearch.types.START:
      state.loadings.searchData = true;
      return state;

    case cLSearch.types.SUCCESS:
      state.searchData = action.payload.data;
      state.loadings.searchData = false;
      return state;

    case cLSearch.types.FAIL:
      state.loadings.searchData = false;
      return state;

    // GET CHANNELS
    case getCLChannels.types.START:
      state.loadings.channels = true;
      return state;

    case getCLChannels.types.SUCCESS:
      state.channels = action.payload.data;
      state.loadings.channels = false;
      return state;

    case getCLChannels.types.FAIL:
      state.loadings.channels = false;
      return state;

    // GET REPLACEMENT CHANNELS
    case getCLReplacementChannels.types.START:
      state.loadings.replacementChannels = true;
      return state;

    case getCLReplacementChannels.types.SUCCESS:
      state.replacementChannels = action.payload.data;
      state.loadings.replacementChannels = false;
      return state;

    case getCLReplacementChannels.types.FAIL:
      state.loadings.replacementChannels = false;
      return state;

    // CLEAN STATE
    case cleanCLState.types.INIT:
      state.dictionary = initialState.dictionary;
      state.channelsTop = initialState.channelsTop;
      state.searchData = initialState.searchData;
      state.channels = initialState.channels;
      state.replacementChannels = initialState.replacementChannels;
      return state;

    default:
      return state;
  }
});

export default reducer;
