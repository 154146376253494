import { css } from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { WHITE, MAIN_BLACK, MYSTIC, BLACK } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

// Fonts Headers Roboto Slab  // Text Raleway
// Header Text Color
// Secondary Color
// Input Color
const epb = {
  dark_blue: '#0D123D',
  blue: '#0071BA',
  light_blue: '#0392CF',
  orange: '#F9A606',
  yellow: '#FFCC5B',
  dark_green: '#7AC043',
  light_green: '#8AD74A',
  background: '#F9F9F9',
  inputBackground: '#FFFFFF',
  hoverPrimary: '#089eff',
  grey1: '#2d2d2d',
  grey5: '#CCCCCC',
};

const epbStyles = css`
  @font-face {
    font-family: 'LL Circular Black Web';
    src: url(${`${process.env.PUBLIC_URL}/fonts/Circular/lineto-circular-black.eot`});
    src: url(${`${process.env.PUBLIC_URL}/fonts/Circular/lineto-circular-black.eot?#iefix`})
        format('embedded-opentype'),
      url(${`${process.env.PUBLIC_URL}/fonts/Circular/lineto-circular-black.woff2`}) format('woff2'),
      url(${`${process.env.PUBLIC_URL}/fonts/Circular/lineto-circular-black.woff`}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Futura Next';
    src: url(${`${process.env.PUBLIC_URL}/fonts/FuturaNext/FuturaNext_Bold.otf`}) format('opentype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'Futura Next';
    src: url(${`${process.env.PUBLIC_URL}/fonts/FuturaNext/FuturaNext_Medium.otf`})
      format('opentype');
    font-weight: medium;
  }

  body,
  #root.epb {
    background-color: ${epb.background};
  }

  #root.epb {
    min-height: 300px;
  }

  .partner-epb,
  .partner-epb-csr {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    span,
    a {
      font-weight: 400;
      color: ${epb.dark_blue};
    }
  }
  /* Bottom Right Notification */
  .MuiSnackbar-anchorOriginBottomRight {
    [class*='MuiSnackbarContent-root'] {
      background-color: ${epb.yellow};
      color: ${epb.dark_blue};
      svg {
        color: ${epb.dark_blue};
      }
    }
  }

  /* Popover Components */
  [class*='MuiPopover-root'] {
    &#menu-devices,
    &#menu-genre_search {
      /* Mui List option */
      [class*='MuiList-root'] {
        [class*='MuiListItem-root'] {
          &:hover {
            color: ${epb.yellow};
          }
        }
        [class*='Mui-selected'] {
          color: ${epb.yellow};
        }

        [class*='touchRipple'] {
          background-color: ${epb.yellow};
        }
      }
    }
  }

  /* Mui Breadcrumbs */
  [class*='MuiBreadcrumbs-root'] {
    [class*='MuiLink-root'] {
      &:hover {
        color: ${epb.yellow};
      }
    }
  }

  /* Mui Circular Progress */
  [class*='MuiCircularProgress'] {
    color: ${epb.dark_blue};
  }

  /* COMPONENTS */
  /* Footer */
  .footer-wrapper {
    .section {
      &--dark {
        background-color: ${BLACK};
      }
    }

    /* footer links */
    [class*='MuiLink-root'] {
      &:hover {
        color: ${epb.yellow};
      }
    }
  }

  /* PAGES */
  /* App Details page */
  .app-details-page {
    color: ${epb.dark_blue};

    .hero-container {
    }

    #packages {
      .package-card {
        &--active {
          border-color: ${epb.yellow};
        }

        &:hover {
          .title {
            color: ${epb.yellow};
          }
        }
      }
    }

    .gallery-wrapper {
      .MuiButton-root,
      .play-fake {
        background-color: ${rgba(epb.yellow, 0.8)};
        border-radius: 50%;
        color: ${epb.dark_blue};
      }
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail.active,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:hover,
      .app-image-gallery .image-gallery-thumbnails .image-gallery-thumbnail:focus {
        border-color: ${epb.yellow};
      }
    }

    #channels {
      .filter-wrapper {
        &__count-available {
          color: ${epb.dark_blue};
        }
      }
    }
  }

  /* All pages */
  body {
    background-color: ${epb.background};
    font-family: 'Futura Next' !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    span,
    a {
      font-family: 'Futura Next' !important;
      font-weight: 400;
    }

    /* MATERIAL UI STYLES */
    /* Mui Buttons */
    /* All Buttons */
    [class*='MuiButtonBase-root'] {
      border-radius: 0px !important;
      border: 0;
      &[class*='Mui-disabled'] {
        background-color: ${rgba(epb.yellow, 0.12)};
      }
    }

    [class*='MuiButton-sizeLarge'] {
      padding: 1.7rem 3.5rem;
    }

    /* Mui Button Contained */
    [class*='MuiButton-contained']:not([class*='MuiButton-containedPrimary']) {
      border: none;
      outline: 1px solid ${epb.dark_blue};
      color: ${epb.dark_blue};
      background-color: ${WHITE};

      &:hover {
        color: ${epb.dark_blue};
        background-color: ${MYSTIC};
      }
    }

    /* Mui Button Contained Primary */
    [class*='MuiButton-containedPrimary'],
    #root .post-page .primary button {
      background-color: ${epb.yellow};
      color: ${epb.dark_blue};
    }

    /* Mui Button Outlined Primary */
    [class*='MuiButton-outlinedPrimary'],
    .header-menu-drawer-button {
      border: none;
      outline: 1px solid ${epb.dark_blue};
      color: ${epb.dark_blue};
      background-color: ${WHITE};

      &:hover {
        color: ${epb.dark_blue};
        background-color: ${MYSTIC};
      }
    }

    .buttons__phone-number .buttons-apps-list [class*='MuiButton-outlinedPrimary'].MuiButton-root {
      color: ${epb.dark_blue};
    }

    /* Mui Tab */
    [class*='MuiTab-root'] {
      outline: none;
      border: 1px solid ${epb.dark_blue};
      color: ${epb.dark_blue};
      background-color: ${WHITE};

      &:not([class*='Mui-selected']):hover {
        background-color: ${MYSTIC};
      }

      &[class*='Mui-selected'] {
        color: ${epb.dark_blue};
        background-color: ${epb.yellow};
        border: 1px solid ${epb.yellow};
      }
    }

    /* Mui Autocomplete */
    [class*='MuiAutocomplete-root'] {
      [class*='MuiAutocomplete-endAdornment'] {
        width: auto;
        height: auto;
      }
    }

    /* Mui Autocomplete option */
    [class*='MuiAutocomplete-option'] {
      [data-reason*='remove-option'] {
        color: ${epb.yellow};
        .icon-check {
          color: ${epb.yellow};
        }
      }
    }

    /* Mui Input */
    [class*='MuiInputBase-root'] {
      [class*='MuiInputBase-input'] {
        color: ${epb.dark_blue};
      }

      &[class*='Mui-focused'] {
        [class*='MuiOutlinedInput'] {
          border-color: ${epb.light_blue};
        }
      }
    }

    /* Mui Chip */
    [class*='MuiChip-root'] {
      border: none;
      outline: 1px solid ${epb.dark_blue};
      color: ${epb.dark_blue};
      background-color: ${WHITE};

      [class*='MuiChip-deleteIconColorPrimary'] {
        color: ${epb.dark_blue};
      }

      &[class*='MuiChip-clickable'] {
        &:hover,
        &:focus {
          color: ${epb.dark_blue};
          background-color: ${epb.yellow};
        }
      }
    }

    /* Mui Form Label */
    [class*='MuiFormLabel-root']:not([class*='Mui-error']) {
      color: ${epb.dark_blue};
    }

    /* Mui Form Control */
    [class*='MuiFormControlLabel-root'] {
      [class*='MuiCheckbox-root'] {
        .icon-correct-symbol {
          color: ${epb.dark_blue};
          &:before {
            border-color: ${epb.dark_blue};
            background-color: ${MYSTIC};
          }
        }

        &[class*='Mui-checked'] {
          & + [class*='MuiFormControlLabel-label'] {
            color: ${epb.dark_blue};
          }
          .icon-correct-symbol {
            color: ${epb.dark_blue};
            &:before {
              background-color: ${MYSTIC};
            }
          }
        }
      }
    }

    /* Mui Rating */
    [class*='MuiRating-root'] {
      &[class*='MuiRating-readOnly'] {
        color: ${epb.yellow};
      }
      &[class*='sizeLarge'] {
        [class*='MuiRating-icon'] {
          &[class*='MuiRating-iconFilled'] {
            background-color: ${epb.yellow};
          }
        }
      }
    }

    /* COMPONENTS */
    /* Marker */
    .marker--el,
    .marker:before {
      background-color: ${epb.dark_blue};
      color: ${WHITE} !important;
    }

    /* Info Card */
    .info-card {
      &:hover {
        .title {
          color: ${epb.yellow};
        }
      }
    }
  }

  .partner-epb,
  .partner-epb-csr {
    &.popover-wrapper {
      background-color: transparent;
    }
    .internet-option__phone,
    .modal-item__phone {
      color: ${epb.primary};
    }

    header {
      .backtohome {
        a {
          color: ${epb.dark_blue};
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.5px;
        }
        &:after {
          @media (max-width: ${breakpoints.xs}) {
            display: none;
          }
        }
      }
      @media (max-width: ${breakpoints.md}) and (min-width: ${breakpoints.xs}) {
        div.toolbar > div > div {
          flex-grow: 0;
          max-width: 33.333333%;
          flex-basis: 33.333333%;
          &.right-section {
            .stepper__dot:not(:last-of-type) {
              margin-right: 5px;
            }
            > div > div {
              padding: 16px 8px;
            }
          }
        }
      }

      .toolbar__link.nextlink {
        border-radius: 0;
        padding: 11px 20px;
        background-color: ${epb.yellow};
        color: ${WHITE};
        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
        }
      }
    }

    footer {
      background-color: ${epb.background};
      .next-button {
        background-color: ${epb.yellow};
        color: ${epb.dark_blue};
        &.Mui-disabled {
          background-color: ${rgba(epb.yellow, 0.12)};
        }
      }
      .previous-button {
        outline: 1px solid ${epb.dark_blue};
      }
    }

    .right-section {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.5px;
      .stepper__dot {
        border-color: ${epb.grey5};
        border-width: 1px;
      }
      .stepper__dot--active {
        background-color: ${epb.light_green};
        border-color: ${epb.light_green};
      }
      .stepper__dot--complete {
        border-color: ${epb.light_green};
      }
      .stepper {
        & > span {
          color: ${epb.dark_blue};
        }
      }
    }

    form {
      h1 {
        color: ${epb.blue};
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 45px;
      }
    }
    .toolbar__link.nextlink {
      border-radius: 0;
      padding: 11px 20px;
      color: ${epb.dark_blue};
      &.Mui-disabled {
        color: ${rgba(MAIN_BLACK, 0.4)};
      }
    }
    .zipcodeinput {
      background-color: ${epb.inputBackground};
      .Mui-focused {
        color: ${epb.dark_blue} !important;
        fieldset {
          border-color: ${epb.light_blue} !important;
        }
      }
    }
    form {
      label.radio-card {
        border-radius: 0 !important;
        background-color: ${epb.inputBackground};
        .radio-card__title {
          font-weight: 500;
          text-align: center;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 24px;
          color: ${epb.dark_blue};
        }
        &:hover {
          border-color: ${epb.light_blue} !important;
        }
        &.radio-card--checked {
          border-radius: 0 !important;
          &:before {
            right: 10px;
            border-radius: 0;
            border: 1px solid ${epb.light_blue};
            color: ${epb.light_blue};
            font-size: 14px;
            background-color: ${WHITE};
          }
          .radio-card__title {
            color: ${epb.dark_blue};
          }
          &:after {
            box-shadow: 0 0 0 1px ${epb.light_blue};
            border-radius: 0 !important;
          }
        }
      }
    }
    .checkbox-card {
      background-color: ${epb.inputBackground};
      border-radius: 0 !important;

      span.checkbox-card__title {
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 24px;
        color: ${epb.dark_blue};
      }
      &:hover {
        border-color: ${epb.light_blue} !important;
      }
      &.checkbox-card--checked {
        border-radius: 0 !important;
        &:before {
          border-radius: 0;
          right: 10px;
          border: 1px solid ${epb.light_blue};
          color: ${epb.light_blue};
          font-size: 14px;
          background-color: ${WHITE} !important;
        }
        .checkbox-card__title {
          color: ${epb.dark_blue};
        }
        &:after {
          box-shadow: 0 0 0 1px ${epb.light_blue};
          border-radius: 0 !important;
        }
      }
    }
    .swiper-button-nav {
      background-color: ${epb.background} !important;

      &.swiper-button-prev:before {
        background: linear-gradient(
          90deg,
          ${rgba(epb.background, 1)} 0%,
          ${rgba(epb.background, 0)} 80%
        ) !important;
      }

      &.swiper-button-next:before {
        background: linear-gradient(
          90deg,
          ${rgba(epb.background, 0)} 0%,
          ${rgba(epb.background, 1)} 80%
        ) !important;
      }
    }

    //Channel Page
    &.channelspage {
      form > div {
        margin-left: auto;
        margin-right: auto;
      }
      h6 {
        font-weight: bold;
        padding-top: 10px;
      }
      .channel-header {
        background-color: ${epb.background};
        .search-channels,
        .genre-select {
          background-color: ${epb.inputBackground};
        }
        .search-channels .Mui-focused {
          color: ${epb.dark_blue};
          fieldset {
            border-color: ${epb.dark_blue};
          }
        }
        .genre-select.Mui-focused {
          fieldset {
            border-color: ${epb.dark_blue};
          }
        }
      }
      .channels-zipcode__submit {
        background-color: ${epb.yellow};
        &:hover {
          background-color: ${epb.yellow};
        }
        &.Mui-disabled {
          background-color: ${rgba(epb.yellow, 0.12)};
        }
      }
      @media (max-width: ${breakpoints.sm}) {
      }

      .channel-list > a {
        font-size: 14px;
      }
    }
    //email page
    .email-address .m-b-30 {
      background-color: ${epb.inputBackground};
      .Mui-focused {
        color: ${epb.dark_blue} !important;
        fieldset {
          border-color: ${epb.light_blue} !important;
        }
      }
    }
    .terms-wrapper {
      > span {
        background: ${epb.inputBackground};
        span {
          color: ${WHITE};
        }
      }
      .Mui-checked .icon-correct-symbol:before {
        background-color: ${epb.dark_blue};
        border: 1px solid ${epb.dark_blue};
      }
      p.noselect {
        a {
          color: ${epb.dark_blue};
        }
      }
    }
    //end email page

    &.resultspage {
      .page-content {
        h4.results-subtitle {
          padding-bottom: 12px;
          color: ${epb.blue};
        }
        p.disclaimer {
          display: none;
        }
        p.internet-subtitle {
          font-size: 1.6rem;
          margin: 0 auto 24px;
          width: 800px;
          max-width: 90%;
        }
      }
      p.internet-subtitle {
        font-size: 1.6rem;
        margin: 0 auto 24px;
        width: 800px;
        max-width: 90%;
      }
      & > .section--grey {
        background-color: ${epb.background};
      }
      .learn-more-button {
        background-color: ${epb.yellow};
        color: ${epb.dark_blue};
        font-size: 18px;
        line-height: 20px;
        &:hover {
          background-color: ${epb.orange};
        }
      }
      .internet-section {
        h5 {
          color: ${epb.blue};
          font-size: 3rem;
          line-height: 3.1rem;
        }
        p {
          font-size: 16px;
          &.internet-section__price {
            font-size: 24px;
          }
        }
        .streaming-marketplace-button {
          background-color: ${epb.yellow};
          color: ${epb.dark_blue};
          outline-color: ${epb.yellow};
          font-size: 18px;
          line-height: 20px;
          border: 0;
          border-radius: 0;
          &:hover {
            background-color: ${epb.orange};
          }
        }
      }
      .streaming-marketplace-button {
        color: ${epb.dark_blue};
        border-color: ${epb.yellow};
        &:hover {
          color: ${WHITE};
          background-color: ${epb.dark_blue};
        }
      }
      .channel-list h2 {
        color: ${epb.dark_blue};
      }
      .share__item a:hover {
        color: ${epb.dark_blue} !important;
      }
      .share__item.share--copy-link:hover {
        color: ${epb.dark_blue};
      }
      .edit-channels-link {
        color: ${epb.dark_blue};
        background-color: ${WHITE};
        border-radius: 0;
        outline: 1px solid ${epb.dark_blue};
      }
      .email-section__btn {
        background-color: ${epb.dark_blue};
        span {
          color: ${WHITE};
        }
        &.Mui-disabled {
          background-color: ${rgba(MAIN_BLACK, 0.12)};
          span {
            color: ${epb.dark_blue};
          }
        }
      }
      .visit-site {
        background-color: ${epb.yellow};
        color: ${epb.dark_blue};
        font-size: 18px;
        line-height: 20px;
      }
      @media (max-width: ${breakpoints.xs}) {
        header.results {
          display: none !important;
        }
        .channel-list > div {
          box-shadow: none;
        }
        footer.app-bar--shadow {
          box-shadow: none;
          background-color: ${WHITE};

          .swiper-button-nav {
            background-color: ${WHITE} !important;

            &.swiper-button-prev:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 1)} 0%,
                ${rgba(WHITE, 0)} 80%
              ) !important;
            }

            &.swiper-button-next:before {
              background: linear-gradient(
                90deg,
                ${rgba(WHITE, 0)} 0%,
                ${rgba(WHITE, 1)} 80%
              ) !important;
            }
          }
        }
        .channel-list__link {
          color: ${epb.dark_blue};
        }
      }
      .no-results {
        &__button {
          background-color: ${epb.yellow};
          color: ${epb.dark_blue};
          &:hover {
            background-color: ${epb.yellow};
          }
        }
      }
    }
    // end results page

    .success {
      i {
        color: ${epb.dark_blue};
      }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${epb.dark_blue} !important;
    }

    /* Modal content */
    .modal-content {
      a {
        border-radius: 0;
      }
      .modal-item__title-title {
        font-weight: bold;
        color: ${epb.blue};
      }
      .learnmore {
        border: none;
        color: ${epb.light_blue};
        font-size: 14px;
        line-height: 20px;
        border-radius: 0;
        text-decoration: underline;
        span {
          color: ${epb.light_blue};
          font-size: 14px;
        }
        &:hover {
          span {
            color: ${epb.blue};
          }
          background-color: ${WHITE};
        }
      }
    }
    .visit-site {
      background-color: ${epb.yellow};
      color: ${epb.dark_blue};
      font-size: 18px;
      line-height: 20px;
      border-radius: 0;
      &:hover {
        background-color: ${epb.orange};
      }
    }
    .detail-item__title {
      .total,
      .servicename {
        color: ${epb.dark_blue};
      }
    }
  }
`;

export default epbStyles;
