import { css } from 'styled-components';

const calStyles = css`
  /* cyrillic-ext */
  .nav-list .streaming-choice {
    display: none;
  }
`;

export default calStyles;
