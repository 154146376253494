import React from 'react';
import { useSelector } from 'react-redux';
import MuiSlide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

const Slide = React.forwardRef((props, ref) => {
  const { isIframe } = useSelector(({ app }) => ({
    isIframe: app.isIframe,
  }));

  return isIframe ? (
    <Fade ref={ref} {...props} />
  ) : (
    <MuiSlide direction="up" ref={ref} {...props} />
  );
});

export default React.memo(Slide);
