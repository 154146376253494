import * as Yup from 'yup';

export default () => {
  return {
    apps: {
      apps: Yup.array().min(1),
    },
    genres: {
      content_types: Yup.array().min(1),
      genres: Yup.array(),
    },
  };
};
