/**
 * Get Streaming Data Temp
 * @param {object} state
 * @returns {null | object}
 */
const getSubscriptionDictionary = ({ subscription }) => subscription.dictionary;

export default {
  getSubscriptionDictionary,
};
