import produce from 'immer';

import {
  getUserStreamingReward,
  getUserPaymentHistory,
  clearUserPaymentHistory,
} from '../actions/paymentHistory';

const initialState = {
  data: {
    apps: [],
    balances: {},
    streaming_reward: null,
  },
  history: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 4,
    },
  },
  loadings: {
    reward: false,
    history: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // USER GET STREAMING REWARD
    case getUserStreamingReward.types.START:
      state.loadings.reward = true;
      return state;

    case getUserStreamingReward.types.SUCCESS:
      state.data.apps = action.payload.data.apps;
      state.data.balances = action.payload.data.balances || {};
      state.data.streaming_reward = action.payload.data.streaming_reward;
      state.loadings.reward = false;
      return state;

    case getUserStreamingReward.types.FAIL:
      state.loadings.reward = false;
      return state;

    // USER GET PAYMENT HISTORY
    case getUserPaymentHistory.types.START:
      state.loadings.history = true;
      return state;

    case getUserPaymentHistory.types.SUCCESS:
      state.history.data = [...state.history.data, ...action.payload.data];
      state.history.meta = action.payload.meta;
      state.loadings.history = false;
      return state;

    case getUserPaymentHistory.types.FAIL:
      state.loadings.history = false;
      return state;

    // USER CLEAR PAYMENT HISTORY
    case clearUserPaymentHistory.types.INIT:
      state = initialState;
      return state;

    default:
      return state;
  }
});

export default reducer;
