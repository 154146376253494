// LOCALIZATION
import { t } from '@/locale/i18n';

// UTILITY
import bugsnagClient from './bugsnagClient';

export class CustomError extends Error {
  constructor(message, opts) {
    super(message, opts);
    this.name = 'CustomError';
  }
}

export function formatErrorMessage(error, defaultMessage) {
  if (
    typeof error === 'string' ||
    error.name === 'CustomError' ||
    (error.response && error.response.status < 500)
  ) {
    return error;
  }

  // eslint-disable-next-line
  console.error(error);

  return defaultMessage;
}

const getErrorMessage = (err, format = true) => {
  let defaultMessage =
    'Looks like we encountered a problem, please contact help@mybundle.tv to continue';

  try {
    defaultMessage = t('notification.encProblem', defaultMessage);

    const error = format ? formatErrorMessage(err, defaultMessage) : err;

    if (typeof error === 'string') {
      return error;
    }

    if (error.name === 'CustomError') {
      return error.message;
    }

    const errorResponse = error.response;
    let errorMessage = defaultMessage;

    if (errorResponse) {
      const { data, status } = errorResponse;

      if (status === 401) {
        errorMessage = '';
      } else if (data.errors) {
        errorMessage = Object.values(data.errors)[0];
      } else {
        errorMessage = data.error || data.message || data.payload?.message || errorMessage;
      }
    }

    return errorMessage;
  } catch (error) {
    bugsnagClient.notify(new Error(error.message, { cause: err }));

    return defaultMessage;
  }
};

export default getErrorMessage;
