import * as Yup from 'yup';

// CONST
import { MIN_PASSWORD_LENGTH } from '@/constants/validation';

export default t => {
  return {
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .min(MIN_PASSWORD_LENGTH, t('validation.passwordLength', { length: MIN_PASSWORD_LENGTH })),
  };
};
