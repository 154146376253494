export default {
  section1: {
    title: 'Discover the Streaming TV Package For You — With MyBundle',
    subtitle: 'HOW IT WORKS',
    description:
      'We know your Cable TV bundle is just too expensive and wasteful. Our free Find My Bundle tool will help you eliminate all the work needed to find the streaming services that have the Live TV content you actually want.  All it takes is a few minutes to find out how you can save hundreds of dollars a year.',
  },
  section2: {
    title: 'Answer questions about your lifestyle/viewing preferences',
    description1:
      'Questions include the size of your household, location, recording needs, and the devices you use. Combined with the next step, our algorithm will be able to instantly generate a personalized viewing package.',
    description2: '',
    cta: 'Get Started',
  },
  section3: {
    title: 'Choose the channels you care most about',
    description1:
      "Select your must-have channels by either browsing the list of most popular channels, searching for specific channels and shows or sorting by genre. Upon selection, the channel will be stored in your customized preferred channels list. You can also click Edit Channels, to modify your Channel selection. The fewer channels you select the more you'll save! If you decide to provide your email address (it's optional,) you will receive a copy of your results to always be able to refer to.",
    cta: 'Get Started',
  },
  section4: {
    title: 'View your results',
    description1:
      'We sort through over 100 million combinations to present your personalized recommended options:',
    bullet1: {
      title: 'Best match',
      text: 'The best match will offer all or most of the channels and features you input. ',
    },
    bullet2: {
      title: 'Top value',
      text:
        'If you want to save money and still get most of your channels, this alternative provides a wide selection of viewing options at an affordable rate. ',
    },
    bullet3: {
      title: 'Build my bundle',
      text:
        'Find alternative services that offer the same or similar content to the channels you selected.',
    },
    description2: '',
    cta: 'Get Started',
  },
  section5: {
    title: 'Sign up for streaming services',
    description1:
      'Browse your results to find the best offer for you. Click on the "Channel Lists" on each live TV streaming service to see a list of which channels selected are offered. For more information about each streaming service, you can also hit the “Learn More” button to visit the apps page in the streaming marketplace. Once finished, hit the “Visit Site” button to sign up directly with your new streaming home.',
    description2: '',
    cta: 'Get Started',
  },
};
