import axios from 'axios';
import { call, put, select, all } from 'redux-saga/effects';

// CONSTANTS
import {
  WATCH_LIST_INFO,
  WATCH_LIST_CONTENT,
  WATCH_LIST_COPY_CONTENT,
  WATCH_LIST_GET_LIST,
  WATCH_LIST_UPDATE,
  WATCH_LIST_DELETE,
  WATCH_LIST_CREATE,
  WATCH_LIST_REMOVE_CONTENT,
  WATCH_LIST_ADD_CONTENT,
  WATCH_LIST_ADD_CONTENT_DEF_LIST,
  WATCH_LIST_ADD_CONTENTS_MULTIPLE_WATCHLISTS,
} from '@/constants/api';

// UTILITY
import { getLink, getSerializedParams } from '@/utility/routes';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';

// STORE
import { WatchListActions, UserActions, NotifActions } from '@/store/actions';
import { AuthSelectors, PartnerSelectors, OnboardingSelectors } from '@/store/selectors';
import { swimlanesGetSaga } from './swimlanes';
import { userGetContentCountSaga } from './user';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  addToWatchList,
  addToDefWatchList,
  removeFromWatchList,
  cleanWatchList,
  getWatchList,
  getWatchListContent,
  copyWatchListContent,
  getWatchlists,
  updateWatchlist,
  deleteWatchlist,
  createWatchlist,
  getContentAddNewWatchList,
  addContentsToWatchLists,
} = WatchListActions;
const { userGetContentCount } = UserActions;
const { pushSuccessNotificationAction } = NotifActions;

export function* watchListGetCollectionsSaga(action) {
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const selectedApps = yield select(OnboardingSelectors.getSelectedApps);

  const apps = isAuth ? [] : selectedApps;

  try {
    yield call(swimlanesGetSaga, {
      payload: { params: { ...action.payload.params, app_ids: apps.map(app => app.id) } },
    });
  } catch (error) {
    yield call(showErrorNotification, error);
  }
}

export function* addToWatchListSaga(action) {
  const { content_id, watch_lists, contentData, callback, isSharedList = false } = action.payload;

  yield put(addToWatchList.start({ content_id }));

  const url = getLink(WATCH_LIST_ADD_CONTENT, { content_id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    const { data: respData } = yield axios.post(url, { watch_lists });

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    yield put(addToWatchList.success({ content_id, watch_lists, contentData, isSharedList }));

    if (callback) callback();

    const partner = yield select(PartnerSelectors.getPartnerData);

    const wlData = respData?.watch_lists || [];
    const wlNames = wlData.map(wl => wl.title).join(', ') || '';

    yield put(
      pushSuccessNotificationAction(
        partner?.text_overwrites?.content_add_to_watchlist ||
          t('notification.successfullyAddedTo', { name: wlNames }),
      ),
    );

    yield put(addToWatchList.stop({ content_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addToWatchList.fail({ content_id }));
  }
}

export function* addToDefWatchListSaga(action) {
  const { content_id, data, onSuccess } = action.payload;

  const url = getLink(WATCH_LIST_ADD_CONTENT_DEF_LIST, { content_id });

  try {
    yield put(addToDefWatchList.start({ content_id }));

    const { data: respData } = yield axios.post(url, data);

    yield put(addToDefWatchList.success({ content_id, data }));

    yield put(
      addToWatchList.success({ content_id, watch_lists: [respData.data.id], isSharedList: false }),
    );

    if (onSuccess) onSuccess();

    const partner = yield select(PartnerSelectors.getPartnerData);

    yield put(
      pushSuccessNotificationAction(
        partner?.text_overwrites?.content_add_to_watchlist ||
          t('notification.successfullyAddedTo', { name: respData.data.title }),
      ),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addToDefWatchList.fail({ content_id }));
  }
}

export function* removeFromWatchListSaga(action) {
  const { content_id, watch_lists, callback, isSharedList = false } = action.payload;

  yield put(removeFromWatchList.start({ content_id }));

  const url = getLink(WATCH_LIST_REMOVE_CONTENT, { content_id });
  const serializedParams = getSerializedParams({ watch_lists });

  try {
    const { data: respData } = yield axios.delete(url, serializedParams);

    yield put(userGetContentCount.init());

    yield put(removeFromWatchList.success({ content_id, watch_lists, isSharedList }));

    if (callback) callback();

    const partner = yield select(PartnerSelectors.getPartnerData);

    const wlData = respData?.watch_lists || [];
    const wlNames = wlData.map(wl => wl.title).join(', ') || '';

    yield put(
      pushSuccessNotificationAction(
        partner?.text_overwrites?.content_remove_from_watchlist ||
          t('notification.successfullyRemovedFrom', { name: wlNames }),
      ),
    );

    yield put(removeFromWatchList.stop({ content_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(removeFromWatchList.fail({ content_id }));
  }
}

export function* getWatchListContentSaga(action) {
  const { id, params = {}, clearCache = false } = action.payload;

  const url = getLink(WATCH_LIST_CONTENT, { id });

  try {
    const userHasApps = !!params?.app_ids?.length;
    const isMyApps = params.appsType === 0;
    const isFreeApps = params.appsType === 3;
    const needClean = isMyApps && !userHasApps;

    if (needClean) {
      yield put(cleanWatchList.init({ cleanData: false }));
      yield put(getWatchListContent.stop());

      return;
    }

    yield put(getWatchListContent.start({ onScroll: !!params.onScroll }));

    if (isFreeApps && !params.app_ids.length) params.app_ids = [''];

    const { data: respData } = yield axios.get(url, {
      params,
      clearCacheEntry: clearCache,
    });

    yield put(getWatchListContent.success(respData));

    return { ...respData, id };
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWatchListContent.fail());
  }
}

export function* getWatchListSaga(action) {
  const { id, params = {}, onError, clearCache = false } = action.payload;

  const url = getLink(WATCH_LIST_INFO, { id });
  const partner = yield call(getPartnerSlug);

  try {
    yield put(getWatchList.start());

    const { data: respData } = yield axios.get(url, {
      params: { partner },
      clearCacheEntry: clearCache,
    });

    yield call(getWatchListContentSaga, { payload: { id, params, clearCache } });

    yield put(getWatchList.success({ data: respData.data, dictionary: respData.dictionary }));
  } catch (error) {
    const status = error.response?.status;

    yield call(showErrorNotification, error);

    if (onError) onError(status);

    yield put(getWatchList.fail());
  }
}

export function* copyWatchListContentSaga(action) {
  yield put(copyWatchListContent.start());

  const { id, onSuccess } = action.payload;

  const url = getLink(WATCH_LIST_COPY_CONTENT, { id });

  try {
    const { data: respData } = yield axios.post(url);

    yield put(
      copyWatchListContent.success({ data: { id: respData.data.id, title: respData.data.title } }),
    );

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(copyWatchListContent.fail());
  }
}

export function* getWatchlistsSaga(action) {
  yield put(getWatchlists.start());

  const { withContent = false, onSuccess } = action.payload;

  try {
    const { data: respData } = yield axios.get(WATCH_LIST_GET_LIST);
    let watchlists = respData.data;

    if (withContent) {
      if (watchlists.length === 1) {
        const watchlist = watchlists[0];

        yield call(getWatchListSaga, {
          payload: { id: watchlist.id, params: { per_page: 12 } },
        });
      } else {
        const watchlistsContentActions = [];

        for (const item of watchlists) {
          const watclistContentAction = call(getWatchListContentSaga, {
            payload: { id: item.id, params: { per_page: 7 } },
          });

          watchlistsContentActions.push(watclistContentAction);
        }

        const watchlistsContent = yield all(watchlistsContentActions);

        watchlists = watchlists.map(watchlist => {
          const watchlistContent = watchlistsContent.find(wcI => watchlist.id === wcI.id);
          const hasMoreContent = !!watchlistContent && watchlistContent.meta.last_page > 1;

          return { ...watchlist, content: watchlistContent.data, hasMoreContent };
        });
      }
    }

    if (onSuccess) onSuccess(watchlists);

    yield put(getWatchlists.success({ data: watchlists }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getWatchlists.fail());
  }
}

export function* updateWatchListSaga(action) {
  const { id, data, onSuccess } = action.payload;

  const url = getLink(WATCH_LIST_UPDATE, { id });

  try {
    yield put(updateWatchlist.start());

    yield axios.post(url, data);

    yield put(updateWatchlist.success({ id, data }));

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(updateWatchlist.fail());
  }
}

export function* deleteWatchListSaga(action) {
  const { id, onSuccess } = action.payload;

  const url = getLink(WATCH_LIST_DELETE, { id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield put(deleteWatchlist.start());

    yield axios.delete(url);

    if (isAuth) {
      yield call(userGetContentCountSaga);
    }

    yield put(deleteWatchlist.success({ id }));

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(deleteWatchlist.fail());
  }
}

export function* createWatchListSaga(action) {
  const { data, withContent = false, onSuccess } = action.payload;
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield put(createWatchlist.start());

    const { data: respData } = yield axios.post(WATCH_LIST_CREATE, data);

    if (isAuth) {
      yield call(userGetContentCountSaga);
    }

    let watchlistContent = { data: [] };
    let hasMoreContent = false;

    if (withContent) {
      watchlistContent = yield call(getWatchListContentSaga, {
        payload: { id: respData.data.id, params: { per_page: 7 } },
      });

      hasMoreContent = !!watchlistContent && watchlistContent.meta.last_page > 1;
    }

    yield put(
      createWatchlist.success({
        data: { ...respData.data, content: watchlistContent.data, hasMoreContent },
      }),
    );

    if (onSuccess) onSuccess(respData.data);
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(createWatchlist.fail());
  }
}

export function* getContentAddNewWatchListSaga(action) {
  const { id, params = {} } = action.payload;

  const url = getLink(WATCH_LIST_CONTENT, { id });

  try {
    yield put(getContentAddNewWatchList.start());

    const { data: respData } = yield axios.get(url, {
      params,
    });

    yield put(getContentAddNewWatchList.success(respData));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getContentAddNewWatchList.fail());
  }
}

export function* addContentsToWatchListsSaga(action) {
  const { data, onSuccess } = action.payload;

  yield put(addContentsToWatchLists.start());

  const hasWatchlistsData = !!data?.watchlists?.length;
  const hasNewWatchlistData = !!data?.newWLList;

  try {
    if (hasWatchlistsData) {
      yield axios.post(WATCH_LIST_ADD_CONTENTS_MULTIPLE_WATCHLISTS, data);
    }

    if (hasNewWatchlistData) {
      yield call(createWatchListSaga, { payload: { data: data.newWLList } });
    }

    yield put(userGetContentCount.init());

    yield put(addContentsToWatchLists.success());

    if (onSuccess) onSuccess();

    yield put(pushSuccessNotificationAction(t('notification.successfullyAddedToWatchLists')));

    yield put(addContentsToWatchLists.stop());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addContentsToWatchLists.fail());
  }
}
