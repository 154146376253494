import React from 'react';
import { useLocation, useNavigate, useParams, matchPath } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const routerMatch = (options = {}) => {
      return !!matchPath(options, location.pathname);
    };

    return <Component {...props} router={{ location, params, navigate, routerMatch }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
