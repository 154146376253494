import { createAction } from '@/utility/actions';

import {
  MOVIE_DETAILS_GET,
  MOVIE_SIMILAR_GET,
  CONTENT_SUBSCRIBE,
  MOVIE_STREAMING_DATA_GET,
  MOVIE_UPDATE_MEDIA,
} from './types';

/**
 * getMovieDetails - params: movie_id
 */
export const getMovieDetails = createAction(MOVIE_DETAILS_GET);

/**
 * getMovieStreamingData - params: movie_id, isTemp
 */
export const getMovieStreamingData = createAction(MOVIE_STREAMING_DATA_GET);

/**
 * getSimilarMovies - params: movie_id
 */
export const getSimilarMovies = createAction(MOVIE_SIMILAR_GET);

/**
 * subscribeToChanges - params: data(object)
 */
export const subscribeToChanges = createAction(CONTENT_SUBSCRIBE);

/**
 * updateMovieMedia - data
 */
export const updateMovieMedia = createAction(MOVIE_UPDATE_MEDIA);

export default {
  getMovieDetails,
  getMovieStreamingData,
  subscribeToChanges,
  getSimilarMovies,
  updateMovieMedia,
};
