import produce from 'immer';

import {
  popNotification,
  pushNotification,
  setNotificationOptions,
  clearNotificationOptions,
} from '../actions/notifications';

const initialState = {
  list: [],
  options: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // PUSH NOTIFICATION
    case pushNotification.types.INIT:
      state.list.push({ ...action.payload, key: new Date().getTime() + Math.random() });
      return state;

    // POP NOTIFICATION
    case popNotification.types.INIT:
      state.list = state.list.filter(notify => notify.key !== action.payload);
      return state;

    // SET NOTIFICATION OPTIONS
    case setNotificationOptions.types.INIT:
      state.options = action.payload;
      return state;

    // CLEAR NOTIFICATION OPTIONS
    case clearNotificationOptions.types.INIT:
      state.options = initialState.options;
      return state;

    default:
      return state;
  }
});

export default reducer;
