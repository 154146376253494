import { matchPath, useLocation, matchRoutes } from 'react-router-dom';

// CONSTANTS
import { mybundleRoutes, partnerRoutes } from '@/constants/routes';

const routes = mybundleRoutes.concat(partnerRoutes).map(item => ({ path: item }));

export function getMatchPathFromArray(link, array) {
  for (const path of array) {
    const match = matchPath(
      {
        path,
      },
      link,
    );

    if (match) {
      return match;
    }
  }

  return null;
}

export function usePathPattern() {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);

  return route.path;
}

export function removeURLParameter(url, paramKey, replaceUrl = true) {
  const urlIns = new URL(url);

  if (Array.isArray(paramKey)) {
    paramKey.map(pKey => urlIns.searchParams.delete(pKey));
  } else {
    urlIns.searchParams.delete(paramKey);
  }

  if (replaceUrl && window.history.replaceState) {
    window.history.replaceState(null, '', urlIns.href);
  }

  return urlIns.href;
}

export default getMatchPathFromArray;
