import produce from 'immer';

// UTILITY
import { updateListStatus } from '@/utility/content';

// STORE
import {
  getDiscoverList,
  cleanDiscoverList,
  getDiscoverFilters,
  getDiscoverSearch,
  cleanDiscoverSearch,
} from '../actions/discover';
import { appListUpdateAppStatuses } from '../actions/appDetails';

const initialState = {
  list: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 24,
    },
    metaData: [],
  },
  filters: null,
  search: {
    data: [],
    string: '',
  },
  loadings: {
    list: true,
    filters: true,
    search: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET LIST
    case getDiscoverList.types.START:
      state.loadings.list = true;
      return state;

    case getDiscoverList.types.SUCCESS:
      state.list.data =
        action.payload.page === 1
          ? action.payload.data
          : state.list.data.concat(action.payload.data);
      state.list.meta.current_page = action.payload.meta.current_page;
      state.list.meta.last_page = action.payload.meta.last_page;
      state.list.meta.per_page = action.payload.meta.per_page;
      state.list.metaData = action.payload?.meta_data || [];
      return state;

    case getDiscoverList.types.STOP:
    case getDiscoverList.types.FAIL:
      state.loadings.list = false;
      return state;

    // CLEAN LIST
    case cleanDiscoverList.types.INIT:
      state.list = initialState.list;
      state.loadings.list = initialState.loadings.list;
      return state;

    // GET FILTERS
    case getDiscoverFilters.types.START:
      state.loadings.filters = true;
      return state;

    case getDiscoverFilters.types.SUCCESS:
      state.filters = action.payload.data;
      state.loadings.filters = false;
      return state;

    case getDiscoverFilters.types.FAIL:
      state.loadings.filters = false;
      return state;

    // GET SEARCH
    case getDiscoverSearch.types.START:
      state.search.string = action.payload.params.search_string;
      state.loadings.search = true;
      return state;

    case getDiscoverSearch.types.SUCCESS:
      state.search.data = action.payload.data;
      state.loadings.search = false;
      return state;

    case getDiscoverSearch.types.FAIL:
      state.loadings.search = false;
      return state;

    // CLEAN DISCOVER SEARCH
    case cleanDiscoverSearch.types.INIT:
      state.search = initialState.search;
      return state;

    // UPDATE APP LIST STATUSES
    case appListUpdateAppStatuses.types.INIT: {
      action.payload.apps.forEach(app => {
        if (state.list.data.length) {
          state.list.data = state.list.data.map(item => {
            return updateListStatus(item, app.id, app.status);
          });
        }
      });

      return state;
    }

    default:
      return state;
  }
});

export default reducer;
