import produce from 'immer';

// UTILITY
import store from '@/utility/store';

// STORE
import {
  startWatchNextFrom,
  submitWatchNextForm,
  getWatchNextResults,
  skipWatchNextShow,
  refreshWatchNextRecommends,
  getMoreWatchNextShows,
  submitWatchNextVote,
  addEmailToWNResults,
  updateVoterData,
  suggestionsAutocompleteWN,
  addWatchNextSuggestion,
  addToWatchNextVotelist,
  removeFromWatchNextVotelist,
  suggestionsAutocompleteWNClear,
  clearWatchNextResults,
  saveWatchNextVotelist,
  deleteWNVotelist,
} from '../actions/watchNext';

const wn_id = store.get('wn_id') || '';
const wn_token = store.get('wn_token') || '';
const voter = store.get('voter') || {};

const initialState = {
  dictionary: {},
  wn_id,
  wn_token,
  results: {},
  recommended_content: [],
  recommended_content_preview: [],
  recContentIsEmpty: false,
  skipLoadings: {},
  voter,
  suggestionOptions: [],
  loadings: {
    main: false,
    moreShows: false,
    voteLoadings: {},
    email: false,
    suggestionAutocomplete: false,
    addSuggestion: false,
    votelistLoadings: {},
    saveVoteList: false,
    delete: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // START FORM
    case startWatchNextFrom.types.START:
      state.dictionary = {};
      state.loadings.main = true;
      return state;

    case startWatchNextFrom.types.SUCCESS:
      state.dictionary = action.payload.dictionary;
      state.wn_id = action.payload.unique_id;
      state.wn_token = action.payload.user_token;
      state.loadings.main = false;
      return state;

    case startWatchNextFrom.types.FAIL:
      state.loadings.main = false;
      return state;

    // SUBMIT FORM
    case submitWatchNextForm.types.START:
      state.loadings.main = true;
      return state;

    case submitWatchNextForm.types.SUCCESS:
      state.loadings.main = false;
      return state;

    case submitWatchNextForm.types.FAIL:
      state.loadings.main = false;
      return state;

    // GET RESULTS
    case getWatchNextResults.types.START:
      state.loadings.main = true;
      return state;

    case getWatchNextResults.types.SUCCESS:
      state.results = action.payload.data;
      state.recommended_content = action.payload.recommended_content.slice(5);
      if (!state.recommended_content_preview.length)
        state.recommended_content_preview = action.payload.recommended_content.slice(0, 5);
      state.recContentIsEmpty = !action.payload.recommended_content.length;
      state.loadings.main = false;
      return state;

    case getWatchNextResults.types.FAIL:
      state.loadings.main = false;
      return state;

    // CLEAR RESULTS
    case clearWatchNextResults.types.INIT:
      state.results = initialState.results;
      state.recommended_content = initialState.recommended_content;
      state.recommended_content_preview = initialState.recommended_content_preview;
      state.recContentIsEmpty = initialState.recContentIsEmpty;
      return state;

    // SKIP SHOW
    case skipWatchNextShow.types.INIT: {
      const updatedRecommendedContent = [...state.recommended_content];
      const updatedRecommendedContentPreview = [...state.recommended_content_preview];
      let newContent = null;

      if (action.payload.new_content) {
        newContent = updatedRecommendedContent.shift();
      }

      const contentIndex = updatedRecommendedContentPreview.findIndex(
        content => content.id === action.payload.old_content.id,
      );

      if (newContent) {
        updatedRecommendedContentPreview[contentIndex] = newContent;
      } else if (contentIndex !== -1) {
        updatedRecommendedContentPreview.splice(contentIndex, 1);
      }

      state.recommended_content = updatedRecommendedContent;
      state.recommended_content_preview = updatedRecommendedContentPreview;
      return state;
    }

    // REFRESH RECOMMENDS
    case refreshWatchNextRecommends.types.INIT: {
      const updatedRecommendedContent = state.recommended_content.slice(5);
      const updatedRecommendedContentPreview = state.recommended_content.slice(0, 5);

      state.recommended_content = updatedRecommendedContent;
      state.recommended_content_preview = updatedRecommendedContentPreview;
      state.recContentIsEmpty = !updatedRecommendedContent.length;
      return state;
    }

    // GET MORE SHOWS
    case getMoreWatchNextShows.types.START:
      state.loadings.moreShows = true;
      return state;

    case getMoreWatchNextShows.types.SUCCESS:
      state.results.page = action.payload.page;
      state.recommended_content = [
        ...state.recommended_content,
        ...action.payload.recommended_content,
      ];
      state.recContentIsEmpty = !action.payload.recommended_content.length;
      state.loadings.moreShows = false;
      return state;

    case getMoreWatchNextShows.types.FAIL:
      state.loadings.moreShows = false;
      return state;

    // SUBMIT VOTE
    case submitWatchNextVote.types.START:
      state.loadings.voteLoadings[action.payload.content_id] = true;
      return state;

    case submitWatchNextVote.types.SUCCESS:
      state.wn_token = action.payload.user_token;
      state.results.selected_content = state.results.selected_content.map(content => {
        return content.id === +action.payload.content_id
          ? { ...content, votes: action.payload.votes }
          : content;
      });
      state.loadings.voteLoadings[action.payload.content_id] = false;
      return state;

    case submitWatchNextVote.types.FAIL:
      state.loadings.voteLoadings[action.payload.content_id] = false;
      return state;

    // Add Email To Results
    case addEmailToWNResults.types.START:
      state.loadings.email = true;
      return state;

    case addEmailToWNResults.types.SUCCESS:
      state.results.has_email = true;
      state.loadings.email = false;
      return state;

    case addEmailToWNResults.types.FAIL:
      state.loadings.email = false;
      return state;

    // UPDATE VOTER DATA
    case updateVoterData.types.INIT:
      state.voter = { ...state.voter, ...action.payload.data };
      return state;

    // SUGGESTIONS AUTOCOMPLETE
    case suggestionsAutocompleteWN.types.START:
      state.loadings.suggestionAutocomplete = true;
      return state;

    case suggestionsAutocompleteWN.types.SUCCESS:
      state.suggestionOptions = action.payload.data;
      state.loadings.suggestionAutocomplete = false;
      return state;

    case suggestionsAutocompleteWN.types.FAIL:
      state.loadings.suggestionAutocomplete = false;
      return state;

    case suggestionsAutocompleteWNClear.types.INIT:
      state.suggestionOptions = initialState.suggestionOptions;
      return state;

    // ADD SUGGESTION
    case addWatchNextSuggestion.types.START:
      state.loadings.addSuggestion = true;
      action.payload.content_ids.map(cId => (state.loadings.votelistLoadings[cId] = true));
      return state;

    case addWatchNextSuggestion.types.SUCCESS: {
      const updatedSelectedContent = [...state.results.selected_content];

      action.payload.content.forEach(content => {
        const curIndex = updatedSelectedContent.findIndex(item => item.id === content.id);

        if (curIndex === -1) {
          updatedSelectedContent.unshift(content);
        } else {
          updatedSelectedContent[curIndex].votes = content.votes;
        }
      });

      state.results.selected_content = updatedSelectedContent;
      state.wn_token = action.payload.user_token;
      state.loadings.addSuggestion = false;
      action.payload.content_ids.map(cId => (state.loadings.votelistLoadings[cId] = false));
      return state;
    }
    case addWatchNextSuggestion.types.FAIL:
      state.loadings.addSuggestion = false;
      action.payload.content_ids.map(cId => (state.loadings.votelistLoadings[cId] = false));
      return state;

    // ADD TO VOTELIST
    case addToWatchNextVotelist.types.START:
      state.loadings.votelistLoadings[action.payload.content_id] = true;
      return state;

    case addToWatchNextVotelist.types.SUCCESS:
      state.results.selected_content.push(action.payload.new_content);
      state.loadings.votelistLoadings[action.payload.content_id] = false;
      return state;

    case addToWatchNextVotelist.types.FAIL:
      state.loadings.votelistLoadings[action.payload.content_id] = false;
      return state;

    // REMOVE FROM VOTELIST
    case removeFromWatchNextVotelist.types.START:
      state.loadings.votelistLoadings[action.payload.content_id] = true;
      return state;

    case removeFromWatchNextVotelist.types.SUCCESS:
      state.results.selected_content = state.results.selected_content.filter(
        item => item.id !== action.payload.content_id,
      );
      state.loadings.votelistLoadings[action.payload.content_id] = false;
      return state;

    case removeFromWatchNextVotelist.types.FAIL:
      state.loadings.votelistLoadings[action.payload.content_id] = false;
      return state;

    // SAVE VOTELIST
    case saveWatchNextVotelist.types.START:
      state.loadings.saveVoteList = true;
      return state;

    case saveWatchNextVotelist.types.SUCCESS:
      state.results.completed = true;
      state.results.title = action.payload.title;
      state.loadings.saveVoteList = false;
      return state;

    case saveWatchNextVotelist.types.FAIL:
      state.loadings.saveVoteList = false;
      return state;

    // DELETE VOTELIST
    case deleteWNVotelist.types.START:
      state.loadings.delete = true;
      return state;

    case deleteWNVotelist.types.SUCCESS:
    case deleteWNVotelist.types.FAIL:
      state.loadings.delete = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
