export default {
  section2: {
    title: 'Helping Broadband Providers benefit from the Streaming Revolution. ',
    description1:
      "Our fully managed partner solutions help you grow broadband subscribers, retain customers, and monetize your customers' streaming purchases. Why choose one video product (or none), when you can offer them all without all the cost & headache of trying to do it yourself? ",
    description2: 'Get in touch with us today:',
    description3: {
      part1: 'or schedule an',
      part2: 'Schedule Meeting',
    },
  },
  section3: {
    title:
      'Trusted by over 65 Broadband Providers & Cable Companies, of all sizes, from 500 to 4.5 Million customers.',
    description1: 'Let us show you how we can help you grow.',
  },
  section4: {
    title: 'Find My Bundle',
    description1:
      'Our algorithm provides personalized Live TV Streaming bundle recommendations for prospects and current customers.',
    bullet1: {
      text: 'Increase Internet Sign-Ups ',
    },
    bullet2: {
      text: 'Upsell Higher Speed Internet to current customers',
    },
    bullet3: {
      text: 'Capture Emails for Retargeting',
    },
    bullet4: {
      text: 'Inclusion of Your TV Packages (Optional)',
    },
    description2: '',
    cta: 'Watch Demo',
  },
  section5: {
    title: 'Streaming Marketplace',
    description1:
      'Help your customers discover & subscribe to streaming services beyond Live TV, while leaving the work to us.',
    bullet1: {
      text: '150+ Streaming Services',
    },
    bullet2: {
      text: 'Commission Revenue Share',
    },
    bullet3: {
      text: 'Value-Add to Consumer',
    },
    bullet4: {
      text: 'MyBundle Special Deals',
    },
    cta: 'Watch Demo',
  },
  section6: {
    title: 'Sales & CSR Agent Tools',
    description1:
      'Equip your Reps with the ability to win internet customers & provide streaming recommendations over the phone or in-person.',
    bullet1: {
      text: 'Direct Lead Generation',
    },
    bullet2: {
      text: 'Increased Conversion',
    },
    bullet3: {
      text: 'Offer Video Products',
    },
    bullet4: {
      text: 'Customer Retention',
    },
    cta: 'Watch Demo',
  },
  section7: {
    title: 'Interested?',
    description1:
      'To learn more about our partnership solutions or get started, please send us an email at: ',
    description2: {
      part1: 'or schedule an',
      part2: 'introductory call.',
    },
  },
  section8: {
    title: 'MyBundle Profiles',
    description1:
      'Your customers can create profiles where they select their current services, providing a wide range of features to improve their streaming experience. ',
    bullet1: {
      text:
        'Access to a personalized Streaming Hub where customers can discover and locate content from across their streaming apps.',
    },
    bullet2: {
      text:
        'Customers can easily search for what apps have the TV Shows and Movies they want to watch. ',
    },
    bullet3: {
      text:
        "Customers can plan what want to watch and track what you're currently watching or have already seen. ",
    },
  },
};
