/**
 * Get partner data
 * @param {object} state
 * @returns {object}
 */
const getPartnerData = ({ partner }) => partner.data;

/**
 * Get partner id
 * @param {object} state
 * @returns {number}
 */
const getPartnerId = ({ partner }) => partner.data?.id || 0;

/**
 * Get domain
 * @param {object} state
 * @returns {string}
 */
const getDomain = ({ partner }) => partner.domain;

/**
 * Get partner slug
 * @param {object} state
 * @returns {bool}
 */
const getIsPartnerDomain = ({ partner }) => partner.isPartnerDomain;

export default {
  getPartnerData,
  getPartnerId,
  getDomain,
  getIsPartnerDomain,
};
