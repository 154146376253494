import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// CONSTANTS
import { cloudfront } from '@/constants/common';

// UTILITY
import {
  analyticsChooseSearchItem,
  analyticsLeftSearch,
  analyticsNoSearchResults,
  analyticsSearched,
} from '@/utility/analytics';
import { getPlaceholderUrl } from '@/utility/content';

// STYLES
import {
  AutocompleteWrapper,
  SearchItem,
  NoResults,
  StyledPaper,
  StyledCircularProgress,
} from './styles';

const SearchAutoComplete = React.forwardRef((props, ref) => {
  const {
    loading,
    searchPrevString,
    searchPlaceholder,
    TextFieldProps,
    showMore,
    onKeyDown,
    analyticsLocation,
    showYear,
    hideNoResults,
    ...restProps
  } = props;

  const [open, setOpen] = React.useState(false);
  const [optionHighlighted, setOptionHighlighted] = React.useState(false);
  const [t] = useTranslation();
  const itemWasSelected = React.useRef(false);

  React.useEffect(() => {
    if (restProps.options?.length) {
      const properties = {
        query: restProps.inputValue,
        numberOfItemsDisplayed: restProps.options.length,
        first_item_type: restProps.options[0].type,
        first_item_name: restProps.options[0].title,
        first_item_id: restProps.options[0].id,
        search_location: analyticsLocation,
      };

      analyticsSearched(properties);
    }
  }, [restProps.options]);

  const isNoResults =
    open &&
    !!searchPrevString &&
    !loading &&
    !restProps?.options?.length &&
    !!restProps.inputValue.length;

  React.useEffect(() => {
    if (isNoResults) {
      analyticsNoSearchResults({ query: restProps.inputValue, search_location: analyticsLocation });
    }
  }, [restProps.options, loading]);

  const handleOpen = state => {
    setOpen(state);
  };

  const onOpen = e => {
    itemWasSelected.current = false;
    handleOpen(true);
    restProps.onOpen(e);
  };

  const handleClose = (e, reason) => {
    if (e?.keyCode === 13 && (reason === 'reset' || reason === 'create-option')) {
      return;
    }

    if (reason === 'blur' && !itemWasSelected.current) {
      const properties = {
        query: restProps.inputValue,
        numberOfResults: restProps.options.length,
        search_location: analyticsLocation,
      };

      analyticsLeftSearch(properties);

      if (itemWasSelected.current) itemWasSelected.current = false;
    }

    handleOpen(false);
  };

  const onHighlightChange = (event, value, reason) => {
    if (reason !== 'auto') {
      if (!optionHighlighted) {
        setOptionHighlighted(true);
      }
    } else if (optionHighlighted) {
      setOptionHighlighted(false);
    }
  };

  const handleKeyDown = e => {
    if (!optionHighlighted && e.keyCode === 13 && e.target.value) {
      restProps.onChange(
        null,
        { type: 'show more enter', value: e.target.value },
        'select-option',
        handleOpen,
      );

      return;
    }

    onKeyDown(e);
  };

  const handleChange = (event, value, reason) => {
    if (reason === 'select-option') {
      itemWasSelected.current = true;
    } else if (reason === 'clear') {
      itemWasSelected.current = false;

      return;
    }

    const index = restProps.options.findIndex(option => option.id === value.id);

    const properties = {
      query: restProps.inputValue,
      numberOfItemsDisplayed: restProps.options.length,
      type: value?.type,
      title: value?.title,
      id: value?.id,
      positionOfItem: index + 1,
      search_location: analyticsLocation,
    };

    analyticsChooseSearchItem(properties);

    restProps.onChange(event, value, reason, handleOpen);
  };

  return (
    <AutocompleteWrapper>
      <Autocomplete
        open={open}
        {...restProps}
        PaperComponent={StyledPaper}
        className={clsx(
          restProps.className,
          open && (restProps.options.length || isNoResults || loading) && 'autocomplete-opened',
        )}
        onChange={handleChange}
        onOpen={onOpen}
        onClose={handleClose}
        filterOptions={options => {
          const filteredOptions = options.slice();

          if (showMore && filteredOptions.length) {
            filteredOptions.length -= 1;
            filteredOptions.push({ type: 'show more' });
          }

          return filteredOptions;
        }}
        onHighlightChange={onHighlightChange}
        onKeyDown={handleKeyDown}
        getOptionLabel={option => option.title || ''}
        loading={loading}
        forcePopupIcon={false}
        renderOption={option => {
          let image;
          let title;
          let year;

          switch (option.type) {
            case 'app':
              image = cloudfront + option.icon;
              title = option.title;
              break;
            case 'sr-app':
              image = option.thumbnail;
              title = option.name;
              break;
            case 'movie':
            case 'show':
              image = option.thumbnail || option.icon;
              title = showYear ? option.content_title : option.title;
              year = showYear && option.first_air_date;
              break;
            default:
              image = option.icon;
              title = option.title;
              break;
          }

          if (option.type === 'show more') {
            return (
              <SearchItem className="search-item">
                <div className="search-item__main-info">
                  <div className="search-item__image">
                    <img src={image} alt="" />
                  </div>
                  <div className="search-item__title">{t('buttons.showMore')}</div>
                </div>
                <span className="search-item__type">
                  <i className="icon-arrow-right noselect" />
                </span>
              </SearchItem>
            );
          }

          return (
            <SearchItem className="search-item">
              <div className="search-item__main-info">
                <div className="search-item__image">
                  <img
                    src={image || getPlaceholderUrl({ title: option.title, width: 45, height: 68 })}
                    alt=""
                  />
                </div>
                <div className="search-item__title-section">
                  <div className="search-item__title">{title}</div>
                  {showYear && year && <div className="search-item__year p4">({year})</div>}
                </div>
              </div>
              {option.type !== 'sr-app' && (
                <span className="search-item__type">
                  {option.type === 'link' ? option.kind : option.type}
                </span>
              )}
            </SearchItem>
          );
        }}
        renderInput={params => (
          <TextField
            inputRef={ref}
            {...params}
            placeholder={searchPlaceholder || t('searchAutoComplete.placeholder')}
            size="small"
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="text-color--default" position="start">
                  <i className="icon-search" />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <StyledCircularProgress size={16} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              ...TextFieldProps.InputProps,
            }}
          />
        )}
      />
      {isNoResults && !hideNoResults && <NoResults>{t('searchAutoComplete.noResults')}</NoResults>}
    </AutocompleteWrapper>
  );
});

SearchAutoComplete.defaultProps = {
  blurOnSelect: true,
  loading: false,
  onOpen: () => {},
  searchPlaceholder: '',
  TextFieldProps: {},
  showMore: false,
  onKeyDown: () => {},
  analyticsLocation: '',
  showYear: false,
  hideNoResults: false,
};

SearchAutoComplete.propTypes = {
  loading: PropTypes.bool,
  searchPrevString: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  blurOnSelect: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  showMore: PropTypes.bool,
  onKeyDown: PropTypes.func,
  analyticsLocation: PropTypes.string,
  showYear: PropTypes.bool,
  hideNoResults: PropTypes.bool,
};

export default React.memo(SearchAutoComplete);
