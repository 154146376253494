/**
 * Get channels allow filters
 * @param {object} state
 * @returns {bool}
 */
const getChannelsAllowFilter = ({ appDetails }) => appDetails.channelsData?.allow_filter || false;

export default {
  getChannelsAllowFilter,
};
