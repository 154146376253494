import styled from 'styled-components';
import { rgba } from 'polished';

// CONSTANTS
import { BLACK } from '@/constants/colors';

// UTILITY
// import { breakpoints } from '@/utility/breakpoints';

export const SwiperStyled = styled.div`
  .swiper-container {
    width: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-button-nav {
    position: absolute;
    color: ${rgba(BLACK, 0.5)};
    z-index: 2;
    font-size: 18px;

    margin-top: -22px;

    &::after {
      font-size: inherit;
      font-family: 'icomoon';
    }
  }

  .swiper-button-prev {
    &::after {
      content: '\\e926';
    }
  }

  .swiper-button-next {
    &::after {
      content: '\\e909';
    }
  }
`;

export default SwiperStyled;
