import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';
import qs from 'query-string';

// CONSTANTS
import {
  MAGIC_LINK_USE,
  AUTH_GET_USER,
  AUTH_LOGIN_SAML,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_RP_SEND_EMAIL,
  AUTH_RP_UPDATE,
} from '@/constants/api';
import appsListStatuses from '@/constants/app';

// UTILITY
import bugsnag from '@/utility/bugsnagClient';
import store from '@/utility/store';
import getErrorMessage from '@/utility/errors';
import { analyticsAuth, customAnalyticsEvent } from '@/utility/analytics';
import { clearCache } from '@/utility/cache';
import { getPartnerData, showErrorNotification } from '@/utility/saga';
import { getSearchParams } from '@/utility/common';
import getLink from '@/utility/routes';
import { getSocialSignInFnByProvider } from '@/utility/firebase';
import { removeURLParameter } from '@/utility/path';

// STORE
import { AuthActions, NotifActions, UserActions } from '@/store/actions';
import { setAuthorizationToken, deleteAuthorizationToken } from '@/store/configDefaultAPI';
import {
  PartnerSelectors,
  WatchNextSelectors,
  AuthSelectors,
  PromotionSelectors,
  OnboardingSelectors,
} from '@/store/selectors';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  login,
  auth0Login,
  registerUser,
  logout,
  resetPassSendEmail,
  resetPassUpdate,
  authDrawerOpen,
  loginWithFirebaseToken,
} = AuthActions;
const { userGetCards } = UserActions;
const { pushSuccessNotificationAction } = NotifActions;

function* setLocalSaga(token, tokenType) {
  yield store.set('token', token);
  yield store.set('token_type', tokenType);
}

function* checkPartner(data) {
  const partnerData = yield call(getPartnerData);
  const curPartnerId = partnerData.id || 0;

  analyticsAuth({ email: data.email, user_id: data.id });

  if (curPartnerId !== data.partner?.id) {
    const nickname = data.nickname;
    const userId = data.id;

    customAnalyticsEvent('Logged In on Another Partner', {
      nickname,
      userId,
      original_partner: data.partner?.id,
      login_partner: curPartnerId,
    });
  }
}

export function* setUserToBugsnag(
  action,
  { user, clearUser = false } = { user: null, clearUser: false },
) {
  const { unique_id, anonymousId: anonymous_id, user: userData } = yield select(
    AuthSelectors.getAuthData,
  );
  const data = user || userData;
  const needUpdateUser = !clearUser && data;

  if (needUpdateUser) {
    const name = [data.first_name, data.last_name].filter(Boolean).join(' ');

    bugsnag.setUser(data.id, data.email, name);
    bugsnag.clearMetadata('user');
  } else {
    bugsnag.setUser();
    bugsnag.addMetadata('user', {
      unique_id,
      anonymous_id: action?.payload?.id || anonymous_id,
    });
  }
}

export function* setAuth(data) {
  yield call(setAuthorizationToken, data.token, data.token_type);
  yield call(setLocalSaga, data.token, data.token_type);

  yield call(checkPartner, data.user);
  yield call(setUserToBugsnag, null, { user: data.user });
}

export function* logoutSaga(action) {
  const callback = action?.payload?.callback;
  const needToReload = action?.payload?.reload || false;

  const params = getSearchParams(document.location.search);
  const anlParam = params.get('anl');
  const anlStoredParam = store.get('anl');
  const AnlIsOff = !anlParam && anlStoredParam && anlStoredParam === 'off';

  yield call(clearCache, false, true);
  yield call(deleteAuthorizationToken);
  yield call(setUserToBugsnag, null, { clearUser: true });

  if (callback?.call) callback();

  if (AnlIsOff || needToReload) window.location.reload(true);
}

export function* getMe() {
  yield put(login.start());

  const token = yield store.get('token');
  const loginAsUser = yield store.get('login_as_user');

  if (token) {
    try {
      const response = yield axios.get(AUTH_GET_USER);

      if (response) {
        const { data: respData } = response;

        yield call(checkPartner, respData.user);
        yield call(setUserToBugsnag, null, { user: respData.user });

        yield put(login.success({ user: respData.user }));

        if (loginAsUser) {
          yield put(
            pushSuccessNotificationAction(
              t('notification.loginAsUser', { name: respData.user.nickname }),
            ),
          );
        }

        yield put(userGetCards.init());

        return respData.user;
      }
    } catch (error) {
      yield call(showErrorNotification, error);

      yield call(logoutSaga);

      yield put(login.fail());
    }
  }
}

export function* registerSaga(action) {
  yield put(registerUser.start());

  const { data: payloadData, callback } = action.payload;
  const partner_id = yield select(PartnerSelectors.getPartnerId);
  const unique_id = yield select(AuthSelectors.getUniqueId);
  const recommendation_token = yield select(WatchNextSelectors.getWNToken);
  const promoCodes = yield select(PromotionSelectors.getPromoCodes);
  let apps = yield select(OnboardingSelectors.getSelectedApps);

  if (payloadData?.apps?.length) apps = payloadData.apps;

  const promoData = promoCodes.length ? promoCodes[promoCodes.length - 1] : {};

  const data = {
    ...payloadData,
    ...promoData,
    partner_id: payloadData.partner_id || partner_id || '',
    unique_id: payloadData.unique_id || unique_id,
    recommendation_token: payloadData.recommendation_token || recommendation_token,
    apps: apps.map(app => ({ app_id: app.id || app.app_id, status: appsListStatuses.have })),
  };

  try {
    const { data: respData } = yield axios.post(AUTH_REGISTER, data);

    yield call(setAuth, respData);

    store.remove('my_apps');
    store.set('show_reg_fmb', true);

    analyticsAuth({ email: respData.user.email, user_id: respData.user.id });

    if (!respData.register && !data.isSocialLogin) {
      yield put(pushSuccessNotificationAction(t('notification.autoLogin')));
    }

    yield put(registerUser.success(respData));

    if (callback) {
      callback({
        user_id: respData.user.id,
        partner_id: respData.user?.partner?.id,
        register: !!respData.register,
        userCard: respData.user?.card,
      });
    }
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(registerUser.fail());
  }
}

export function* getTokenByMagicLink(action) {
  const { token } = action;
  const url = getLink(MAGIC_LINK_USE, { token });

  yield put(login.start());

  try {
    const redirectUrl = removeURLParameter(window.location.href, ['mt', 'anl'], false);
    const { data: respData } = yield axios.get(url);

    yield call(setAuth, respData.payload);

    if (respData.action === 'login_as_user') {
      store.set('login_as_user', true);
    }

    window.location.href = redirectUrl;
  } catch (error) {
    yield call(showErrorNotification, t('notification.tokenAlreadyUsed'));

    yield put(login.fail());
  }
}

export function* getTokenByFirebaseAccTokenSaga(action) {
  const { firebase_token } = action;
  const redirectUrl = window.location.href.split('?')[0];

  yield put(loginWithFirebaseToken.start());

  window.history.replaceState({}, '', redirectUrl);

  yield call(registerSaga, { payload: { data: { firebase_token, isSocialLogin: true } } });

  yield put(loginWithFirebaseToken.stop());
}

export function* authCheckTokenSaga() {
  const params = getSearchParams(document.location.search);
  const magicToken = params.get('mt');
  const accessToken = params.get('acc-token'); // Access firebase token for partners social login
  const hasResetPasswordParam = params ? params.has('reset_password') : '';
  const hasLogout = params ? params.has('logout') : false;
  const needToLogout = hasLogout || hasResetPasswordParam;
  const token = yield store.get('token');
  const tokenType = yield store.get('token_type');

  if (needToLogout) {
    if (token) {
      yield call(logoutSaga, {
        payload: {
          callback: () => {
            removeURLParameter(window.location.href, 'logout');

            window.location.reload(true);
          },
        },
      });
    }

    return;
  }

  if (magicToken) {
    // if (token) yield call(logoutSaga);

    yield call(getTokenByMagicLink, { token: magicToken });

    return;
  }

  if (accessToken) {
    if (token) yield call(logoutSaga);

    yield call(getTokenByFirebaseAccTokenSaga, { firebase_token: accessToken });

    return;
  }

  if (token) {
    try {
      yield call(setAuthorizationToken, token, tokenType);
      yield call(getMe);
    } catch (error) {
      yield call(showErrorNotification, error);

      yield put(logout.init());
    }
  }
}

export function* auth0Saga(action) {
  yield put(auth0Login.start());

  const {
    data: { SAMLRequestSearchParams },
  } = action.payload;

  try {
    const { data: respData } = yield axios.get(`${AUTH_LOGIN_SAML}${SAMLRequestSearchParams}`);

    yield put(auth0Login.success());

    window.location.replace(qs.stringifyUrl({ url: respData.url, query: respData.data }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(auth0Login.fail());
  }
}

export function* authSocialLoginSaga(action) {
  const { providerId, data: payloadData = {}, callback } = action.payload;

  const socialSignInFn = getSocialSignInFnByProvider(providerId);

  if (socialSignInFn) {
    yield put(registerUser.start());

    try {
      const { user } = yield call(socialSignInFn);

      yield call(registerSaga, {
        payload: {
          data: { ...payloadData, firebase_token: user.accessToken, isSocialLogin: true },
          callback,
        },
      });
    } catch (error) {
      yield call(showErrorNotification, error);

      yield put(registerUser.fail());
    }
  }
}

export function* authSaga(action) {
  yield put(login.start());

  const {
    data: { SAMLRequestSearchParams, ...data },
    callback,
  } = action.payload;

  try {
    const { data: respData } = yield axios.post(AUTH_LOGIN, data);

    yield call(setAuth, respData);

    if (callback) {
      callback({
        user_id: respData.user.id,
        partner_id: respData.user?.partner?.id,
        register: false,
        userCard: respData.user?.card,
      });
    }

    if (SAMLRequestSearchParams) {
      yield put(auth0Login.init({ data: { SAMLRequestSearchParams } }));
    } else {
      yield put(login.success({ user: respData.user }));
    }
  } catch (error) {
    const errorStatus = error.response?.status;

    if (errorStatus !== 422) {
      yield call(showErrorNotification, error);
    }

    if (errorStatus === 300) {
      yield put(
        authDrawerOpen.init({
          data: {
            screen: 'registration',
            email: data.email,
          },
        }),
      );
    }

    yield put(login.fail({ error: errorStatus === 422 ? getErrorMessage(error) : '' }));
  }
}

export function* resetPassSendEmailSaga(action) {
  yield put(resetPassSendEmail.start());

  const { data } = action.payload;

  try {
    yield axios.post(AUTH_RP_SEND_EMAIL, data);

    yield put(
      pushSuccessNotificationAction(t('notification.resetPassSuccess'), {
        autoHideDuration: undefined,
      }),
    );

    yield put(resetPassSendEmail.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(resetPassSendEmail.fail());
  }
}

export function* resetPassUpdateSaga(action) {
  yield put(resetPassUpdate.start());

  const { data, callback } = action.payload;

  try {
    const { data: respData } = yield axios.post(AUTH_RP_UPDATE, data);

    yield call(setAuth, respData);

    yield put(pushSuccessNotificationAction(t('notification.resetPassUpdateSuccess')));

    if (callback) callback();

    yield put(resetPassUpdate.success({ user: respData.user }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(resetPassUpdate.fail());
  }
}
