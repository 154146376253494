import { createAction } from '@/utility/actions';

import {
  STREAMING_REWARDS_FUNDS_PAY,
  STREAMING_REWARDS_FUNDS_RESET_STATE,
  STREAMING_REWARDS_FUNDS_CANCEL_RECURRING_PAYMENT,
  STREAMING_REWARDS_FUNDS_GET_CLIENT_SECRET,
  STREAMING_REWARDS_FUNDS_ADD_FUNDS,
  STREAMING_REWARDS_FUNDS_ADD_FUNDS_BY_PM,
  STREAMING_REWARDS_FUNDS_RETRIEVE_PAYMENT_STATUS,
} from './types';

export const payFunds = createAction(STREAMING_REWARDS_FUNDS_PAY);

export const cancelRecurringPayment = createAction(
  STREAMING_REWARDS_FUNDS_CANCEL_RECURRING_PAYMENT,
);

export const resetState = createAction(STREAMING_REWARDS_FUNDS_RESET_STATE);

/**
 * getSRClientSecret - data(object)
 */
export const getSRClientSecret = createAction(STREAMING_REWARDS_FUNDS_GET_CLIENT_SECRET);

/**
 * addSRFunds - confirmPayment, onSuccess, onPending
 */
export const addSRFunds = createAction(STREAMING_REWARDS_FUNDS_ADD_FUNDS);

/**
 * addSRFundsByPM - paymentData, confirmCardPayment, onSuccess, onPending
 */
export const addSRFundsByPM = createAction(STREAMING_REWARDS_FUNDS_ADD_FUNDS_BY_PM);

/**
 * retrievePaymentStatus - params ({ payment_intent | transaction_id , payment_method }),
 * onSuccess - callback function, OnPending
 */
export const retrievePaymentStatus = createAction(STREAMING_REWARDS_FUNDS_RETRIEVE_PAYMENT_STATUS);

export default {
  resetState,
  payFunds,
  cancelRecurringPayment,
  getSRClientSecret,
  addSRFunds,
  addSRFundsByPM,
  retrievePaymentStatus,
};
