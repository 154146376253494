import React from 'react';
import { matchPath } from 'react-router-dom';

// ROUTES
import { usePrefetchRoutes } from '@/routes';

// UTILITY
import { routeEnhance } from '@/utility/routes';

const usePrefetch = pagePath => {
  const prefetchedRoutes = usePrefetchRoutes();
  const routeEnhanced = React.useMemo(() => routeEnhance(pagePath), [pagePath]);

  const route = React.useMemo(
    () =>
      prefetchedRoutes?.find(props => {
        return matchPath(
          {
            ...props,
          },
          routeEnhanced.pathname,
        );
      }),
    [prefetchedRoutes, routeEnhanced.pathname],
  );

  const handlePrefetch = () => {
    return route?.component?.prefetch();
  };

  return {
    handlePrefetch,
  };
};

export default usePrefetch;
