import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

export const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paper {
    width: 300px;
  }

  .drawer {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      min-height: 70px;

      padding: 0 20px;

      button {
        margin-right: -12px;

        svg {
          font-size: 22px;
        }
      }
    }
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 40px;

  .subtitle {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export default DrawerStyled;
