import { css } from 'styled-components';

const nctcvipStyles = css`
  .nctc_vip {
    .header {
      .logo-partner {
        a img {
          max-height: 60px;
        }
      }
    }
  }
`;

export default nctcvipStyles;
