import { createAction } from '@/utility/actions';

import {
  WATCH_NEXT_START_FORM,
  WATCH_NEXT_SUBMIT_FORM,
  WATCH_NEXT_GET_RESULTS,
  WATCH_NEXT_SKIP_CONTENT,
  WATCH_NEXT_REFRESH_RECOMMENDS,
  WATCH_NEXT_UPDATE_REC_CONTENT,
  WATCH_NEXT_GET_MORE_SHOWS,
  WATCH_NEXT_SUBMIT_VOTE,
  WATCH_NEXT_ADD_EMAIL,
  WATCH_NEXT_UPDATE_VOTER_DATA,
  WATCH_NEXT_AUTOCOMPLETE,
  WATCH_NEXT_AUTOCOMPLETE_CLEAR,
  WATCH_NEXT_ADD_SUGGESTION,
  WATCH_NEXT_ADD_TO_VOTELIST,
  WATCH_NEXT_REMOVE_FROM_VOTELIST,
  WATCH_NEXT_CLEAR_RESULTS,
  WATCH_NEXT_SAVE_VOTELIST,
  WATCH_NEXT_DELETE_VOTELIST,
} from './types';

/**
 * submitWatchNextForm - params: token
 */
export const startWatchNextFrom = createAction(WATCH_NEXT_START_FORM);

/**
 * submitWatchNextForm - params: id, data
 */
export const submitWatchNextForm = createAction(WATCH_NEXT_SUBMIT_FORM);

/**
 * submitWatchNextForm - params: wn_id, token, onError
 */
export const getWatchNextResults = createAction(WATCH_NEXT_GET_RESULTS);

/**
 * clearWatchNextResults - params: void
 */
export const clearWatchNextResults = createAction(WATCH_NEXT_CLEAR_RESULTS);

/**
 * skipWatchNextShow - params: old_content, new_content
 */
export const skipWatchNextShow = createAction(WATCH_NEXT_SKIP_CONTENT);

/**
 * refreshWatchNextRecommends
 */
export const refreshWatchNextRecommends = createAction(WATCH_NEXT_REFRESH_RECOMMENDS);

/**
 * updateRecContent - params: data
 */
export const updateRecContent = createAction(WATCH_NEXT_UPDATE_REC_CONTENT);

/**
 * getMoreWatchNextShows - params: wn_id, token, page
 */
export const getMoreWatchNextShows = createAction(WATCH_NEXT_GET_MORE_SHOWS);

/**
 * submitWatchNextVote - params: wn_id, data
 */
export const submitWatchNextVote = createAction(WATCH_NEXT_SUBMIT_VOTE);

/**
 * updateVoterData - params: data
 */
export const updateVoterData = createAction(WATCH_NEXT_UPDATE_VOTER_DATA);

/**
 * addEmailToWNResults - params: wn_id, token, email
 */
export const addEmailToWNResults = createAction(WATCH_NEXT_ADD_EMAIL);

/**
 * suggestionsAutocompleteWN - params: wn_id, params
 */
export const suggestionsAutocompleteWN = createAction(WATCH_NEXT_AUTOCOMPLETE);

/**
 * suggestionsAutocompleteWNClear - params: void
 */
export const suggestionsAutocompleteWNClear = createAction(WATCH_NEXT_AUTOCOMPLETE_CLEAR);

/**
 * addWatchNextSuggestion - params: wn_id, data
 */
export const addWatchNextSuggestion = createAction(WATCH_NEXT_ADD_SUGGESTION);

/**
 * addToWatchNextVotelist - params: wn_id, content_id, new_content, callback
 */
export const addToWatchNextVotelist = createAction(WATCH_NEXT_ADD_TO_VOTELIST);

/**
 * removeFromWatchNextVotelist - params: wn_id, data
 */
export const removeFromWatchNextVotelist = createAction(WATCH_NEXT_REMOVE_FROM_VOTELIST);

/**
 * saveWatchNextVotelist - params: wn_id, data
 */
export const saveWatchNextVotelist = createAction(WATCH_NEXT_SAVE_VOTELIST);

/**
 * deleteWNVotelist - params: wn_id, onSuccess
 */
export const deleteWNVotelist = createAction(WATCH_NEXT_DELETE_VOTELIST);

export default {
  startWatchNextFrom,
  submitWatchNextForm,
  getWatchNextResults,
  skipWatchNextShow,
  refreshWatchNextRecommends,
  updateRecContent,
  getMoreWatchNextShows,
  submitWatchNextVote,
  addEmailToWNResults,
  updateVoterData,
  suggestionsAutocompleteWN,
  suggestionsAutocompleteWNClear,
  addWatchNextSuggestion,
  addToWatchNextVotelist,
  removeFromWatchNextVotelist,
  clearWatchNextResults,
  saveWatchNextVotelist,
  deleteWNVotelist,
};
