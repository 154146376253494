import { createAction } from '@/utility/actions';

import { COUNTIES_GET, COUNTIES_GET_BY_ADDRESS, COUNTIES_CLEAN_STATE } from './types';

/**
 * getCounties - params: zipcode, partner_id_fk, unique_id
 */
export const getCounties = createAction(COUNTIES_GET);

/**
 * getCountiesByAddress - params: address, unique_id, zipcode
 */
export const getCountiesByAddress = createAction(COUNTIES_GET_BY_ADDRESS);

export const cleanCountiesState = createAction(COUNTIES_CLEAN_STATE);

export default {
  getCounties,
  getCountiesByAddress,
  cleanCountiesState,
};
