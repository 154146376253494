export default {
  hero: {
    searchedFor: 'Maybe you searched for',
  },
  services: {
    title: 'You can watch this movie on the following services',
  },
  similarContent: {
    title: "Similar Movies We Think You'd Like",
  },
  notifyMe: {
    title: 'Get notified for any updates or changes regarding this movie.',
    subtitle:
      "You may experience content moving between streaming services, price fluctuations, and changes in available content. Don't worry, we've got you covered! Simply enter your email to receive notifications for all updates and changes.",
  },
};
