import produce from 'immer';

// CONSTANTS
import { contentStatuses } from '@/constants/watchList';

// UTILITY
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

// ACTIONS
import { cleanHomeAuthContent } from '../actions/home';
import { logout } from '../actions/auth';
import { changeContentStatus } from '../actions/content';
import { addToWatchList, removeFromWatchList } from '../actions/watchList';
import { deleteWNVotelist } from '../actions/watchNext';
import {
  userEditProfile,
  userGetResults,
  userCleanResults,
  userAppsAutocomplete,
  userGetApps,
  userUpdateApps,
  userChangeAppPrice,
  userRemoveApp,
  userGetRecommendations,
  userCleanRecommendations,
  userGetRecommendedApps,
  userAddCard,
  userAddPaymentMethod,
  userRemovePaymentMethod,
  getIOByUniqueId,
  userChangePassword,
  userGetCards,
  userSetDefaultCard,
  userGetSRWidgetData,
  userUpdateSRBalance,
  retrievePaymentMethodStatus,
} from '../actions/user';

const initialState = {
  results: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 6,
    },
  },
  apps: [],
  recommended: [],
  autocompleteData: [],
  recommendations: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 12,
    },
  },
  internetOffers: [],
  cards: [],
  virtualCard: null,
  streamingRewardWidget: null,
  loadings: {
    editUser: false,
    results: false,
    apps: false,
    recommended: false,
    updatePrice: {},
    autocompleteData: false,
    recommendations: false,
    addCard: false,
    retrievePMStatus: false,
    addPaymentMethod: false,
    removePaymentMethod: false,
    internetOffers: false,
    changePassword: false,
    cards: false,
    setDefaultCard: false,
    virtualCard: false,
    streamingRewardWidget: false,
  },
  loading: false,
  errors: {
    addPaymentMethod: null,
  },
  warnings: {
    addPaymentMethod: null,
  },
};

function sortAppsBySubscription(apps) {
  return apps.sort((a, b) => b.user_subscribed - a.user_subscribed);
}

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // EDIT USER PROFILE
    case userEditProfile.types.START:
      state.loadings.editUser = true;
      return state;

    case userEditProfile.types.SUCCESS:
      state.loadings.editUser = false;
      return state;

    case userEditProfile.types.FAIL:
      state.loadings.editUser = false;
      return state;

    // GET RESULTS
    case userGetResults.types.START:
      state.loadings.results = true;
      return state;

    case userGetResults.types.SUCCESS:
      state.results.data = action.payload.data;
      state.results.meta.current_page = action.payload.current_page;
      state.results.meta.last_page = action.payload.last_page;
      state.results.meta.per_page = action.payload.per_page;
      state.loadings.results = false;
      return state;

    case userGetResults.types.FAIL:
      state.loadings.results = false;
      return state;

    // CLEAN RESULTS
    case userCleanResults.types.INIT:
      state.results = initialState.results;
      return state;

    // APPS AUTOCOMPLETE
    case userAppsAutocomplete.types.START:
      state.loadings.autocompleteData = true;
      return state;

    case userAppsAutocomplete.types.SUCCESS:
      state.autocompleteData = action.payload.data;
      state.loadings.autocompleteData = false;
      return state;

    case userAppsAutocomplete.types.FAIL:
      state.loadings.autocompleteData = false;
      return state;

    // GET APPS
    case userGetApps.types.START:
      state.loadings.apps = true;
      return state;

    case userGetApps.types.SUCCESS:
      state.apps = action.payload.data;
      state.loadings.apps = false;
      return state;

    case userGetApps.types.FAIL:
      state.loadings.apps = false;
      return state;

    // UPDATE APPS
    case userUpdateApps.types.INIT:
      state.apps = sortAppsBySubscription(action.payload.apps);
      return state;

    // CHANGE APP PRICE
    case userChangeAppPrice.types.START:
      state.loadings.updatePrice[action.payload.id] = true;
      return state;

    case userChangeAppPrice.types.SUCCESS:
      state.apps = state.apps.map(app => {
        if (app.id === action.payload.data.id) {
          return { ...app, user_price: action.payload.data.price };
        }

        return app;
      });
      state.loadings.updatePrice[action.payload.data.id] = false;
      return state;

    case userChangeAppPrice.types.FAIL:
      state.loadings.updatePrice[action.payload.id] = false;
      return state;

    // REMOVE APP FROM MY APPS
    case userRemoveApp.types.INIT:
      state.apps = sortAppsBySubscription(
        state.apps.filter(app => app.id !== action.payload.app_id),
      );
      return state;

    // GET RECOMMENDATIONS
    case userGetRecommendations.types.START:
      state.loadings.recommendations = true;
      return state;

    case userGetRecommendations.types.SUCCESS:
      state.recommendations.data = action.payload.data;
      state.recommendations.meta = action.payload.meta;
      state.loadings.recommendations = false;
      return state;

    case userGetRecommendations.types.FAIL:
      state.loadings.recommendations = false;
      return state;

    // CLEAN RECOMMENDATIONS
    case userCleanRecommendations.types.INIT:
      state.recommendations = initialState.recommendations;
      return state;

    // CLEAN RECOMMENDATIONS
    case deleteWNVotelist.types.SUCCESS:
      state.recommendations.data = state.recommendations.data.filter(
        recList => recList.unique_id !== action.payload.wn_id,
      );
      return state;

    // GET RECOMMENDED APPS
    case userGetRecommendedApps.types.START:
      state.loadings.recommended = true;
      return state;

    case userGetRecommendedApps.types.SUCCESS:
      state.recommended = action.payload.data;
      state.loadings.recommended = false;
      return state;

    case userGetRecommendedApps.types.FAIL:
      state.loadings.recommended = false;
      return state;

    // GET CARDS
    case userGetCards.types.START:
      state.loadings.cards = true;
      state.loadings.virtualCard = true;
      return state;

    case userGetCards.types.SUCCESS:
      state.cards = action.payload?.cards || [];

      if (action.payload?.virtual_card) {
        state.virtualCard = state.virtualCard
          ? { ...state.virtualCard, ...action.payload?.virtual_card }
          : action.payload?.virtual_card;
      } else {
        state.virtualCard = null;
      }

      state.loadings.cards = false;
      state.loadings.virtualCard = false;
      return state;

    case userGetCards.types.FAIL:
      state.loadings.cards = false;
      state.loadings.virtualCard = false;
      return state;

    // SET DEFAULT CARD
    case userSetDefaultCard.types.START:
      state.loadings.setDefaultCard = true;
      return state;

    case userSetDefaultCard.types.SUCCESS:
      state.cards = state.cards.map(card => ({
        ...card,
        default: card.payment_method_id === action.payload.payment_method_id,
      }));
      state.loadings.setDefaultCard = false;
      return state;

    case userSetDefaultCard.types.FAIL:
      state.loadings.setDefaultCard = false;
      return state;

    // ADD CARD
    case userAddCard.types.START:
      state.loadings.addCard = true;
      return state;

    case userAddCard.types.SUCCESS:
      state.cards = state.cards.map(card => ({ ...card, default: false })).concat(action.payload);
      state.loadings.addCard = false;
      return state;

    case userAddCard.types.FAIL:
      state.loadings.addCard = false;
      return state;

    // RETRIEVE PAYMENT METHOD STATUS
    case retrievePaymentMethodStatus.types.START:
      state.loadings.retrievePMStatus = true;
      return state;

    case retrievePaymentMethodStatus.types.SUCCESS:
      state.loadings.retrievePMStatus = false;
      return state;

    case retrievePaymentMethodStatus.types.FAIL:
      state.loadings.retrievePMStatus = false;

      if (action.payload.warning) {
        state.warnings.payment = action.payload.message;
      } else {
        state.errors.payment = action.payload.message;
      }

      return state;

    // ADD PAYMENT METHOD
    case userAddPaymentMethod.types.START:
      state.warnings.addPaymentMethod = null;
      state.errors.addPaymentMethod = null;
      state.loadings.addPaymentMethod = true;
      return state;

    case userAddPaymentMethod.types.SUCCESS:
      if (
        !state.cards.find(card => card.payment_method_id === action.payload.card.payment_method_id)
      ) {
        if (action.payload.card.default) {
          state.cards = state.cards
            .map(card => ({ ...card, default: false }))
            .concat(action.payload.card);
        } else {
          state.cards = state.cards.concat(action.payload.card);
        }
      }

      state.loadings.addPaymentMethod = false;
      return state;

    case userAddPaymentMethod.types.FAIL:
      state.loadings.addPaymentMethod = false;

      if (action.payload.warning) {
        state.warnings.addPaymentMethod = action.payload.message;
      } else {
        state.errors.addPaymentMethod = action.payload.message;
      }

      return state;

    // REMOVE PAYMENT METHOD
    case userRemovePaymentMethod.types.START:
      state.loadings.removePaymentMethod = true;
      return state;

    case userRemovePaymentMethod.types.SUCCESS:
      state.cards = state.cards.filter(card => card.id !== action.payload.card);
      state.loadings.removePaymentMethod = false;
      return state;

    case userRemovePaymentMethod.types.FAIL:
      state.loadings.removePaymentMethod = false;
      return state;

    // UPD VIRTUAL CARD BALANCE
    case userUpdateSRBalance.type:
      if (state.virtualCard) {
        state.virtualCard.balance = action.payload.balance;
      }
      return state;

    // GET STREAMING REWARD WIDGET
    case userGetSRWidgetData.types.START:
      state.loadings.streamingRewardWidget = true;
      return state;

    case userGetSRWidgetData.types.SUCCESS:
      state.loadings.streamingRewardWidget = false;
      state.streamingRewardWidget = action.payload;
      return state;

    case userGetSRWidgetData.types.FAIL:
      state.loadings.streamingRewardWidget = false;
      return state;

    // GET POPULAR CONTENT
    case getIOByUniqueId.types.START:
      state.loadings.internetOffers = true;
      return state;

    case getIOByUniqueId.types.SUCCESS:
      state.internetOffers = action.payload.data;
      state.loadings.internetOffers = false;
      return state;

    case getIOByUniqueId.types.FAIL:
      state.loadings.internetOffers = false;
      return state;

    // CLEAN HOME CONTENT
    case cleanHomeAuthContent.types.INIT:
      state.apps = initialState.apps;
      state.internetOffers = initialState.internetOffers;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;
      const {
        recommendations: { data },
      } = state;

      state.recommendations.data = data.map(recList => {
        recList.content = recList.content.map(item => {
          if (item.id === content_id) {
            const wlAction = isAdd ? addWatchlists : removeWatchlists;
            const watchlists = wlAction(item.watchlists, watch_lists);

            return { ...item, watchlists, in_watchlist: !!watchlists.length };
          }

          return item;
        });

        return recList;
      });

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;

      const shouldBeRemoved =
        status === contentStatuses.watched || status === contentStatuses.notInterested;

      if (!state.recommendations.data.length || shouldBeRemoved) {
        return state;
      }

      state.recommendations.data = state.recommendations.data.map(recList => {
        recList.content = recList.content.map(item =>
          updateUserContentStatus(item, content_id, status),
        );

        return recList;
      });

      return state;
    }

    // CHANGE PASSWORD
    case userChangePassword.types.START:
      state.loadings.changePassword = true;
      return state;

    case userChangePassword.types.SUCCESS:
    case userChangePassword.types.FAIL:
      state.loadings.changePassword = false;
      return state;

    // LOGOUT
    case logout.types.INIT:
      return initialState;

    default:
      return state;
  }
});

export default reducer;
